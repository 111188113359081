import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useInput } from '../../hooks/useInput';

import { SIGN_UP_ROUTES, ROUTE_TERMS_OF_USE } from '../../constants/routes';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../constants/regularExpressions';
import { isNotEmpty, valueIsMatching } from '../../utils/validations';
import { FormInputGroup } from '../FormInputGroup/FormInputGroup';
import { PasswordInput } from '../FormInputGroup/PasswordInput';
import { PasswordHint } from '../PasswordHint/PasswordHint';

import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { ButtonContainer, TermsOfUseText, TermsOfUseWrapper } from './style';

import { InputSuccessMessage } from '../FormInputGroup/style';

import { useAppDispatch } from '../../store/store';
import { signUp } from '../../store/actions/auth.action';
import { AdditionalInfo } from '../Reusable-styled-elements/AdditionalInfo';
import { GoogleLoginForm } from '../GoogleLoginForm/GoogleLoginForm';
import { Language } from '../../utils/languages/general';
import { getRegistrationTexts } from '../../services/languages.service';
export interface RegisterFormProps {
  customEmailRegex?: RegExp;
  platformName: string;
  invitedEmail?: string;
  registrationLanguage?: Language;
  navigateToLogin: () => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  customEmailRegex,
  platformName,
  navigateToLogin,
  invitedEmail,
  registrationLanguage,
}) => {
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const {
    value: email,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    ...restEmailInputProps
  } = useInput({
    defaultValue: invitedEmail ?? '',
    validate: (value) => isNotEmpty(value) && valueIsMatching(value, customEmailRegex ? customEmailRegex : EMAIL_REGEX),
  });

  const {
    value: password,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    ...restPasswordInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value) && valueIsMatching(value, PASSWORD_REGEX),
  });
  const {
    value: confirmPassword,
    hasError: confirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    ...restConfirmPasswordInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value),
  });

  const navigate = useNavigate();
  const disaptch = useAppDispatch();
  let search = '';
  if (localStorage.getItem('desiredUrl')) search = localStorage.getItem('desiredUrl') ?? '';
  //if (search != '') search = '?' + search;
  //: string = localStorage.getItem('desiredUrl') ? localStorage.getItem('desiredUrl').toString() : ''; //?.split('?')[1] || '';
  const formIsValid = emailIsValid && passwordIsValid && confirmPasswordIsValid;

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formIsValid) {
      restEmailInputProps.onBlur();
      restPasswordInputProps.onBlur();
      restConfirmPasswordInputProps.onBlur();
      return;
    }

    const res = await disaptch(signUp({ email, password, search, platformName, language: registrationLanguage }));
    if (res.meta.requestStatus === 'fulfilled') navigate(SIGN_UP_ROUTES.CONFIRM, { state: { email } });
  };
  const texts = getRegistrationTexts(registrationLanguage);
  const passwordsDoesntMatch = isNotEmpty(password) && isNotEmpty(confirmPassword) && password !== confirmPassword;

  let confirmPasswordError = '';
  if (passwordsDoesntMatch) {
    confirmPasswordError = texts.passwordMatch;
  }

  const renderPasswordHint = isTyping && password && (
    <PasswordHint password={password} registrationLanguage={registrationLanguage} />
  );
  const linkStyle: React.CSSProperties = {
    color: 'var(--almost-black)',
    fontSize: '13px',
  };

  return (
    <form onSubmit={submitHandler}>
      <FormInputGroup
        label={texts.email}
        required
        errorMessage={emailHasError ? texts.fillValidEmailError : ''}
        isValid={emailIsValid}
        disabled={!!invitedEmail}
        autoComplete={invitedEmail ? 'off' : 'on'}
        placeholder="email@domain.com"
        value={email}
        {...restEmailInputProps}
      />
      <div style={{ position: 'relative' }}>
        <PasswordInput
          label={texts.password}
          required
          autoComplete={invitedEmail ? 'off' : 'on'}
          errorMessage={passwordHasError && password.length > 0 ? texts.fillValidPasswordError : ''}
          isValid={passwordIsValid}
          name="password"
          value={password}
          toggleInputHandler={(arg) => {
            setIsTyping(arg);
            restPasswordInputProps.onBlur();
          }}
          {...restPasswordInputProps}
        />
        {renderPasswordHint}
      </div>
      <PasswordInput
        label={texts.confirmPassword}
        required
        autoComplete={invitedEmail ? 'off' : 'on'}
        errorMessage={confirmPasswordError}
        isValid={!passwordsDoesntMatch}
        name="Confirm Password"
        value={confirmPassword}
        {...restConfirmPasswordInputProps}
      />

      {isNotEmpty(password) &&
      valueIsMatching(password, PASSWORD_REGEX) &&
      isNotEmpty(confirmPassword) &&
      password === confirmPassword &&
      isNotEmpty(email) &&
      valueIsMatching(email, customEmailRegex ? customEmailRegex : EMAIL_REGEX) ? (
        <InputSuccessMessage>{texts.success}</InputSuccessMessage>
      ) : (
        <></>
      )}

      <TermsOfUseWrapper>
        <TermsOfUseText>
          {texts.termsOfUsePart1}
          {' ' /* intentional */}
          <Link to={ROUTE_TERMS_OF_USE} target="_blank" style={linkStyle}>
            {texts.termsOfUsePart2}
          </Link>
          {texts.termsOfUsePart3}
          {' ' /* intentional */}
          <Link to={ROUTE_TERMS_OF_USE} target="_blank" style={linkStyle}>
            {texts.termsOfUsePart4}
          </Link>
          .
        </TermsOfUseText>
      </TermsOfUseWrapper>
      <ButtonContainer>
        <ButtonOrangeFilled type="submit">{texts.createYourAccount}</ButtonOrangeFilled>
        <GoogleLoginForm
          shouldDisableRegistration={invitedEmail ? true : false}
          registrationLanguage={registrationLanguage}
        />
      </ButtonContainer>
      <AdditionalInfo>
        {texts.alreadyHaveAccount}
        <button onClick={navigateToLogin} type="button">
          {texts.login}
        </button>
      </AdditionalInfo>
    </form>
  );
};
