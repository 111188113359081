import { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SIGN_UP_ROUTES } from '../../constants/routes';

import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { PageContainer, PageWrapper } from '../../components/Reusable-styled-elements/PageContainer';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { ConfirmEmailInnerContainer, RecreateContainer, UserEmail } from './style';

import { RootState, useAppDispatch } from '../../store/store';
import { resendSignupCode } from '../../store/actions/auth.action';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import { IAuthSlice, resetError } from '../../store/slices/authSlice';
import { Language } from '../../utils/languages/general';
import { textTemplates } from '../../utils/types';
import {
  INITIAL_TOAST_CONFIG,
  ToastNotificationType,
  ToastNotificationWithDisplay,
  ToastNotification,
} from '@skillset/ui';
import { zIndices } from '../../assets/zIndices';
import { Welcome } from '../Welcome/Welcome';
import { getConfirmEmailTexts } from '../../services/languages.service';
interface ILocationState {
  email: string;
  shouldResendCode?: boolean;
}

export const ConfirmEmailMessage: React.FC<{ isBusinessUser: boolean; registrationLanguage?: Language }> = ({
  isBusinessUser,
  registrationLanguage,
}) => {
  const { state: locationState } = useLocation();
  const { email } = locationState as ILocationState;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { error } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const [toastNotificationConfig, setToastNotificationConfig] =
    useState<ToastNotificationWithDisplay>(INITIAL_TOAST_CONFIG);

  const resetToastMessage = () => setToastNotificationConfig(INITIAL_TOAST_CONFIG);

  const setToastMessage = (message: string, type: ToastNotificationType) => {
    setToastNotificationConfig({
      display: true,
      message,
      type,
      zIndex: zIndices.mobilePopup,
    });

    setTimeout(() => {
      resetToastMessage();
    }, 3000);
  };
  const navigateToLogin = () => navigate('/login');
  const handleResendCode = async () => {
    const res = await dispatch(resendSignupCode(email));
    if (res.meta.requestStatus === 'fulfilled') {
      setToastMessage('Check your inbox for a new verification email', 'success');
    }
  };

  useEffect(() => {
    if (!email) {
      navigateToLogin();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setToastMessage(error, 'error');
      dispatch(resetError());
    }
  }, [error]);
  const texts = getConfirmEmailTexts(registrationLanguage);
  return (
    <PageWrapper>
      {
        <Welcome
          textTemplate={textTemplates.SIGNUP}
          isBusinessUser={isBusinessUser}
          registrationLanguage={registrationLanguage}
        />
      }
      <PageContainer>
        <ConfirmEmailInnerContainer>
          <ImageContainer src="https://shared-assets.tatio.io/images/confirm-email.gif" />
          <Heading>{texts.verifyYourEmail}</Heading>
          <span>{texts.sentVerificationEmail}</span>
          <UserEmail>{email}.</UserEmail>
          <span>{texts.expireSoon} </span>
          <ButtonOrangeFilled onClick={handleResendCode}>{texts.resendEmail}</ButtonOrangeFilled>
          {error && <ErrorMessage error={error} />}
          <RecreateContainer>
            <strong>{texts.needToChangeEmailQuestion}</strong>
            <span>
              {texts.needToChangeEmailQuestion} <Link to={SIGN_UP_ROUTES.REGISTER}>{texts.createAccount}</Link>
            </span>
          </RecreateContainer>
        </ConfirmEmailInnerContainer>
        {toastNotificationConfig.display && (
          <ToastNotification
            message={toastNotificationConfig.message}
            type={toastNotificationConfig.type}
            handleClose={resetToastMessage}
            zIndex={zIndices.mobilePopup}
          />
        )}
      </PageContainer>
    </PageWrapper>
  );
};
