import React, { PropsWithChildren, useCallback } from 'react';
import { ListChildComponentProps, areEqual } from 'react-window';
import throttle from 'lodash-es/throttle';

import { WithComponentKey } from '../../../utils/types';
import { CenteredColumn } from '../TableRowItem/style';
import { TableBaseProps, TableRowItemProps } from '../types';
import { TableRowItem } from '../TableRowItem/TableRowItem';

export interface VirtualRowProps<RowData extends WithComponentKey>
  extends Omit<TableBaseProps<RowData>, 'data' | 'renderDataCondition'> {
  rows: TableBaseProps<RowData>['data'];
  isMobile: boolean;
  setRowSizeByIndex: (index: number) => (size: number) => void;
  shouldMarkFirstRow: boolean;
}
const VirtualRow = <TRowData extends WithComponentKey>({
  data: context,
  index,
  style: virtualRowStyle,
}: ListChildComponentProps<VirtualRowProps<TRowData>>) => {
  const {
    rows,
    columns,
    components,
    isMobile,
    rowsBorderLess,
    rowGridTemplateColumns,
    mobileRowGridTemplateColumns,
    renderRowProps,
    setRowSizeByIndex,
    shouldMarkFirstRow,
  } = context;
  const rowData = rows[index];
  const setRowSize = useCallback(throttle(setRowSizeByIndex(index), 100, { trailing: true }), [index]);
  const mobileMainLayoutColumns = columns.filter(({ mobileMainLayout }) => mobileMainLayout);
  const tableRowItemChildren = (!isMobile ? columns : mobileMainLayoutColumns).map(
    ({ render, keyName, title, padding, tableCellProps }) => {
      return (
        <CenteredColumn key={title + keyName} padding={padding} {...(tableCellProps ?? {})}>
          {render
            ? render(rowData, index)
            : undefined ?? typeof rowData[keyName] === 'string'
            ? rowData[keyName]
            : title}
        </CenteredColumn>
      );
    },
  );

  const props: PropsWithChildren<TableRowItemProps & { key: WithComponentKey['componentKey'] }> = {
    rowProps: { ...(renderRowProps?.(rowData) ?? {}), virtualRowStyle, setRowSize },
    rowGridTemplateColumns: rowGridTemplateColumns,
    mobileRowGridTemplateColumns: mobileRowGridTemplateColumns,
    $borderLess: !!rowsBorderLess,
    key: rowData.componentKey,
    $index: index === 0 && shouldMarkFirstRow ? 0 : 1, // Pass the index
  };

  const renderRow = components?.Row ? (
    components.Row({ ...props, ...rowData, children: tableRowItemChildren })
  ) : (
    <div style={virtualRowStyle}>{<TableRowItem {...props}>{tableRowItemChildren}</TableRowItem>}</div>
  );
  return renderRow;
};
const MemoizedVirtualRow = React.memo(VirtualRow, areEqual) as typeof VirtualRow;
export { MemoizedVirtualRow as VirtualRow };
