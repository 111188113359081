import { Language } from './general';

export interface Texts {
  haveAcountQuestion: string;
  createNewAccount: string;
  rememberMe: string;
  forgotPassword: string;
  email: string;
  password: string;
  loginToYourAccount: string;
  continueWithGoogle: string;
  login: string;
  welcome: string;
  welcomeMessage: string;
  validEmailError: string;
  validPasswordError: string;
  incorrectUserNameOrPassword: string;
  emailAlreadyRegistered: string;
}

export const loginTexts: Record<Language, Texts> = {
  en: {
    haveAcountQuestion: 'Dont have an account? ',
    createNewAccount: 'Create a new one',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password?',
    email: 'Email',
    password: 'Password',
    loginToYourAccount: 'Login to your account',
    continueWithGoogle: 'Continue with Google',
    login: 'Login',
    welcome: 'Welcome!',
    welcomeMessage: 'Dive into our Virtual Job Simulation and get a fun peek at the role you`re eyeing.',
    validEmailError: 'Please fill a valid email',
    validPasswordError: 'Please enter your password',
    incorrectUserNameOrPassword: 'Incorrect username or password!',
    emailAlreadyRegistered: 'This email address is already registered',
  },
  es: {
    haveAcountQuestion: '¿No tienes cuenta? ',
    createNewAccount: 'Crea una nueva',
    rememberMe: 'Recuérdame',
    forgotPassword: '¿Olvidaste la contraseña?',
    email: 'Correo electrónico',
    password: 'Contraseña',
    loginToYourAccount: 'Inicia sesión en tu cuenta',
    continueWithGoogle: 'Continuar con Google',
    login: 'Iniciar sesión',
    welcome: '¡Bienvenido!',
    welcomeMessage:
      '`Mientras que otras soluciones dependen de currículums, TaTiO ofrece algo diferente. Los candidatos están más comprometidos desde el principio y se califican según sus habilidades.` — Tech Crunch',
    validEmailError: 'Por favor complete un correo electrónico válido',
    validPasswordError: 'Por favor, introduzca su contraseña',
    incorrectUserNameOrPassword: 'Nombre de usuario o contraseña incorrectos.',
    emailAlreadyRegistered: 'Esta dirección de correo electrónico ya está registrada',
  },
  he: {
    haveAcountQuestion: 'אין לך חשבון? ',
    createNewAccount: 'צור חשבון חדש',
    rememberMe: 'זכור אותי',
    forgotPassword: 'שכחת את הסיסמה?',
    email: 'דוא"ל',
    password: 'ססמא',
    loginToYourAccount: 'היכנס לחשבונך',
    continueWithGoogle: 'המשך עם גוגל',
    login: 'כניסה',
    welcome: 'ברוך הבא!',
    welcomeMessage: 'הכנסו לסימולצית העבודה שלנו וקבלו הצצה לתפקיד שאתם מעוניינים בו',
    validEmailError: 'אנא מלא כתובת דוא"ל חוקית',
    validPasswordError: 'אנא הזן את הסיסמה שלך',
    incorrectUserNameOrPassword: 'שם משתמש או סיסמה שגויים!',
    emailAlreadyRegistered: 'ככתובת הדואל הזו כבר רשומה',
  },
  pt: {
    haveAcountQuestion: 'Não tem uma conta? ',
    createNewAccount: 'Crie uma nova',
    rememberMe: 'Lembrar de mim',
    forgotPassword: 'Esqueceu a senha?',
    email: 'E-mail',
    password: 'Senha',
    loginToYourAccount: 'Faça login na sua conta',
    continueWithGoogle: 'Continue com o Google',
    login: 'Entrar',
    welcome: 'Bem-vindo!',
    welcomeMessage:
      'Mergulhe em nossa Simulação de Trabalho Virtual e tenha uma visão divertida da função que você está de olho.',
    validEmailError: 'Por favor, insira um e-mail válido',
    validPasswordError: 'Por favor, insira sua senha',
    incorrectUserNameOrPassword: 'Nome de usuário ou senha incorretos!',
    emailAlreadyRegistered: 'Este endereço de e-mail já está registrado',
  },
};
