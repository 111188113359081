import styled from 'styled-components';
import { SMALL_DEVICES } from '../../../constants/breakpoints';
import { IOSScrollbar } from '../../../utils/IOSScrollbar';

export const TableListElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 332px);
  @media screen and (max-width: ${SMALL_DEVICES}) {
    height: calc(100vh - 294px);
  }
  ${IOSScrollbar}
  padding-bottom: 10px;
`;
TableListElement.displayName = 'TableListElement.styled';
