export default {
  stage: 'prod',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'skillset-storage-main-site-prod-s3bucket-utbt5gzxqybj',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://uoi6f41qn2.execute-api.us-east-1.amazonaws.com/main-site-prod',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_6ruUBZN8D',
    APP_CLIENT_ID: '76pu8u15gongd0g5mc8ehjr3uc',
    IDENTITY_POOL_ID: 'us-east-1:6cb21638-bf4e-462b-861e-eee68e2d90f4',
    GOOGLE_REDIRECT_SIGN_IN: location.origin + '/social-login',
    GOOGLE_REDIRECT_SIGN_OUT: location.origin + '/login',
    GOOGLE_COGNITO_DOMAIN: 'tatio.auth.us-east-1.amazoncognito.com',
  },
  social: {
    GOOGLE_ANALYTICS: 'UA-182396580-1',
    TAG_MANAGER_ARGS: 'GTM-TP2N8K2',
  },
  kinesisFirehose: {
    REGION: 'us-east-1',
    STREAM_NAME: 'FirehoseDeliveryStream-main-site-prod-skillset-analytics',
  },
  webCameraUse: {
    disable: false,
  },
  urls: {
    simulations_url: 'https://simulations.tatio.io/',
    api_documentation: 'developers.tatio.io',
  },
};
