import styled, { CSSProperties, css } from 'styled-components';
import { ResettedButton } from './ResettedButton';

export const CustomizedButton = styled(ResettedButton)<{ style: CSSProperties }>`
  ${({ style }) => {
    const { height, background, color, fontSize, ...restOfStyleProps } = style;
    return css`
      height: ${height ?? '44px'};
      background: ${background ?? 'white'};
      color: ${color ?? 'var(--almost-black)'};
      font-size: ${fontSize ?? '17px'};
      border: 1.5px solid ${color ?? 'var(--almost-black)'};
      {...${restOfStyleProps}}
    `;
  }}
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 600;
  line-height: 20px;
  margin-top: 26px;
`;
CustomizedButton.displayName = 'CustomizedButton.styled';
