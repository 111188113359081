import React from 'react';
import { RoleColumnWrapper, RoleElement } from './style';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { HighlightedSearchTerm } from '@skillset/ui';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { EmployersAdministrationSlice } from '../../../store/types/EmployerAdministrationInterface';

interface RoleColumnProps {
  role: string;
}

export const RoleColumn: React.FC<RoleColumnProps> = ({ role }) => {
  const { searchTerm } = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('horizontal');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const roleToRender = role[0].toUpperCase() + role.slice(1).toLowerCase();

  return (
    <RoleColumnWrapper>
      <RoleElement ref={refCallback}>
        <HighlightedSearchTerm text={roleToRender} searchTerm={searchTerm.trim()} />
      </RoleElement>
      {isOverflowing && !isMobile && (
        <Tooltip color="var(--almost-black)" direction="down" fontWeight="600" textColor="white" top="-5px">
          {roleToRender}
        </Tooltip>
      )}
    </RoleColumnWrapper>
  );
};
