import { faBell, faGrid, faMessageSmile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { zIndices } from '../../assets/zIndices';
import { SMALL_DEVICES } from '@skillset/ui';

export const HeaderElement = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--grey90);
  box-shadow: 0px 0px 2px #cccccc;
  z-index: ${zIndices.header};
  background-color: var(--light-grey);
  padding: 7px 41px 7px 0px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    background-color: var(--menu-dark-blue);
    padding: 7.5px 30px;
  }
`;
HeaderElement.displayName = 'HeaderElement.styled';

export const NotificationsIconElement = styled(FontAwesomeIcon).attrs({ icon: faBell })`
  width: 21px;
  height: 24px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 17px;
    height: 19px;
    margin-right: 18.5px;
  }
  margin-right: 21.5px;
`;
NotificationsIconElement.displayName = 'NotificationsIconElement.styled';

export const GridIcon = styled(FontAwesomeIcon).attrs({ icon: faGrid })`
  height: 24px;
  margin-right: 17.5px;
  cursor: pointer;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-right: 18.5px;
    width: 17px;
    height: 19px;
  }
`;
GridIcon.displayName = 'GridIcon.styled';

export const CalendarSmilingIcon = styled(FontAwesomeIcon).attrs({ icon: faMessageSmile })`
  margin-right: 18.5px;
  width: 19px;
  height: 19px;
  @keyframes blinkingOpacity {
    0% {
      opacity: 50%;
    }
    50% {
      opacity: 100%;
    }
    100% {
      opacity: 50%;
    }
  }
  animation: blinkingOpacity 0.75s infinite;
`;
CalendarSmilingIcon.displayName = 'CalendarSmilingIcon.styled';

export const HeaderController = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    svg {
      /* Meant for fontawesome icons, without important wouldnt work */
      color: white !important;
    }
  }
`;
HeaderController.displayName = 'HeaderController.styled';

export const ExpansionArrowIcon = styled(FontAwesomeIcon).attrs({ color: 'var(--dark-grey)' })`
  width: 13px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  transition: 1s all ease-in-out;
`;
ExpansionArrowIcon.displayName = 'ExpansionArrowIcon.styled';

export const MobileLogoElement = styled.img.attrs({
  src: 'https://shared-assets.tatio.io/images/tatio-small-logo.svg',
})`
  height: 30px;
  width: 30px;
`;
MobileLogoElement.displayName = 'MobileLogoElement.styled';
