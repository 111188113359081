import { PropsWithChildren, ReactElement } from 'react';
import { CSSProperties } from 'styled-components';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { detectFirefox } from '../../../utils';
import { HeaderColumn, Column } from '../HeaderColumn/HeaderColumn';
import { TableHeaderElement } from './style';
interface ITableHeaderProps<RowData> {
  columns: Column<RowData>[];
  isListScrollVisible: boolean;
  handleSortClick: (keyName: string) => void;
  getSortByOrder: (keyName: string) => 'ASC' | 'DESC' | null;
  rowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  mobileRowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  zIndexTooltip: number;
}
export const TableHeader = <RowData extends object>({
  columns,
  rowGridTemplateColumns,
  mobileRowGridTemplateColumns,
  isListScrollVisible,
  zIndexTooltip,
  handleSortClick,
  getSortByOrder,
}: PropsWithChildren<ITableHeaderProps<RowData>>): ReactElement | null => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const isFirefoxBrowser = detectFirefox();
  const renderHeaderColumns = (!isMobile ? columns : columns.filter(({ mobileMainLayout }) => mobileMainLayout)).map(
    ({ keyName, ...headerColumnProps }) => {
      return (
        <HeaderColumn
          key={headerColumnProps.title + keyName}
          zIndexTooltip={zIndexTooltip}
          {...headerColumnProps}
          handleSorting={() => handleSortClick(keyName)}
          getSortByOrder={() => getSortByOrder(keyName)}
        />
      );
    },
  );
  return (
    <TableHeaderElement
      mobileRowGridTemplateColumns={mobileRowGridTemplateColumns}
      rowGridTemplateColumns={rowGridTemplateColumns}
      shouldPadRight={isListScrollVisible && !isFirefoxBrowser}
    >
      {renderHeaderColumns}
    </TableHeaderElement>
  );
};
