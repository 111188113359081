import { useState, useEffect } from 'react';

interface UseWindowDimensionsProps {
  requestedWidth: number;
}

export const useWindowDimensions = ({ requestedWidth }: UseWindowDimensionsProps) => {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    const isAtRequestedWidth = !!(width ? width <= requestedWidth : null);

    return { isAtRequestedWidth, width, height };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
