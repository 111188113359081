import { confirmEmailTexts } from '../utils/languages/ConfirmEmailTexts';
import { forgotPasswordTexts } from '../utils/languages/ForgotPasswordTexts';
import { headerTexts } from '../utils/languages/HeaderTexts';
import { loginTexts } from '../utils/languages/LoginTexts';
import { noSimulationTexts } from '../utils/languages/NoSimulationTexts';
import { passwordHintTexts } from '../utils/languages/PasswordHintTexts';
import { questionnaireTexts } from '../utils/languages/QuestionnaireTexts';
import { registrationTexts } from '../utils/languages/RegistrationTexts';
import { resendPasswordTexts } from '../utils/languages/ResendPasswordTexts';
import { resetPasswordTexts } from '../utils/languages/ResetPasswordTexts';
import { sideMenuTexts } from '../utils/languages/SideMenuTexts';
import { termsOfUseTexts } from '../utils/languages/TermsOfUseTexts';
import { DefaultLanguage, Language } from '../utils/languages/general';

export const getConfirmEmailTexts = (registrationLanguage: Language | undefined) => {
  return confirmEmailTexts[registrationLanguage ?? DefaultLanguage];
};

export const getNoSimulatinsTexts = (registrationLanguage: Language | undefined) => {
  return noSimulationTexts[registrationLanguage ?? DefaultLanguage];
};

export const getHeaderTexts = (registrationLanguage: Language | undefined) => {
  return headerTexts[registrationLanguage ?? DefaultLanguage];
};

export const getLoginTexts = (registrationLanguage: Language | undefined) => {
  return loginTexts[registrationLanguage ?? DefaultLanguage];
};

export const getForgotPasswordTexts = (registrationLanguage: Language | undefined) => {
  return forgotPasswordTexts[registrationLanguage ?? DefaultLanguage];
};

export const getPasswordHintTexts = (registrationLanguage: Language | undefined) => {
  return passwordHintTexts[registrationLanguage ?? DefaultLanguage];
};

export const getQuestionnaireTexts = (registrationLanguage: Language | undefined) => {
  return questionnaireTexts[registrationLanguage ?? DefaultLanguage];
};

export const getRegistrationTexts = (registrationLanguage: Language | undefined) => {
  return registrationTexts[registrationLanguage ?? DefaultLanguage];
};

export const getResendPasswordTexts = (registrationLanguage: Language | undefined) => {
  return resendPasswordTexts[registrationLanguage ?? DefaultLanguage];
};

export const getResetPasswordTexts = (registrationLanguage: Language | undefined) => {
  return resetPasswordTexts[registrationLanguage ?? DefaultLanguage];
};

export const getSideMenuTexts = (registrationLanguage: Language | undefined) => {
  return sideMenuTexts[registrationLanguage ?? DefaultLanguage];
};

export const getTermsOfUseTexts = (registrationLanguage: Language | undefined) => {
  return termsOfUseTexts[registrationLanguage ?? DefaultLanguage];
};
