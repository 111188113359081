export interface CognitoUserInfo {
  id: string;
  username: string;
  attributes: Record<string, string>;
}
export interface GoogleCognitoUserInfo extends CognitoUserInfo {
  email: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
}
export interface IPlatformUserPersonal {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  state: string;
  zipCode: string;
  city?: string | null;
  dateOfBirth?: string | null;
  nextJobWill?: string | null;
  jobTitle?: string;
}

export interface IPlatformUser {
  aff?: string;
  subAff?: string;
  sAff?: string;
  utm_medium: string;
  utm_campaign: string;
  utm_source: string;
  lang: string;
  email: string;
  businessId: number | null;
  personal: IPlatformUserPersonal;
  'amp;utm_source'?: string;
  'amp;utm_medium'?: string;
  'amp;utm_campaign'?: string;
  source: string;
  platformName: string;
  userUUID?: string;
  businessName?: string;
  role?: string;
}

export const textTemplates = {
  LOGIN: 'loginTemplate',
  SIGNUP: 'signUpTemplate',
  BUSINESS_QUESTIONNAIRE: 'businessQuestionnaire',
  QUESTIONARE: 'questionnaire',
} as const;

export type TextTemplate = (typeof textTemplates)[keyof typeof textTemplates];
