import React, { useState } from 'react';

import { useAnimatedSwipeX } from '../../../hooks/useAnimatedSwipeX';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { MobileFullscreenPopup } from '@skillset/ui';

import { Notes } from '../../../components/Notes/Notes';
import {
  CounterOverIconElement,
  NotesButtonIcon,
  NotesButtonIconWrapper,
} from '../../../components/CandidatesSimulations/Columns/style';
import { INote, NotesIdentifier } from '../../../components/Notes/types';
import { zIndices } from '../../../assets/zIndices';

interface JobNotesColumnProps {
  jobName: string;
  jobId: string;
  notes: INote[];
}

export const JobNotesColumn: React.FC<JobNotesColumnProps> = ({ jobName, jobId, notes }) => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const { open, ...restOfAnimatedSwipeProps } = useAnimatedSwipeX(window.innerWidth - 100);
  const [notesDisplay, setNotesDisplay] = useState(false);
  const toggleNotesDisplay = () => {
    setNotesDisplay((prevState) => !prevState);
  };
  const compareJobIdentifier = <T extends NotesIdentifier>(draftJobNoteIdentifier: T, noteJobIdentifier: T) => {
    const errorMessage = 'Wrong note identifier';
    if (typeof draftJobNoteIdentifier === 'number' || typeof noteJobIdentifier === 'number')
      throw new Error(errorMessage);
    if (draftJobNoteIdentifier.name === 'NotePerJob' && noteJobIdentifier.name === 'NotePerJob') {
      return draftJobNoteIdentifier.jobId === noteJobIdentifier.jobId;
    }
    throw new Error(errorMessage);
  };

  const title = `${jobName} - Notes`;

  const handleIconClick = () => {
    if (!isMobile) {
      toggleNotesDisplay();
    } else {
      open({ canceled: false });
    }
  };
  const renderNotesCount =
    notes.length > 0 ? (
      <CounterOverIconElement>{notes.length < 10 ? notes.length : '9+'}</CounterOverIconElement>
    ) : null;
  const renderToolTip = (
    <Tooltip color="var(--almost-black)" fontWeight="600" direction="down" textColor="white" right="-12px" top="-21px">
      Notes
      {/* The following line should be updated when fixing tooltip position */}
      {/* {notes.length === 0 ? 'Create a note' : 'Add to notes'} */}
    </Tooltip>
  );
  const renderNotes = () => {
    const identifier = { name: 'NotePerJob', jobId } as const;
    if (!isMobile) {
      if (notesDisplay) {
        return (
          <Notes
            identifier={identifier}
            compareIdentifiers={compareJobIdentifier}
            toggleDisplay={toggleNotesDisplay}
            notes={notes}
            title={title}
          />
        );
      } else {
        return null;
      }
    } else {
      return (
        <MobileFullscreenPopup
          zIndexPopup={zIndices.mobilePopup}
          {...restOfAnimatedSwipeProps}
          title={title}
          scrollable={false}
        >
          <Notes
            identifier={identifier}
            compareIdentifiers={compareJobIdentifier}
            toggleDisplay={toggleNotesDisplay}
            notes={notes}
          />
        </MobileFullscreenPopup>
      );
    }
  };
  return (
    <>
      <NotesButtonIconWrapper>
        <NotesButtonIcon
          className={notes.length === 0 ? 'fa-regular fa-message-plus' : 'fa-regular fa-message'}
          onClick={handleIconClick}
        />
        {renderNotesCount}
        {!isMobile && renderToolTip}
      </NotesButtonIconWrapper>
      {renderNotes()}
    </>
  );
};
