import React from 'react';
import { ContainerElement, TitleElement } from './style';
interface IMobileCoreTasksContainer {
  title: string;
  isViewed?: boolean;
}
export const MobileCoreTasksContainer: React.FC<IMobileCoreTasksContainer> = ({ title, children, isViewed }) => {
  return (
    <ContainerElement>
      <TitleElement fontSize={isViewed ? '15px' : '15px'} fontWeight={isViewed ? '200' : '600'}>
        {title}
      </TitleElement>
      {children}
    </ContainerElement>
  );
};
