import { SocialLink, SocialsList } from './style';

interface SocialLinksSvgs {
  FacebookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  LinkedinIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  TwitterIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  YoutubeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const SocialLinks: React.FC<SocialLinksSvgs> = ({ FacebookIcon, LinkedinIcon, TwitterIcon, YoutubeIcon }) => {
  return (
    <SocialsList>
      <SocialLink href="https://www.facebook.com/tatioHR" target="_blank" className="facebook">
        <FacebookIcon height="20px" width="20px" />
      </SocialLink>
      <SocialLink href="https://www.linkedin.com/company/tatiohr" target="_blank" className="linkedin">
        <LinkedinIcon height="20px" width="20px" />
      </SocialLink>
      <SocialLink href="https://twitter.com/Maya_HuberHR" target="_blank" className="twitter">
        <TwitterIcon height="20px" width="20px" />
      </SocialLink>
      <SocialLink href="https://www.youtube.com/channel/UC4S6NPO-lxDqRyrV9c8oG_Q" target="_blank" className="youtube">
        <YoutubeIcon height="20px" width="20px" />
      </SocialLink>
    </SocialsList>
  );
};
