import { API } from 'aws-amplify';

interface CountriesStateCitiesObj {
  name: string;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
function _compare(a: string, b: string) {
  if ('United States' === a) return -Infinity;
  if ('United States' === b) return Infinity;
  a = a.toUpperCase();
  b = b.toUpperCase();
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

type CountriesStateCitiesData = CountriesStateCitiesObj[];
export const getCountries = async () => {
  try {
    const data: CountriesStateCitiesData = await API.get('api', `/countries`, {});
    const options = data
      .reduce((acc, currentValue) => {
        if (currentValue.name === 'United States') {
          acc.unshift(currentValue.name);
        } else {
          acc.push(currentValue.name);
        }
        return acc;
      }, [] as string[])
      .sort(_compare);
    return {
      options,
      error: '',
    };
  } catch (err) {
    console.error(err);

    return {
      error: (err as Error).message,
    };
  }
};

export const getStatesByCountry = async (country: string) => {
  try {
    const statesResponse = await API.get('api', `/countries/states/${country}`, {});
    const data = statesResponse.payload as CountriesStateCitiesData;
    return {
      options: Array.from(new Set(data.map(({ name }) => name).sort(_compare))),
      error: '',
    };
  } catch (err) {
    console.error(err);

    return {
      error: (err as Error).message,
    };
  }
};

export const getCityByState = async (state: string) => {
  try {
    const citiesResponse = await API.get('api', `/countries/cities/${state}`, {});
    const data = citiesResponse.payload as CountriesStateCitiesData;
    return {
      options: Array.from(new Set(data.map(({ name }) => name).sort(_compare))),
      error: '',
    };
  } catch (err) {
    console.error(err);

    return {
      error: (err as Error).message,
    };
  }
};
