import styled from 'styled-components';

export const ContainerElement = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 15px;
  gap: 31.5px;
`;

ContainerElement.displayName = 'ContainerElement.styled';

interface ITitleElementProps {
  fontSize?: string;
  fontWeight?: string;
}

export const TitleElement = styled.h2<ITitleElementProps>`
  font-family: var(--primary-font);
  overflow: hidden;
  color: var(--almost-black);
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
  height: 23px;
  flex-shrink: 0;
`;
TitleElement.displayName = 'TitleElement.styled';
