import { MouseEventHandler } from 'react';
import { useSpring } from '@react-spring/web';

import { ResettedButton } from '../../reusable-elements/ResettedButton';
import { AnimatedStarIcon } from './style';
interface StarButtonProps {
  isStarred: boolean;
  starStyle?: React.CSSProperties;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isMobile?: boolean;
}

export const StarButton: React.FC<StarButtonProps> = ({ isMobile, isStarred, onClick, starStyle }) => {
  const from = { angle: -50, fontSize: 5 };
  const to = { angle: 0, fontSize: 17 };
  const [{ angle, fontSize }, api] = useSpring(() => ({ from, to }));

  const style = {
    transform: angle.to((value) => `rotate(${value}deg)`),
    fontSize: fontSize.to((size) => size + 'px'),
    ...starStyle,
  };
  return (
    <ResettedButton
      style={{ width: '21px', height: '40px' }}
      type="button"
      onClick={(ev) => {
        if (!isStarred) {
          api.set(from);
          api.start(to);
        }
        onClick(ev);
      }}
    >
      <AnimatedStarIcon
        role="img"
        aria-label={isStarred ? 'Starred' : 'Not starred'}
        className={isStarred ? 'starred' : ''}
        style={style}
        $isMobile={isMobile}
      />
    </ResettedButton>
  );
};
