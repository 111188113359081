import React, { useCallback, useState } from 'react';
import { ButtonContainer } from './style';
import { FloatingToolTipWithTriggerElement } from '../../reusable-elements/FloatingToolTipWithTriggerElement';
import { Clone } from '../../reusable-elements/icons';

interface CopyUniqueLinkButtonProps {
  uniqueLink: string;
  zIndex: number;
}
const TriggerElement = (setReference: (ref: HTMLElement | null) => void) => (
  <div
    ref={setReference}
    style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '10px' }}
  ></div>
);
export const CopyUniqueLinkButton: React.FC<CopyUniqueLinkButtonProps> = ({ uniqueLink, zIndex }) => {
  const [hoveredTooltip, setHoveredTooltip] = useState(false);
  const [copiedTooltipDisplay, setCopiedTooltipDisplay] = useState(false);
  const handleCopyTooltip = useCallback(() => {
    setCopiedTooltipDisplay(true);
    setTimeout(() => {
      setCopiedTooltipDisplay(false);
    }, 2500);
  }, [setCopiedTooltipDisplay]);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(`${uniqueLink}`);
      handleCopyTooltip();
    } catch (error) {
      console.error('Could not copy to clipboard', error);
    }
  }, [handleCopyTooltip]);

  const RenderCloneIcon = useCallback(
    (setReference: (ref: HTMLElement | null) => void) => <Clone onClick={copyToClipboard} ref={setReference} />,
    [copyToClipboard],
  );
  return (
    <ButtonContainer onMouseEnter={() => setHoveredTooltip(true)} onMouseLeave={() => setHoveredTooltip(false)}>
      <FloatingToolTipWithTriggerElement
        text="Copy unique link"
        className="hover-tooltip"
        color="var(--almost-black)"
        textColor="white"
        floatingPlacement="top"
        direction="down"
        isDisplayed={hoveredTooltip && !copiedTooltipDisplay}
        zindex={zIndex}
        placementOffset={-2}
      >
        {RenderCloneIcon}
      </FloatingToolTipWithTriggerElement>
      <FloatingToolTipWithTriggerElement
        color="var(--almost-black)"
        textColor="white"
        text="Link copied to clipboard"
        floatingPlacement="top"
        isDisplayed={copiedTooltipDisplay}
        zindex={zIndex}
      >
        {TriggerElement}
      </FloatingToolTipWithTriggerElement>
    </ButtonContainer>
  );
};
