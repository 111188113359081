import styled from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';

export const CheckboxContainer = styled.label<{ alignment?: string }>`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: ${({ alignment }) => alignment ?? 'center'};
  justify-content: flex-start;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 8px;
    gap: 6.53px;
  }

  & span,
  & a {
    font-family: var(--gilroy-font);
    cursor: pointer;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      line-height: 17px;
    }
  }
`;
CheckboxContainer.displayName = 'CheckboxContainer.styled';

export const InputButton = styled.input.attrs((props) => ({
  type: props.type,
  required: props.required,
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1px;
  width: 1px;
`;

export const CustomCheckBox = styled.label<{ color?: string }>`
  width: 18.5px;
  height: 18.5px;
  flex-shrink: 0;
  border: 2px solid var(--grey);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: ${({ color }) => `2px solid ${color ?? 'var(--orange)'}`};
  }

  &.checked {
    background: ${({ color }) => color ?? 'var(--orange)'};
    color: var(--white);
    border: none;
    &:hover {
      box-shadow: 0px 4px 7px -6px rgba(24, 39, 75, 0.5), 0px 9px 22px -4px rgba(24, 39, 75, 0.5);
    }
  }
  @media (max-width: ${SMALL_DEVICES}) {
    width: 14px;
    height: 14px;
  }
`;

CustomCheckBox.displayName = 'CustomCheckBox.styled';

export const CheckIcon = styled.i.attrs({ className: 'fa-solid fa-check' })`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
  align-self: center;
  @media (max-width: ${SMALL_DEVICES}) {
    font-size: 12px;
  }
`;
CheckIcon.displayName = 'CheckIcon.styled';
