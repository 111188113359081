import { CheckIcon, CheckboxContainer, CustomCheckBox, InputButton } from './style';

interface CheckboxProps {
  required?: boolean;
  color?: string;
  alignment?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ children, required, checked, onChange, color, alignment }) => {
  return (
    <CheckboxContainer alignment={alignment}>
      <CustomCheckBox className={checked ? 'checked' : ''} color={color}>
        <InputButton
          required={required}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
        />
        {checked && <CheckIcon />}
      </CustomCheckBox>
      {children}
    </CheckboxContainer>
  );
};
