import React, { useCallback, useState } from 'react';
import { ShareIconButton, ShareIconContainer } from './style';
import { autoPlacement, offset, useFloating } from '@floating-ui/react';
import { MenuWithIcons } from '@skillset/ui';
import { zIndices } from '../../../assets/zIndices';
import QRCode from 'qrcode';
import { FloatingToolTipWithTriggerElement } from '../../../reusable-elements/FloatingToolTipWithTriggerElement/FloatingToolTipWithTriggerElement';

interface IShareColumn {
  simLink: string;
  simName: string;
}
const TriggerElement = (setReference: (ref: HTMLElement | null) => void) => <div ref={setReference}></div>;

export const ShareColumn: React.FC<IShareColumn> = ({ simLink, simName }) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [copiedTooltipDisplay, setCopiedTooltipDisplay] = useState(false);
  const { refs, strategy, x, y } = useFloating({
    middleware: [offset(10), autoPlacement({ allowedPlacements: ['left-start'] })],
  });
  const [shareToolTip, setShareTooltip] = useState(false);

  const { setReference: setOptionsMenuRef, setFloating } = refs;
  const listStyle = { display: 'flex', flexDirection: 'column', gap: '5px' } as const;

  const handleCopyTooltip = () => {
    setCopiedTooltipDisplay(true);
    setTimeout(() => {
      setCopiedTooltipDisplay(false);
    }, 2500);
  };

  const toggleDisplayMenu = useCallback(() => {
    setShareTooltip(false);
    setDisplayMenu((prevState) => !prevState);
  }, [setShareTooltip, setDisplayMenu]);
  const simLinkToCopy = simLink === 'sampleLink' ? 'https://www.tatio.io' : simLink;
  const copyLink = async () => {
    await navigator.clipboard.writeText(simLinkToCopy);
    handleCopyTooltip();
  };

  const downloadQRCode = async () => {
    try {
      const canvas = document.createElement('canvas');
      await QRCode.toCanvas(canvas, simLink);
      const dataURL = canvas.toDataURL('image/png');
      const blob = await (await fetch(dataURL)).blob();
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${simName}_Simulation_QR.png`; // Set the file name
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error(err);
    }
  };
  const menuOptions = [
    {
      title: 'Copy link',
      iconClassName: 'fa-regular fa-clone',
      onClick: copyLink,
    },
    {
      title: 'Download QR',
      iconClassName: 'fa-regular fa-download',
      onClick: downloadQRCode,
    },
  ];
  const renderOptionsMenu = displayMenu && (
    <MenuWithIcons
      zIndexBackdrop={zIndices.backdrop}
      menuOptions={menuOptions}
      closeMenu={toggleDisplayMenu}
      ref={setFloating}
      style={{ position: strategy, top: y ?? 0, left: x ?? 0, zIndex: zIndices.mobilePopup, width: '220px' }}
      listStyle={listStyle}
    />
  );

  const ShareButton = useCallback(
    (setReference: (ref: HTMLElement | null) => void) => (
      <ShareIconButton onClick={toggleDisplayMenu} ref={setReference} />
    ),
    [toggleDisplayMenu],
  );
  return (
    <ShareIconContainer
      onMouseEnter={() => setShareTooltip(true)}
      onMouseLeave={() => setShareTooltip(false)}
      ref={setOptionsMenuRef}
    >
      <FloatingToolTipWithTriggerElement
        text="Share Simulation Link"
        className="hover-tooltip"
        color="var(--almost-black)"
        fontWeight="600"
        textColor="white"
        floatingPlacement="left-start"
        direction="right"
        isDisplayed={shareToolTip}
      >
        {ShareButton}
      </FloatingToolTipWithTriggerElement>
      <FloatingToolTipWithTriggerElement
        color="var(--almost-black)"
        fontWeight="600"
        textColor="white"
        text="Link copied to clipboard"
        floatingPlacement="bottom-end"
        isDisplayed={copiedTooltipDisplay}
      >
        {TriggerElement}
      </FloatingToolTipWithTriggerElement>
      {renderOptionsMenu}
    </ShareIconContainer>
  );
};
