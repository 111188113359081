import { Link } from 'react-router-dom';
import { LogoContainerElement, TextElement } from './style';
import { LogoThemeDetails, LogoThemeType } from './constants';

interface LogoContainerProps {
  clickHandler?: () => void;
  style?: React.CSSProperties;
  theme: LogoThemeType;
  route?: string;
  text?: string;
  imgSize: { width: string; height: string };
  breakPointImgSize?: { width: string; height: string; breakpoint: string };
  className?: string;
}
export const LogoContainer: React.FC<LogoContainerProps> = ({
  clickHandler,
  style,
  theme,
  route,
  text,
  imgSize,
  breakPointImgSize,
  className,
}) => {
  return (
    <LogoContainerElement
      theme={LogoThemeDetails[theme]}
      style={style}
      onClick={clickHandler}
      logoWithText={text && text.length > 0 ? true : false}
      imgSize={imgSize}
      breakPointImgSize={breakPointImgSize}
      className={className}
    >
      {route ? (
        <Link to={route}>
          <img src={LogoThemeDetails[theme].img} alt="TaTiO" />
        </Link>
      ) : (
        <img src={LogoThemeDetails[theme].img} alt="TaTiO" />
      )}
      {text && <TextElement>{text}</TextElement>}
    </LogoContainerElement>
  );
};
