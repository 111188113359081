import styled from 'styled-components';
import { ResettedButton } from '@skillset/ui';

export const INITIAL_INPUT_WIDTH = 160;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  font-family: var(--primary-font);
  padding: 8.5px 18px;
  border: 2px solid var(--almost-black);
  border-radius: 5px;
  gap: 5px;
  overflow-x: auto;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 78, 0.2);
  }
`;
Container.displayName = 'Container.styled';

export const CleanInput = styled.input`
  font-family: var(--primary-font);
  border: none;
  &:focus-visible {
    outline: none;
  }
`;
CleanInput.displayName = 'CleanInput.styled';

export const EmailAddressContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1.5px solid var(--almost-black);
  border-radius: 8px;
  padding: 0px 5px 0px 10px;
  height: 28px;
  gap: 11px;
`;
EmailAddressContainer.displayName = 'EmailAddressContainer.styled';

export const EmailAddressTextElement = styled.span`
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
`;
EmailAddressTextElement.displayName = 'EmailAddressTextElement.styled';

export const IconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 24px;
  align-items: center;
  justify-content: center;
`;
IconContainer.displayName = 'IconContainer.styled';

export const RemoveIconButton = styled(ResettedButton).attrs({ className: 'fa-solid fa-circle-xmark' })`
  color: #1a1a1a;
  font-size: 12px;
`;
RemoveIconButton.displayName = 'RemoveIconButton.styled';

export const MeasuringInputTextElement = styled.span`
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
  pointer-events: none;
`;
MeasuringInputTextElement.displayName = 'MeasuringInputTextElement.styled';
