import styled from 'styled-components';
import { AnchorLikeButton } from './CandidateElements';

export const ChevronBack = styled(AnchorLikeButton).attrs(({ className: c }) => ({
  className: c ?? '' + ' fa-regular',
}))`
  ::before {
    content: '';
    font-size: 28;
    line-height: 150%;
  }
  color: var(--almost-black);
  padding-inline-end: 7px;
`;
ChevronBack.displayName = 'ChevronBack.styled';
