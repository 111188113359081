import React from 'react';
import { CompletedItemIcon, MenuItemWrapper, SecondItemIcon, CurrentItemIcon } from './style';
import { TextTemplate } from '../../utils/types';

type Page = Omit<TextTemplate, 'loginTemplate'>;
interface SideMenuProps {
  textTemplate: Page;
}

interface MenuItemProps extends SideMenuProps {
  icon: JSX.Element;
}

const pagesInit: [Page, JSX.Element][] = [
  ['signUpTemplate', <CurrentItemIcon />],
  ['businessQuestionnaire', <SecondItemIcon />],
];

const MenuItem: React.FC<MenuItemProps> = ({ textTemplate, icon }) => {
  let title, description;
  switch (textTemplate) {
    case 'signUpTemplate':
      title = 'Set up your account';
      description = 'Provide your email and password';
      break;
    case 'businessQuestionnaire':
      title = 'Company Information';
      description = 'Tell us a bit about yourself';
      break;
  }
  return (
    <MenuItemWrapper>
      {icon}
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', alignSelf: 'center' }}>
        <section className="title">{title}</section>
        <section className="description">{description}</section>
      </div>
    </MenuItemWrapper>
  );
};

export const BusinessSideMenu: React.FC<SideMenuProps> = ({ textTemplate }) => {
  let icon;
  const menuItems = pagesInit.map((page, index) => {
    const currentPageIndex = pagesInit.findIndex(([type]) => type === textTemplate);
    if (index < currentPageIndex) {
      icon = <CompletedItemIcon />;
    } else if (currentPageIndex === index) {
      icon = <CurrentItemIcon />;
    } else {
      icon = pagesInit[index][1];
    }
    return <MenuItem textTemplate={page[0]} icon={icon} key={page[0] as string}></MenuItem>;
  });
  return <>{menuItems}</>;
};
