import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import { zIndices } from '../../assets/zIndices';
import { BackDropLayer, SpinnerElement } from '@skillset/ui';
import { CloseIcon, Header, PopupIcon, PopupWrapper, ResponseButton } from './style';

interface ConfirmationPopupProps {
  onClosePopup: () => void;
  onConfirm: () => void;
  title: string;
  subtitle: string;
  loading?: boolean;
  icon: string;
}

type ButtonComponent<T = unknown> = React.FC<
  PropsWithChildren<{ onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined }> & T
>;

interface CompoundConfirmationPopup extends React.FC<unknown> {
  Buttons: React.FC<PropsWithChildren<unknown>>;
  Confirm: ButtonComponent<{ loading?: boolean }>;
  Cancel: ButtonComponent;
  Icons: React.FC<PropsWithChildren<unknown>>;
  CloseButton: ButtonComponent;
  PopupIcon: typeof PopupIcon;
  Backdrop: React.FC;
  Header: React.FC<{ title?: ReactNode; subtitle?: ReactNode }>;
}

function useAutoFocus<T extends HTMLElement>() {
  const focusRef = useRef<T>(null);
  useEffect(() => {
    focusRef.current?.focus();
  }, []);
  return focusRef;
}

export const ConfirmationPopup: CompoundConfirmationPopup = ({ children }) => {
  const focusRef = useAutoFocus<HTMLDivElement>();

  return (
    <>
      <BackDropLayer zIndex={zIndices.noDataBackdrop} background="rgba(0, 0, 0, 0.25)" />
      <PopupWrapper tabIndex={-1} ref={focusRef}>
        {children}
      </PopupWrapper>
    </>
  );
};

ConfirmationPopup.Buttons = ({ children }) => <section className="confirmation">{children}</section>;
ConfirmationPopup.Icons = ({ children }) => <section className="icons">{children}</section>;
ConfirmationPopup.Confirm = ({ loading, children = 'Confirm', onClick }) => (
  <ResponseButton onClick={onClick} msg="confirm">
    {loading ? <SpinnerElement color="white" /> : children}
  </ResponseButton>
);

ConfirmationPopup.Cancel = ({ children = 'Cancel', onClick }) => (
  <ResponseButton onClick={onClick} msg="cancel">
    {children}
  </ResponseButton>
);

ConfirmationPopup.CloseButton = ({ onClick }) => <CloseIcon onClick={onClick} />;
ConfirmationPopup.PopupIcon = PopupIcon;
ConfirmationPopup.Backdrop = () => <BackDropLayer zIndex={zIndices.noDataBackdrop} background="rgba(0, 0, 0, 0.25)" />;
ConfirmationPopup.Header = ({ title, subtitle }) => {
  if (!(title || subtitle)) return null;
  return (
    <Header>
      {title ? <h1>{title}</h1> : null}
      {subtitle ? <h2>{subtitle}</h2> : null}
    </Header>
  );
};

export const SimpleConfirmTemplate = ({
  icon,
  onClosePopup,
  onConfirm,
  loading,
  title,
  subtitle,
}: ConfirmationPopupProps) => (
  <ConfirmationPopup>
    <ConfirmationPopup.Icons>
      <ConfirmationPopup.PopupIcon className={icon} />
      <ConfirmationPopup.CloseButton />
    </ConfirmationPopup.Icons>
    <ConfirmationPopup.Header title={title} subtitle={subtitle} />
    <ConfirmationPopup.Buttons>
      <ConfirmationPopup.Cancel onClick={onClosePopup} />
      <ConfirmationPopup.Confirm onClick={onConfirm} loading={loading} />
    </ConfirmationPopup.Buttons>
  </ConfirmationPopup>
);
