import React, { ReactNode, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { IAuthSlice } from '../../store/slices/authSlice';
import { RootState } from '../../store/store';
import { LoadingScreen } from '@skillset/ui';

interface ProtectedRouteProps {
  Component: React.ComponentType;
  fallback?: NonNullable<ReactNode> | null;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ Component: RouteComponent, fallback = null }) => {
  const authSlice = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const { authenticated, platformUser, loading } = authSlice;
  const location = useLocation();
  const { search } = useLocation();
  const desiredUrl = location.pathname;

  if (!loading && !authenticated && desiredUrl) localStorage.setItem('desiredUrl', desiredUrl + search);
  if (loading) {
    return <LoadingScreen blockBodyOverflow={false} text="Loading" />;
  } else if (authenticated && platformUser) {
    return (
      <Suspense fallback={fallback}>
        <RouteComponent />
      </Suspense>
    );
  } else if (authenticated) {
    return <Navigate replace to={'/accounts/signup'} />;
  }
  return <Navigate replace to={'/login'} />;
};
