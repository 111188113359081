export type ToastNotificationType = 'success' | 'alert' | 'info' | 'error';

interface ITypeDetails {
  title: string;
  iconColor: string;
  iconClass: string;
}

export const notificationTypeDetails: Record<ToastNotificationType, ITypeDetails> = {
  success: {
    title: 'Success Message',
    iconClass: 'fa-light fa-circle-check',
    iconColor: 'var(--tatio-green)',
  },
  alert: {
    title: 'Alert Message',
    iconClass: 'fa-regular fa-exclamation-tringle',
    iconColor: 'var(--tatio-green)',
  },

  info: {
    title: 'Info Message',
    iconClass: 'fa-regular fa-exclamation-circle',
    iconColor: 'var(--light-pink)',
  },
  error: {
    title: 'Error Message',
    iconClass: 'fa-regular fa-info-circle',
    iconColor: 'var(--red)',
  },
} as const;

export interface IToastNotification {
  message: string;
  type: ToastNotificationType;
  zIndex: number;
}

export type ToastNotificationWithDisplay = IToastNotification & { display: boolean };

export const INITIAL_TOAST_CONFIG: ToastNotificationWithDisplay = {
  display: false,
  message: '',
  type: 'success',
  zIndex: 0,
};
