import styled from 'styled-components';
import { EXTRA_SMALL_DEVICES, MEDIUM_DEVICES, SMALL_DEVICES } from '@skillset/ui';
import { InputGroupContainer } from '../FormInputGroup/style';
export const InputsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    & ${InputGroupContainer} {
      position: relative;
      width: calc(50% - 5px);
    }
    flex-wrap: wrap;
    gap: 2.5px;
  }
  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    & ${InputGroupContainer} {
      position: relative;
      width: 100%;
    }
  }
`;
InputsRowContainer.displayName = 'InputsRowContainer.styled';

export const QuestionnaireButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    padding: 15.5px 14px;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    button {
      width: 100%;
    }
  }
`;
QuestionnaireButtonContainer.displayName = 'QuestionnaireButtonContainer.styled';

export const TwoInputFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    flex-direction: column;
  }
`;
