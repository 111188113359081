import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '@skillset/ui';

import { useTypedSelector } from '../../store/store';
import { selectLoadingBusinessSlice, selectMainBusinessPage } from '../../store/selectors';
import { MainBusinessPages } from '../../store/types/EmployerBusinessSliceInterface';

import { zIndices } from '../../assets/zIndices';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const Home: React.FC = () => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const mainBusinessPage = useTypedSelector(selectMainBusinessPage);
  const loading = useTypedSelector(selectLoadingBusinessSlice);

  if (isMobile) {
    return <Navigate replace to="switch-device" />;
  }
  if (mainBusinessPage && !loading) {
    return <Navigate replace to={mainBusinessPage} />;
  } else if (loading) {
    return <LoadingScreen zindex={zIndices.loadingBackDrop} />;
  }
  return <Navigate replace to={MainBusinessPages.jobs} />;
};
