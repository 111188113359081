import { createSlice } from '@reduxjs/toolkit';
import { CognitoUserInfo, GoogleCognitoUserInfo, IPlatformUser } from '../../utils/types';
import {
  completeForgotPassword,
  confirmSignUp,
  getAuthenticatedUser,
  requestForgotPassword,
  resendSignupCode,
  saveUserData,
  signIn,
  signOut,
  signUp,
  signInWithGoogle,
  anonymousSignUp,
} from '../actions/auth.action';
export interface IAuthSlice {
  loading: boolean;
  error: string | null;
  cognitoUser: CognitoUserInfo | GoogleCognitoUserInfo | null;
  platformUser: IPlatformUser | null;
  anonymousUser: IPlatformUser | null;
  authenticated: boolean | null;
  codeSent: boolean;
  platformConfirmed: boolean;
}

const initialState: IAuthSlice = {
  loading: false,
  error: null,
  cognitoUser: null,
  platformUser: null,
  anonymousUser: null,
  authenticated: null,
  codeSent: false,
  platformConfirmed: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.cognitoUser = action.payload?.cognitoUser as CognitoUserInfo;
        state.platformUser = action.payload?.platformUser as IPlatformUser;
        state.loading = false;
        state.error = null;
        state.authenticated = true;
        state.anonymousUser = null;
      })
      .addCase(anonymousSignUp.fulfilled, (state, action) => {
        state.cognitoUser = null;
        state.platformUser = null;
        state.anonymousUser = action.payload?.platformUser as IPlatformUser;
        state.loading = false;
        state.error = null;
        state.authenticated = false;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.cognitoUser = action.payload?.cognitoUser as GoogleCognitoUserInfo;
        state.platformUser = action.payload?.platformUser as IPlatformUser;
        state.loading = false;
        state.error = null;
        state.authenticated = true;
      })
      .addCase(signUp.fulfilled, (state) => {
        state.codeSent = true;
        state.error = null;
        state.loading = false;
      })
      .addCase(saveUserData.fulfilled, (state, action) => {
        state.platformUser = action?.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(resendSignupCode.fulfilled, (state) => {
        state.codeSent = true;
        state.error = null;
        state.loading = false;
      })
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        state.cognitoUser = action.payload?.cognitoUser as CognitoUserInfo | GoogleCognitoUserInfo;
        state.platformUser = action.payload?.platformUser as IPlatformUser;
        state.authenticated = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.authenticated = false;
        state.platformUser = null;
        state.cognitoUser = null;
        state.error = null;
      })
      .addCase(confirmSignUp.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(requestForgotPassword.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(completeForgotPassword.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(requestForgotPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(completeForgotPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(resendSignupCode.rejected, (state, action) => {
        state.loading = false;
        state.codeSent = false;
        state.error = action.payload as string;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.cognitoUser = null;
        state.platformUser = null;
        state.authenticated = false;
      })
      .addCase(getAuthenticatedUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(confirmSignUp.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addMatcher(
        (action) => {
          const allAuthPendingActions = /^(?=auth)\S+\/pending$/;
          return action.type.match(allAuthPendingActions);
        },
        (state) => {
          state.loading = true;
        },
      );
  },
});
export const { resetError } = authSlice.actions;
export const authSliceReducer = authSlice.reducer;
