import React, { useRef, useState } from 'react';
import { AsYouType } from 'libphonenumber-js';

export const usePhoneInput = () => {
  const [formattedNumber, setFormattedNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current && e.target.value.at(-1) === ' ') {
      inputRef.current.value = e.target.value.slice(0, -3);
    }
    const inputNumber = e.target.value;
    const output = new AsYouType().input(inputNumber);
    setFormattedNumber(output);
  };

  const handleBlur = () => {
    const phoneNumber = formattedNumber.replace(/\s/g, ''); // Remove any spaces for validation
    const isValidPhoneNumber = phoneNumber.length <= 14 && /^(\+)?\d*$/.test(phoneNumber);
    setIsValid(isValidPhoneNumber);
    setTouched(true);
  };

  return {
    value: formattedNumber,
    onChange: handleChange,
    onBlur: handleBlur,
    hasError: !isValid,
    valueIsValid: isValid,
    touched,
    inputRef,
  };
};
