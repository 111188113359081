import styled from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';

export const NaivigationMenuWrapper = styled.menu<{ zIndex: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 55px;
  right: 31px;
  z-index: ${({ zIndex }) => zIndex};
  padding: 4.5px 10px;
  width: 200px;
  background-color: white;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    right: 20px;
  }
`;
NaivigationMenuWrapper.displayName = `NaivigationMenuWrapper.styled`;

export const NavigationList = styled.ul`
  list-style: none;
  font-family: var(--primary-font);
  margin: 0;
  cursor: pointer;
  background-color: white;
  width: 100%;
  li:not(:last-child) {
    margin-bottom: 2.5px;
  }
`;
NavigationList.displayName = `NavigationList.styled`;
