import { Column } from '@skillset/ui';
import { ToastNotificationType } from '@skillset/ui';
import { getDateStringOrNA } from '../../../utils';
import { OptionalBusinessUser } from '../types';
import { LastActivityColumn } from './LastActivityColumn';
import { OptionsMenuColumn } from './OptionsMenuColumn';
import { RoleColumn } from './RoleColumn';
import { StatusColumn } from './StatusColumn';
import { UserColumn } from './UserColumn';
import { CustomerColumn } from './CustomerColumn';

export const useBusinessUsersTableColumns = (
  setToastMessage: (message: string, type: ToastNotificationType) => void,
) => {
  const columns: Column<OptionalBusinessUser>[] = [
    {
      title: 'User',
      keyName: 'email',
      render: ({ email, fullName }, index) => <UserColumn fullName={fullName} email={email} colorIndex={index} />,
      mobileMainLayout: true,
    },
    {
      title: 'Role',
      keyName: 'role',
      render: ({ role }) => <RoleColumn role={role} />,
      mobileMainLayout: true,
    },
    {
      title: 'Status',
      keyName: 'status',
      render: ({ status, expirationDate }) => <StatusColumn status={status} expirationDate={expirationDate} />,
      mobileMainLayout: false,
    },
    {
      title: 'Customer',
      keyName: 'customerName',
      render: ({ customerName }) => <CustomerColumn customer={customerName ?? ''} />,
      mobileMainLayout: true,
    },
    {
      title: 'Last Activity',
      keyName: 'lastVisit',
      render: ({ lastVisit }) => {
        const lastActivity = getDateStringOrNA(lastVisit);
        return <LastActivityColumn lastActivity={lastActivity} />;
      },
      mobileMainLayout: false,
    },
    {
      title: '',
      keyName: 'role',
      hideSortingFeature: true,
      render: ({ email, status }) => (
        <OptionsMenuColumn email={email} status={status} setToastMessage={setToastMessage} />
      ),
      mobileMainLayout: true,
    },
  ];
  return [columns];
};
