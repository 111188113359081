import { useCallback, useState } from 'react';

export interface ISortBy {
  keyName: string;
  orderBy: 'ASC' | 'DESC';
}

export const useSortBy = (initialSortBy: ISortBy) => {
  const [sortBy, setSortBy] = useState(initialSortBy);

  const getSortByOrder = useCallback(
    (keyName: string) => {
      const isKeySorted = sortBy?.keyName === keyName;
      return isKeySorted ? sortBy.orderBy : null;
    },
    [sortBy],
  );
  const handleSortClick = useCallback(
    (keyName: string) => {
      const isKeyNameAlreadySorted = sortBy.keyName === keyName;
      if (isKeyNameAlreadySorted) {
        const orderByToSet = sortBy.orderBy === 'ASC' ? 'DESC' : 'ASC';
        setSortBy({ keyName, orderBy: orderByToSet });
      } else {
        setSortBy({ keyName, orderBy: 'ASC' });
      }
    },
    [sortBy],
  );

  return [sortBy, handleSortClick, getSortByOrder] as const;
};
