import { API } from '@skillset/onelogin';
import { validate as isValidUUID } from 'uuid';
import qs from 'qs';
import { IFilterBy } from '../components/CandidatesSimulations/types';
import { IEmployersSimulation, ISortByDetails } from '../constants/simulation';

let loadSimulationsRequest: ReturnType<typeof API.get> | null = null;
interface GetAllSimulations {
  typeId?: number;
  filterBy: IFilterBy;
  isFavorite?: boolean;
}
const getAllSimulations = async ({ filterBy, isFavorite }: GetAllSimulations) => {
  const queryParams = { ...filterBy, favorites: isFavorite };
  const filterByString = qs.stringify(queryParams);
  try {
    if (loadSimulationsRequest)
      API.cancel(loadSimulationsRequest, 'A new request has been made before the last one has returned.');
    loadSimulationsRequest = API.get('api', `/employers/simulation?${filterByString}`, {});
    const res = await loadSimulationsRequest;
    loadSimulationsRequest = null;
    return res;
  } catch (error) {
    if (API.isCancel(error)) {
      return 'canceled';
    }
  }
};

const getAllStatuses = async () => {
  const loadStatusesRequest = await API.get('api', `/employers/simulation/statuses`, {});
  return loadStatusesRequest;
};

const updateBusinessSimulationStatus = (simulationId: number, statusToUpdate: number) => {
  return API.put('api', `/employers/simulation/${simulationId}`, { body: { statusToUpdate } });
};
const createBusinessSimulationNote = (simulationId: number, note: string) => {
  return API.post('api', `/employers/simulation/notes/${simulationId}`, { body: { note } });
};
const updateBusinessSimulationNote = (noteId: number, note: string) => {
  return API.patch('api', `/employers/simulation/notes/${noteId}`, { body: { note } });
};
const deleteBusinessSimulationNote = (noteId: number) => {
  return API.del('api', `/employers/simulation/notes/${noteId}`, {});
};

const updateSimulationViewStatus = (sessionId: string, statusToUpdate: boolean) => {
  return API.put('api', `/employers/simulation/viewStatus/${sessionId}`, { body: { statusToUpdate } });
};

const updateSimulationFavoriteStatus = (sessionId: string, statusToUpdate: boolean) => {
  return API.put('api', `/employers/simulation/favoriteStatus/${sessionId}`, { body: { statusToUpdate } });
};

const getAllStatesAndCoreTasksNames = (typeId: number) => {
  return API.get('api', `/employers/simulation/states/${typeId}`, {});
};

const getSimulationCompletedTexts = async (uniqueLink: string) => {
  const { payload: texts } = (await API.get('api', `/employers/simulation/simulationCompleted/${uniqueLink}`, {})) as {
    payload: { wellDone: string; applyTo: string; simulationRecieved: string };
  };
  return texts;
};

const compareSortValues = <T>(A: T, B: T, orderBy: 'ASC' | 'DESC'): 1 | -1 => {
  const expression = orderBy === 'ASC' ? A > B : A < B;
  return expression ? 1 : -1;
};

const sortSimulations = <T extends IEmployersSimulation>(simulations: T[], sortBy: ISortByDetails) => {
  const { keyName, orderBy } = sortBy;
  if (sortBy.isCoreTask) {
    return simulations.sort((a, b) => {
      return compareSortValues(
        a?.[keyName as `coreTask${number}`].score,
        b?.[keyName as `coreTask${number}`].score,
        orderBy,
      );
    });
  } else if (keyName === 'candidateStatus') {
    return simulations.sort((a, b) => {
      return compareSortValues(a.candidateStatus || 0, b.candidateStatus || 0, orderBy);
    });
  } else if (keyName === 'name') {
    return simulations.sort((a, b) => {
      return compareSortValues(a[keyName].toLowerCase() || 0, b[keyName].toLowerCase() || 0, orderBy);
    });
  } else {
    const castedKeyName = keyName as keyof Pick<
      IEmployersSimulation,
      'name' | 'candidateStatus' | 'predictionScore' | 'updatedAt'
    >;
    return simulations.sort((a, b) => {
      return compareSortValues(a[castedKeyName] || 0, b[castedKeyName] || 0, orderBy);
    });
  }
};

export const fetchCandidateResumeLink = async (sessionId: string, fileName: string) => {
  const defaultLink = '';
  const shouldFetchToSampleEndpoint = !isValidUUID(sessionId);
  if (!shouldFetchToSampleEndpoint) {
    const { payload: resume } = (await API.get(
      'api',
      `/employers/reports/resume/link/${sessionId}?fileName=${fileName}`,
      {},
    )) as {
      payload: string;
    };
    return resume;
  }
  return defaultLink;
};

export const SimulationService = {
  getAllSimulations,
  updateBusinessSimulationStatus,
  getAllStatesAndCoreTasksNames,
  sortSimulations,
  updateSimulationViewStatus,
  createBusinessSimulationNote,
  deleteBusinessSimulationNote,
  updateBusinessSimulationNote,
  updateSimulationFavoriteStatus,
  fetchCandidateResumeLink,
  getAllStatuses,
  getSimulationCompletedTexts,
};
