import { useRef, useState, useCallback, useEffect } from 'react';

export const useOverflowDetection = <Element extends HTMLElement>(overflowDirection: 'vertical' | 'horizontal') => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const setIsOverflowingByDirection = (node: Element) => {
    const expressionByDirection =
      overflowDirection === 'vertical' ? node.scrollHeight > node.clientHeight : node.scrollWidth > node.clientWidth;
    setIsOverflowing(expressionByDirection);
  };

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const refCallback = useCallback((node: Element | null) => {
    if (node) {
      resizeObserverRef.current = new ResizeObserver(() => {
        setIsOverflowingByDirection(node);
      });
      resizeObserverRef.current.observe(node);
    }
  }, []);
  useEffect(() => {
    return () => resizeObserverRef.current?.disconnect();
  }, []);
  return { refCallback, isOverflowing };
};
