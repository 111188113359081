import React from 'react';
import {
  CloseButton,
  IconElement,
  IconWrapper,
  MessageElement,
  TitleAndMessageContainer,
  TitleElement,
  ToastNotificationContainer,
} from './style';
import { IToastNotification, notificationTypeDetails } from './constants';

export const ToastNotification: React.FC<IToastNotification & { handleClose: () => void }> = ({
  message,
  type,
  handleClose,
  zIndex,
}) => {
  return (
    <ToastNotificationContainer zIndex={zIndex}>
      <IconWrapper>
        <IconElement type={type} />
      </IconWrapper>
      <TitleAndMessageContainer>
        <TitleElement>{notificationTypeDetails[type]?.title}</TitleElement>
        <MessageElement>{message}</MessageElement>
      </TitleAndMessageContainer>
      <CloseButton onClick={handleClose} />
    </ToastNotificationContainer>
  );
};
