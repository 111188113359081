import styled from 'styled-components';
import { MagnifyingGlass } from './icons';
import { ResettedButton } from './ResettedButton';
import { Tooltip } from './ToolTip';
import { SMALL_DEVICES } from '../constants/breakpoints';

export const LabelWithSearchIcon = styled.label<{ background?: string }>`
  background: ${({ background }) => background ?? 'white'};

  border-radius: 8px;

  border: 1px solid transparent;
  display: flex;
  ${MagnifyingGlass}, ${ResettedButton} {
    margin-block: auto;
    margin-inline-end: 14px;
  }
  :focus-within,
  :hover {
    border-color: var(--almost-black);
  }
  :focus-within {
    outline: 1px solid var(--almost-black);
  }
`;
LabelWithSearchIcon.displayName = 'LabelWithSearchIcon.styled';

export const AnchorLikeButton = styled.a`
  appearance: button;
  -moz-appearance: button;
  -webkit-appearance: button;
  display: inline-block;
  text-decoration: none;
  text-align: center;
`;
AnchorLikeButton.displayName = 'AnchorLikeButton.styled';

export const ShareIconElement = styled.i.attrs({ className: 'fa-regular fa-share-nodes' })`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: var(--almost-black);
`;

ShareIconElement.displayName = 'ShareIconElement.styled';

export const ExportIconElement = styled.i.attrs(({ className }) => ({
  className: 'fa-regular fa-file-export ' + className ?? '',
}))`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: var(--almost-black);
`;

ExportIconElement.displayName = 'ExportIconElement.styled';

export const CandidatesHeaderBaseLink = styled(AnchorLikeButton)`
  height: 44px;
  width: 48px;
  display: grid;
  place-items: center;
`;
CandidatesHeaderBaseLink.displayName = 'CandidatesHeaderBaseLink.styled';

export const CandidatesActionsElements = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30.5px;
`;
CandidatesActionsElements.displayName = 'CandidatesActionsElements.styled';

export const CandidatesHeaderControls = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-end: ${({ isMobile }) => (isMobile ? '32px' : 'unset')};
  ${LabelWithSearchIcon} {
    ${({ isMobile }) => (isMobile ? '' : 'margin-inline-end: 10px;')};
  }
`;
CandidatesHeaderControls.displayName = 'CandidatesHeaderControls.styled';

export const ElementWithTooltip = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${Tooltip} {
    display: none;
  }
  .disabled {
    color: var(--grey);
    cursor: not-allowed;
  }
  & :hover + ${Tooltip} {
    display: flex;
  }
`;
ElementWithTooltip.displayName = 'ElementWithTooltip.styled';

export const SearchField = styled.input.attrs({ type: 'search', placeholder: 'search' })`
  background: none;
  font-weight: 600;
  &,
  ::placeholder {
    color: var(--almost-black);
    font-family: var(--primary-font);
    font-size: 14px;
    font-style: normal;
    line-height: 28px;
  }
  ::placeholder {
    font-weight: 400;
    text-transform: capitalize;
    opacity: 1;
  }
  border: none;
  outline: none;
  border-radius: 8px 0 0 8px;
  padding-block: 6px;
  padding-inline-start: 11.5px;
  height: 40px;
  /* ::-webkit-search-decoration,
  ::-webkit-search-results-decoration,
  ::-webkit-search-results-button, */
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 205.5px;
  }

  width: 267px;
`;
SearchField.displayName = 'SearchField.styled';

export const NewLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  :not(.static) {
    position: absolute;
    top: -6.5px;
    left: 50%;
  }
  width: 30px;
  --label-height: 18px;
  height: var(--label-height);
  border-radius: var(--label-height);
  color: white;
  background: var(--platform-pink-gradient);
  flex-shrink: 0;
  ::before {
    text-transform: lowercase;
    content: 'new';
  }
`;
NewLabel.displayName = 'NewLabel.styled';
