import { CandidateWelcome } from '../../pages/Welcome/CandidateWelcome';
import { LogoContainerWrapper } from '../LogoContainer/LogoContainerWrapper';
import { PageIndicator } from '../PageIndicator/PageIndicator';
import { StandardSectionWrapper } from '../RegistrationSideMenu/style';
import { AdditionalInfo } from '../Reusable-styled-elements/AdditionalInfo';
import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { WelcomeContainer } from './style';

export const MobileRegisterWelcome: React.FC<{
  onContinue: (value: boolean) => void;
  navigateToLogin: () => void;
}> = ({ onContinue, navigateToLogin }) => {
  return (
    <WelcomeContainer>
      <LogoContainerWrapper />
      <CandidateWelcome alignCenter titleSize="28px" registrationLanguage="en"></CandidateWelcome>
      <StandardSectionWrapper>
        <ButtonOrangeFilled type="button" onClick={() => onContinue(true)}>
          Continue
        </ButtonOrangeFilled>
        <AdditionalInfo color="white">
          Already have an account?
          <button onClick={navigateToLogin} type="button" style={{ color: 'white' }}>
            Login
          </button>
        </AdditionalInfo>
        <PageIndicator currentPage={2} totalPages={2}></PageIndicator>
      </StandardSectionWrapper>
    </WelcomeContainer>
  );
};
