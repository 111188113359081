import React, { useMemo } from 'react';
import { areEqual } from 'react-window';
import { Placement, autoPlacement, offset, useFloating } from '@floating-ui/react';

import { zIndices } from '../../assets/zIndices';
import { DirectionOptionsOptions, Tooltip } from '../ToolTip';
interface ToolTipProps {
  color?: string;
  fontWeight?: string;
  textColor?: string;
  className?: string;
  direction?: DirectionOptionsOptions;
  text: string;
  floatingPlacement: Placement;
  isDisplayed: boolean;
  children: (referenceCallback: (ref: HTMLElement | null) => void) => React.ReactNode;
}

export const FloatingToolTipWithTriggerElement: React.FunctionComponent<ToolTipProps> = React.memo(
  ({
    text,
    color,
    fontWeight,
    textColor,
    floatingPlacement,
    className,
    isDisplayed,
    direction,
    children: TriggerElement,
  }) => {
    const { refs, strategy, x, y } = useFloating({
      middleware: [offset(10), autoPlacement({ allowedPlacements: [floatingPlacement] })],
    });
    const { setReference, setFloating } = refs;
    const memoizedChildren = useMemo(() => TriggerElement(setReference), [TriggerElement, setReference]);
    return (
      <>
        {memoizedChildren} {/* Render the children function */}
        {isDisplayed && (
          <Tooltip
            color={color}
            fontWeight={fontWeight}
            textColor={textColor}
            className={className}
            ref={setFloating}
            direction={direction ?? 'none'}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0, zIndex: zIndices.mobilePopup }}
          >
            {text}
          </Tooltip>
        )}
      </>
    );
  },
  areEqual,
);
//Share simulation link
