import React from 'react';
import reactStringReplace from 'react-string-replace';
import { areEqual } from 'react-window';
import { HighlightTextElement } from './style';

const Highlight: React.FC<{ searchTerm: string }> = ({ searchTerm }) => (
  <HighlightTextElement className="highlight-search">{searchTerm}</HighlightTextElement>
);

interface HighlightedSearchTermProps {
  text: string;
  searchTerm: string;
}
/**
 * using index as a key is okay this time since the text will always preserve it's order
 */
const renderHighlight = (match: string, i: number) => <Highlight searchTerm={match} key={i} />;

export const HighlightedSearchTerm: React.FC<HighlightedSearchTermProps> = React.memo(({ text, searchTerm }) => {
  const highlightedText = reactStringReplace(text.trim(), searchTerm.trim(), renderHighlight);
  return <>{highlightedText}</>;
}, areEqual);
