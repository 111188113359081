import { formatCoreTaskScore } from '../../../utils';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { MobileCoreTasksContainer } from '../../MobileTheme/MobileCoreTasksContainer/MobileCoreTasksContainer';
import { HighlightedSearchTerm } from '../../HighlightedSearchTerm/HighlightedSearchTerm';
import {
  EitherBackground,
  FilledBar,
  GradeElement,
  HorizontalProgressBarElement,
  ProgressBarColumnContainer,
} from './style';
import { CSSProperties } from 'react';

type IHorizontalProgressBarProps = {
  grade: number;
  max: number;
  title: string;
  withSearchHighlight?: boolean;
  withoutGradeLabel?: boolean;
  height?: CSSProperties['height'];
  borderRadius?: CSSProperties['borderRadius'];
  width?: CSSProperties['width'];
  search: string;
  isViewed?: boolean;
} & EitherBackground;

/**
 * HorizontalProgressBar Component.
 *
 * This component displays a horizontal progress bar with various configurable options.
 *
 * @component
 *
 * @example
 * ```tsx
 * <HorizontalProgressBar grade={50} max={100} />
 * ```
 *
 *  @note It's required to specify either background either backgroundColor
 *
 *
 * @param {number} props.grade - The current grade or value of the progress bar.
 * @param {number} props.max - The maximum value for the progress bar.
 * @param {string} props.title - The title to be displayed along with the progress bar on mobile displays.
 * @param {boolean} [props.withSearchHighlight] - Whether to highlight the search term within the progress bar.
 * @param {boolean} [props.withoutGradeLabel] - Whether to hide the grade label on the progress bar.
 * @param {CSSProperties['height']} [props.height] - Optional height for the progress bar.
 * @param {CSSProperties['borderRadius']} [props.borderRadius] - Optional border radius for the progress bar.
 * @param {CSSProperties['width']} [props.width] - Optional width for the progress bar.
 *
 * @param {string} [props.backgroundColor] - The background color to use. Must specify either `backgroundColor` or `background`.
 * @param {string} [props.background] - The background image to use. Must specify either `backgroundColor` or `background`.
 *
 * @returns {React.ReactElement} The rendered HorizontalProgressBar component.
 */

export const HorizontalProgressBar: React.FC<IHorizontalProgressBarProps> = ({
  grade,
  max,
  borderRadius,
  title,
  withSearchHighlight,
  withoutGradeLabel,
  height,
  width,
  search,
  isViewed,
  ...restOfProps
}) => {
  let filledBarAdditionalPropsByType: EitherBackground;

  if ('background' in restOfProps) {
    const { background } = restOfProps;
    filledBarAdditionalPropsByType = { background };
  } else if ('backgroundColor' in restOfProps) {
    const { backgroundColor } = restOfProps;
    filledBarAdditionalPropsByType = { backgroundColor };
  } else return null;

  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const percentage: `${number}%` = `${(grade / max) * 100}%`;
  const gradeAsString = formatCoreTaskScore(grade);

  const gradeToRender = withSearchHighlight ? (
    <HighlightedSearchTerm text={gradeAsString} searchTerm={search} />
  ) : (
    gradeAsString
  );
  const renderHorizontalProgressBar = (
    <ProgressBarColumnContainer width={width}>
      {!withoutGradeLabel && (
        <GradeElement fontSize={isViewed ? '15px' : '15px'} fontWeight={isViewed ? '200' : '600'}>
          {gradeToRender}
        </GradeElement>
      )}
      <HorizontalProgressBarElement height={height} borderRadius={borderRadius}>
        <FilledBar {...filledBarAdditionalPropsByType} percentage={percentage} />
      </HorizontalProgressBarElement>
    </ProgressBarColumnContainer>
  );

  return isMobile ? (
    <MobileCoreTasksContainer title={title}>{renderHorizontalProgressBar}</MobileCoreTasksContainer>
  ) : (
    <>{renderHorizontalProgressBar}</>
  );
};
