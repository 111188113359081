import styled from 'styled-components';
import { AvatarElement } from '@skillset/ui';

export const UserCardWrapper = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  height: 67px;
`;
UserCardWrapper.displayName = 'UserCardWrapper.styled';

export const UserCardElement = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  ${AvatarElement} {
    margin: 0 8px 0 15px;
  }
`;
UserCardElement.displayName = 'UserCardElement.styled';

export const DetailsColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
DetailsColumnContainer.displayName = 'DetailsColumnContainer.styled';

export const FullNameSpan = styled.span`
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 12.5px;
  line-height: 14.71px;
  color: var(--almost-black);
  text-transform: capitalize;
`;
FullNameSpan.displayName = 'FullNameSpan.styled';

export const EmailSpan = styled.span`
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-grey);
`;
EmailSpan.displayName = 'EmailSpan.styled';
