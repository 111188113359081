import styled from 'styled-components';

import { EXTRA_EXTRA_SMALL_DEVICES, EXTRA_SMALL_DEVICES, SMALL_DEVICES } from '@skillset/ui';
import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { Heading } from '../../components/Reusable-styled-elements/Heading';

export const ResendPasswordFormContainer = styled.div`
  max-width: 438px;
  width: 100%;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px auto 0;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    justify-content: flex-start;
    margin-top: 34px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    margin-top: 55px;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    justify-content: center;
    margin-top: 0;
  }

  ${ButtonOrangeFilled} {
    width: unset;
    padding: 10px 20.5px;
    margin: 35px auto 0;
  }

  ${Heading} {
    margin-bottom: 10px;
  }
`;
ResendPasswordFormContainer.displayName = 'ResendPasswordFormContainer.styled';
