/*
 * At least one upper case English letter, (?=.*[A-Z])
 * At least one lower case English letter, (?=.*[a-z])
 * At least one digit, (?=.*\d)
 * At least one special character, (?=.*?[@$!%*?&])
 * Minimum eight in length .{8,} (with the anchors)
 */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&-])[A-Za-z\d@#$!%*?&-]{8,}$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
