import TagManager from 'react-gtm-module';

export interface IGAParams {
  eventCategory: string;
  eventName: string;
  label: string;
  userID?: string;
}
const disableGTM = process.env.NODE_ENV === 'development';
interface N {
  (): void;
  callMethod: (...args: IArguments & unknown[]) => void;
  version: '2.0';
  queue: unknown[];
  loaded: true;
  push: () => void;
}

interface FBQ {
  (action: 'init' | 'track' | 'trackCustom', event: string): void;
}
declare global {
  let fbq: FBQ;
  interface Window {
    fbq?: FBQ;
    _fbq: FBQ;
  }
}
/**
 * copy paste from script tag snippet
 */
const initMetaPixel = (code: string) => {
  (function (window: Window, document: Document, pixelScriptSrc: string) {
    if (window.fbq) return;
    const n: N = (window.fbq = function () {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prefer-spread, prefer-rest-params
      n.callMethod ? n.callMethod.apply(n, arguments as N['callMethod']['arguments']) : n.queue.push(arguments);
    }) as N;
    if (!window._fbq) window._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    const t = document.createElement('script');
    t.async = true;
    const s = document.getElementsByTagName('script')[0];
    (s.parentNode as ParentNode).insertBefore(t, s);
    t.src = pixelScriptSrc;
  })(window, document, 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', code);
};

export const platformAnalyticsInit = (tagManagerCode: string, fbCode: string) => {
  if (disableGTM) return;
  if (tagManagerCode) {
    const tagManagerArgs = {
      gtmId: tagManagerCode,
      dataLayer: {
        cookieFlags: 'SameSite=None; Secure', // Ensure that cookies follow Chrome's standards
      },
    };

    TagManager.initialize(tagManagerArgs);
  }

  if (fbCode) initMetaPixel(fbCode);
};

export const triggerAnalyticsEvent = (params: IGAParams, facebookAnalyticsCode?: string, tagManagerCode?: string) => {
  if (disableGTM) return;

  if (facebookAnalyticsCode) {
    fbq('trackCustom', params.eventName);
  }

  if (tagManagerCode)
    TagManager.dataLayer({
      dataLayer: {
        event: 'ga_event',
        category: params.eventCategory,
        action: params.eventName,
        label: params.label,
        userId: params.userID || '',
        cookieFlags: 'SameSite=None; Secure', // Ensure that cookies follow Chrome's standards
      },
    });
};
