import styled from 'styled-components';

import { EXTRA_EXTRA_LARGE_DEVICES, EXTRA_LARGE_DEVICES, MEDIUM_DEVICES, SMALL_DEVICES } from '@skillset/ui';
import { TextTemplate } from '../../utils/types';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  align-items: center;
  gap: 50px;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    justify-content: flex-start;
    padding: 30px 30px 60px 30px;
  }
`;
PageContainer.displayName = 'PageContainer.styled';

export const PageWrapper = styled.div<{ textTemplate?: TextTemplate }>`
  display: grid;
  grid-template-columns: ${({ textTemplate }) => (textTemplate === 'loginTemplate' ? '473px 1fr' : '355px 1fr')};
  min-height: 100vh;

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    grid-template-columns: 1fr;
  }
`;
PageWrapper.displayName = 'PageWrapper.styled';

export const CenteredPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

CenteredPageWrapper.displayName = 'CenteredPageWrapper.styled';

export const QuestionnairePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 100px;
  gap: 50px;
  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    padding: 50px 75px;
  }
  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    padding: 40px 75px;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    padding: 50px;
  }
  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding: 25px 30px 35px 30px;
  }
`;
QuestionnairePageContainer.displayName = 'QuestionnairePageContainer.styled';
