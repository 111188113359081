import { UAParser } from 'ua-parser-js';

import { brandColors } from '../constants';
import { SHOW_NEWLABEL_TIMESTAMP_DIFF, PREDICTIONSCORE_MAX } from '../constants/simulation';

export const getNameInitials = (name: string) => {
  const splittedName = name.toUpperCase().split(' ');
  if (splittedName.length < 2) return 'N/A';
  return `${splittedName[0].substring(0, 1)}${splittedName[1].substring(0, 1)} `;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

/**
 * The format is: MM/DD/YY
 */
export const formatDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2); // Take last two digits of the year
  return `${month}/${day}/${year}`;
};

/**
 * The format is: HH:MM
 */
export const formatTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * inspired by https://github.com/kolodny/weak-key
 */
const keysMap = new WeakMap<object, string>();
let index = 0;

/**
 * generate react key for objects
 */
export const weakKey = <T extends object>(obj: T) => {
  let key = keysMap.get(obj);
  if (!key) {
    key = 'weak-key-' + index++;
    keysMap.set(obj, key);
  }
  return key;
};

/**
 * returns true for mobile
 */
export const detectMobile = () => {
  const parser = new UAParser();

  return UAParser.DEVICE.MOBILE === parser.getDevice().type;
};
/**
 * returns true for Apple Mobile devices
 */
export const detectAppleMobileDevice = () => {
  const parser = new UAParser();
  return parser.getDevice().vendor === 'Apple' && UAParser.DEVICE.MOBILE === parser.getDevice().type;
};
/**
 * returns true for Firefox
 */
export const detectFirefox = () => {
  const parser = new UAParser();
  return parser.getBrowser().name === 'Firefox';
};

export const getBrandColorByOrder = (idx: number) => {
  const colorIndex = idx % brandColors.length;
  return brandColors[colorIndex];
};

export const isNewSimulation = (updatedAt: ReturnType<Date['toJSON']>) =>
  new Date(updatedAt).getTime() > Date.now() - SHOW_NEWLABEL_TIMESTAMP_DIFF;

export const normalizePredictionScore = (score: number) => Math.round((score / PREDICTIONSCORE_MAX) * 100);

export const formatPredictionScore = (score: number) => `${Math.round(score)}%`;

export const formatCoreTaskScore = (score: number) => {
  const twoDigitsGrade = score.toPrecision(2);
  const gradeAsString =
    twoDigitsGrade.length > 3 ? twoDigitsGrade.substring(0, 3) : twoDigitsGrade === '0.0' ? '0' : twoDigitsGrade;
  return gradeAsString;
};

export const getFirstNameFirstLetter = (firstName: string | undefined) =>
  Array.from(firstName ?? '')[0]?.toUpperCase() || 'N/A'; //this one works for weird unicodes

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type OptionalSortValues = string | number | Date;
export const compareSortValues = <T extends OptionalSortValues>(A: T, B: T, orderBy: 'ASC' | 'DESC'): 1 | -1 => {
  const expression = orderBy === 'ASC' ? A > B : A < B;
  return expression ? 1 : -1;
};

export const toCamelCase = (str: string): string => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, idx) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return idx === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const removeClassIfExists = (selector: string, className: string) => {
  const elements = document.querySelectorAll(selector) as NodeListOf<Element>;
  elements.forEach((element) => {
    element.classList.remove(className);
  });
};

export const addParentClassIfChildExists = (selector: string, className: string) => {
  const elements = document.querySelectorAll(selector) as NodeListOf<Element>;
  elements.forEach((element) => {
    element.parentElement?.classList.add(className);
  });
};

export const getClickableLink = (link: string) => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
};
