const defaultSignUpRoute = '/accounts/signup';
const defaultRecoverRoute = '/accounts/recover';

export const SIGN_UP_ROUTES = {
  REGISTER: defaultSignUpRoute,
  CONFIRM: `${defaultSignUpRoute}/confirm`,
};

export const RECOVER_ROUTES = {
  FORGOT_PASSWORD: `${defaultRecoverRoute}/forgot`,
  RESET_PASSWORD: `${defaultRecoverRoute}/reset`,
  RESET_PASSWORD_SUCCESS: `${defaultRecoverRoute}/reset-success`,
  RESEND_PASSWORD: `${defaultRecoverRoute}/resend`,
};

export const ROUTE_TERMS_OF_USE = '/terms-of-use';
export const ROUTE_LOGIN = '/login';
