import { CurrentPageIndicator, OtherPageIndicator, PageIndicatorElement } from './style';

interface PageIndicatorProps {
  totalPages: number;
  currentPage: number;
}

export const PageIndicator: React.FC<PageIndicatorProps> = ({ totalPages, currentPage }) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index);
  const indicatorBar = pages.map((page) => {
    if (page === currentPage - 1) {
      return <CurrentPageIndicator key={page} />;
    }
    return <OtherPageIndicator key={page} />;
  });
  return <PageIndicatorElement>{indicatorBar}</PageIndicatorElement>;
};
