import styled from 'styled-components';

interface ISpinnerElementProps {
  size?: string;
  color?: string;
}

export const SpinnerElement = styled.span<ISpinnerElementProps>`
  height: ${({ size }) => (size ? size : '16px')};
  width: ${({ size }) => (size ? size : '16px')};
  border: ${({ color }) => `3px solid ${color ?? 'var(--almost-black)'}`};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
SpinnerElement.displayName = 'SpinnerElement.styled';
