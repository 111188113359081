import React from 'react';
import { FilterSectionContainer, FiltersSectionRow } from '../../../components/FilterSection/style';
import { CustomizedFilterField, CustomizedCheckboxOption } from '@skillset/ui';
import { zIndices } from '../../../assets/zIndices';

interface JobsFilterSectionProps {
  options: CustomizedCheckboxOption[];
  updateCheckboxField: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked: (category: string, value: string) => boolean;
}

export const JobsFilterSection: React.FC<JobsFilterSectionProps> = ({
  options,
  updateCheckboxField,
  isCheckboxOptionChecked,
}) => {
  return (
    <FilterSectionContainer>
      <FiltersSectionRow>
        <span className="title">Filter by: </span>
        <CustomizedFilterField
          zIndexMobilePopup={zIndices.mobilePopup}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          name="Job Name"
          type="customizedCheckboxWithSearch"
          options={options}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
        />
      </FiltersSectionRow>
    </FilterSectionContainer>
  );
};
