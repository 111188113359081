import { Language } from './general';

export interface Texts {
  theEntireFlow: string;
  createAccount: string;
  tryOutTheJob: string;
  discoverYourNextJob: string;
}

export const sideMenuTexts: Record<Language, Texts> = {
  en: {
    theEntireFlow: 'The entire flow:',
    createAccount: 'Create account',
    tryOutTheJob: 'Try out the job',
    discoverYourNextJob: 'Discover your next job oppertunities',
  },
  es: {
    theEntireFlow: 'Todo el flujo',
    createAccount: 'Crear cuenta',
    tryOutTheJob: 'Prueba el trabajo',
    discoverYourNextJob: 'Descubre tus próximas oportunidades de trabajo',
  },
  he: {
    theEntireFlow: 'הFLOW המלא',
    createAccount: 'צור חשבון',
    tryOutTheJob: 'נסה את התפקיד',
    discoverYourNextJob: 'גלה את התפקיד הבא שלך',
  },
  pt: {
    theEntireFlow: 'Todo o fluxo:',
    createAccount: 'Criar conta',
    tryOutTheJob: 'Experimente o trabalho',
    discoverYourNextJob: 'Descubra suas próximas oportunidades de trabalho',
  },
};
