import styled from 'styled-components';

import {
  EXTRA_EXTRA_LARGE_DEVICES,
  EXTRA_EXTRA_SMALL_DEVICES,
  EXTRA_LARGE_DEVICES,
  EXTRA_SMALL_DEVICES,
  LARGE_DEVICES,
  MEDIUM_DEVICES,
  SMALL_DEVICES,
} from '@skillset/ui';

import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';

export const ConfirmEmailInnerContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  justify-self: center;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 50px;
    justify-content: flex-start;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    margin-top: 70px;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    margin-top: 70px;
  }

  ${Heading} {
    margin-bottom: 33px;
    text-align: center;

    @media screen and (max-width: ${MEDIUM_DEVICES}) {
      margin-bottom: 46px;
    }

    @media screen and (max-width: ${SMALL_DEVICES}) {
      margin-bottom: 10px;
    }
  }

  & span,
  & strong,
  & a {
    font-family: var(--gilroy-font);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: var(--dark-gray);

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      font-size: 17px;
      line-height: 26px;
    }

    @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & strong {
    font-weight: 500;
    color: var(--very-dark-gray);
  }

  ${ButtonOrangeFilled} {
    margin: 50px 0px 40px 0px;
    align-self: center;
    padding: 15.5px 14px;
    width: fit-content;

    @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
      font-size: 18.5px;
    }

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      padding: 12px 14px;
      font-size: 17px;
    }

    @media screen and (max-width: ${LARGE_DEVICES}) {
      padding: 15.5px 14px;
      font-size: 18.5px;
    }
    @media screen and (max-width: ${LARGE_DEVICES}) {
      font-size: 17px;
    }
  }
`;
ConfirmEmailInnerContainer.displayName = 'ConfirmEmailInnerContainer.styled';

export const UserEmail = styled.span`
  display: block;
  margin-bottom: 10px;
  color: var(--very-dark-gray) !important;
`;
UserEmail.displayName = 'UserEmail.styled';

export const RecreateContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  & span,
  & strong,
  & a {
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
RecreateContainer.displayName = 'RecreateContainer.styled';
