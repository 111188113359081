import { useFloatingTree, useListItem } from '@floating-ui/react';
import { useMenuContext } from './MenuContext';
interface MenuItemProps {
  label: string;
  disabled?: boolean;
}
export const useMenuItem = ({ label, disabled }: MenuItemProps) => {
  const menu = useMenuContext();
  const item = useListItem({ label: disabled ? null : label });
  const tree = useFloatingTree();
  return { menu, item, tree };
};
