import { LogoContainerWrapper } from '../../../components/LogoContainer/LogoContainerWrapper';
import { PageIndicator } from '../../../components/PageIndicator/PageIndicator';
import { Heading } from '../../../components/Reusable-styled-elements/Heading';
import { QuestionnaireForm } from '../../../components/QuestionnaireForm/QuestionnaireForm';
import { PageWrapper, QuestionnairePageContainer } from '../../../components/Reusable-styled-elements/PageContainer';
import { Subheader } from '../../../components/Reusable-styled-elements/Subheader';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { textTemplates } from '../../../utils/types';
import { Welcome } from '../../Welcome/Welcome';
import { BusinessUserQuestionare } from './BusinessUserQuestionare';
import { BriefcaseIcon, QuestionnaireFormContainer } from './styles';
import { Language } from '../../../utils/languages/general';
import { getQuestionnaireTexts } from '../../../services/languages.service';

export const QuestionnairePhase: React.FC<{
  platformName: string;
  isBusinessUser?: boolean;
  uniqueLink?: string;
  registrationLanguage?: Language;
}> = ({ platformName, isBusinessUser, uniqueLink, registrationLanguage }) => {
  const { isMobile } = useWindowDimensions({ widthConsideredAsMobile: 1024 });
  const desiredUrl = localStorage.getItem('desiredUrl');
  const isAnonymous = desiredUrl && desiredUrl.includes('username');

  const texts = getQuestionnaireTexts(registrationLanguage);
  const candidateQuestionate = (
    <>
      <Heading>{texts.personalInformationTitle}</Heading>
      <Subheader>{texts.tellUseAboutYourself}</Subheader>
      <QuestionnaireForm
        platformName={platformName}
        uniqueLink={uniqueLink ?? ''}
        registrationLanguage={registrationLanguage}
      />
    </>
  );

  return (
    <PageWrapper textTemplate={isBusinessUser ? textTemplates.BUSINESS_QUESTIONNAIRE : textTemplates.QUESTIONARE}>
      {!isMobile && (
        <Welcome
          textTemplate={isBusinessUser ? textTemplates.BUSINESS_QUESTIONNAIRE : textTemplates.QUESTIONARE}
          isBusinessUser={isBusinessUser}
          registrationLanguage={registrationLanguage}
        />
      )}
      <QuestionnairePageContainer>
        {isMobile && !isAnonymous && <LogoContainerWrapper style={{ alignSelf: 'flex-start' }} />}
        <QuestionnaireFormContainer>
          {!isAnonymous && <BriefcaseIcon />}
          {isBusinessUser ? (
            <BusinessUserQuestionare platformName={platformName} />
          ) : !isAnonymous ? (
            candidateQuestionate
          ) : (
            <QuestionnaireForm
              platformName={platformName}
              uniqueLink={uniqueLink ?? ''}
              registrationLanguage={registrationLanguage}
            />
          )}
        </QuestionnaireFormContainer>
        {!isAnonymous && <PageIndicator totalPages={2} currentPage={2}></PageIndicator>}
      </QuestionnairePageContainer>
    </PageWrapper>
  );
};
