import { validate as isValidUUID } from 'uuid';
import { API } from '@skillset/onelogin';

import { SuccessResponse } from '../utils/types';
import { Candidate } from '../store/types/EmployerCandidatesInterface';
import { ISortBy } from '../hooks/useSortBy';
import { compareSortValues } from '../utils';

const getAllByJobId = (jobId: string) => {
  const queryStringParameters = { jobId } as const;
  return API.get('api', '/employers/candidate', { queryStringParameters }) as Promise<SuccessResponse<Candidate[]>>;
};
const getAllBySimulationTypeId = (simulationTypeId: number) => {
  const queryStringParameters = { simulationTypeId } as const;
  return API.get('api', '/employers/candidate', { queryStringParameters }) as Promise<SuccessResponse<Candidate[]>>;
};

export const sortCandidates = (candidates: Candidate[], sortBy: ISortBy) => {
  const { keyName, orderBy } = sortBy;
  switch (keyName) {
    case 'name':
      return candidates.sort((a, b) => {
        return compareSortValues(a.name, b.name, orderBy);
      });
    case 'phone':
      return candidates.sort((a, b) => {
        return compareSortValues(a.phone, b.phone, orderBy);
      });
    case 'email':
      return candidates.sort((a, b) => {
        return compareSortValues(a.email, b.email, orderBy);
      });
    case 'latestInteraction':
      return candidates.sort((a, b) => {
        return compareSortValues(a.latestInteraction, b.latestInteraction, orderBy);
      });
    case 'engagementScore':
      return candidates.sort((a, b) => {
        return compareSortValues(a?.engagementScore || 0, b?.engagementScore || 0, orderBy);
      });
    case 'predictionScore':
      return candidates.sort((a, b) => {
        return compareSortValues(a?.predictionScore || 0, b?.predictionScore || 0, orderBy);
      });
    default:
      return candidates;
  }
};
export type SampleNoteId = `sample-${number}`;

const createCandidatePerJobNote = (candidateUUID: string, jobId: string, note: string) => {
  return API.post('api', `/employers/notes/candidate/${candidateUUID}/job/${jobId}`, { body: { note } });
};
const updateCandidatePerJobNote = (noteId: number | SampleNoteId, note: string) => {
  return API.patch('api', `/employers/notes/candidate/job/${noteId}`, { body: { note } });
};
const deleteCandidatePerJobNote = (noteId: number | SampleNoteId) => {
  return API.del('api', `/employers/notes/candidate/job/${noteId}`, {});
};

const createCandidatePerSimulationTypeNote = (candidateUUID: string, simulationTypeId: number, note: string) => {
  return API.post('api', `/employers/notes/candidate/${candidateUUID}/simulationType/${simulationTypeId}`, {
    body: { note },
  });
};
const updateCandidatePerSimulationTypeNote = (noteId: number | SampleNoteId, note: string) => {
  return API.patch('api', `/employers/notes/candidate/simulationType/${noteId}`, { body: { note } });
};
const deleteCandidatePerSimulationTypeNote = (noteId: number | SampleNoteId) => {
  return API.del('api', `/employers/notes/candidate/simulationType/${noteId}`, {});
};
const isSampleNoteId = (noteId: string | number): noteId is SampleNoteId =>
  typeof noteId === 'string' && noteId.startsWith('sample-');
const updateCandidateFavorite = (
  candidateUserUUID: string,
  statusToUpdate: boolean,
  queryStringParameters: { jobId: string } | { simulationTypeId: number },
) => {
  return API.put('api', `/employers/candidate/favoriteStatus/${candidateUserUUID}`, {
    body: { statusToUpdate },
    queryStringParameters,
  });
};

const updateCandidateViewed = (
  candidateUserUUID: string,
  queryStringParameters: { jobId: string } | { simulationTypeId: number },
) => {
  return API.put('api', `/employers/candidate/viewedStatus/${candidateUserUUID}`, {
    queryStringParameters,
  });
};

export const fetchCandidateResumePath = async (candidateUUID: string) => {
  try {
    const { payload: resumeFileName } = (await API.get(
      'api',
      `/employers/candidate/resume/path/${candidateUUID}`,
      {},
    )) as {
      payload: string;
    };
    return resumeFileName;
  } catch (error) {
    throw error;
  }
};

export const fetchCandidateResumeLink = async (candidateUUID: string, fileName: string) => {
  const defaultLink = '';
  const shouldFetchToSampleEndpoint = !isValidUUID(candidateUUID);
  if (!shouldFetchToSampleEndpoint) {
    const { payload: resume } = (await API.get(
      'api',
      `/employers/candidate/resume/link/${candidateUUID}?fileName=${fileName}`,
      {},
    )) as {
      payload: string;
    };
    return resume;
  }
  return defaultLink;
};

export const CandidateService = {
  getAllByJobId,
  getAllBySimulationTypeId,
  sortCandidates,
  createCandidatePerJobNote,
  updateCandidatePerJobNote,
  deleteCandidatePerJobNote,
  isSampleNoteId,
  updateCandidateFavorite,
  updateCandidateViewed,
  createCandidatePerSimulationTypeNote,
  updateCandidatePerSimulationTypeNote,
  deleteCandidatePerSimulationTypeNote,
  fetchCandidateResumePath,
  fetchCandidateResumeLink,
};
