import { forwardRef } from 'react';
import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react';
import styled from 'styled-components';

import { MenuComponent, MenuProps } from './MenuComponent';
import { MenuItem } from './MenuItem';

const Menu = forwardRef<HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement>>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});
Menu.displayName = 'FloatingMenu';

const Icon = styled.i`
  width: 16px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  display: grid;
  place-items: center;
`;
Icon.displayName = 'FloatingMenu.Icon.styled';

const FloatingMenu = Menu as typeof Menu & { Item: typeof MenuItem; Icon: typeof Icon };
FloatingMenu.Item = MenuItem;
FloatingMenu.Icon = Icon;

export { FloatingMenu };
