import React, { ChangeEvent, useEffect, useState } from 'react';
import { InputGroupContainer } from '../../FormInputGroup/style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { CustomerAdmin } from '../../../store/types/EmployerAdministrationInterface';

interface SelectCustomerDropDownProps {
  onCustomerSelected: (customerUUID: string) => void;
}

export const SelectCustomerDropDown: React.FC<SelectCustomerDropDownProps> = ({ onCustomerSelected }) => {
  const customers = useSelector((state: RootState) => state.employersAdministrationSliceReducer.customers);
  const businessCustomer = useSelector(
    (state: RootState) => state.employersAdministrationSliceReducer.businessCustomer,
  );
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>('');

  // Ensure we select businessCustomer automatically when no customers are available
  useEffect(() => {
    if (customers.length === 0 && businessCustomer) {
      onCustomerSelected(businessCustomer.customerUUID);
      setSelectedCustomerName(`${businessCustomer.customerName} - (All Customers)`);
    }
  }, [customers, businessCustomer, onCustomerSelected]);

  const handleCustomerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCustomerName(e.target.value);
    const customerUUID = e.target.value.includes('All Customers')
      ? businessCustomer.customerUUID
      : customers.find((customer: CustomerAdmin) => customer.customerName === e.target.value)?.customerUUID ?? '';
    onCustomerSelected(customerUUID);
  };
  const renderDropdown = (label: string, options: string[], selectedValue: string | null) => {
    if (customers.length === 0) {
      return <></>; // No need to render the dropdown if no customers are available
    }
    return (
      <InputGroupContainer hasError={false} touched={false}>
        <label>
          {label}
          <select
            value={selectedValue || ''}
            onChange={handleCustomerChange}
            style={{
              color: selectedValue ? 'var(--almost-black)' : 'var(--grey)',
            }}
          >
            <option value="" disabled>
              {label}
            </option>
            {options.map((option, index) => (
              <option
                key={option}
                value={option}
                style={{ color: 'var(--almost-black)', fontWeight: index === 0 ? 'bold' : 'normal' }}
              >
                {option}
              </option>
            ))}
          </select>
        </label>
      </InputGroupContainer>
    );
  };
  const customerNames: string[] = customers.map((customer: CustomerAdmin) => customer.customerName);
  if (businessCustomer && businessCustomer.customerName) {
    customerNames.unshift(`${businessCustomer.customerName} - (All Customers)`); // Add businessCustomer at the beginning
  }
  return (
    <>
      {renderDropdown('Access to view candidates', customerNames, selectedCustomerName)}
      {/* {renderDropdown(handleStateChange, 'State', states, selectedState, stateError)}
      {renderDropdown(handleCityChange, 'City', cities, selectedCity, cityError)} */}
    </>
  );
};
