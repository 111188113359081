import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IAuthSlice } from '../../store/slices/authSlice';
import { RootState } from '../../store/store';

interface INavigateAndResetURL {
  to: string;
}

export const NavigateAndResetURL: React.FC<INavigateAndResetURL> = ({ to }) => {
  const { authenticated, platformUser } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  if (authenticated && platformUser) {
    localStorage.removeItem('desiredUrl');
  }
  return <Navigate to={to || '/'} />;
};
