import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployerBusinessSlice, Job } from '../types/EmployerBusinessSliceInterface';
import {
  createJobNote,
  getBusinessPermissions,
  getJobs,
  getJobsSampleData,
  getSimulationTypes,
  setJobNotes,
} from '../actions/employerBusinessSlice.action';
import { BusinessService } from '../../services/employerBusinessService';
import { ISortBy } from '../../hooks/useSortBy';

const initialState: EmployerBusinessSlice = {
  jobs: [],
  simulationTypes: [],
  loading: false,
  loadSimulationTypes: false,
  mainBusinessPage: null,
  hasMatchedJobs: false,
  hasAllowedSimulations: false,
  showResume: false,
  businessName: '',
};

const employersBusinessSlice = createSlice({
  name: 'employerBusiness',
  initialState: initialState,
  reducers: {
    applySortBy(state, { payload: { sortBy } }: PayloadAction<{ sortBy: ISortBy }>) {
      state.jobs = BusinessService.sortJobs(state.jobs, sortBy);
    },
    initJobs(state) {
      state.jobs = initialState.jobs;
    },
    initSimulationTypes(state) {
      state.simulationTypes = initialState.simulationTypes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobsSampleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimulationTypes.fulfilled, (state, { payload }) => {
        state.loadSimulationTypes = false;
        state.simulationTypes = payload;
      })
      .addCase(getSimulationTypes.rejected, (state) => {
        state.loadSimulationTypes = false;
      })
      .addCase(getSimulationTypes.pending, (state) => {
        state.loadSimulationTypes = true;
      })
      .addCase(getJobs.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getJobsSampleData.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessPermissions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getJobs.fulfilled, (state, { payload }: PayloadAction<Job[]>) => {
        state.jobs = payload;
        state.loading = false;
      })
      .addCase(getJobsSampleData.fulfilled, (state, { payload }: PayloadAction<Job[]>) => {
        state.jobs = payload;
        state.loading = false;
      })
      .addCase(getBusinessPermissions.fulfilled, (state, { payload }) => {
        state.mainBusinessPage = payload.mainPage;
        state.hasMatchedJobs = payload.hasMatchedJobs;
        state.hasAllowedSimulations = payload.hasAllowedSimulations;
        state.showResume = payload.showResume;
        state.businessName = payload.businessName;
        state.loading = false;
      })
      .addCase(setJobNotes, (state, { payload }) => {
        const { jobId, notes } = payload;
        const jobIndex = state.jobs.findIndex((job) => job.id === jobId);
        if (jobIndex !== -1) state.jobs[jobIndex].notes = notes;
      })
      .addCase(createJobNote.fulfilled, (state, { payload }) => {
        const { createdNoteId, jobId, tempId } = payload;
        const jobIndex = state.jobs.findIndex((job) => job.id === jobId);
        const jobNoteToUpdate = state.jobs[jobIndex].notes.find((note) => note.id === tempId);
        if (jobNoteToUpdate) {
          jobNoteToUpdate.id = createdNoteId;
          jobNoteToUpdate.isPrivileged = true;
        }
      });
  },
});

//Actions are also reducers functions
export const { applySortBy, initSimulationTypes, initJobs } = employersBusinessSlice.actions;

export const employersBusinessSliceReducer = employersBusinessSlice.reducer;
