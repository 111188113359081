import styled from 'styled-components';

import { SMALL_DEVICES } from '@skillset/ui';

export const Heading = styled.h2<{ alignSelf?: string }>`
  margin: 0;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: var(--almost-black);
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'center')};
  text-align: center;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 26px;
    line-height: 31px;
  }
`;
Heading.displayName = 'Heading.styled';
