import styled from 'styled-components';

export const Clone = styled.i.attrs({ className: 'fa-regular fa-clone' })`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* same as element height */
  line-height: 40px;
`;
Clone.displayName = 'Clone.styled';

export const MagnifyingGlass = styled.i.attrs({ className: 'fa-regular fa-magnifying-glass' })`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;

  /* same as element height */
  line-height: 40px;
`;
MagnifyingGlass.displayName = 'MagnifyingGlass.styled';

export const CircleXMark = styled.i.attrs({ className: 'fa-regular  fa-circle-xmark' })`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
`;
CircleXMark.displayName = 'CircleXMark.styled';

export const TrashCan = styled.i.attrs({ className: 'fa-regular fa-trash-can' })`
  display: flex;
  gap: 8px;
  font-size: 18px;
`;
TrashCan.displayName = 'TrashCan.styled';

export const RightArrow = styled.i.attrs({ className: 'fa-regular fa-arrow-right' })`
  display: flex;
  gap: 8px;
  font-size: 18px;
`;
RightArrow.displayName = 'RightArrow.styled';

export const LongRightArrow = styled.i.attrs({ className: 'fa-regular fa-arrow-right-long' })`
  display: flex;
  gap: 10px;
  font-size: 22px;
  color: black;
  align-self: flex-end;
`;
LongRightArrow.displayName = 'LongRightArrow.styled';

export const ChevronUp = styled.i.attrs({ className: 'fa-regular fa-chevron-up' })`
  display: flex;
  gap: 10px;
  font-size: 18px;
  color: black;
  align-self: center;
`;
ChevronUp.displayName = 'ChevronUp.styled';

export const ChevronDown = styled.i.attrs({ className: 'fa-regular fa-chevron-down' })`
  display: flex;
  gap: 10px;
  font-size: 18px;
  color: black;
  align-self: center;
`;
ChevronDown.displayName = 'ChevronDown.styled';
