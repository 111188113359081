import devConfig from './dev.aws.config';
import stagingConfig from './staging.aws.config';
import prodConfig from './prod.aws.config';
import demoConfig from './devdemo.aws.config';
import { localConfig } from './.local.aws.config';

const stage = process.env.REACT_APP_STAGE || process.env.NODE_ENV;
let config: typeof devConfig | typeof stagingConfig | typeof prodConfig | typeof demoConfig | typeof localConfig;
switch (stage) {
  case 'development':
    config = localConfig;
    document.title = `${document.title} | main-site-${config.stage}`;
    break;
  case 'demo':
    config = demoConfig;
    break;
  case 'staging':
    config = stagingConfig;
    document.title = `${document.title} | platform-staging`;
    break;
  case 'production':
    config = prodConfig;
    break;
  default: //fallback
    config = devConfig;
    document.title = `${document.title} | FALLBACK`;
    break;
}

export default config;
