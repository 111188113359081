import styled from 'styled-components';

import { EXTRA_EXTRA_SMALL_DEVICES, EXTRA_SMALL_DEVICES, SMALL_DEVICES } from '@skillset/ui';
import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { ResetPasswordFormContainer } from '../ResetPassword/style';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { PageContainer } from '../../components/Reusable-styled-elements/PageContainer';

export const ResetSuccessInnerContainer = styled(ResetPasswordFormContainer)`
  ${ButtonOrangeFilled} {
    margin: 35px auto;
    padding: 12px 32px;
    width: fit-content;

    @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
      padding: 12px 20.5px;
    }
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    text-align: center;
    max-width: 290px;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    margin-top: 0;
  }

  ${Heading} {
    margin-bottom: 10px;
  }
`;
ResetSuccessInnerContainer.displayName = 'ResetSuccessInnerContainer.styled';

export const ResetSuccessContainer = styled(PageContainer)`
  .logoContainerOverride {
    @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    justify-content: center;
  }
`;
ResetSuccessContainer.displayName = 'ResetSuccessContainer.styled';
