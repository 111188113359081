const localConfig = {
  stage: 'dev',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'skillset-media-storage-main-site-dev-s3bucket-pzp7btdav82z',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ppmcr037m4.execute-api.us-east-1.amazonaws.com/main-site-dev',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_bKKT92uWt',
    APP_CLIENT_ID: '7qgl5qip4suf2v3v8nbud4nuhk',
    IDENTITY_POOL_ID: 'us-east-1:16245e78-8027-40e7-b28c-b2f0eae00175',
    GOOGLE_REDIRECT_SIGN_IN: 'http://localhost:3001/social-login',
    GOOGLE_REDIRECT_SIGN_OUT: 'http://localhost:3001/login',
    GOOGLE_COGNITO_DOMAIN: '',
  },
  social: {
    GOOGLE_ANALYTICS: '',
    TAG_MANAGER_ARGS: '',
  },
  urls: {
    simulations_url: 'http://localhost:3002/',
    api_documentation: 'developer-staging.tatio.io',
  },
};
export default localConfig;
