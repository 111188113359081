import React, { ForwardedRef, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import {
  FloatingNode,
  Placement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { useMenuContext } from './MenuContext';

export interface MenuProps {
  nested?: boolean;
  zIndex?: number;
  placement?: Placement;
  offset?: {
    x?: number;
    y?: number;
  };
}

export const useFloatingMenu = <T extends HTMLElement>(
  { offset: offsetProp, placement = 'bottom-start', zIndex, ...props }: MenuProps & React.HTMLProps<T>,
  forwardedRef?: ForwardedRef<T>,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasFocusInside, setHasFocusInside] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const elementsRef = useRef<Array<T | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const parent = useMenuContext();

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null;

  const { floatingStyles, refs, context } = useFloating<T>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isNested ? 'right-start' : placement,
    middleware: [
      offset({ mainAxis: isNested ? 0 : offsetProp?.y ?? 4, alignmentAxis: isNested ? -4 : offsetProp?.x ?? 0 }),
      flip(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });
  if (!Number.isNaN(zIndex)) floatingStyles.zIndex = zIndex;
  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, { role: 'menu' });

  const dismiss = useDismiss(context, { bubbles: true });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    hover,
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) return;

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpen, nodeId, parentId]);

  const rootButtonProps = {
    ref: useMergeRefs([refs.setReference, item.ref, forwardedRef]),
    tabIndex: !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1,
    role: isNested ? 'menuitem' : undefined,
    'data-open': isOpen ? '' : undefined,
    'data-nested': isNested ? '' : undefined,
    'data-focus-inside': hasFocusInside ? '' : undefined,
    className: isNested ? 'MenuItem' : 'RootMenu',
    ...getReferenceProps(
      parent.getItemProps({
        ...props,
        onFocus(event: React.FocusEvent<T>) {
          props.onFocus?.(event);
          setHasFocusInside(false);
          parent.setHasFocusInside(true);
        },
      }),
    ),
  };
  const contextValue = { activeIndex, setActiveIndex, getItemProps, setHasFocusInside, isOpen };

  const menuListProviderProps = { value: contextValue, elementsRef, labelsRef };

  const MenuAndButtonWrapper = useCallback<React.FC>(
    function MenuAndButtonWrapper({ children: c }: PropsWithChildren<unknown>) {
      return <FloatingNode id={nodeId}>{c}</FloatingNode>;
    },

    [nodeId],
  );

  const focusManagerProps = {
    context,
    modal: false,
    initialFocus: isNested ? -1 : 0,
    returnFocus: false,
  };
  const menuWrapperProps = {
    ref: refs.setFloating,
    className: 'Menu',
    style: floatingStyles,
    ...getFloatingProps(),
  };

  return {
    isNested,
    isOpen,
    menuListProviderProps,
    MenuAndButtonWrapper,
    rootButtonProps,
    focusManagerProps,
    menuWrapperProps,
  };
};
