import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ResettedButton } from '@skillset/ui';
import { Tooltip } from '../../../reusable-elements/ToolTip';

export const ShareIconButton = styled(ResettedButton).attrs({ className: 'fa-regular fa-share-nodes' })`
  font-size: 22px;
`;
ShareIconButton.displayName = 'ShareIconButton.styled';

export const NotesIconButton = styled(ResettedButton)`
  color: var(--almost-black);
  font-size: 24px;
  ${Tooltip} {
    display: none;
  }

  &:hover ~ ${Tooltip} {
    display: flex;
  }
`;
NotesIconButton.displayName = 'NotesIconButton.styled';

export const ShareIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
ShareIconContainer.displayName = 'ShareIconContainer.styled';

export const ResettedLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  height: 100%;
  width: 100%;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
`;
ResettedLink.displayName = 'ResettedLink.styled';
