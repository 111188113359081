import { useState } from 'react';
import { CheckboxItem, CustomizedCheckboxWithLinkContainer, OptionsContainerWithScroll, SearchInput } from '../style';
import { CopyUniqueLinkButton } from '../../../../UniqueLinksOperations/CopyUniqueLinkButton';
import { DownloadQRCodeButton } from '../../../../UniqueLinksOperations/DownloadQRCodeButton';
import { CustomizedCheckboxOption } from '../CustomizedCheckbox/CustomizedCheckbox';
import { SearchableOverflowingText } from '../../../../Table/SearchableOverflowingText/SearchableOverflowingText';
import { NoDataView } from '../../../../Table/NoDataView/NoDataView';
import React from 'react';

export interface CustomizedWithLinkAndSearchOption extends CustomizedCheckboxOption {
  url: string;
}

export interface CustomizedCheckboxWithLinkAndSearchProps {
  options?: CustomizedWithLinkAndSearchOption[];
  zIndex: number;
  updateCheckboxField: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked: (category: string, value: string) => boolean;
}

export const CustomizedCheckboxWithLinkAndSearch: React.FC<CustomizedCheckboxWithLinkAndSearchProps> = ({
  options,
  zIndex,
  updateCheckboxField,
  isCheckboxOptionChecked,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  if (!options) return <></>;
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
  );

  const renderCheckboxOptions = filteredOptions.map(({ name, value, category, url }) => {
    return (
      <React.Fragment key={name}>
        <CustomizedCheckboxWithLinkContainer>
          <CheckboxItem>
            <input
              type="checkbox"
              name={name}
              value={value}
              id={name}
              onChange={({ target }) => updateCheckboxField(category, value, target.checked)}
              checked={isCheckboxOptionChecked('', value)}
            />
            <SearchableOverflowingText zIndexTooltip={1} text={name} searchTerm={searchTerm} />
          </CheckboxItem>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', gap: '10px' }}>
            <CopyUniqueLinkButton uniqueLink={url.concat(value)} zIndex={zIndex} />
            <DownloadQRCodeButton uniqueLink={url.concat(value)} zIndex={zIndex} name={name} />
          </div>
        </CustomizedCheckboxWithLinkContainer>
      </React.Fragment>
    );
  });
  return (
    <>
      <SearchInput onChange={({ target }) => setSearchTerm(target.value)} value={searchTerm} placeholder="Search" />
      <OptionsContainerWithScroll>
        {renderCheckboxOptions.length === 0 ? (
          <CustomizedCheckboxWithLinkContainer>
            <NoDataView />
          </CustomizedCheckboxWithLinkContainer>
        ) : (
          renderCheckboxOptions
        )}
      </OptionsContainerWithScroll>
    </>
  );
};
