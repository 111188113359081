import { API } from '@skillset/onelogin';
import { AdministrationSortBy } from '../pages/Administration/constants';
import { BusinessUserRole, OptionalBusinessUser } from '../pages/Administration/types';
import { SuccessResponse } from '../utils/types';
import { SimUniqueAndApplicationLink } from '../store/types/EmployerAdministrationInterface';

const getAll = () => {
  return API.get('api', `/employers/users`, {});
};

interface InviteUsers {
  emails: string[];
  invitationText: string;
  role: BusinessUserRole;
  customerUUID: string;
}

const inviteUsers = (body: InviteUsers) => {
  return API.post('api', `/employers/users/invite`, { body });
};
const removeUser = (email: string) => {
  return API.del('api', `/employers/users`, { body: { email } });
};
const suspendUser = (email: string) => {
  return API.post('api', `/employers/users/suspend`, { body: { email } });
};
const unsuspendUser = (email: string) => {
  return API.post('api', `/employers/users/unsuspend`, { body: { email } });
};
const resendInvitation = (email: string) => {
  return API.post('api', `/employers/users/resendInvitation`, { body: { email } });
};
const removeInvitedUser = (email: string) => {
  return API.post('api', `/employers/users/removeInvitedUser`, { body: { email } }) as Promise<
    SuccessResponse<unknown>
  >;
};
const getEmailByToken = (token: string) => {
  return API.get('api', `/employers/users/getemail/${token}`, {});
};

const compareSortValues = <T>(A: T, B: T, orderBy: 'ASC' | 'DESC'): 1 | -1 => {
  const expression = orderBy === 'ASC' ? A > B : A < B;
  return expression ? 1 : -1;
};

export const sortUsers = (users: OptionalBusinessUser[], sortBy: AdministrationSortBy) => {
  const { keyName, orderBy } = sortBy;
  if (keyName === 'email') {
    return users.sort((a, b) => {
      return compareSortValues(a.fullName || a.email, b.fullName || b.email, orderBy);
    });
  } else if (keyName === 'role') {
    return users.sort((a, b) => {
      return compareSortValues(a.role, b.role, orderBy);
    });
  } else if (keyName === 'status') {
    return users.sort((a, b) => {
      return compareSortValues(a.status, b.status, orderBy);
    });
  } else if (keyName === 'lastVisit') {
    return users.sort((a, b) => {
      return compareSortValues(
        a.lastVisit ? new Date(a.lastVisit) : 0,
        b.lastVisit ? new Date(b.lastVisit) : 0,
        orderBy,
      );
    });
  } else if (keyName === 'customerName') {
    return users.sort((a, b) => {
      return compareSortValues(a.customerName, b.customerName, orderBy);
    });
  } else {
    return users;
  }
};

export const getAllIntegrationOptions = () => {
  return API.get('api', `/employers/integrations`, {});
};

export const getUserIntegration = () => {
  return API.get('api', `/employers/users/integration`, {});
};

export const deleteAccount = () => {
  return API.del('api', `/employers/integrations/deleteAccount`, {});
};

export const generateApiKey = async () => {
  const res: { success: true; payload: string } = await API.get('api', `/employers/admin/generatekey`, {});
  const { payload: apiKey } = res;
  return apiKey;
};

export const getUniqueLinkPerBussiness = async () => {
  const uniqueLinks = await API.get('api', `/employers/admin/getBussinessSimulationUniqueLinks`, {});
  return uniqueLinks;
};
export const checkAPIKeyGenerated = async () => {
  const { payload } = (await API.get('api', `/employers/admin/hasAPIKey`, {})) as { payload: boolean };
  return payload;
};

export const addSimulationToBussiness = async (simulationTypeIds: number[]) => {
  return API.post('api', `/employers/admin/addSimulationToBussiness`, { body: { simulationTypeIds } });
};

export const getSimTypesPrimaryLinks = async () => {
  const uniqueLinks = await API.get('api', `/employers/admin/getSimTypesPrimaryLinks`, {});
  return uniqueLinks;
};

export const getSimUniqueAndApplicationLinks = async (simulationTypeId: number) => {
  const uniqueAndApplicationLinks = (await API.get(
    'api',
    `/employers/admin/getSimUniqueAndApplicationLinks/${simulationTypeId}`,
    {},
  )) as Promise<SuccessResponse<SimUniqueAndApplicationLink[]>>;
  return uniqueAndApplicationLinks;
};
export const publishSimUniqueAndApplicationLinks = async (
  simUniqueAndApplicationLinks: SimUniqueAndApplicationLink[],
) => {
  return API.post('api', `/employers/admin/publishSimUniqueAndApplicationLinks`, {
    body: { simUniqueAndApplicationLinks },
  });
};

export const getCustomers = async () => {
  const customers = await API.get('api', `/employers/admin/customers`, {});
  return customers;
};
export const AdministrationService = {
  getAll,
  inviteUsers,
  sortUsers,
  resendInvitation,
  removeInvitedUser,
  removeUser,
  suspendUser,
  unsuspendUser,
  getAllIntegrationOptions,
  getUserIntegration,
  deleteAccount,
  generateApiKey,
  getUniqueLinkPerBussiness,
  addSimulationToBussiness,
  getEmailByToken,
  checkAPIKeyGenerated,
  getSimTypesPrimaryLinks,
  getSimUniqueAndApplicationLinks,
  publishSimUniqueAndApplicationLinks,
  getCustomers,
};
