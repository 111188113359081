import React from 'react';
import { CheckboxInput, Slider, SwitchLabel, TitleAndCheckboxWrapper, TitleElement } from './style';

export interface ToggleButtonProps {
  title: string;
  value: boolean;
  setValue: (newVal: boolean) => void;
}
export const ToggleButton: React.FC<ToggleButtonProps> = ({ title, value, setValue }) => {
  return (
    <TitleAndCheckboxWrapper>
      <TitleElement>{title}</TitleElement>
      <SwitchLabel>
        <CheckboxInput
          checked={value}
          onChange={(ev) => {
            setValue(ev.target.checked);
          }}
        />
        <Slider />
      </SwitchLabel>
    </TitleAndCheckboxWrapper>
  );
};

export default ToggleButton;
