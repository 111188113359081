import styled from 'styled-components';

import { zIndices } from '../../assets/zIndices';

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;

  .confirmation {
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: row;
  }
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 24px 10px 24px;
  }

  position: fixed;
  top: 50%;
  right: 50%;
  width: 400px;
  transform: translate(50%, -50%);
  z-index: ${zIndices.noDataPopUp};

  background: white;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
`;

PopupWrapper.displayName = 'PopupWrapper.styled';

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: calc(100% - 48px);
  h1 {
    font-weight: 600;
    margin-left: 24px;
    font-size: 18px;
    color: var(--almost-black);
    line-height: 28px;
  }

  h2 {
    color: var(--almost-black);
    margin-left: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;
Header.displayName = 'Header.styled';

export const CloseIcon = styled.button.attrs({ className: 'fa-regular fa-xmark' })`
  font-weight: 400;
  font-size: 24px;
  align-self: center;
  color: var(--almost-black);
  border: none;
  background: none;
`;
CloseIcon.displayName = 'CloseIcon.styled';

export const PopupIcon = styled.i`
  font-size: 24px;
  font-weight: 400;
  aspect-ratio: 1;
  width: 30px;
  display: grid;
  place-items: center;
  color: var(--almost-black);
`;

PopupIcon.displayName = 'PopupIcon.styled';

export const ResponseButton = styled.button<{ msg: 'confirm' | 'cancel' }>`
  display: flex;
  height: 44px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: ${({ msg }) => (msg === 'cancel' ? 'white' : 'var(--almost-black)')};
  color: ${({ msg }) => (msg === 'cancel' ? 'var(--almost-black)' : 'white')};
  text-align: center;
  font-family: Gilroy;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: ${({ msg }) => (msg === 'cancel' ? '1.5px solid var(--almost-black)' : 'none')};
  &:hover {
    box-shadow: 2px 8px 10px -6px rgba(26, 26, 26, 0.35);
  }
`;
ResponseButton.displayName = 'ResponseButton.styled';
