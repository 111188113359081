import styled, { CSSProperties } from 'styled-components';

interface IPrimaryButtonElementProps {
  backgroundColor?: CSSProperties['backgroundColor'];
  color?: CSSProperties['color'];
}
export const PrimaryButtonElement = styled.button<IPrimaryButtonElementProps>`
  background: ${({ backgroundColor }) => backgroundColor || 'var(--platform-pink-gradient)'};
  color: ${({ color }) => color || 'white'};
  border: none;
  border-radius: 8px;
  width: max-content;
  padding: 10.5px 16px;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 18.5px;
  text-decoration: none;

  &:disabled {
    pointer-events: none;
    background: var(--grey);
  }
  &:hover:not(:disabled):not(.no-shadow) {
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  }

  &:active:not(:disabled):not(.no-shadow) {
    box-shadow: 0px 4px 12px rgba(255, 89, 18, 0.3);
  }
`;
