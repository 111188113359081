import { lazy, Suspense } from 'react';

const Lottie = lazy(() => import('lottie-react'));

export const LazyLottiePlayer = (props: Parameters<typeof Lottie>[0]) => {
  const isReadyToRender = props.animationData;

  return isReadyToRender ? (
    <Suspense fallback={null}>
      <Lottie {...props} />
    </Suspense>
  ) : null;
};
