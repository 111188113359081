import React from 'react';
import { CloseIcon, NoDataPopupElement } from './style';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { CSSProperties } from 'styled-components';
import { BackDropLayer } from '../BackDropLayer/BackDropLayer';
import { createPortal } from 'react-dom';

const customizedPinkButtonStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '14px',
  gap: '10px',
  width: '72px',
  height: '44px',
  background: 'var(--tatio-pink)',
  borderRadius: '8px',
  fontSize: '17px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
};

interface INoDataPopup {
  onClosePopup: () => void;
  zIndexBackDrop: number;
  zIndexPopup: number;
}
export const NoDataPopup: React.FC<INoDataPopup> = ({ onClosePopup, zIndexBackDrop, zIndexPopup }) => {
  const portalRoot = document.getElementById('portal') as HTMLDivElement;

  return createPortal(
    <>
      <BackDropLayer zIndex={zIndexBackDrop} background="rgba(0, 0, 0, 0.25)" />
      <NoDataPopupElement zIndex={zIndexPopup}>
        <CloseIcon onClick={onClosePopup} />
        <div className="icon">
          <FontAwesomeIcon icon={faCircleExclamation} />
        </div>
        <span className="title">Oops!</span>
        <span className="subtitle">
          We couldn't find a resume in our system.
          <br />
          Please try again later.
        </span>
        <div className="closeButton">
          <PrimaryButton text="Close" style={customizedPinkButtonStyle} onClickHandler={onClosePopup} />
        </div>
      </NoDataPopupElement>
    </>,
    portalRoot,
  );
};
