import styled, { css } from 'styled-components';

interface ISelectContainerStyle {
  minWidth?: string;
  maxWidth?: string;
}
export const SelectContainer = styled.div<ISelectContainerStyle>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  position: relative;
  min-width: ${({ minWidth }) => minWidth ?? '140px'};
`;
SelectContainer.displayName = 'SelectContainer.styled';

interface ISelectWrapper {
  marginBottom?: string;
}
export const SelectWrapper = styled.div<ISelectWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: '330px';
  min-height: 40px;
  max-height: 45px;
  flex-shrink: 0;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid var(--grey);
  border-radius: 8px;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '10px'};
  overflow: hidden;
`;
SelectWrapper.displayName = 'SelectWrapper.styled';

interface ISelectSection {
  disabled?: boolean;
}

export const SelectSection = styled.section<ISelectSection>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border: none;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  padding: 12.5px 12px 12.5px 12px;
  ${({ disabled }) => {
    if (disabled)
      return css`
        cursor: not-allowed;
        pointer-events: none;
      `;
  }}
`;
SelectSection.displayName = 'SelectSection.styled';

interface IOptionSectionStyle {
  display: string;
}

export const OptionsSection = styled.section<IOptionSectionStyle>`
  display: ${(props) => props.display};
  height: fit-content;
  width: 100%;
  left: 0;
  position: absolute;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-top: none;
  border-radius: 0 0 8px 8px;
  z-index: 1;
`;
OptionsSection.displayName = 'OptionsSection.styled';

interface ISingleOption {
  fontSize?: string;
}
export const SingleOption = styled.button<ISingleOption>`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p {
    margin: 0;
  }
  padding: 0;
  border: none;
  background: none;
  text-align: inherit;
  width: 100%;
  height: fit-content;
  margin-block-end: 12px;
  margin-block-start: 12px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: 25px;
  cursor: pointer;
  color: var(--very-dark-grey);
  margin: 0;
  transition: all 0.2s ease;
  padding: 12.5px 12px 12.5px 12px;

  &:hover {
    background-color: var(--almost-black);
    color: var(--white);
  }
`;
SingleOption.displayName = 'SingleOption.styled';

export const Label = styled.label`
  margin-bottom: 5px;
  font-size: 13px;
  color: var(--dark-grey);
`;
Label.displayName = 'Label.styled';
