import styled from 'styled-components';

export const CurrentPageIndicator = styled.div`
  width: 28px;
  height: 4px;
  border-radius: 32px;
  background-color: var(--orange);
`;
CurrentPageIndicator.displayName = 'CurrentPageIndicator.styled';

export const OtherPageIndicator = styled.div`
  width: 8px;
  height: 4px;
  border-radius: 32px;
  background-color: var(--silver-sage);
`;
OtherPageIndicator.displayName = 'OtherPageIndicator.styled';

export const PageIndicatorElement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
PageIndicatorElement.displayName = 'PageIndicatorElement.styled';
