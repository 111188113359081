import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationCategoryItem } from '../../../constants/routes';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { TransparentBackDropLayer } from '../../BackDropLayer/BackDropLayer';
import {
  CategoryItemElement,
  ComingSoonLabel,
  DisplayArrowIndicator,
  IconElement,
  MobileCategoryColumnWrapper,
  MobileRouteListElement,
  MobileRouteListWrapper,
  MobileSelectCategoryUnderline,
  RouteItemElement,
  RoutesListElement,
  TreeBranch,
  TreeTrunk,
} from './style';
interface ISideMenuCategoryProps {
  category: NavigationCategoryItem;
  $isSmallerThan1919: boolean;
  index: number;
  zIndexTooltip: number;
  zIndexBackdropLayer: number;
  zIndexMobileRouteListWrapper: number;
}
export const SideMenuCategory: React.FC<ISideMenuCategoryProps> = ({
  category,
  $isSmallerThan1919,
  index,
  zIndexTooltip,
  zIndexBackdropLayer,
  zIndexMobileRouteListWrapper,
}) => {
  const { isExpanded: initialIsExpanded, mobileIsExpanded, iconClass, iconColor, text, items, isComingSoon } = category;
  const [isExpanded, setIsExpanded] = useState($isSmallerThan1919 ? mobileIsExpanded : initialIsExpanded);
  const location = useLocation();

  const isCategoryHasOnlySingleItem = items.length === 1;
  const toggleCategoryExpansion = () => {
    if (!isCategoryHasOnlySingleItem) {
      setIsExpanded((prevState) => !prevState);
    }
  };
  const categoryAsRouteProps = isCategoryHasOnlySingleItem
    ? { $categoryUsedAsRouteHref: items?.[0].route, $isActive: location.pathname === items?.[0].route }
    : {};
  const renderComingSoonOrArrow = isCategoryHasOnlySingleItem ? null : isComingSoon ? (
    <ComingSoonLabel>Coming soon</ComingSoonLabel>
  ) : (
    <DisplayArrowIndicator $isExpanded={isExpanded} />
  );

  const isCategorySelected = location.pathname.startsWith(`/${category.text.toLocaleLowerCase()}`);
  const itemsToRender = isCategoryHasOnlySingleItem
    ? []
    : items.map(({ text: itemText, route, notClickable = false }, itemIndex) => {
        const isItemLast = itemIndex === items.length - 1;
        return (
          <RouteItemElement
            key={itemText}
            //Temp fix - should be later replaced with NavLink
            active={location.pathname === route}
            $isSmallerThan1919={$isSmallerThan1919}
            isFirst={index === 0}
          >
            {$isSmallerThan1919 && !isItemLast && <TreeBranch />}
            {notClickable ? (
              <a style={{ marginLeft: isItemLast && $isSmallerThan1919 ? '10px' : 'unset' }}>{itemText}</a>
            ) : (
              <Link to={route} style={{ marginLeft: isItemLast && $isSmallerThan1919 ? '10px' : 'unset' }}>
                {itemText}
              </Link>
            )}
          </RouteItemElement>
        );
      });

  return $isSmallerThan1919 ? (
    <>
      <MobileCategoryColumnWrapper
        $isSelected={isCategorySelected}
        onClick={() => !isComingSoon && toggleCategoryExpansion()}
        $isExpanded={isExpanded}
        {...categoryAsRouteProps}
      >
        <CategoryItemElement $isSmallerThan1919>
          <IconElement icon={iconClass} color={iconColor} />
        </CategoryItemElement>
        {(isCategorySelected || categoryAsRouteProps.$isActive) && <MobileSelectCategoryUnderline />}
        <Tooltip
          color="var(--purple-grey)"
          direction="left"
          fontWeight="600"
          textColor="white"
          top="8%"
          left="30px"
          zIndex={zIndexTooltip}
        >
          {text}
        </Tooltip>
      </MobileCategoryColumnWrapper>
      <>
        <MobileRouteListWrapper $isExpanded={isExpanded} zIndex={zIndexMobileRouteListWrapper}>
          <TreeTrunk />
          <MobileRouteListElement>{itemsToRender}</MobileRouteListElement>
        </MobileRouteListWrapper>
        {isExpanded && <TransparentBackDropLayer zIndex={zIndexBackdropLayer} onClick={() => setIsExpanded(false)} />}
      </>
    </>
  ) : (
    <>
      <CategoryItemElement
        $isSmallerThan1919={false}
        onClick={toggleCategoryExpansion}
        $shouldMarginBottom={isExpanded}
        {...categoryAsRouteProps}
      >
        <IconElement icon={iconClass} color={iconColor} />
        <span>{text}</span>
        {renderComingSoonOrArrow}
      </CategoryItemElement>
      <RoutesListElement $isExpanded={isExpanded}>{itemsToRender}</RoutesListElement>
    </>
  );
};
