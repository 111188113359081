import React, { useLayoutEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { StyledDayPicker } from './styles';
import { OneOfLayoutTypes } from '../../style';
import { addParentClassIfChildExists, removeClassIfExists } from '../../../../utils';
interface DateRangePickerProps {
  range: DateRange | undefined;
  setDateRangeFilter: (range: DateRange | undefined) => void;
  layoutType?: OneOfLayoutTypes;
}

const wrapInnerTextWithSpan = () => {
  const elements = document.querySelectorAll('th') as NodeListOf<HTMLTableHeaderCellElement>;
  elements.forEach((element) => {
    const span = document.createElement('span');
    span.style.display = 'flex';
    span.style.marginLeft = '8px';
    span.innerText = element.textContent || '';
    element.innerHTML = ''; // Clear innerHTML
    element.appendChild(span); // Append the span
  });
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ range, layoutType, setDateRangeFilter }) => {
  const [currentMonth, setCurrentMonth] = useState<Date | null>(null);
  useLayoutEffect(() => {
    // Remove existing classes from previous cells if they exist
    removeClassIfExists('.today-cell', 'today-cell');
    removeClassIfExists('.start-cell', 'start-cell');
    removeClassIfExists('.end-cell', 'end-cell');
    removeClassIfExists('.middle-cell', 'middle-cell');

    // Add new special classes to parent elements
    if (document.querySelector('.rdp-day_today')?.classList.contains('rdp-day_range_middle')) {
      addParentClassIfChildExists('.rdp-day_today.rdp-day_range_middle', 'today-cell');
    }

    addParentClassIfChildExists('.rdp-day_range_start', 'start-cell');
    addParentClassIfChildExists('.rdp-day_range_middle', 'middle-cell');
    addParentClassIfChildExists('.rdp-day_range_end', 'end-cell');
    wrapInnerTextWithSpan();
  }, [range, currentMonth]);

  return (
    <StyledDayPicker
      onMonthChange={setCurrentMonth}
      mode="range"
      selected={range}
      captionLayout={'dropdown-buttons'}
      fromYear={2019}
      toYear={new Date().getFullYear()}
      onSelect={setDateRangeFilter}
      styles={
        layoutType === 'allFilters'
          ? {
              root: {
                padding: '20px 25px 30px 25px',
                margin: 0,
              },
              caption: { marginBottom: '16px' },
              head_cell: {
                width: '100px',
              },
              table: {
                maxWidth: 'none',
              },
              day: {
                margin: '0',
                maxWidth: 'none',
              },
            }
          : {}
      }
    />
  );
};
