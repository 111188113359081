import { Link } from 'react-router-dom';
import { LazyLottiePlayer } from '../../Lottie/LazyLottiePlayer';
import { NoDataView } from './NoDataView';
import { BackToCandidates, ColumnContainer, NoDataViewContainer, NoResultsText } from './style';
import noFavoritesJson from '../../../assets/lotties/find-your-star.json';

export const FindYourStar: React.FC = () => {
  return (
    <NoDataViewContainer>
      <ColumnContainer>
        <BackToCandidates as={Link} to="/candidates">
          <LazyLottiePlayer style={{ height: '67px' }} animationData={noFavoritesJson} />
          <NoResultsText>Find your first star</NoResultsText>
        </BackToCandidates>
      </ColumnContainer>
    </NoDataViewContainer>
  );
};
export const renderFavoritesNoDataView = (noFirstStar: boolean) => (noFirstStar ? <FindYourStar /> : <NoDataView />);
