import styled from 'styled-components';

import { EXTRA_LARGE_DEVICES, EXTRA_SMALL_DEVICES, SMALL_DEVICES } from '@skillset/ui';
import { InputGroupContainer } from '../FormInputGroup/style';
import { Button } from '../Reusable-styled-elements/Button';

export const FormGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 10px;
  height: 101px;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    min-height: 95px;
    row-gap: 5px;
  }

  ${InputGroupContainer} {
    height: 70px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    column-gap: 10px;
  }

  & > span {
    grid-column: span 2;
  }
`;
FormGroupContainer.displayName = 'FormGroupContainer.styled';

export const TermsOfUseWrapper = styled.label`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 8px;
    gap: 6.53px;
  }

  & span,
  & a {
    font-family: var(--gilroy-font);
    cursor: pointer;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      line-height: 17px;
    }
  }
`;
TermsOfUseWrapper.displayName = 'TermsOfUseWrapper.styled';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    gap: 12.5px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    gap: 10px;
    margin-top: 14.5px;
  }

  ${Button} {
    img {
      width: 20.52px;
      height: 20.52px;

      @media screen and (max-width: ${SMALL_DEVICES}) {
        width: 15.52px;
        height: 15.52px;
      }
    }
  }
`;
ButtonContainer.displayName = 'ButtonContainer.styled';

export const TermsOfUseText = styled.span`
  font-family: var(--primary-font);
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0em;
  text-align: left;
`;
TermsOfUseText.displayName = 'TermsOfUseText.styled';
