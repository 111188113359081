import styled from 'styled-components';
import { EXTRA_EXTRA_LARGE_DEVICES, EXTRA_LARGE_DEVICES, SMALL_DEVICES } from '../../constants/breakpoints';

export const PageWrapper = styled.div`
  font-family: var(--primary-font);
  display: flex;
  padding: 25px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  flex: 1 0 0;
  align-self: stretch;
  text-align: center;
  gap: 5px;
  .trophy {
    height: 150px;
    width: 150px;
  }
  h1 {
    font-size: 30px;
    font-weight: 600;
    color: var(--almost-black);
  }
  h2 {
    font-size: 18px;
    font-weight: 400;
    color: var(--very-dark-grey);
  }
  @media screen and (max-width: ${SMALL_DEVICES}) {
    h1 {
      font-size: 28px;
      font-weight: 600;
    }
    h2 {
      font-size: 18px;
      font-weight: 400;
    }
    .trophy {
      height: 125px;
      width: 125px;
    }
  }
`;
PageWrapper.displayName = 'PageWrapper.styled';

export const JobsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 25px;
`;

JobsWrapper.displayName = 'JobsWrapper.styled';

export const JobsPartialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    flex-direction: column;
  }
`;

JobsPartialWrapper.displayName = 'JobsPartialWrapper.styled';

export const JobElement = styled.div`
  display: flex;
  padding: 20px;
  height: 126px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 71, 0.06), 0px 8px 8px 0px rgba(50, 50, 71, 0.08);
  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--almost-black);
    text-align: start;
    width: 100%;
  }
  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-grey);
  }
  :hover {
    box-shadow: 0px 9px 22px -4px rgba(24, 39, 75, 0.14), 0px 4px 7px -6px rgba(24, 39, 75, 0.12);
  }
  width: 297px;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    width: 213px;
  }
  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    width: 187px;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 297px;
  }
`;
JobElement.displayName = 'JobElement.styled';
export const DoubleRowEllipsis = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
DoubleRowEllipsis.displayName = 'DoubleRowEllipsis.styled';
