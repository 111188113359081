import { useNavigate, useParams } from 'react-router-dom';

import { ResetPasswordForm } from '../../components/ResetPasswordForm/ResetPasswordForm';

import { ROUTE_LOGIN } from '../../constants/routes';

import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { ResetPasswordFormContainer } from './style';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { PageContainer } from '../../components/Reusable-styled-elements/PageContainer';
import { useEffect } from 'react';
import { Language } from '../../utils/languages/general';
import { getResetPasswordTexts } from '../../services/languages.service';

export const ResetPassword: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const navigate = useNavigate();

  const { username, code } = useParams();
  const navigateToLogin = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
  useEffect(() => {
    if (!username || !code) navigateToLogin();
  }, []);
  const texts = getResetPasswordTexts(registrationLanguage);
  return (
    <PageContainer>
      <LogoContainerWrapper clickHandler={navigateToLogin} />
      <ResetPasswordFormContainer>
        <ImageContainer src="https://shared-assets.tatio.io/images/reset-password.gif" />

        <Heading>{texts.createNewPasswordTitle}</Heading>
        <Subheader>{texts.passwordInstructions}</Subheader>
        <ResetPasswordForm registrationLanguage={registrationLanguage} />
      </ResetPasswordFormContainer>
    </PageContainer>
  );
};
