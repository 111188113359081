import { useState } from 'react';
import { ConfirmationPopup } from '../reusable-elements/ConfirmationPopup/ConfirmationPopup';
import { createPortal } from 'react-dom';

interface ConfirmationPopupProps {
  onClosePopup: () => void;
  onConfirm: () => void;
  iconClass: string;
  subtitle: string;
  title: string;
  confirmText?: string;
}
export const useConfirmPopup = ({
  onClosePopup,
  onConfirm,
  iconClass,
  subtitle,
  title,
  confirmText,
}: ConfirmationPopupProps) => {
  const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);

  const render = displayConfirmationPopup && (
    <ConfirmationPopup>
      <ConfirmationPopup.Icons>
        <ConfirmationPopup.PopupIcon className={iconClass} />
        <ConfirmationPopup.CloseButton onClick={onClosePopup} />
      </ConfirmationPopup.Icons>
      <ConfirmationPopup.Header title={title} subtitle={subtitle} />
      <ConfirmationPopup.Buttons>
        <ConfirmationPopup.Cancel onClick={onClosePopup} />
        <ConfirmationPopup.Confirm onClick={onConfirm}>{confirmText}</ConfirmationPopup.Confirm>
      </ConfirmationPopup.Buttons>
    </ConfirmationPopup>
  );
  return {
    renderPopup: createPortal(render, document.body),
    setPopupDisplay: setDisplayConfirmationPopup,
  };
};
