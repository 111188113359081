import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signOut } from '@skillset/onelogin';
import { IEmployersSimulation, ISortByDetails } from '../../constants/simulation';
import { SimulationService } from '../../services/simulationService';
import {
  updateBusinessSimulationStatus,
  getSimulationStates,
  getSimulationTypes,
  loadSimulations,
  updateViewedSimulationStatus,
  createBusinessSimulationNote,
  loadFavorites,
  setSimulationFields,
  removeFromFavoritesArray,
  loadStatuses,
} from '../actions/employersSimulation.action';
import { IEmployersSimulationSlice, ISimulationType, StarredFrom } from '../types/EmployersSimulationSliceInterface';
import { INote } from '../../components/Notes/types';

export interface WithStates {
  states: IEmployersSimulation['state'][];
}

const initialState: IEmployersSimulationSlice = {
  simulations: [],
  favorites: [],
  simulationTypes: [],
  statuses: [],
  loading: false,
  displayAllFilters: false,
  states: [],
  search: '',
  displaySimulationStatusUpdate: null,
};

const employersSimulationSlice = createSlice({
  name: 'employersSimulation',
  initialState: initialState,

  reducers: {
    toggleAllFiltersDisplay(state) {
      state.displayAllFilters = !state.displayAllFilters;
    },
    applySortBy(
      state,
      { payload: { sortBy, origin } }: PayloadAction<{ sortBy: ISortByDetails; origin: StarredFrom }>,
    ) {
      state[origin] = SimulationService.sortSimulations(state[origin], sortBy);
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setBusinessSimulationStatus(state, { payload }: PayloadAction<{ statusToUpdate: number; simulationId: number }>) {
      const { simulationId, statusToUpdate } = payload;
      const simulationToUpdateIdx = state.simulations.findIndex((sim) => sim.simulationId === simulationId);
      if (simulationToUpdateIdx !== -1) state.simulations[simulationToUpdateIdx].candidateStatus = statusToUpdate;
      const favouritesToUpdateIdx = state.favorites.findIndex((sim) => sim.simulationId === simulationId);
      if (favouritesToUpdateIdx !== -1) state.favorites[favouritesToUpdateIdx].candidateStatus = statusToUpdate;
    },
    setSimulationNotes(state, { payload }: PayloadAction<{ simulationId: number; notes: INote[] }>) {
      const { simulationId, notes } = payload;
      const simIndex = state.simulations.findIndex((sim) => sim.simulationId === simulationId);
      if (simIndex !== -1) state.simulations[simIndex].notes = notes;
      const favIndex = state.favorites.findIndex((sim) => sim.simulationId === simulationId);
      if (favIndex !== -1) state.favorites[favIndex].notes = notes;
    },
    setSingleStatusDisplay(state, { payload }: PayloadAction<number | null>) {
      state.displaySimulationStatusUpdate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSimulationFields, (state, { payload }) => {
        const simulationToUpdateIdx = state.simulations.findIndex((sim) => sim.sessionId === payload.sessionId);
        const favoriteToUpdateIdx = state.favorites.findIndex((sim) => sim.sessionId === payload.sessionId);

        if (simulationToUpdateIdx !== -1) {
          const { startAnimation, ...rest } = payload.fields;
          Object.assign(state.simulations[simulationToUpdateIdx], rest);
        }
        if (favoriteToUpdateIdx !== -1) Object.assign(state.favorites[favoriteToUpdateIdx], payload.fields);
      })
      .addCase(loadSimulations.fulfilled, (state, { payload }: PayloadAction<IEmployersSimulation[]>) => {
        state.simulations = payload;
        state.loading = false;
      })
      .addCase(removeFromFavoritesArray, (state, { payload }) => {
        const { isFavorite, sessionId } = payload;
        if (isFavorite) return;
        state.favorites = state.favorites.filter((favSim) => favSim.sessionId !== sessionId);
      })
      .addCase(loadFavorites.fulfilled, (state, { payload }: PayloadAction<IEmployersSimulation[]>) => {
        state.favorites = payload;
      })
      .addCase(loadStatuses.fulfilled, (state, { payload }) => {
        if (payload) {
          state.statuses = payload;
        }
        state.loading = false;
      })
      .addCase(loadSimulations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loadStatuses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSimulationTypes.fulfilled, (state, { payload }: PayloadAction<ISimulationType[]>) => {
        state.simulationTypes = payload;
        state.loading = false;
      })
      .addCase(getSimulationTypes.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(loadStatuses.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadSimulations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimulationTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBusinessSimulationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBusinessSimulationStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateBusinessSimulationStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateViewedSimulationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateViewedSimulationStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        updateViewedSimulationStatus.fulfilled,
        (state, { payload }: PayloadAction<{ updatedStatus: boolean; sessionId: string }>) => {
          const { sessionId, updatedStatus } = payload;
          state.loading = false;
          const simulationToUpdateIdx = state.simulations.findIndex((sim) => sim.sessionId === sessionId);
          if (simulationToUpdateIdx !== -1) state.simulations[simulationToUpdateIdx].isViewed = updatedStatus;
          const favouritesToUpdateIdx = state.favorites.findIndex((sim) => sim.sessionId === sessionId);
          if (favouritesToUpdateIdx !== -1) state.favorites[favouritesToUpdateIdx].isViewed = updatedStatus;
        },
      )
      .addCase(getSimulationStates.rejected, (state) => {
        state.loading = false;
        state.states = [];
      })
      .addCase(getSimulationStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBusinessSimulationNote.fulfilled, (state, { payload }) => {
        const { createdNoteId, simulationId, tempId } = payload;
        const simIndex = state.simulations.findIndex((sim) => sim.simulationId === simulationId);
        const simNoteToUpdate = state.simulations[simIndex].notes.find((note) => note.id === tempId);
        if (simNoteToUpdate) {
          simNoteToUpdate.id = createdNoteId;
          simNoteToUpdate.isPrivileged = true;
        }
        const favIndex = state.favorites.findIndex((sim) => sim.simulationId === simulationId);
        const favNoteToUpdate = state.favorites?.[favIndex]?.notes.find((note) => note.id === tempId);
        if (favNoteToUpdate) {
          favNoteToUpdate.id = createdNoteId;
          favNoteToUpdate.isPrivileged = true;
        }
      })
      .addCase(getSimulationStates.fulfilled, (state, { payload }: PayloadAction<WithStates>) => {
        state.loading = false;
        state.states = payload.states;
      })
      .addCase(signOut.fulfilled, () => {
        return initialState;
      });
  },
});

export const { toggleAllFiltersDisplay, applySortBy, setSearchTerm, setSingleStatusDisplay } =
  employersSimulationSlice.actions;

export const employersSimulationSliceReducer = employersSimulationSlice.reducer;
