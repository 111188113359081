import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { SupportedOverflowedWrapper } from './style';
import { HighlightedSearchTerm, TableTextElement } from '@skillset/ui';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { EmployersAdministrationSlice } from '../../../store/types/EmployerAdministrationInterface';

interface LastActivityColumnProps {
  lastActivity: string;
}

export const LastActivityColumn: React.FC<LastActivityColumnProps> = ({ lastActivity }) => {
  const { searchTerm } = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('horizontal');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  return (
    <SupportedOverflowedWrapper>
      <TableTextElement ref={refCallback}>
        <HighlightedSearchTerm text={lastActivity} searchTerm={searchTerm.trim()} />
      </TableTextElement>
      {isOverflowing && !isMobile && (
        <Tooltip color="var(--almost-black)" direction="down" fontWeight="600" textColor="white" top="-5px">
          {lastActivity}
        </Tooltip>
      )}
    </SupportedOverflowedWrapper>
  );
};
