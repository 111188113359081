import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@aws-amplify/api-rest/node_modules/axios/index';
import { IFilterBy } from '../../components/CandidatesSimulations/types';
import { IEmployersSimulation, FavoriteSimulation, ISortByDetails } from '../../constants/simulation';
import { INote } from '../../components/Notes/types';
import { SimulationService } from '../../services/simulationService';
import { SimulationTypeService } from '../../services/simulationTypeService';

import { StarredFrom } from '../types/EmployersSimulationSliceInterface';
import { IStatuses } from '../../utils/types';
import { WithTypesType } from '../types/WithTypesType';
import { GetSimulationTypes } from './employerBusinessSlice.action';

interface LoadFavoritesParams {
  filterBy: IFilterBy;
  sortBy: ISortByDetails;
}
interface LoadSimulationsParams extends LoadFavoritesParams {
  isFavorite?: boolean;
}

const generateKeys = <T, K extends keyof T>(data: T[], keyName: T[K] extends string | number ? K : never) => {
  return data.map((item) => ({ ...item, componentKey: item[keyName] }));
};

export const setSimulationNotes = createAction(
  'employersSimulation/setSimulationNotes',
  (payload: { simulationId: number; notes: INote[] }) => {
    return { payload };
  },
);

export const setBusinessSimulationStatus = createAction(
  'employersSimulation/setBusinessSimulationStatus',
  (payload: { statusToUpdate: number; simulationId: number }) => {
    return { payload };
  },
);

export const setSimulationFields = createAction(
  'employersSimulation/setSimulationFields',
  (payload: { sessionId: string; fields: Partial<FavoriteSimulation> }) => {
    return { payload };
  },
);

export const loadSimulations = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/loadSimulations',
  async (params: LoadSimulationsParams, thunkAPI) => {
    const { filterBy, sortBy, isFavorite } = params;

    try {
      const { payload }: { payload: Omit<IEmployersSimulation, 'componentKey'>[] } =
        await SimulationService.getAllSimulations({ filterBy, isFavorite });
      const simulationsWithKeys = generateKeys(payload, 'sessionId');
      return SimulationService.sortSimulations(simulationsWithKeys, sortBy);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

type LoadFavoritesPayload = Omit<IEmployersSimulation, 'componentKey' | 'simulationTypeId' | 'simulationName'>;
export const loadFavorites = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/loadFavorites',
  async (params: LoadFavoritesParams, thunkAPI) => {
    const { filterBy, sortBy } = params;

    try {
      const { payload }: { payload: LoadFavoritesPayload[] } = await SimulationService.getAllSimulations({
        filterBy,
        isFavorite: true,
      });
      const simulationsWithKeys = generateKeys(payload, 'sessionId');
      const sorted = SimulationService.sortSimulations(simulationsWithKeys, sortBy);
      return sorted;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const loadStatuses = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/loadStatuses',
  async (_, thunkAPI) => {
    try {
      const { statuses } = thunkAPI.getState().employersSimulationSliceReducer;
      if (statuses.length) return;
      const { payload }: { payload: IStatuses[] } = await SimulationService.getAllStatuses();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getSimulationTypes = createAsyncThunk(
  'employersSimulation/getSimulationTypes',
  async (params: GetSimulationTypes, thunkAPI) => {
    try {
      const { payload } = await SimulationTypeService.getAll(params.customerUUID ?? '');
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const updateBusinessSimulationStatus = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/updateBusinessSimulationStatus',
  async (
    params: {
      simulationId: number;
      statusToUpdate: number;
    },
    thunkAPI,
  ) => {
    const { simulationId, statusToUpdate } = params;
    const { simulations } = thunkAPI.getState().employersSimulationSliceReducer;
    const currentSim = simulations.find((sim) => sim.simulationId === simulationId);
    if (!currentSim) throw new Error("Couldn't find simulation to update");
    const currentStatus = currentSim.candidateStatus ?? 'updateStatus';
    thunkAPI.dispatch(setBusinessSimulationStatus({ statusToUpdate, simulationId }));
    try {
      const { payload } = await SimulationService.updateBusinessSimulationStatus(simulationId, statusToUpdate);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(setBusinessSimulationStatus({ statusToUpdate: currentStatus, simulationId }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

/* 
The following variables are made for optimistic rendered notes temporarily IDs.
*/
const TEMP_NOTE_ID_PREFIX = 'tempNoteId';
let noteTempIdCounter = 0;

export const createBusinessSimulationNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/createBusinessSimulationNote',
  async (
    params: {
      simulationId: number;
      note: string;
    },
    thunkAPI,
  ) => {
    const tempId = `${TEMP_NOTE_ID_PREFIX}${noteTempIdCounter++}`;
    const { simulationId, note } = params;
    const { simulations } = thunkAPI.getState().employersSimulationSliceReducer;
    const { platformUser } = thunkAPI.getState().authSliceReducer;
    const currentNotes = simulations.find((sim) => sim.simulationId === simulationId)?.notes;
    if (!platformUser?.personal || !Array.isArray(currentNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes or user personal details');
    }
    const noteToCreate: INote = {
      id: tempId,
      createdAt: new Date(Date.now()).toJSON(),
      authorFirstName: platformUser.personal.firstName,
      authorLastName: platformUser.personal.lastName,
      note,
      isPrivileged: false,
    };
    thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: [noteToCreate, ...currentNotes] }));
    try {
      const { payload } = await SimulationService.createBusinessSimulationNote(simulationId, note);
      return { tempId, simulationId, createdNoteId: payload.createdNoteId };
    } catch (err) {
      thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const updateBusinessSimulationNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/updateBusinessSimulationNote',
  async (
    params: {
      noteId: number;
      simulationId: number;
      note: string;
    },
    thunkAPI,
  ) => {
    const { noteId, simulationId, note } = params;
    const { simulations } = thunkAPI.getState().employersSimulationSliceReducer;
    const simNotes = simulations.find((sim) => sim.simulationId === simulationId)?.notes;
    if (!Array.isArray(simNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    const notesToUpdate = simNotes.map((currNote) => {
      return currNote.id === noteId ? { ...currNote, note } : currNote;
    });

    thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: notesToUpdate }));
    try {
      const { payload } = await SimulationService.updateBusinessSimulationNote(noteId, note);
      return { payload };
    } catch (err) {
      thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: simNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const deleteBusinessSimulationNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/deleteBusinessSimulationNote',
  async (
    params: {
      noteId: number;
      simulationId: number;
    },
    thunkAPI,
  ) => {
    const { noteId, simulationId } = params;
    const { simulations } = thunkAPI.getState().employersSimulationSliceReducer;
    const currentNotes = simulations.find((sim) => sim.simulationId === simulationId)?.notes;
    if (!currentNotes) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: currentNotes.filter((note) => note.id !== noteId) }));
    try {
      const { payload } = await SimulationService.deleteBusinessSimulationNote(noteId);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(setSimulationNotes({ simulationId, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const updateViewedSimulationStatus = createAsyncThunk(
  'employersSimulation/updateViewedSimulationStatus',
  async (
    params: {
      sessionId: string;
      statusToUpdate: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const { sessionId, statusToUpdate } = params;
      const { payload } = await SimulationService.updateSimulationViewStatus(sessionId, statusToUpdate);
      return { sessionId: sessionId, updatedStatus: payload.isViewed };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

/**
 * optimistically updates isFavorite, reverts on error
 */
export const updateFavoriteSimulationStatus = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/updateFavoriteSimulationStatus',
  async (
    params: {
      sessionId: string;
      statusToUpdate: boolean;
      origin: StarredFrom;
    },
    thunkAPI,
  ) => {
    const { sessionId, statusToUpdate, origin } = params;
    const { [origin]: simulations } = thunkAPI.getState().employersSimulationSliceReducer;
    const currentStatus = !!simulations.find(({ sessionId: s }) => s === sessionId)?.isFavorite;
    try {
      thunkAPI.dispatch(setSimulationFields({ sessionId, fields: { isFavorite: statusToUpdate } }));
      const { payload } = await SimulationService.updateSimulationFavoriteStatus(sessionId, statusToUpdate);
      return { sessionId: sessionId, updatedStatus: payload.isFavorite };
    } catch (err) {
      thunkAPI.dispatch(setSimulationFields({ sessionId, fields: { isFavorite: currentStatus } }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const removeFromFavoritesArray = createAction(
  'employersSimulation/removeFromFavoritesArray',
  (payload: { sessionId: string; isFavorite: boolean }) => {
    return { payload };
  },
);

/**
 * optimistically updates isFavorite, reverts on error
 */
export const removeFromFavorites = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/removeFromFavorites',
  async (
    params: {
      sessionId: string;
    },
    thunkAPI,
  ) => {
    const { sessionId } = params;

    try {
      thunkAPI.dispatch(setSimulationFields({ sessionId, fields: { isFavorite: false, startAnimation: true } }));
      const { payload } = await SimulationService.updateSimulationFavoriteStatus(sessionId, false);
      return thunkAPI.dispatch(removeFromFavoritesArray({ sessionId, isFavorite: !!payload.isFavorite }));
    } catch (err) {
      console.error(err);
      thunkAPI.dispatch(setSimulationFields({ sessionId, fields: { isFavorite: true, startAnimation: false } }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
export const getSimulationStates = createAsyncThunk(
  'employersSimulation/getSimulationStates',
  async (typeId: number, thunkAPI) => {
    try {
      const { payload } = await SimulationService.getAllStatesAndCoreTasksNames(typeId);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
