import styled from 'styled-components';
import { SMALL_DEVICES } from '@skillset/ui';

export const AdditionalInfo = styled.span<{ color?: string; size?: string }>`
  display: block;
  margin-top: 5px;
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: ${({ size }) => size ?? '16px'};
  line-height: 19px;
  text-align: center;
  color: ${({ color }) => (color ? color : 'var(--almost-black)')};

  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 16px;
    text-wrap: nowrap;
  }

  & button {
    //Button Resetting
    background: none;
    border: none;
    display: inline;
    font-family: var(--primary-font);
    line-height: 21px;
    font-size: 16px;
    color: var(--almost-black);
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
AdditionalInfo.displayName = 'AdditionalInfo.styled';
