import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ColumnContainer, LoaderAnimation, LoadingScreenContainer, LoadingText } from './style';
import { LogoContainer } from '../LogoContainer/LogoContainer';
import bouncingLoader from '../../assets/lotties/loader.json';
interface ILoadingScreenProps {
  text?: string;
  zindex?: number;
  blockBodyOverflow?: boolean;
}
const body = document.body;
export const LoadingScreen: React.FC<ILoadingScreenProps> = ({ text, zindex, blockBodyOverflow = true }) => {
  useEffect(() => {
    if (blockBodyOverflow) {
      const initial = body.style.overflow;
      body.style.overflow = 'hidden';
      return () => {
        body.style.overflow = initial;
      };
    }
  }, []);
  const imageSize = {
    width: '190px',
    height: '50px',
  };
  const breakPointImgSize = {
    width: '113px',
    height: '30px',
    breakpoint: '478px',
  };
  return createPortal(
    <LoadingScreenContainer zindex={zindex ? zindex : 0}>
      {!blockBodyOverflow && (
        <LogoContainer
          theme="blueLongLogo"
          imgSize={imageSize}
          breakPointImgSize={breakPointImgSize}
          style={{ position: 'absolute', left: '6%', top: '6%' }}
        />
      )}
      <ColumnContainer>
        <LoaderAnimation animationData={bouncingLoader} />
        {text && <LoadingText>{text}</LoadingText>}
      </ColumnContainer>
    </LoadingScreenContainer>,
    body,
  );
};
