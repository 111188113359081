import { useState } from 'react';
import { faArrowRightFromBracket, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { signOut, IUserMenuOptions, UserMenu, getBrandColorByString, selectPlatformUser } from '@skillset/onelogin';

import { SALES_CALENDLY_LINK } from '../../constants';
import { getFirstNameFirstLetter, isUserBelongsToBusiness } from '../../utils';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import {
  NotificationsIconElement,
  HeaderController,
  HeaderElement,
  GridIcon,
  ExpansionArrowIcon,
  MobileLogoElement,
  CalendarSmilingIcon,
} from './style';
import { ResettedButton, NavigationMenu, INavigationMenuOption, AvatarElement } from '@skillset/ui';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { initJobs } from '../../store/reducers/employerBusinessSlice';
import { zIndices } from '../../assets/zIndices';

export const Header = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userMenuOptions: IUserMenuOptions[] = [
    {
      icon: faArrowRightFromBracket,
      label: 'Logout',
      onClick: () => {
        dispatch(initJobs());
        dispatch(signOut());
        navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
      },
    },
  ];

  const toggleUserMenu = () => {
    setIsNavigationMenuOpen(false);
    setIsUserMenuOpen((prevState) => !prevState);
  };
  const openNavigationMenu = () => {
    setIsUserMenuOpen(false);
    setIsNavigationMenuOpen(true);
  };
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const platformUser = useTypedSelector(selectPlatformUser);
  const navigationMenuOptions: INavigationMenuOption[] = [
    { title: 'Tatio', route: 'candidates', iconAssetName: 'mini-tatio-logo.svg' },
  ];

  if (platformUser && platformUser.role?.includes('admin')) {
    navigationMenuOptions.push({
      title: 'Administration',
      route: 'administration',
      iconAssetName: 'administration-icon.svg',
    });
  }
  const userBelongsToBusiness = isUserBelongsToBusiness(platformUser);
  if (!platformUser) return null;
  const fullName = `${platformUser.personal.firstName} ${platformUser.personal.lastName}`;
  const firstNameFirstLetter = getFirstNameFirstLetter(platformUser.personal.firstName);

  return (
    <>
      <HeaderElement>
        {isMobile && <MobileLogoElement />}
        <HeaderController>
          {isMobile && !userBelongsToBusiness && (
            <a href={SALES_CALENDLY_LINK} target="_blank">
              <CalendarSmilingIcon />
            </a>
          )}
          <NotificationsIconElement />
          <ResettedButton onClick={openNavigationMenu}>
            <GridIcon />
          </ResettedButton>
          <AvatarElement $beforeContent={firstNameFirstLetter} backgroundColor={getBrandColorByString(fullName)} />
          <ResettedButton onClick={toggleUserMenu}>
            <ExpansionArrowIcon
              icon={isUserMenuOpen ? faChevronUp : faChevronDown}
              data-test-id="expansion-arrow-icon"
            />
          </ResettedButton>
        </HeaderController>
      </HeaderElement>
      {isUserMenuOpen && <UserMenu menuOptions={userMenuOptions} closeMenu={() => setIsUserMenuOpen(false)} />}
      {isNavigationMenuOpen && (
        <NavigationMenu
          zIndexBackdropLayer={zIndices.backdrop}
          zIndexMenu={zIndices.userMenu}
          menuOptions={navigationMenuOptions}
          closeMenu={() => setIsNavigationMenuOpen(false)}
        />
      )}
    </>
  );
};
