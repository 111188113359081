import styled from 'styled-components';
import { ToastNotificationType, notificationTypeDetails } from './constants';
import { ResettedButton } from '../../reusable-elements/ResettedButton';

interface IToastNotificationContainer {
  zIndex: number;
}

export const ToastNotificationContainer = styled.div<IToastNotificationContainer>`
  display: flex;
  position: fixed;
  right: 50px;
  bottom: 50px;
  padding: 20px 20px;
  background-color: var(--menu-dark-blue);
  width: 375px;
  height: 101px;
  border-radius: 12px;
  z-index: ${({ zIndex }) => zIndex};
`;
ToastNotificationContainer.displayName = 'ToastNotificationContainer.styled';

interface IconElementProps {
  type: ToastNotificationType;
}

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;
IconWrapper.displayName = 'IconWrapper.styled';

export const IconElement = styled.i.attrs<IconElementProps>(({ type }) => {
  return {
    className: notificationTypeDetails[type]?.iconClass,
  };
})<IconElementProps>`
  font-size: 18.5px;
  color: ${({ type }) => notificationTypeDetails[type]?.iconColor};
`;
IconElement.displayName = 'IconElement.styled';

export const TitleAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin-right: 20px;
`;
TitleAndMessageContainer.displayName = 'TitleAndMessageContainer.styled';

export const TitleElement = styled.span`
  color: white;
  font-family: var(--primary-font);
  font-size: 20px;
  font-weight: 500;
`;
TitleElement.displayName = 'TitleElement.styled';

export const MessageElement = styled.div`
  color: white;
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
`;
MessageElement.displayName = 'MessageElement.styled';

export const CloseButton = styled(ResettedButton).attrs({ className: 'fa-regular fa-xmark' })`
  color: var(--light-purple-grey);
  align-self: flex-start;
`;
CloseButton.displayName = 'CloseButton.styled';
