import styled from 'styled-components';
import { EXTRA_EXTRA_LARGE_DEVICES } from '../../constants/breakpoints';

const CONFETTI_BACKGROUND_URL = 'https://shared-assets.tatio.io/images/contact-sales-confetti.svg';

export const AlignmentRowWrapper = styled.div`
  display: flex;
  height: 100%;
`;

AlignmentRowWrapper.displayName = 'AlignmentRowWrapper.styled';

export const TitleElement = styled.h2`
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 24px;
  color: white;
  margin-bottom: 5px;
  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    font-size: 18px;
  }
`;

TitleElement.displayName = 'TitleElement.styled';

export const SubTitleElement = styled.h3`
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 16px;
  color: white;
  max-width: 200px;
  margin-bottom: 25px;
  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    font-size: 14px;
    margin-bottom: 15px;
  }
`;

SubTitleElement.displayName = 'SubTitleElement.styled';

export const ContactSalesBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 208px;
  width: 100%;
  padding: 28.5px 20px;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 8px;
  background-color: rgba(37, 37, 84, 1);
  background-image: url(${CONFETTI_BACKGROUND_URL});
  background-position: center center;
  align-self: flex-end;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    padding: 20px;
    height: 161px;
  }
`;

ContactSalesBoxWrapper.displayName = 'ContactSalesBoxWrapper.styled';

export const SmallDevicesBoxInnerWrapper = styled.div`
  display: flex;
  padding: 10px 12px;
  height: 64px;
  transform: rotate(-90deg);
`;
SmallDevicesBoxInnerWrapper.displayName = 'SmallDevicesBoxInnerWrapper.styled';

export const SmallDevicesBoxOuterWrapper = styled(ContactSalesBoxWrapper)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 163px;
  margin-bottom: 39px;
  background-size: contain;
  background-position: bottom;
`;
SmallDevicesBoxOuterWrapper.displayName = 'SmallDevicesBoxOuterWrapper.styled';
