import { loginTexts } from './languages/LoginTexts';
import { Language, DefaultLanguage } from './languages/general';

export const amplifyErrorHandler = (error: Error, lang?: Language) => {
  const texts = loginTexts[lang ?? DefaultLanguage];
  switch (error.name) {
    case 'NotAuthorizedException':
      return error.message;
    case 'UserNotFoundException':
      return texts.incorrectUserNameOrPassword;
    case 'UsernameExistsException':
      return texts.emailAlreadyRegistered;
    case 'UsernameExistsException':
      return error.message;
    case 'UserNotConfirmedException':
      return error.name;
    case 'LimitExceededException':
      return error.message;
    default:
      return null;
  }
};
