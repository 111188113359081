import React, { isValidElement } from 'react';
import { BackDropLayer } from '../../BackDropLayer/BackDropLayer';
import {
  MobilePopupContainer,
  PopupHeader,
  PopupTitle,
  PopupUpperButton,
  VerticallyScrollableContainer,
} from './style';
import { animated, Interpolation, SpringValue } from '@react-spring/web';

interface BackdropStyle<Axis extends 'x' | 'y'> {
  display: Interpolation<number, 'flex' | 'none'>;
  transform: Interpolation<
    number,
    `translate${Uppercase<Axis>}(-8%) scale(1.16)` | `translate${Uppercase<Axis>}(0px) scale(1.05)`
  >;
}

interface WithSwipedYOrXConmponentProps {
  display: Interpolation<number, 'flex' | 'none'>;
  x?: SpringValue<number>;
  y?: SpringValue<number>;
  backDropStyle: BackdropStyle<'x'> | BackdropStyle<'y'>;
  close: (velocity?: number) => void;
  bind: (...args: unknown[]) => import('@use-gesture/react/dist/declarations/src/types').ReactDOMAttributes;
}
interface IMobilePopupProps extends WithSwipedYOrXConmponentProps {
  title?: string | unknown;
  style?: React.CSSProperties;
  zIndexBackdropLayer: number;
  zIndexPopup: number;
}
export const MobilePopup: React.FC<IMobilePopupProps> = ({
  title,
  children,
  display,
  backDropStyle,
  zIndexBackdropLayer,
  zIndexPopup,
  bind,
  close,
  x,
  y,
}) => {
  const titleToRender = typeof title === 'string' || (typeof title === 'object' && isValidElement(title)) ? title : '';
  const customizedPopupContainerStyle = y?.toString()
    ? { paddingBottom: '100px', display, bottom: '-100px', y }
    : x?.toString()
    ? { display, x }
    : {};
  return (
    <>
      <MobilePopupContainer zIndex={zIndexPopup} {...bind()} style={customizedPopupContainerStyle} as={animated.div}>
        <PopupHeader as={animated.div}>
          <PopupUpperButton></PopupUpperButton>
        </PopupHeader>
        {titleToRender && <PopupTitle>{titleToRender}</PopupTitle>}
        <VerticallyScrollableContainer>{children}</VerticallyScrollableContainer>
      </MobilePopupContainer>
      {
        <BackDropLayer
          zIndex={zIndexBackdropLayer}
          background="rgba(0, 0, 0, 0.35)"
          as={animated.div}
          onClick={() => {
            close();
          }}
          style={backDropStyle}
        />
      }
    </>
  );
};
