import React, { useEffect, useRef, useState } from 'react';

import { SearchableItemsList, TooltipContent, ToolTipItem, ToolTipItemsList } from './style';
import { MultipleSearchableOverflowingTextItem } from './MultipleSearchableOverflowingTextItem';

interface MultipleSearchableOverflowingTextProps {
  labels: string[];
  searchTerm: string;
  zIndexTooltip: number;
  isViewed?: boolean;
  portalize?: boolean;
}
function useUpdateVisibleLablesOnResize(
  labelRefs: React.MutableRefObject<(HTMLLIElement | null)[]>,
  labels: string[],
  setVisibleLabels: React.Dispatch<React.SetStateAction<string[]>>,
  containerRef: React.RefObject<HTMLUListElement>,
) {
  useEffect(() => {
    const updateVisibleLabels = (containerWidth: number) => {
      const { fittingLabels } = labelRefs.current.reduce(
        (acc, el, index) => {
          const labelWidth = el ? el.offsetWidth : 0;
          const padding = 10;
          const totalPadding = (acc.fittingLabels.length - 1) * padding;
          if (acc.totalWidth + labelWidth + totalPadding <= containerWidth) {
            acc.fittingLabels.push(labels[index]);
            acc.totalWidth += labelWidth;
          }

          return acc;
        },
        { totalWidth: 0, fittingLabels: [] } as { totalWidth: number; fittingLabels: string[] },
      );

      setVisibleLabels(fittingLabels);
    };

    // Update container width initially and on resize
    const handleResize = () => {
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
      updateVisibleLabels(containerWidth);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    handleResize(); // Initial setup

    return () => resizeObserver.disconnect();
  }, [labels]);
}

export const MultipleSearchableOverflowingText: React.FC<MultipleSearchableOverflowingTextProps> = ({
  labels,
  searchTerm,
  zIndexTooltip,
  isViewed,
  portalize,
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const labelRefs = useRef<(HTMLLIElement | null)[]>([]);

  const [visibleLabels, setVisibleLabels] = useState<string[]>([]);

  useUpdateVisibleLablesOnResize(labelRefs, labels, setVisibleLabels, containerRef);

  const areThereHiddenLabels = visibleLabels.length !== labels.length;

  const renderTooltipContent = (
    <TooltipContent>
      <ToolTipItemsList>
        {labels.map((label, index) => (
          <ToolTipItem key={index}>{label}</ToolTipItem>
        ))}
      </ToolTipItemsList>
    </TooltipContent>
  );
  const commonProps = {
    labelRefs,
    visibleLabels,
    zIndexTooltip,
    searchTerm,
    isViewed,
    portalize,
    renderTooltipContent,
  };
  return (
    <SearchableItemsList ref={containerRef}>
      <div style={{ position: 'absolute', left: -9999, visibility: 'hidden', zIndex: -666 }}>
        {labels.map((label, index) => {
          return (
            <MultipleSearchableOverflowingTextItem
              setLabelRef={(el) => (labelRefs.current[index] = el)}
              key={index}
              label={label}
              showTooltip={areThereHiddenLabels && index >= visibleLabels.length - 2}
              {...commonProps}
            />
          );
        })}
      </div>

      {visibleLabels.map((label, index) => {
        return (
          <MultipleSearchableOverflowingTextItem
            setLabelRef={(el) => (labelRefs.current[index] = el)}
            key={index}
            label={label}
            showTooltip={areThereHiddenLabels && index >= visibleLabels.length - 2}
            {...commonProps}
          />
        );
      })}
    </SearchableItemsList>
  );
};
