import { CSSProperties } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { TransparentBackDropLayer } from '../BackDropLayer/BackDropLayer';
import { IMenuWithIconsOption, MenuItem } from './MenuItem/MenuItem';
import { MenuListElement, MenuWithIconsWrapper } from './style';
import { forwardRef } from 'react';

/**
 * Menu that each item has an icon and a function callback to be executed once clicked
 */

export interface IMenuWithIcons {
  menuOptions: IMenuWithIconsOption[];
  zIndexBackdrop: number;
  style?: CSSProperties;
  listStyle?: CSSProperties;
  closeMenu: React.MouseEventHandler<HTMLElement>;
}
export const MenuWithIcons = forwardRef<HTMLMenuElement, IMenuWithIcons>(
  ({ menuOptions, style, closeMenu, listStyle, zIndexBackdrop }, ref) => {
    const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

    const renderMenuItems = menuOptions.map(({ title, iconClassName, onClick }) => {
      const handleClick: React.MouseEventHandler<HTMLElement> = (ev) => {
        onClick(ev);
        closeMenu(ev);
      };
      return <MenuItem key={title} title={title} iconClassName={iconClassName} onClick={handleClick} />;
    });
    return (
      <>
        <MenuWithIconsWrapper ref={ref} style={style}>
          <MenuListElement style={listStyle}>{renderMenuItems}</MenuListElement>
        </MenuWithIconsWrapper>
        {!isMobile && <TransparentBackDropLayer onClick={closeMenu} zIndex={zIndexBackdrop} />}
      </>
    );
  },
);
