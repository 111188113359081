import styled from 'styled-components';

import { LazyLottiePlayer } from '../Lottie/LazyLottiePlayer';

export const LoadingScreenContainer = styled.div<{ zindex: number }>`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: ${({ zindex }) => `${zindex}`};
  overflow: hidden;
`;
LoadingScreenContainer.displayName = 'LoadingScreenContainer.styled';

export const LoaderAnimation = styled(LazyLottiePlayer)`
  width: 150px;
  height: 150px;
  @media screen and (max-width: 767px) {
    height: 112.5px;
    width: 112.5px;
  }
`;
LoaderAnimation.displayName = 'LoaderAnimation.styled';

export const LoadingText = styled.span`
  font-family: var(--primary-font);
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: var(--almost-black);
`;
LoadingText.displayName = 'LoadingText.styled';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
ColumnContainer.displayName = 'ColumnContainer.styled';

export const LogoContainer = styled.img`
  width: 190px;
  height: 50px;

  @media screen and (max-width: 478px) {
    width: 113px;
    height: 30px;
  }
`;
LogoContainer.displayName = 'LogoContainer.styled';
