export enum FilterSelectorsNames {
  dualInputRange = 'dualInputRange',
  customizedRadioButton = 'customizedRadioButton',
  customizedCheckbox = 'customizedCheckbox',
  customizedCheckboxWithSearch = 'customizedCheckboxWithSearch',
  customizedCheckboxWithLinkAndSearch = 'customizedCheckboxWithLinkAndSearch',
  dateRangePicker = 'dateRangePicker',
  toggleButton = 'toggleButton',
  customizedRadioButtonWithSelectionDisplay = 'customizedRadioButtonWithSelectionDisplay',
}

export interface IFilterRange {
  min: number | string;
  max: number | string;
}
export interface NumericRange {
  min: number;
  max: number;
}

export interface RadioOption {
  name: string;
  value: string | boolean | number;
}

export interface IFilterRange {
  min: number | string;
  max: number | string;
}
