import animation from '../../assets/lotties/trophy.json';
import { DoubleRowEllipsis, JobElement, JobsPartialWrapper, JobsWrapper, PageWrapper } from './style';
import { CandidateScores, JobApplicationLink } from '../../utils/types';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { LongRightArrow } from '../../reusable-elements/icons';
import { LazyLottiePlayer } from '../Lottie/LazyLottiePlayer';
import { getClickableLink } from '../../utils';

const generateQueryParameters = (sessionId: string | undefined, candidateScoring?: CandidateScores) => {
  if (!sessionId) return '';
  if (!candidateScoring) return `?sId=${sessionId}`;

  const { engagement, prediction } = candidateScoring;

  const engagementScore = engagement !== null ? engagement * 2 : 0;
  const qualificationScore = prediction !== null ? prediction : 0;

  return `?engagement_score=${engagementScore}&qualification_score=${qualificationScore}&sId=${sessionId}`;
};
const linksToElements = (
  jobApplicationLinks: JobApplicationLink[],
  sessionId: string,
  candidateScoring?: CandidateScores,
) => {
  return jobApplicationLinks.map((link) => {
    const locationToRender = link.state && link.city ? `${link.state}, ${link.city}` : link.state || link.city;
    const querryParameters = generateQueryParameters(sessionId, candidateScoring);
    return (
      <a
        style={{ marginLeft: 'auto', marginBottom: 'auto', textDecoration: 'none' }}
        href={getClickableLink(`${link.applicationLink}${querryParameters}`)}
        target="_blank"
        rel="noopener noreferrer"
        key={link.applicationLink}
      >
        <JobElement>
          <h1>
            <DoubleRowEllipsis>{link.title}</DoubleRowEllipsis>
          </h1>
          <h2>{locationToRender}</h2>

          <LongRightArrow />
        </JobElement>
      </a>
    );
  });
};

const getJobLinkRows = (
  jobsLinkElements: JobApplicationLink[],
  sessionId: string,
  candidateScoring?: CandidateScores,
) => {
  let topRowLinks: JSX.Element[] = [];
  let bottomRowLinks: JSX.Element[] = [];
  const total = jobsLinkElements.length;

  // For less than or equal to 3 elements
  if (total <= 3) {
    topRowLinks = linksToElements(jobsLinkElements, sessionId, candidateScoring);
  }
  // For even number of elements more than 3
  else if (total % 2 === 0) {
    const middleIndex = total / 2;
    topRowLinks = linksToElements(jobsLinkElements.slice(0, middleIndex), sessionId, candidateScoring);
    bottomRowLinks = linksToElements(jobsLinkElements.slice(middleIndex), sessionId, candidateScoring);
  }
  // For odd number of elements more than 3
  else {
    const middleIndex = Math.ceil(total / 2);
    topRowLinks = linksToElements(jobsLinkElements.slice(0, middleIndex), sessionId, candidateScoring);
    bottomRowLinks = linksToElements(jobsLinkElements.slice(middleIndex), sessionId, candidateScoring);
  }
  return { topRowElements: topRowLinks, bottomRowElements: bottomRowLinks };
};

export interface ApplyToBusinessJobsProps {
  jobApplicationLinks: JobApplicationLink[];
  wellDone: string;
  simulationReceived: string;
  simulationCompletedDefault: string;
  applyTo: string;
  FacebookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  LinkedinIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  TwitterIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  YoutubeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  socialLinksVisible: boolean;
  candidateScoring?: CandidateScores;
  sessionId: string;
  dir: 'ltr' | 'rtl';
}

export const ApplyToBusinessJobs: React.FC<ApplyToBusinessJobsProps> = ({
  jobApplicationLinks,
  wellDone,
  simulationReceived,
  simulationCompletedDefault,
  applyTo,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
  socialLinksVisible,
  candidateScoring,
  sessionId,
  dir,
}) => {
  const { topRowElements, bottomRowElements } = getJobLinkRows(
    jobApplicationLinks.slice(0, 6),
    sessionId,
    candidateScoring,
  );
  let simulationCompletedText = <h2>{simulationCompletedDefault}</h2>;
  if (simulationReceived) {
    const [preSimulationName, simulationName, postSimulationName] = simulationReceived.split('*');
    simulationCompletedText = (
      <h2>
        {preSimulationName}
        <span style={{ fontWeight: 600 }}>{simulationName ?? ''}</span>
        {postSimulationName}
      </h2>
    );
  }

  return (
    <PageWrapper dir={dir}>
      <LazyLottiePlayer className="trophy" animationData={animation} />
      <h1>{wellDone}</h1>
      {simulationCompletedText}
      {jobApplicationLinks.length > 0 && (
        <>
          <h2 style={{ marginTop: '16px' }}>{applyTo}</h2>
          <JobsWrapper>
            <JobsPartialWrapper>{topRowElements}</JobsPartialWrapper>
            <JobsPartialWrapper>{bottomRowElements}</JobsPartialWrapper>
          </JobsWrapper>
        </>
      )}

      <section style={{ marginTop: '50px' }}>
        {socialLinksVisible && (
          <SocialLinks
            FacebookIcon={FacebookIcon}
            LinkedinIcon={LinkedinIcon}
            TwitterIcon={TwitterIcon}
            YoutubeIcon={YoutubeIcon}
          />
        )}
      </section>
    </PageWrapper>
  );
};
