import { API } from '@skillset/onelogin';
import { Job, OptionalBusinessPage } from '../store/types/EmployerBusinessSliceInterface';
import { compareSortValues } from '../utils';
import { ISortBy } from '../hooks/useSortBy';
import { SuccessResponse } from '../utils/types';
import { INote } from '../components/Notes/types';

export interface JobToFront {
  id: string;
  name: string;
  candidatesCount: number;
  uniqueLink: string;
  simName: string;
  notes: INote[];
}

const getBusinessPermissions = () => {
  return API.get('api', '/employers/permissions', {}) as Promise<
    SuccessResponse<{
      mainPage: OptionalBusinessPage;
      hasMatchedJobs: boolean;
      hasAllowedSimulations: boolean;
      showResume: boolean;
      businessName: string;
    }>
  >;
};

const getJobs = () => {
  return API.get('api', '/employers/jobs', {}) as Promise<SuccessResponse<JobToFront[]>>;
};

const getJobsSampleData = () => {
  return API.get('api', '/employers/jobs/sampleData', {}) as Promise<SuccessResponse<Job[]>>;
};

export const sortJobs = (jobs: Job[], sortBy: ISortBy) => {
  const { keyName, orderBy } = sortBy;
  switch (keyName) {
    case 'name':
      return jobs.sort((a, b) => {
        return compareSortValues(a.name, b.name, orderBy);
      });
    case 'id':
      return jobs.sort((a, b) => {
        return compareSortValues(a.id, b.id, orderBy);
      });
    case 'candidatesCount':
      return jobs.sort((a, b) => {
        return compareSortValues(a.candidatesCount, b.candidatesCount, orderBy);
      });
    default:
      return jobs;
  }
};

const createJobsNote = (jobId: string, note: string) => {
  return API.post('api', `/employers/notes/job/${jobId}`, { body: { note } });
};
const updateJobsNote = (noteId: number, note: string) => {
  return API.patch('api', `/employers/notes/job/${noteId}`, { body: { note } });
};
const deleteJobsNote = (noteId: number) => {
  return API.del('api', `/employers/notes/job/${noteId}`, {});
};

export const getSocialLinksVisibility = async (uniqueLink: string) => {
  return API.get('api', `/simulation/socialLinks/${uniqueLink}`, {}) as Promise<SuccessResponse<boolean>>;
};
export const BusinessService = {
  getSocialLinksVisibility,
  getBusinessPermissions,
  getJobs,
  getJobsSampleData,
  sortJobs,
  createJobsNote,
  updateJobsNote,
  deleteJobsNote,
};
