import styled, { css } from 'styled-components';
import { zIndices } from '../../assets/zIndices';

interface INotesContainer {
  isMobile: boolean;
}
export const NotesContainer = styled.div<INotesContainer>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  z-index: ${zIndices.mobilePopup};
  background-color: white;
  ${({ isMobile }) => {
    if (!isMobile) {
      return css`
        position: fixed;
        top: 166px;
        right: 30px;
        /* height:  */
        width: 360px;
        box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
      `;
    } else {
      return css`
        position: static;
        width: 100%;
        height: calc(100% - 51px);
      `;
    }
  }}
`;

NotesContainer.displayName = 'NotesContainer.styled';

export const NotesHeader = styled.header`
  display: flex;
  padding: 12.5px 20px;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.5px solid var(--border-light-grey);
  border-radius: 8px 8px 0px 0px;
`;
NotesHeader.displayName = 'NotesHeader.styled';

export const TitleElement = styled.span`
  font-size: 16px;
  font-weight: 700;
  font-family: var(--primary-font);
  color: var(--almost-black);
`;
TitleElement.displayName = 'TitleElement.styled';

export const FormAndListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
  height: 100%;
`;

FormAndListContainer.displayName = 'FormAndListContainer.styled';

interface IFormControlsProps {
  isTextAreaFocused: boolean;
}
export const FormControls = styled.div<IFormControlsProps>`
  display: ${({ isTextAreaFocused }) => (!isTextAreaFocused ? 'none' : 'flex')};
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

FormControls.displayName = 'FormControls.styled';

export const NotesForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

NotesForm.displayName = 'NotesForm.styled';

export const WriteNoteTextArea = styled.textarea.attrs({ placeholder: 'Write your note' })`
  height: 100%;
  width: 100%;
  height: 40px;
  padding: 7.5px 11.5px;
  border-radius: 5px;
  border: none;
  background-color: var(--light-grey);
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  color: var(--almost-black);
  resize: none;
  &::placeholder {
    font-family: var(--primary-font);
    color: var(--almost-black);
    font-size: 14px;
    font-weight: 400;
  }

  &:focus {
    height: auto;
    border: 2px solid #1a1a1a;
    outline: 1px solid #1a1a1a;
    padding: 11px 11.5px 15px 11.5px;
    margin-bottom: 10px;
    overflow-y: scroll;
    font-size: 16px;
  }
`;

WriteNoteTextArea.displayName = 'WriteNoteTextArea.styled';
