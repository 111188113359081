import styled from 'styled-components';
import { SMALL_DEVICES } from '../constants/breakpoints';

export const TableAndFiltersWrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding: 16px;
  }
  height: 100%;
`;
TableAndFiltersWrapper.displayName = 'TableAndFiltersWrapper.styled';
