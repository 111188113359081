import styled from 'styled-components';
import { zIndices } from '../../assets/zIndices';
import { SMALL_DEVICES } from '@skillset/ui';

export const FiltersSectionRow = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  height: 33px;
  overflow: hidden;

  .title {
    margin-right: 2px;
    white-space: nowrap;
    color: var(--almost-black);
    font-weight: 600;
    font-size: 14px;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      height: 100%;
      align-items: center;
      display: flex;
      background-color: white;
      padding-right: 5px;
      left: 0;
      position: sticky;
      z-index: ${zIndices.minimal};
    }
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .title {
      font-weight: 400;
    }
  }
`;

export const FilterSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 0px;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding: 7.5px 0px 0px;
  }

  .reset {
    color: var(--almost-black);
    font-weight: 600;
    font-size: 14px;
    font-family: var(--gilroy-font);
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }
`;

FilterSectionContainer.displayName = 'FilterSectionContainer.styled';

export const CounterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 9px;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 12px;
`;

FiltersSectionRow.displayName = 'FiltersSectionRow.styled';

export const CheckMarkElement = styled.i.attrs({ className: 'fa-solid fa-check' })``;
CheckMarkElement.displayName = 'CheckMarkElement.styled';
