import styled from 'styled-components';
import { ResettedButton } from '../../../reusable-elements/ResettedButton';

export const MenuItemElement = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  line-height: 17px;
  cursor: pointer;
  height: 28px;
  border-radius: 5px;
`;
MenuItemElement.displayName = 'MenuItemElement.styled';

export const MenuItemButton = styled(ResettedButton)`
  width: 100%;
  padding: 5px;
  font: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  gap: inherit;

  &:hover {
    box-shadow: 0px 8px 16px 0px rgba(50, 50, 71, 0.06), 0px 8px 8px 0px rgba(50, 50, 71, 0.08);
  }
`;
MenuItemButton.displayName = 'MenuItemButton.styled';
export const MenuIconElement = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`;

MenuIconElement.displayName = 'MenuIconElement.styled';

export const TextElement = styled.span`
  color: var(--almost-black);
  font-size: 14px;
  font-weight: 600;
`;
TextElement.displayName = 'TextElement.styled';
