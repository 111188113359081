import React from 'react';
import { SearchableOverflowingText } from '@skillset/ui';
import { WithSearchTerm } from '../../../utils/types';
import { zIndices } from '../../../assets/zIndices';

interface IDColumnProps extends WithSearchTerm {
  id: string;
}

export const IDColumn: React.FC<IDColumnProps> = ({ id, searchTerm }) => {
  return <SearchableOverflowingText zIndexTooltip={zIndices.sideMenuTooltip} text={id} searchTerm={searchTerm} />;
};
