import React from 'react';
import { SearchableOverflowingText } from '@skillset/ui';
import { WithSearchTerm } from '../../../utils/types';
import { zIndices } from '../../../assets/zIndices';

interface CandidatesCountColumnProps extends WithSearchTerm {
  candidatesCount: number;
}

export const CandidatesCountColumn: React.FC<CandidatesCountColumnProps> = ({ candidatesCount, searchTerm }) => {
  return (
    <SearchableOverflowingText
      zIndexTooltip={zIndices.sideMenuTooltip}
      text={String(candidatesCount)}
      searchTerm={searchTerm}
    />
  );
};
