import styled, { CSSProperties } from 'styled-components';
import { SMALL_DEVICES } from '../../../constants/breakpoints';
import { HeaderColumnElement } from '../HeaderColumn/style';

interface ITableHeaderElementProps {
  rowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  mobileRowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  shouldPadRight?: boolean;
}

export const TableHeaderElement = styled.header<ITableHeaderElementProps>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ rowGridTemplateColumns }) => rowGridTemplateColumns};

  width: 100%;
  height: 58px;
  background-color: var(--light-grey);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-right: ${({ shouldPadRight }) => (shouldPadRight ? '14px' : 'unset')};
  ${HeaderColumnElement}:not(:last-child) {
    border-right: 1.2px solid #efefef;
  }
  @media screen and (max-width: ${SMALL_DEVICES}) {
    grid-template-columns: ${({ mobileRowGridTemplateColumns }) => mobileRowGridTemplateColumns};
    ${HeaderColumnElement}:not(:last-child) {
      border-right: unset;
    }
  }
`;
TableHeaderElement.displayName = 'TableHeaderElement.styled';
