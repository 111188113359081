import styled, { css } from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';
import { NewLabel } from '../../reusable-elements/CandidateElements';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 7.5px;
  }
  height: 100%;
`;
TableContainer.displayName = 'TableContainer.styled';

interface IOverflowingTextElementProps {
  fontSize?: string;
  fontWeight?: string;
}

interface ITableTextElementProps extends IOverflowingTextElementProps {
  color?: string;
}

export const OverflowingTextElement = styled.span<IOverflowingTextElementProps>`
  /* can be generalized if needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  ${NewLabel} ~ & {
    margin-inline-start: 7px;
  }
`;
OverflowingTextElement.displayName = 'OverflowingTextElement.styled';

export const TableTextElement = styled(OverflowingTextElement)<ITableTextElementProps>`
  font-family: var(--primary-font);
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  color: ${({ color }) => (color ? color : 'var(--almost-black)')};
`;
TableTextElement.displayName = 'TableTextElement.styled';

interface CustomerTableTextElementProps extends ITableTextElementProps {
  highlightBackground: string | undefined;
}

export const CustomerTableTextElement = styled(TableTextElement)<CustomerTableTextElementProps>`
  ${(props) =>
    props.highlightBackground
      ? css`
          .highlight-search {
            background: ${props.highlightBackground};
          }
        `
      : ''}
`;
CustomerTableTextElement.displayName = 'CustomerTableTextElement.styled';
