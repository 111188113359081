import React, { useState } from 'react';
import { Button } from '../Reusable-styled-elements/Button';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { SpinnerElement } from '@skillset/ui';
import { Language } from '../../utils/languages/general';
import { getLoginTexts } from '../../services/languages.service';

export interface GoogleLoginFormProps {
  shouldDisableRegistration: boolean;
  registrationLanguage?: Language;
}

export const GoogleLoginForm: React.FC<GoogleLoginFormProps> = ({
  shouldDisableRegistration,
  registrationLanguage,
}) => {
  const [loading, setIsLoading] = useState(false);
  const texts = getLoginTexts(registrationLanguage);
  const handleFederatedLogin = async () => {
    setIsLoading(true);
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Button type="button" onClick={handleFederatedLogin} disabled={shouldDisableRegistration}>
      {loading ? (
        <SpinnerElement size="25px" />
      ) : (
        <img src="https://shared-assets.tatio.io/images/google-logo.svg" alt="google logo" />
      )}
      <span>{texts.continueWithGoogle}</span>
    </Button>
  );
};
