import styled from 'styled-components';

export const MenuItemWrapper = styled.div<{ titleSize?: string; descriptionSize?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  color: white;
  font-family: var(--primary-font);
  letter-spacing: 0em;
  text-align: left;
  .title {
    font-size: ${({ titleSize }) => (titleSize ? titleSize : '18px')};
    font-weight: 600;
    line-height: 21px;
  }
  .description {
    font-size: ${({ descriptionSize }) => (descriptionSize ? descriptionSize : '16px')};
    font-weight: 300;
    line-height: 21px;
  }
`;

MenuItemWrapper.displayName = 'MenuItemWrapper.styled';

export const CurrentItemIcon = styled.i.attrs({ className: 'fa-regular fa-circle-dot' })`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
  align-self: center;
`;

CurrentItemIcon.displayName = 'CurrentItemIcon.styled';

export const CompletedItemIcon = styled.i.attrs({ className: 'fa-solid fa-circle-check' })`
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  align-self: center;
  color: white;
`;
CompletedItemIcon.displayName = 'CompletedItemIcon.styled';

export const SecondItemIcon = styled.i.attrs({ className: 'fa-regular fa-circle-2' })`
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: white;
  align-self: center;
`;
SecondItemIcon.displayName = 'SecondItemIcon.styled';

export const ThirdItemIcon = styled.i.attrs({ className: 'fa-regular fa-circle-3' })`
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: white;
  align-self: center;
`;
ThirdItemIcon.displayName = 'ThirdItemIcon.styled';

export const CandidateMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
`;

export const StandardSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;
StandardSectionWrapper.displayName = 'StandardSectionWrapper.styled';
