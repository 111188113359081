import { Language } from './general';

export interface Texts {
  personalInformationTitle: string;
  tellUseAboutYourself: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  continue: string;
  fillFieldError: string;
  anonymousLoginMessage: string;
  fillValidPhone: string;
}

export const questionnaireTexts: Record<Language, Texts> = {
  en: {
    personalInformationTitle: 'Personal Information',
    tellUseAboutYourself: 'Please tell us a bit about yourself',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of birth',
    phone: 'Phone',
    country: 'Country',
    state: 'State',
    city: 'City',
    zipCode: 'Zip Code',
    continue: 'Continue',
    fillFieldError: 'Please fill out this field',
    anonymousLoginMessage: 'Verifying Identity...',
    fillValidPhone: 'Please fill a valid Phone number',
  },
  es: {
    personalInformationTitle: 'Información personal',
    tellUseAboutYourself: 'Por favor, cuéntanos un poco sobre ti',
    firstName: 'Nombre',
    lastName: 'Apellido',
    dateOfBirth: 'Fecha de nacimiento',
    phone: 'Teléfono',
    country: 'País',
    state: 'Estado',
    city: 'Ciudad',
    zipCode: 'Código postal',
    continue: 'Continuar',
    fillFieldError: 'complete este campo',
    anonymousLoginMessage: 'Verificando identidad...',
    fillValidPhone: 'Por favor complete un número de teléfono válido',
  },
  he: {
    personalInformationTitle: 'מידע אישי',
    tellUseAboutYourself: 'ספר לנו קצת על עצמך',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    dateOfBirth: 'ת.לידה',
    phone: 'טלפון',
    country: 'מדינה',
    state: 'מחוז,',
    city: 'עיר',
    zipCode: 'מיקוד',
    continue: 'המשך',
    fillFieldError: 'מלא שדה זה',
    anonymousLoginMessage: 'מאמת זהות...',
    fillValidPhone: 'נא למלא מספר טלפון חוקי',
  },
  pt: {
    personalInformationTitle: 'Informações pessoais',
    tellUseAboutYourself: 'Por favor, conte-nos um pouco sobre você',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    dateOfBirth: 'Data de nascimento',
    phone: 'Telefone',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    zipCode: 'Código postal',
    continue: 'Continuar',
    fillFieldError: 'preencha este campo',
    anonymousLoginMessage: 'Verificando identidade...',
    fillValidPhone: 'Por favor preencha um número de telefone válido',
  },
};
