import styled from 'styled-components';

import { ResettedButton } from '../../reusable-elements/ResettedButton';

export const MenuWrapper = styled.div`
  outline: none;
  top: 45px;
  right: 0;
  width: 200px;
  padding-block: 5px;
  border-radius: 4px;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);

  &.light {
    background-color: var(--light-grey);
  }
  &.dark {
    background: var(--almost-black, #1a1a1a);
    color: #fff;
  }
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
MenuWrapper.displayName = 'MenuWrapper.styled';

export const MenuItemElement = styled(ResettedButton)`
  padding: 8px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .light & {
    color: var(--almost-black);
  }
  .dark & {
    color: #fff;
  }
  width: 100%;
  font: inherit;
  outline: none;

  &:hover,
  &:focus {
    background-color: var(--orange);
    color: #fff;
  }

  & svg,
  & span {
    color: inherit;
  }
`;
MenuItemElement.displayName = 'MenuItemElement.styled';
