import styled from 'styled-components';
import { SMALL_DEVICES } from '@skillset/ui';

export const ErrorMessageElement = styled.span`
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: var(--gilroy-font);
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: var(--error-red) !important;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    line-height: 14px;
  }
`;
