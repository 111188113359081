import { ReactElement, PropsWithChildren, useRef, useCallback, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { Size } from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { WithComponentKey } from '../../../utils/types';
import { tableRowItemHeight } from '../TableRowItem/style';
import { NoDataView } from '../NoDataView/NoDataView';
import { TableBaseProps } from '../types';
import { TableListElement } from './style';
import { VirtualRow, VirtualRowProps } from './VirtualRow';

interface ITableList<RowData extends WithComponentKey> extends TableBaseProps<RowData> {
  measureRef: (node: HTMLDivElement | null) => void;
}

export const TableList = <RowData extends WithComponentKey>({
  columns,
  data,
  renderDataCondition,
  rowGridTemplateColumns,
  mobileRowGridTemplateColumns,
  rowsBorderLess,
  renderRowProps,
  measureRef,
  components,
  shouldMarkFirstRow,
}: PropsWithChildren<ITableList<RowData>>): ReactElement | null => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const listRef = useRef<VariableSizeList<VirtualRowProps<RowData>>>(null);

  const sizeMap = useRef(Array(data.length).fill(tableRowItemHeight));
  const setRowSizeByIndex = useCallback(
    (index: number) => (size: number | null) => {
      sizeMap.current = { ...sizeMap.current, [index]: size };
      if (null === size && index in sizeMap.current) {
        delete sizeMap.current[index];
        listRef.current?.resetAfterIndex(index, false);
      } else listRef.current?.resetAfterIndex(index);
    },
    [],
  );
  const context: VirtualRowProps<RowData> = {
    rows: data,
    columns,
    rowGridTemplateColumns,
    mobileRowGridTemplateColumns,
    rowsBorderLess,
    components,
    isMobile,
    renderRowProps,
    setRowSizeByIndex,
    shouldMarkFirstRow: shouldMarkFirstRow ?? false,
  };
  useEffect(() => {
    if (data.length > sizeMap.current.length) {
      sizeMap.current = Array(data.length).fill(tableRowItemHeight);
      listRef.current?.resetAfterIndex(0);
    }
  }, [data.length]);
  const getSize = useCallback(
    (index: number) => {
      const currentRowSize = sizeMap.current[index];
      return currentRowSize ?? tableRowItemHeight;
    },
    [sizeMap.current, tableRowItemHeight],
  );

  const renderRows = (
    <AutoSizer>
      {({ height, width }: Size) => (
        <VariableSizeList<VirtualRowProps<RowData>>
          ref={listRef}
          height={height}
          width={width}
          itemCount={data.length}
          itemSize={getSize}
          itemData={context}
        >
          {VirtualRow}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
  const renderNoData = components?.NoData ? components.NoData() : <NoDataView />;

  return <TableListElement ref={measureRef}>{renderDataCondition ? renderRows : renderNoData}</TableListElement>;
};
