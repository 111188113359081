import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@aws-amplify/api-rest/node_modules/axios/index';

import { BusinessService } from '../../services/employerBusinessService';
import config from '../../config/aws.config';
import { WithTypesType } from '../types/WithTypesType';

import { INote } from '../../components/Notes/types';
import { SimulationTypeService } from '../../services/simulationTypeService';
import { Job } from '../types/EmployerBusinessSliceInterface';

export const getBusinessPermissions = createAsyncThunk(
  'employerBusiness/getBusinessPermissions',
  async (_, thunkAPI) => {
    try {
      const { payload } = await BusinessService.getBusinessPermissions();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getJobs = createAsyncThunk('employerBusiness/getJobs', async (_, thunkAPI) => {
  try {
    const { payload } = await BusinessService.getJobs();
    const jobs: Job[] = payload.map((job) => {
      job.uniqueLink = `${config.urls.simulations_url}${job.uniqueLink}`;
      return job;
    });
    return jobs;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data.name);
  }
});

export const getJobsSampleData = createAsyncThunk('employerBusiness/getJobsSampleData', async (_, thunkAPI) => {
  try {
    const { payload } = await BusinessService.getJobsSampleData();
    return payload;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data.name);
  }
});

/* 
The following variables are made for optimistic rendered notes temporarily IDs.
*/
const TEMP_NOTE_ID_PREFIX = 'tempJobNoteId';
let jobNotesTempIdCounter = 0;

export const setJobNotes = createAction(
  'employerBusiness/setJobNotes',
  (payload: { jobId: string; notes: INote[] }) => {
    return { payload };
  },
);
interface CreateJobNote {
  jobId: string;
  note: string;
}

export const createJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerBusiness/createJobNote',
  async (params: CreateJobNote, thunkAPI) => {
    const tempId = `${TEMP_NOTE_ID_PREFIX}${jobNotesTempIdCounter++}`;
    const { jobId: jobId, note } = params;
    const { jobs } = thunkAPI.getState().employersBusinessSliceReducer;
    const { platformUser } = thunkAPI.getState().authSliceReducer;
    const currentNotes = jobs.find((job) => job.id === jobId)?.notes;
    if (!platformUser?.personal || !Array.isArray(currentNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes or user personal details');
    }
    const noteToCreate: INote = {
      id: tempId,
      createdAt: new Date(Date.now()).toJSON(),
      authorFirstName: platformUser.personal.firstName,
      authorLastName: platformUser.personal.lastName,
      note,
      isPrivileged: false,
    };
    thunkAPI.dispatch(setJobNotes({ jobId, notes: [noteToCreate, ...currentNotes] }));
    try {
      const { payload } = await BusinessService.createJobsNote(jobId, note);
      return { tempId, jobId, createdNoteId: payload.createdNoteId };
    } catch (err) {
      thunkAPI.dispatch(setJobNotes({ jobId, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

interface UpdateJobNote extends CreateJobNote {
  noteId: number;
}
export const updateJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerBusiness/updateJobNote',
  async (params: UpdateJobNote, thunkAPI) => {
    const { noteId, jobId, note } = params;
    const { jobs } = thunkAPI.getState().employersBusinessSliceReducer;
    const jobNotes = jobs.find((job) => job.id === jobId)?.notes;
    if (!Array.isArray(jobNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    const notesToUpdate = jobNotes.map((currNote) => {
      return currNote.id === noteId ? { ...currNote, note } : currNote;
    });

    thunkAPI.dispatch(setJobNotes({ jobId, notes: notesToUpdate }));
    try {
      const { payload } = await BusinessService.updateJobsNote(noteId, note);
      return { payload };
    } catch (err) {
      thunkAPI.dispatch(setJobNotes({ jobId, notes: jobNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

type DeleteJobNote = Omit<UpdateJobNote, 'note'>;
export const deleteJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerBusiness/deleteJobNote',
  async (params: DeleteJobNote, thunkAPI) => {
    const { noteId, jobId } = params;
    const { jobs } = thunkAPI.getState().employersBusinessSliceReducer;
    const currentNotes = jobs.find((job) => job.id === jobId)?.notes;
    if (!currentNotes) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    thunkAPI.dispatch(setJobNotes({ jobId, notes: currentNotes.filter((note) => note.id !== noteId) }));
    try {
      const { payload } = await BusinessService.deleteJobsNote(noteId);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(setJobNotes({ jobId, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export interface GetSimulationTypes {
  customerUUID: string | null;
}

export const getSimulationTypes = createAsyncThunk(
  'employerBusiness/getSimulationTypes',
  async (params: GetSimulationTypes, thunkAPI) => {
    try {
      const { payload } = await SimulationTypeService.getAll(params.customerUUID ?? '');
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
