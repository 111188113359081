import { Job } from '../../../store/types/EmployerBusinessSliceInterface';
import { JobsFilterBy } from '../types';

export const filterJobs = (jobs: Job[], searchTerm: string, filterBy: JobsFilterBy) => {
  let filteredJob = jobs.filter((job) => {
    let jobShouldBeIncluded = true;
    if (filterBy.jobsNames.length > 0) {
      jobShouldBeIncluded = filterBy.jobsNames.includes(job.name);
    }

    return jobShouldBeIncluded;
  });

  filteredJob = filteredJob.filter((job) => {
    const { candidatesCount, name, id } = job;
    const columnsToMatchSearchTerm = [name, id, candidatesCount];
    const okByString = columnsToMatchSearchTerm.some((str) => {
      return str?.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
    return okByString;
  });

  return filteredJob;
};
