import React from 'react';
import { LottieContainer, PageContainer, SubTitle, Title } from './style';
import { LazyLottiePlayer } from '@skillset/ui';
import noPhone from '../../assets/lotties/no-phone.json';

export const SwitchDevice = () => {
  return (
    <PageContainer>
      <LottieContainer>
        <LazyLottiePlayer animationData={noPhone} />
      </LottieContainer>
      <Title>
        Our platform currently <br />
        works best on desktop
      </Title>
      <SubTitle>
        Please log in from a computer for the optimal
        <br />
        experience
      </SubTitle>
    </PageContainer>
  );
};
