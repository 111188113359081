import { InputHTMLAttributes, RefObject } from 'react';
import { InputErrorMessage, InputGroupContainer, FormGroupText, InputSuccessMessage } from './style';

export interface FormInputGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  isValid?: boolean;
  hideStatusIndications?: boolean;
  displayInputStatus?: boolean;
  showSuccessIndications?: boolean;
  touched: boolean;
  type?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  toggleInputHandler?: (arg: boolean) => void;
  phoneRef?: RefObject<HTMLInputElement>;
}

export const FormInputGroup: React.FC<FormInputGroupProps> = ({
  label,
  required,
  errorMessage,
  isValid,
  touched,
  hideStatusIndications = false,
  showSuccessIndications = false,
  type = 'text',
  placeholder = '',
  onChange: onChanged,
  phoneRef,
  ...restInputAttributes
}) => {
  const showError = errorMessage && 0 < errorMessage.length && <InputErrorMessage>{errorMessage}</InputErrorMessage>;
  const showSuccess = showSuccessIndications && <InputSuccessMessage>Success</InputSuccessMessage>;
  const inputStatus = !isValid ? showError : showSuccess;

  return (
    <InputGroupContainer
      hasError={hideStatusIndications ? false : (errorMessage && 0 < errorMessage?.length) || false}
      touched={hideStatusIndications ? false : touched}
    >
      <label>
        <FormGroupText required={required}>{label}</FormGroupText>
        <input
          required={required}
          type={type}
          placeholder={placeholder}
          style={{ color: restInputAttributes.value ? 'var(--almost-black)' : 'var(--gray)' }}
          onChange={onChanged ? onChanged : undefined}
          ref={phoneRef}
          {...restInputAttributes}
        />
        {!hideStatusIndications && inputStatus}
      </label>
    </InputGroupContainer>
  );
};
