import { useEffect, useState } from 'react';
import { getCityByState, getCountries, getStatesByCountry } from '../services/countriesApi.service';

interface UseCountryStateCityProps {
  countryValidator: (value: string) => boolean;
  stateValidator: (value: string) => boolean;
  cityValidator: (value: string) => boolean;
}
export const useCountryStateCity = ({ countryValidator, stateValidator, cityValidator }: UseCountryStateCityProps) => {
  interface IInitialData {
    value: string;
    error: string;
    options: string[];
  }

  const initialData: IInitialData = {
    value: '',
    error: '',
    options: [],
  };
  const [countryObj, setCountryObj] = useState({
    ...initialData,
    required: true,
  });

  const [stateObj, setStateObj] = useState({
    ...initialData,
    required: true,
    disabled: true,
  });

  const [cityObj, setCityObj] = useState({
    ...initialData,
    required: true,
    disabled: true,
  });
  const [touchedMap, setTouchedMap] = useState({ country: false, state: false, city: false });

  const countryObjIsValid = countryValidator(countryObj.value);
  const stateObjIsValid = stateValidator(stateObj.value) || !stateObj.required;
  const cityObjIsValid = cityValidator(cityObj.value) || !cityObj.required;
  const countryObjHasError = !countryObjIsValid && touchedMap.country;
  const stateObjHasError = !stateObjIsValid && touchedMap.state;
  const cityObjHasError = !cityObjIsValid && touchedMap.city;

  async function fetchCountries() {
    const { options, error } = await getCountries();
    if (options) {
      setCountryObj({
        ...countryObj,
        options,
      });
      setStateObj({
        ...initialData,
        required: true,
        disabled: false,
      });
      setCityObj({
        ...initialData,
        required: true,
        disabled: false,
      });
    } else if (error) {
      setCountryObj((prevState) => ({ ...prevState, error }));
    }
  }

  async function fetchStates(country: string) {
    const { options, error } = await getStatesByCountry(country);
    if (options) {
      setStateObj((prevState) => ({
        ...prevState,
        options,
        required: !!options.length,
        disabled: false,
      }));
      setCityObj({
        ...initialData,
        required: !!options.length,
        disabled: false,
      });
    } else if (error) {
      setStateObj((prevState) => ({ ...prevState, error }));
    }
  }

  async function fetchCities(state: string) {
    const { options, error } = await getCityByState(state);
    if (options) {
      setCityObj({
        ...cityObj,
        options,
        required: !!options.length,
        disabled: false,
      });
    } else if (error) {
      setCityObj((prevState) => ({ ...prevState, error }));
    }
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (countryObj.value) {
      fetchStates(countryObj.value);
    }
  }, [countryObj.value]);

  useEffect(() => {
    if (stateObj.value) {
      fetchCities(stateObj.value);
    }
  }, [stateObj.value]);

  const onSelectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'country':
        setTouchedMap((prevState) => ({ ...prevState, [name]: true }));
        setCountryObj((prevState) => ({ ...prevState, value }));
        setStateObj((prevState) => ({ ...prevState, value: '' }));
        setCityObj((prevState) => ({ ...prevState, value: '', disabled: true }));
        break;
      case 'state':
        setTouchedMap((prevState) => ({ ...prevState, [name]: true }));
        setStateObj((prevState) => ({ ...prevState, value }));
        setCityObj((prevState) => ({ ...prevState, value: '' }));
        break;
      case 'city':
        setTouchedMap((prevState) => ({ ...prevState, [name]: true }));
        setCityObj((prevState) => ({ ...prevState, value }));
        break;
      default:
        break;
    }
  };
  return {
    countryObj,
    stateObj,
    cityObj,
    countryObjHasError,
    stateObjHasError,
    cityObjHasError,
    onSelectChanged,
    setTouchedMap,
    touchedMap,
  };
};
