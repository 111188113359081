import React, { createContext, useCallback, useContext, useState } from 'react';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { HighlightedSearchTerm } from '../../../components/HighlightedSearchTerm/HighlightedSearchTerm';
import { CustomerTableTextElement } from '../style';
import { FloatingToolTipWithTriggerElement } from '../../../reusable-elements/FloatingToolTipWithTriggerElement';
import { useMergeRefs } from '@floating-ui/react';

interface SearchableOverflowingTextProps {
  text: string;
  searchTerm: string;
  zIndexTooltip: number;
  isViewed?: boolean;
  color?: string;
  highlightBackground?: string;
  portalize?: boolean;
  width?: string;
}
interface SearchableOverflowingTextContextValue extends Omit<SearchableOverflowingTextProps, 'zIndexTooltip'> {
  refCallback: ((node: HTMLSpanElement | null) => void) | null;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
}
const SearchableOverflowingTextContext = createContext<SearchableOverflowingTextContextValue>({
  text: '',
  searchTerm: '',
  isViewed: false,
  color: 'var(--almost-black)',
  highlightBackground: 'var(--grey90)',
  refCallback: () => {},
  setIsHovered: () => {},
});
const renderTableText: (referenceCallback: (ref: HTMLElement | null) => void) => React.ReactNode = (setReference) => {
  const { text, searchTerm, isViewed, setIsHovered, refCallback, color, highlightBackground } = useContext(
    SearchableOverflowingTextContext,
  );

  const tableTextElementRefs = useMergeRefs([setReference, refCallback]);
  return (
    <CustomerTableTextElement
      fontSize={isViewed ? '15px' : '15px'}
      fontWeight={isViewed ? '200' : '600'}
      ref={tableTextElementRefs}
      onMouseEnter={useCallback(() => setIsHovered(true), [])}
      onMouseLeave={useCallback(() => setIsHovered(false), [])}
      color={color}
      highlightBackground={highlightBackground}
      className="customer-table-text-element"
    >
      <HighlightedSearchTerm text={text} searchTerm={searchTerm.trim()} />
    </CustomerTableTextElement>
  );
};

export const SearchableOverflowingText: React.FC<SearchableOverflowingTextProps> = ({
  text,
  searchTerm,
  zIndexTooltip,
  isViewed = true,
  color = 'var(--almost-black)',
  highlightBackground,
  portalize,
}) => {
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('horizontal');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const [isHovered, setIsHovered] = useState(false);
  const shouldDisplayTooltip = isOverflowing && !isMobile && isHovered;
  return (
    <>
      <SearchableOverflowingTextContext.Provider
        value={{
          text,
          searchTerm,
          isViewed,
          refCallback,
          setIsHovered,
          color,
          highlightBackground,
        }}
      >
        <FloatingToolTipWithTriggerElement
          color={color}
          fontWeight="600"
          textColor="white"
          text={text}
          floatingPlacement="top"
          isDisplayed={shouldDisplayTooltip}
          zindex={zIndexTooltip}
          direction="down"
          portalize={portalize}
        >
          {renderTableText}
        </FloatingToolTipWithTriggerElement>
      </SearchableOverflowingTextContext.Provider>
    </>
  );
};
