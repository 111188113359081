import React from 'react';
import { WithSwipedYComponentProps } from '../../../../hooks/useAnimatedSwipeY';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { MobilePopup } from '../../../MobileTheme/MobilePopup/MobilePopup';
import { OneOfLayoutTypes, SelectorsContainer, TitleElement } from '../../style';
import { FilterSelectorsNames } from '../../types';
import { CSSProperties } from 'styled-components';

interface ISelectorContainerComponentProps extends Partial<WithSwipedYComponentProps> {
  name: string;
  type?: keyof typeof FilterSelectorsNames;
  layoutType?: OneOfLayoutTypes;
  style?: CSSProperties;
  desktopFilterDisplay?: boolean;
  'data-test-id'?: string;
  zIndexMobilePopup: number;
  zIndexBackdrop: number;
  zIndexFilterField: number;
}
export const SelectorsContainerComponent: React.FC<ISelectorContainerComponentProps> = ({
  name,
  type,
  children,
  layoutType = 'regular',
  desktopFilterDisplay,
  style,
  display,
  y,
  backDropStyle,
  close,
  bind,
  ['data-test-id']: dataTestId = 'core-task-container',
  zIndexMobilePopup,
  zIndexBackdrop,
  zIndexFilterField,
}) => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const isDualInputRange = type === 'dualInputRange';
  const shouldBeFullWidth = (type === 'dateRangePicker' && layoutType === 'allFilters') || type === 'toggleButton';
  const typesWithoutTitle: (typeof type)[] = ['customizedCheckboxWithSearch', 'toggleButton'];

  if (!isMobile) {
    if (desktopFilterDisplay) {
      return (
        <SelectorsContainer
          zIndex={zIndexFilterField}
          layoutType={layoutType}
          style={
            shouldBeFullWidth
              ? { width: '100%', height: 'unset', ...style }
              : !isDualInputRange
              ? { height: 'unset', width: 'unset', ...style }
              : {}
          }
        >
          {!typesWithoutTitle.includes(type) && <TitleElement data-test-id={dataTestId}>{name}</TitleElement>}
          {children}
        </SelectorsContainer>
      );
    } else {
      return null;
    }
  } else {
    if (display && y && backDropStyle && close && bind) {
      return (
        <MobilePopup
          zIndexBackdropLayer={zIndexBackdrop}
          zIndexPopup={zIndexMobilePopup}
          title={name}
          close={close}
          display={display}
          y={y}
          backDropStyle={backDropStyle}
          bind={bind}
        >
          <SelectorsContainer
            zIndex={zIndexFilterField}
            layoutType={layoutType}
            style={!isDualInputRange ? { height: 'unset', alignItems: 'flex-start' } : {}}
          >
            {!isMobile && <TitleElement data-test-id="core-task-container">{name}</TitleElement>}
            {children}
          </SelectorsContainer>
        </MobilePopup>
      );
    } else {
      return null;
    }
  }
};
