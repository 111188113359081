import styled from 'styled-components';
import { AnchorLikeButton } from '../../../reusable-elements/CandidateElements';

export const NoDataViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
NoDataViewContainer.displayName = 'NoDataViewContainer.styled';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
ColumnContainer.displayName = 'ColumnContainer.styled';

export const NoResultsText = styled.span`
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
`;
NoResultsText.displayName = 'NoResultsText.styled';

export const NoDataImage = styled.img.attrs({ src: 'https://shared-assets.tatio.io/images/no-results.svg' })`
  height: 66px;
  width: 67px;
`;
NoDataImage.displayName = 'NoDataImage.styled';

export const NoFavoritesImage = styled.img.attrs({ src: 'https://shared-assets.tatio.io/images/no-favorites.svg' })`
  height: 66px;
  width: 67px;
`;
NoFavoritesImage.displayName = 'NoFavoritesImage.styled';

export const BackToCandidates = styled(AnchorLikeButton)`
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  color: inherit;
`;
BackToCandidates.displayName = 'BackToCandidates.styled';
