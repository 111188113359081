import { useEffect, useRef, useState } from 'react';
import { Heading } from '../../../components/Reusable-styled-elements/Heading';
import { Subheader } from '../../../components/Reusable-styled-elements/Subheader';
import { userService } from '../../../services/user.service';
import { BusinessQuestionareForm } from '../../../components/QuestionnaireForm/BusinessQuestionareForm';

export const BusinessUserQuestionare: React.FC<{ platformName: string }> = ({ platformName }) => {
  const [businessName, setBusinessName] = useState<string | null>(null);
  const mountRef = useRef(false);

  const getInvitedUserBusinessDetails = async () => {
    try {
      const { payload } = await userService.getInvitedUserBusiness();
      if (mountRef.current) {
        setBusinessName(payload.businessName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    mountRef.current = true;
    getInvitedUserBusinessDetails();
    return () => {
      mountRef.current = false;
    };
  }, []);

  return (
    <>
      <Heading>Company Information</Heading>
      <Subheader>Please tell us a bit about yourself</Subheader>
      <BusinessQuestionareForm currentBusinessName={businessName} platformName={platformName} />
    </>
  );
};
