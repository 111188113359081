import styled from 'styled-components';
import { Subheader } from '../../../components/Reusable-styled-elements/Subheader';
import { SMALL_DEVICES } from '@skillset/ui';
import { RegisterFormContainer } from '../../Register/style';
export const QuestionnaireFormContainer = styled(RegisterFormContainer)`
  max-width: unset;
  justify-content: unset;
  text-align: start;
  margin-top: 25px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 25px;
  }

  & form {
    position: relative;
    gap: 20px;
    input,
    select,
    textarea {
      @media screen and (max-width: ${SMALL_DEVICES}) {
        font-size: 16px;
      }
    }
  }
  & ${Subheader} {
    font-size: 18px;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 18px;
    }
  }
`;

export const BriefcaseIcon = styled.i.attrs({ className: 'fa-regular fa-briefcase' })`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: var(--orange);
  background: rgba(255, 88, 17, 0.1);
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  margin-bottom: 20px;
`;
BriefcaseIcon.displayName = 'BriefcaseIcon.styled';
