import styled from 'styled-components';

import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { PageContainer } from '../../components/Reusable-styled-elements/PageContainer';

import {
  EXTRA_EXTRA_LARGE_DEVICES,
  EXTRA_EXTRA_SMALL_DEVICES,
  EXTRA_LARGE_DEVICES,
  EXTRA_SMALL_DEVICES,
  LARGE_DEVICES,
  MEDIUM_DEVICES,
  SMALL_DEVICES,
} from '@skillset/ui';
import { TextTemplate } from '../../utils/types';

export const OnBoardingWelcomeContainer = styled(PageContainer)<{
  textTemplate?: TextTemplate;
  isBusinessUser?: boolean;
}>`
  ${({ textTemplate, isBusinessUser }) =>
    textTemplate === 'loginTemplate' &&
    isBusinessUser &&
    'background-image: url(https://shared-assets.tatio.io/images/employers_login_welcome_image.jpg)'};

  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--platform-blue);
  background-position: ${({ textTemplate }) => (textTemplate !== 'questionnaire' ? 'right' : 'unset')};
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 40px 34px 40px 34px;
  justify-content: ${({ textTemplate, isBusinessUser }) =>
    (textTemplate === 'loginTemplate' && isBusinessUser) || textTemplate === 'questionnaire'
      ? 'space-between'
      : 'flex-start'};
  gap: 50px;
`;
OnBoardingWelcomeContainer.displayName = 'OnBoardingWelcomeContainer.styled';

export const WelcomeText = styled.h2`
  margin: 0;
  font-family: var(--gilroy-font);
  font-weight: 800;
  font-size: 100px;
  letter-spacing: 0.01em;
  color: var(--white);
  text-transform: uppercase;
  z-index: 1;

  & span {
    color: var(--platform-yellow-gradient-fallback);
  }
  /* gradient text for modern browsers */
  @supports (background-clip: text) {
    & span {
      background-image: var(--platform-yellow-gradient);
      background-clip: text;
      color: transparent;
    }
  }

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    font-size: 90px;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    font-size: 70px;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    font-size: 90px;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 50px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    font-size: 60px;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    font-size: 55px;
  }
`;
WelcomeText.displayName = 'WelcomeText.styled';

export const LoginWelcomeSubheader = styled.span`
  font-family: var(--gilroy-font);
  font-weight: 300;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.01em;
  color: var(--white);
  margin-top: -40px;
  margin-left: 15px;
  z-index: 1;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    font-size: 67px;
    line-height: 167px;
    margin-top: -71px;
    margin-left: 5px;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    font-size: 58px;
    line-height: 144px;
    margin-top: -62px;
    margin-left: 2px;
  }

  @media screen and (max-width: ${LARGE_DEVICES}) {
    font-size: 50px;
    line-height: 82px;
    margin-top: -35px;
    margin-left: 20px;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    font-size: 70px;
    line-height: 178px;
    margin-top: -76px;
    margin-left: 10px;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 32.5px;
    line-height: 82px;
    margin-top: -36px;
    margin-left: 3px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    font-size: 39px;
    line-height: 99px;
    margin-top: -42px;
    margin-left: 3px;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    font-size: 32.5px;
    line-height: 82px;
    margin-top: -36px;
    margin-left: 3px;
  }
`;
LoginWelcomeSubheader.displayName = 'LoginWelcomeSubheader.styled';

export const LoginWelcomeControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 50px;
  left: 0;
  width: 100%;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    bottom: 40px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    bottom: 50px;
  }

  ${ButtonOrangeFilled} {
    @media screen and (max-width: ${MEDIUM_DEVICES}) {
      height: 50px;
      margin: 0 auto;
      font-size: 18.5px;
      line-height: 25px;
    }

    @media screen and (max-width: ${SMALL_DEVICES}) {
      height: 48px;
      font-size: 17px;
    }
  }
`;
LoginWelcomeControls.displayName = 'LoginWelcomeControls.styled';

export const WelcomeTextContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 525px;
  height: 100%;
  justify-content: flex-end;
  align-self: baseline;
  text-align: initial;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    left: 51%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    left: 54%;
  }
`;
WelcomeTextContainer.displayName = 'WelcomeTextContainer.styled';

export const LoginWelcomeLoginText = styled.span`
  font-family: var(--gilroy-font);
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);

  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 12px;
  }

  & button {
    background-color: transparent;
    border: none;
    color: var(--white);
    font: inherit;
    margin-left: 6px;
    font-weight: 600;
    text-decoration: underline;
  }
`;
LoginWelcomeLoginText.displayName = 'LoginWelcomeLoginText.styled';

export const LogoContainer = styled.img`
  width: 100%;
  height: 50px;
  object-fit: cover;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    height: 31px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    height: 34px;
  }
`;
LogoContainer.displayName = 'LogoContainer.styled';

// CIRCLES

export const EllipseImage = styled.img`
  height: auto;
  width: 100%;
  position: absolute;
`;
EllipseImage.displayName = 'EllipseImage.styled';

export const EllipseTopGreen = styled(EllipseImage)`
  width: 212px;
  height: 212px;
  top: -4%;
  right: 7%;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    top: -7%;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    width: 164px;
    height: 164px;
    top: -5%;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    width: 199px;
    height: 199px;
    top: -6%;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 92px;
    height: 92px;
    top: -4.5%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    width: 110px;
    height: 110px;
    top: -4%;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    width: 92px;
    height: 92px;
    top: -6%;
  }
`;
EllipseTopGreen.displayName = 'EllipseTopGreen.styled';

export const EllipseLeftGreen = styled(EllipseImage)`
  left: -5%;
  top: 65%;
  max-width: 141px;
  height: 141px;

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    height: 92px;
    width: 92px;
    left: -3.5%;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    height: 133px;
    width: 133px;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 61px;
    height: 61px;
    top: 60%;
    left: -3%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    width: 74px;
    height: 74px;
    top: 63%;
    left: -4%;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    width: 61px;
    height: 61px;
    top: 60%;
    left: -3%;
  }
`;
EllipseLeftGreen.displayName = 'EllipseLeftGreen.styled';

export const EllipseLeftGreenOutlined = styled(EllipseImage)`
  width: 173px;
  height: 169px;
  top: -60%;
  left: -12%;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    width: 145px;
    height: 142px;
    top: -45%;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    width: 125px;
    height: 122px;
    top: -48%;
  }

  @media screen and (max-width: ${LARGE_DEVICES}) {
    top: -56%;
    left: -15%;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    width: 154px;
    height: 150px;
    top: -47%;
    left: -12%;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 71px;
    height: 70px;
    top: -57%;
    left: -10%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    width: 86px;
    height: 86px;
    top: -54%;
    left: -11%;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    width: 71px;
    height: 70px;
    top: -54%;
    left: -12%;
  }
`;
EllipseLeftGreenOutlined.displayName = 'EllipseLeftGreenOutlined.styled';

export const EllipsePink = styled(EllipseImage)`
  width: 400px;
  height: 400px;
  top: -38%;
  left: -6.5%;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    width: 335px;
    height: 335px;
    top: -36%;
    left: -5.5%;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    width: 288px;
    height: 288px;
    left: -6%;
  }

  @media screen and (max-width: ${LARGE_DEVICES}) {
    top: -36%;
    left: -12%;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    width: 356px;
    height: 356px;
    left: -6%;
    top: -37%;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 164px;
    height: 164px;
    top: -39%;
    left: -4%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    width: 198px;
    height: 198px;
    top: -38%;
    left: -5%;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    width: 164px;
    height: 164px;
    top: -40%;
    left: -4.5%;
  }
`;
EllipsePink.displayName = 'EllipsePink.styled';

export const EllipseWhite = styled(EllipseImage)`
  width: 80px;
  height: 80px;
  top: -100%;
  left: 17%;

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    width: 67px;
    height: 67px;
    top: -80%;
  }

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    width: 58px;
    height: 58px;
    top: -83%;
  }

  @media screen and (max-width: ${LARGE_DEVICES}) {
    top: -95%;
  }

  @media screen and (max-width: ${MEDIUM_DEVICES}) {
    width: 71px;
    height: 71px;
    top: -90%;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 33px;
    height: 33px;
    top: -85%;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    width: 40px;
    height: 40px;
    top: -83%;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    width: 33px;
    height: 33px;
    top: -90%;
  }
`;
EllipseWhite.displayName = 'EllipseWhite.styled';
