import styled from 'styled-components';

export const InviteUsersPopupElement = styled.div`
  position: absolute;
  top: 120px;
  right: 30px;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  z-index: 9000000000;
  width: 560px;
  min-height: 285px;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 8px;
  background-color: white;
`;

InviteUsersPopupElement.displayName = 'InviteUsersPopupElement.styled';

export const PopupHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 0.5px solid var(--almost-black);
`;
PopupHeader.displayName = 'PopupHeader.styled';

export const TitleElement = styled.span`
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 700;
`;
TitleElement.displayName = 'TitleElement.styled';

export const InviteForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  gap: 10px;
`;
InviteForm.displayName = 'InviteForm.styled';

export const StyledInput = styled.input`
  font-family: var(--primary-font);
  padding: 8.5px 18px;
  border: 2px solid var(--almost-black);
  border-radius: 5px;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 78, 0.2);
  }
`;
StyledInput.displayName = 'StyledInput.styled';

export const StyledTextArea = styled.textarea`
  font-family: var(--primary-font);
  resize: none;
  height: 100px;
  padding: 11px 18px;
  border: 2px solid var(--almost-black);
  border-radius: 5px;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 78, 0.2);
  }
`;
StyledTextArea.displayName = 'StyledTextArea.styled';

export const RoleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 65px;
  gap: 10px;
  margin-bottom: 25px;
`;
RoleContainer.displayName = 'RoleContainer.styled';

export const ViewerRoleIcon = styled.i.attrs({ className: 'fa-regular fa-eye' })`
  font-size: 14px;
  color: white;
`;
ViewerRoleIcon.displayName = 'ViewerRoleIcon.styled';

export const RoleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #5b32af;
`;
RoleIconContainer.displayName = 'RoleIconContainer.styled';

export const RoleTitleAndDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  gap: 5px;
`;
RoleTitleAndDescContainer.displayName = 'RoleTitleAndDescContainer.styled';

export const RoleTitle = styled.span`
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;
RoleTitle.displayName = 'RoleTitle.styled';

export const RoleDescription = styled.span`
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: var(--dark-grey);
`;
RoleDescription.displayName = 'RoleDescription.styled';
