import React, { useState } from 'react';
import { OptionsMenuButton } from './style';
import { MenuWithIcons } from '@skillset/ui';
import { useFloating, offset, autoPlacement } from '@floating-ui/react';
import { useAppDispatch } from '../../../store/store';
import {
  removeInvitedUser,
  removeUser,
  resendInvitation,
  suspendUser,
  unsuspendUser,
} from '../../../store/actions/employerAdministration.action';
import { OptionalBusinessUser } from '../types';
import { zIndices } from '../../../assets/zIndices';
import { ToastNotificationType } from '@skillset/ui';
import { useConfirmPopup } from '../../../hooks/useConfirmPopup';

interface OptionsMenuColumnProps {
  email: string;
  status: OptionalBusinessUser['status'];
  setToastMessage: (message: string, type: ToastNotificationType) => void;
}

export const OptionsMenuColumn: React.FC<OptionsMenuColumnProps> = ({ email, status, setToastMessage }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const dispatch = useAppDispatch();
  const { refs, strategy, x, y } = useFloating({
    middleware: [offset(10), autoPlacement({ allowedPlacements: ['left-start'] })],
  });
  const { setReference, setFloating } = refs;
  const listStyle = { display: 'flex', flexDirection: 'column', gap: '5px' } as const;

  const { renderPopup: renderSuspendPopup, setPopupDisplay: setSuspendPopupDisplay } = useConfirmPopup({
    onClosePopup: () => setSuspendPopupDisplay(false),
    onConfirm: () => {
      dispatch(suspendUser(email));
      setSuspendPopupDisplay(false);
    },
    title: 'Suspend user',
    subtitle: `Are you sure you want to suspend this user? The user won't have access to the whole platform.`,
    iconClass: 'fa-regular fa-user-slash',
  });

  const { renderPopup: renderRemovePopup, setPopupDisplay: setRemovePopupDisplay } = useConfirmPopup({
    onClosePopup: () => setRemovePopupDisplay(false),
    onConfirm: () => {
      dispatch(removeUser(email));
      setRemovePopupDisplay(false);
    },
    title: 'Remove user',
    subtitle: `Are you sure you want to remove this user? Once removed, the user won't have access to the whole platform.`,
    iconClass: 'fa-regular fa-user-xmark',
  });

  const handleResendInvitation = () => {
    dispatch(resendInvitation(email));
    setToastMessage(`The invitation email has been resent succesfully`, 'success');
  };

  const handleRemoveInvitedUser = () => {
    dispatch(removeInvitedUser(email));
    setToastMessage(`The invitation has been removed successfully`, 'success');
  };

  const getMenuOptions = () => {
    const activeUserActions = [
      {
        title: 'Suspend user',
        iconClassName: 'fa-regular fa-user-slash',
        onClick: () => setSuspendPopupDisplay(true),
      },
      {
        title: 'Remove user',
        iconClassName: 'fa-regular fa-user-xmark',
        onClick: () => setRemovePopupDisplay(true),
      },
    ];
    const invitedUserActions = [
      {
        title: 'Resend invitation',
        iconClassName: 'fa-regular fa-paper-plane',
        onClick: () => handleResendInvitation(),
      },
      {
        title: 'Cancel invitation',
        iconClassName: 'fa-regular fa-user-xmark',
        onClick: () => handleRemoveInvitedUser(),
      },
    ];

    switch (status) {
      case 'User is active':
        return activeUserActions;
      case 'User is suspended':
        return [
          {
            title: 'Unsuspend user',
            iconClassName: 'fa-regular fa-user-slash',
            onClick: () => dispatch(unsuspendUser(email)),
          },
        ];
      case 'Invitation was sent':
      case 'Invitation was expired':
        return invitedUserActions;
      default:
        return activeUserActions;
    }
  };

  const renderOptionsMenu = isDisplayed && getMenuOptions().length > 0 && (
    <MenuWithIcons
      zIndexBackdrop={zIndices.backdrop}
      menuOptions={getMenuOptions()}
      closeMenu={() => setIsDisplayed(false)}
      ref={setFloating}
      style={{ position: strategy, top: y ?? 0, left: x ?? 0, zIndex: zIndices.mobilePopup, width: '220px' }}
      listStyle={listStyle}
    />
  );
  return (
    <>
      <OptionsMenuButton onClick={() => setIsDisplayed(true)} ref={setReference} />
      {renderOptionsMenu}
      {renderSuspendPopup}
      {renderRemovePopup}
    </>
  );
};
