import React, { useState } from 'react';
import { ButtonContainer, QRCodeIcon } from './style';
import QRCode from 'qrcode';
import { FloatingToolTipWithTriggerElement } from '../../reusable-elements/FloatingToolTipWithTriggerElement';

interface DownloadQRCodeButtonProps {
  uniqueLink: string;
  zIndex: number;
  name: string;
}

export const DownloadQRCodeButton: React.FC<DownloadQRCodeButtonProps> = ({ uniqueLink, zIndex, name }) => {
  const [hoveredTooltip, setHoveredTooltip] = useState(false);

  const downloadQRCode = async () => {
    if (!uniqueLink) return;

    const simLink = `$${uniqueLink}`;
    try {
      const canvas = document.createElement('canvas');
      await QRCode.toCanvas(canvas, simLink);
      const dataURL = canvas.toDataURL('image/png');
      const blob = await (await fetch(dataURL)).blob();
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${name}_Simulation_QR.png`; // Set the file name
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ButtonContainer
      onMouseEnter={() => setHoveredTooltip(true)}
      onMouseLeave={() => setHoveredTooltip(false)}
      onClick={downloadQRCode}
    >
      <FloatingToolTipWithTriggerElement
        text="Download QR code"
        className="hover-tooltip"
        color="var(--almost-black)"
        textColor="white"
        floatingPlacement="top"
        direction="down"
        isDisplayed={hoveredTooltip}
        zindex={zIndex}
      >
        {(setReference) => <QRCodeIcon ref={setReference} />}
      </FloatingToolTipWithTriggerElement>
    </ButtonContainer>
  );
};
