import React from 'react';
import { PasswordProgressBar } from './PasswordProgressBar';
import { Dot, PasswordHintWrapper } from './style';
import { Language } from '../../utils/languages/general';
import { getPasswordHintTexts } from '../../services/languages.service';

interface IPasswordHintProps {
  password: string;
  registrationLanguage?: Language;
}
const SPECIAL_CHARS =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
export const PasswordHint: React.FC<IPasswordHintProps> = ({ password, registrationLanguage }) => {
  const includesUpperAndLower = /[A-Z]/.test(password) && /[a-z]/.test(password);
  const includesNumeric = /\d/.test(password);
  const includesSpecialCharacters = /[!@#$%^&*?]/.test(password);
  const includesAtLeast8Characters = SPECIAL_CHARS.test(password);

  const allPasswordConditions = [includesUpperAndLower, includesNumeric, includesAtLeast8Characters];
  const condintionsAreTrueArr = allPasswordConditions.filter((condition) => condition === true);
  const texts = getPasswordHintTexts(registrationLanguage);
  let passwordLengthCondition = '';
  if (password.length > 7) {
    passwordLengthCondition = texts.weakPassword;
    if (condintionsAreTrueArr.length === 2) {
      passwordLengthCondition = texts.averagePassword;
    }
    if (condintionsAreTrueArr.length > 2) {
      passwordLengthCondition = texts.strongPassword;
    }
  }
  return (
    <PasswordHintWrapper>
      <div className="tip"></div>
      <span>{passwordLengthCondition ? passwordLengthCondition : texts.mustBe8Chars}</span>
      <PasswordProgressBar conditionsLength={condintionsAreTrueArr.length} />

      <ul>
        <li
          style={{
            textDecoration: includesUpperAndLower ? 'line-through' : 'none',
          }}
        >
          {includesUpperAndLower ? <i className="fa-solid fa-shield-check" /> : <Dot />}
          {texts.upperAndLowerCase}
        </li>
        <li
          style={{
            textDecoration: includesNumeric ? 'line-through' : 'none',
          }}
        >
          {includesNumeric ? <i className="fa-solid fa-shield-check" /> : <Dot />}
          {texts.oneOrMoreNumeric}
        </li>
        <li
          style={{
            textDecoration: includesSpecialCharacters ? 'line-through' : 'none',
          }}
        >
          {includesSpecialCharacters ? <i className="fa-solid fa-shield-check" /> : <Dot />}
          {texts.symbol} (!,@,#,$,%,^,&,*,?)
        </li>
        <li
          style={{
            textDecoration: includesAtLeast8Characters ? 'line-through' : 'none',
          }}
        >
          {includesAtLeast8Characters ? <i className="fa-solid fa-shield-check" /> : <Dot />}
          {texts.atLeat8Chars}
        </li>
      </ul>
    </PasswordHintWrapper>
  );
};
