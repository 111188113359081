import { LabelWithSearchIcon, SearchField } from '../../reusable-elements/CandidateElements';
import { ResettedButton } from '../../reusable-elements/ResettedButton';
import { CircleXMark, MagnifyingGlass } from '../../reusable-elements/icons';

export interface TableSearchBaseProps {
  searchTerm: string;
  setSearchTerm: (query: string) => void;
  onClose?: () => void;
  background?: string;
}

export const TableSearch: React.FC<TableSearchBaseProps> = ({ searchTerm, background, setSearchTerm }) => {
  const icon = !searchTerm.trim() ? (
    <MagnifyingGlass />
  ) : (
    <ResettedButton
      onClick={() => {
        setSearchTerm('');
      }}
    >
      <CircleXMark />
    </ResettedButton>
  );

  return (
    <LabelWithSearchIcon background={background}>
      <SearchField onChange={({ target: { value } }) => setSearchTerm(value)} value={searchTerm} />
      {icon}
    </LabelWithSearchIcon>
  );
};
