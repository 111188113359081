import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RECOVER_ROUTES } from '../../constants/routes';
import { PASSWORD_REGEX } from '../../constants/regularExpressions';
import { isNotEmpty, valueIsMatching } from '../../utils/validations';

import { useInput } from '../../hooks/useInput';

import { PasswordInput } from '../FormInputGroup/PasswordInput';

import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { useAppDispatch } from '../../store/store';
import { completeForgotPassword } from '../../store/actions/auth.action';
import { RelativeContainer } from './style';
import { PasswordHint } from '../PasswordHint/PasswordHint';
import { Language } from '../../utils/languages/general';
import { getResetPasswordTexts } from '../../services/languages.service';

export const ResetPasswordForm: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const { username, code } = useParams();
  if (!username || !code) return null;

  const texts = getResetPasswordTexts(registrationLanguage);
  const {
    value: password,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    ...restPasswordInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value) && valueIsMatching(value, PASSWORD_REGEX),
  });

  const {
    value: confirmPassword,
    hasError: confirmPasswordHasError,
    valueIsValid: confirmPasswordIsValid,
    ...restConfirmPasswordInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value) && value === password,
  });

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!passwordIsValid || !confirmPasswordIsValid) {
      restPasswordInputProps.onBlur();
      restConfirmPasswordInputProps.onBlur();
      return;
    }
    dispatch(completeForgotPassword({ username, code, password }));
    navigate(RECOVER_ROUTES.RESET_PASSWORD_SUCCESS);
  };
  const renderPasswordHint = isTyping && password && (
    <PasswordHint password={password} registrationLanguage={registrationLanguage} />
  );

  return (
    <form onSubmit={submitHandler}>
      <RelativeContainer>
        <PasswordInput
          label={texts.password}
          required
          errorMessage={passwordHasError ? texts.fillOurField : ''}
          isValid={passwordIsValid}
          name="password"
          value={password}
          toggleInputHandler={(arg) => setIsTyping(arg)}
          {...restPasswordInputProps}
          placeholder={texts.minCharacters}
        />
        {renderPasswordHint}
      </RelativeContainer>
      <PasswordInput
        label={texts.confirmPassword}
        required
        errorMessage={confirmPasswordHasError ? texts.passwordNotMatch : ''}
        isValid={confirmPasswordIsValid}
        name="password"
        value={confirmPassword}
        showSuccessIndications
        {...restConfirmPasswordInputProps}
        placeholder={texts.retypePassword}
      />

      <ButtonOrangeFilled type="submit">{texts.resetPassword}</ButtonOrangeFilled>
    </form>
  );
};
