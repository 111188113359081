import React from 'react';
import { SearchableOverflowingText } from '@skillset/ui';
import { WithSearchTerm } from '../../../utils/types';
import { zIndices } from '../../../assets/zIndices';

interface NameColumnProps extends WithSearchTerm {
  name: string;
}

export const NameColumn: React.FC<NameColumnProps> = ({ name, searchTerm }) => {
  return (
    <div style={{ width: '100%' }}>
      <SearchableOverflowingText zIndexTooltip={zIndices.sideMenuTooltip} text={name} searchTerm={searchTerm} />
    </div>
  );
};
