import React from 'react';
import { selectPlatformUser } from '@skillset/onelogin';
import { NoPermissions } from '@skillset/ui';

import { useTypedSelector } from '../../store/store';

interface RoleProtectedRouteProps {
  element: JSX.Element;
  roles: string[];
}

export const useIsSufficientRole = (roles: string[]) => {
  const platformUser = useTypedSelector(selectPlatformUser);
  const isSufficientRole = Boolean(platformUser?.role && roles.includes(platformUser.role));
  return { isSufficientRole };
};

export const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({ element: ProtectedRouteComponent, roles }) => {
  const { isSufficientRole } = useIsSufficientRole(roles);
  if (isSufficientRole) return ProtectedRouteComponent;
  else return <NoPermissions errorType="noPermissions" />;
};
