import styled, { css } from 'styled-components';
import { ILogoTheme } from './constants';

export const TextElement = styled.span`
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 15px;
  align-self: flex-end;
  margin-left: 10px;
  margin-bottom: 5px;
`;
TextElement.displayName = 'TextElement.styled';

export const optionalThemes = {
  whiteLongLogo: 'whiteLongLogo',
  blueLongLogo: 'blueLongLogo',
  shortLogo: 'shortLogo',
} as const;

export type OneOfOptionalThemes = (typeof optionalThemes)[keyof typeof optionalThemes];
interface ILogoContainerElement {
  theme: ILogoTheme;
  logoWithText: boolean;
  imgSize: { width: string; height: string };
  breakPointImgSize?: { width: string; height: string; breakpoint: string };
}
export const LogoContainerElement = styled.div<ILogoContainerElement>`
  width: min-content;
  align-self: ${(props) => props.theme.alignSelf};
  cursor: pointer;
  img {
    width: ${(props) => props.imgSize.width || '151.26px'};
    height: ${(props) => props.imgSize?.height || '40px'};
    ${(props) =>
      props.breakPointImgSize?.breakpoint &&
      `@media screen and (max-width: ${props.breakPointImgSize?.breakpoint}) {
      width: ${props.breakPointImgSize?.width || '60px'};
      height: ${props.breakPointImgSize?.height || '60px'};
    }`}
  }
  ${({ logoWithText }) => {
    if (logoWithText) {
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        ${TextElement} {
          margin: 0;
        }
      `;
    }
  }}
`;
LogoContainerElement.displayName = 'LogoContainerElement.styled';
