import styled from 'styled-components';

export const TermsOfUseHeading = styled.h2`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: var(--almost-black);
  text-align: center;
  margin: 10px 0;
`;
TermsOfUseHeading.displayName = 'TermsOfUseHeading.styled';

export const TermsOfUseContainer = styled.div`
  max-width: 1340px;
  padding: 50px;
  margin: 0 auto;
  font-family: var(--gilroy-font);

  & img {
    margin: 0 auto;
    display: block;
  }

  & div {
    font-weight: 400;
    font-size: 18.5px;
    line-height: 28px;
    color: var(--very-dark-gray);
  }
  .header {
    font-family: var(--gilroy-font);
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: var(--almost-black);
    text-align: start;
  }

  & a {
    &:link,
    &:visited {
      color: var(--very-dark-gray);
    }

    &:hover,
    &:active {
      color: var(--almost-black);
    }
  }
`;
TermsOfUseContainer.displayName = 'TermsOfUseContainer.styled';

export const TextElement = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: var(--almost-black);
`;
TextElement.displayName = 'TextElement.styled';

export const PolicyWrapper = styled.div`
  width: 1120px;
  section {
    display: flex;
    flex-direction: column;
  }
`;
PolicyWrapper.displayName = 'PolicyWrapper.styled';

export const BoldHeader = styled(TextElement)`
  font-weight: 600;
`;
BoldHeader.displayName = 'BoldHeader.styled';
