import { PropsWithChildren, ReactElement } from 'react';
import { useOverflowDetection } from '../../hooks/useOverflowDetection';
import { WithComponentKey } from '../../utils/types';
import { TableContainer } from './style';
import { TableHeader } from './TableHeader/TableHeader';
import { TableList } from './TableList/TableList';
import { TableBaseProps } from './types';
interface ITableProps<RowData extends WithComponentKey> extends TableBaseProps<RowData> {
  handleSortClick: (keyName: string) => void;
  getSortByOrder: (keyName: string) => 'ASC' | 'DESC' | null;
  zIndexTooltip: number;
}

export const Table = <RowData extends WithComponentKey>({
  columns,
  data,
  rowGridTemplateColumns,
  mobileRowGridTemplateColumns,
  renderDataCondition,
  rowsBorderLess,
  zIndexTooltip,
  handleSortClick,
  getSortByOrder,
  renderRowProps,
  ...rest
}: PropsWithChildren<ITableProps<RowData>>): ReactElement | null => {
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLDivElement>('vertical');

  return (
    <TableContainer>
      <TableHeader<RowData>
        columns={columns}
        isListScrollVisible={isOverflowing}
        rowGridTemplateColumns={rowGridTemplateColumns}
        mobileRowGridTemplateColumns={mobileRowGridTemplateColumns}
        handleSortClick={handleSortClick}
        getSortByOrder={getSortByOrder}
        zIndexTooltip={zIndexTooltip}
      />
      <TableList<RowData>
        mobileRowGridTemplateColumns={mobileRowGridTemplateColumns}
        rowGridTemplateColumns={rowGridTemplateColumns}
        columns={columns}
        data={data}
        renderDataCondition={renderDataCondition}
        measureRef={refCallback}
        renderRowProps={renderRowProps}
        rowsBorderLess={rowsBorderLess}
        {...rest}
      />
    </TableContainer>
  );
};
