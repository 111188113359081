import { useLocation } from 'react-router-dom';
import { authRoutes } from '../../constants/routes';
import { Header } from '../Header/Header';
import { HeaderAndPageWrapper } from './style';

export const PageWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const shouldRenderHeader = authRoutes.every((authRoute) => !location.pathname.startsWith(authRoute));

  return (
    <HeaderAndPageWrapper>
      {shouldRenderHeader && <Header />}
      {children}
    </HeaderAndPageWrapper>
  );
};
