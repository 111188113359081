import {
  CandidatesHeaderBaseLink,
  ElementWithTooltip,
  ExportIconElement,
} from '../../reusable-elements/CandidateElements';
import { Tooltip } from '../../reusable-elements/ToolTip';

type ExportLinkProps = (typeof CandidatesHeaderBaseLink)['defaultProps'] & {
  disabled: boolean;
  isMobile: boolean;
  zIndexTooltip: number;
};

export const ExportLinkWithTooltip: React.FC<ExportLinkProps> = ({ disabled, isMobile, zIndexTooltip, ...props }) => {
  if (disabled)
    return (
      <ExportIconElement
        style={{ height: '44px', width: '48px', display: 'grid', placeItems: 'center' }}
        className="disabled"
      />
    );

  return (
    <ElementWithTooltip>
      <CandidatesHeaderBaseLink {...props}>
        <ExportIconElement />
      </CandidatesHeaderBaseLink>

      {!isMobile && !disabled && (
        <Tooltip color="black" fontWeight="400" textColor="white" direction="down" bottom="100%" zIndex={zIndexTooltip}>
          Export
        </Tooltip>
      )}
    </ElementWithTooltip>
  );
};
