import { ChangeEventHandler, SelectHTMLAttributes } from 'react';
import { FormGroupText, InputErrorMessage, InputGroupContainer } from '../FormInputGroup/style';

interface IFormSelectGroup extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  errorMessage: string;
  onChange: ChangeEventHandler;
  options: string[];
  touched: boolean;
  width?: string;
}

export const FormSelectGroup: React.FC<IFormSelectGroup> = ({
  label,
  required,
  disabled = false,
  errorMessage,
  value,
  name,
  options,
  placeholder,
  touched,
  onChange,
}) => {
  const renderError = errorMessage && 0 < errorMessage.length && <InputErrorMessage>{errorMessage}</InputErrorMessage>;
  return (
    <InputGroupContainer hasError={!!errorMessage} touched={touched}>
      <label>
        <FormGroupText required={required}>{label}</FormGroupText>
        <select
          onChange={onChange}
          name={name}
          style={{
            color: value ? 'var(--almost-black)' : 'var(--grey)',
          }}
          disabled={disabled}
        >
          <option value={value} disabled selected>
            {placeholder}
          </option>
          {options.length > 0 &&
            options.map((option, index) => (
              <option
                value={option}
                key={`${index}-${options}`}
                style={{ color: 'var(--almost-black)', whiteSpace: 'nowrap' }}
              >
                {option}
              </option>
            ))}
        </select>
        {renderError}
      </label>
    </InputGroupContainer>
  );
};
