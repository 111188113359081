import styled from 'styled-components';
import { ResettedButton } from '../../../reusable-elements/ResettedButton';

export const MobilePopupContainer = styled.div<{ zIndex: number }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex};
  border-radius: 8px 8px 0px 0px;
  touch-action: none;
`;
MobilePopupContainer.displayName = 'MobilePopupContainer.styled';

export const PopupHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
`;
PopupHeader.displayName = 'PopupHeader.styled';

export const PopupUpperButton = styled(ResettedButton)`
  width: 134px;
  height: 4px;
  border-radius: 100px;
  background-color: var(--very-dark-grey);
`;
PopupUpperButton.displayName = 'PopupUpperButton.styled';
export const TitleContainer = styled.div`
  display: flex;
`;
TitleContainer.displayName = 'TitleContainer.styled';

export const PopupTitle = styled.h1`
  margin: 0;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 700;
  color: var(--almost-black);
  text-align: start;
  text-transform: capitalize;
  padding: 12.5px 30px 10px 30px;
`;
PopupTitle.displayName = 'PopupTitle.styled';

export const VerticallyScrollableContainer = styled.div`
  border-top: 0.5px solid var(--border-light-grey);
  overflow-y: auto;
`;
VerticallyScrollableContainer.displayName = 'VerticallyScrollableContainer.styled';
