import styled from 'styled-components';
import { InputErrorMessage } from '../../components/FormInputGroup/style';
import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';

import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import {
  EXTRA_EXTRA_LARGE_DEVICES,
  EXTRA_LARGE_DEVICES,
  LARGE_DEVICES,
  MEDIUM_DEVICES,
  SMALL_DEVICES,
} from '@skillset/ui';
import { RegisterFormContainer } from '../Register/style';

export const ForgotPasswordFormContainer = styled(RegisterFormContainer)`
  text-align: center;
  & form {
    margin-top: 50px;

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      margin-top: 58px;
    }

    @media screen and (max-width: ${LARGE_DEVICES}) {
      margin-top: 50px;
    }
  }

  & label {
    text-align: left;
  }

  ${Subheader} {
    margin-top: 15px;
    color: var(--dark-grey);
    @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
      font-size: 18.5px;
    }
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 16px;
    }
  }
  ${InputErrorMessage} {
    margin-bottom: 10px;
  }
  ${ButtonOrangeFilled} {
    margin-top: 10px;
    @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
      font-size: 18.5px;
    }

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      font-size: 17px;
    }

    @media screen and (max-width: ${LARGE_DEVICES}) {
      font-size: 18.5px;
    }

    @media screen and (max-width: ${MEDIUM_DEVICES}) {
      font-size: 17px;
    }
  }
`;
ForgotPasswordFormContainer.displayName = 'ForgotPasswordFormContainer.styled';
