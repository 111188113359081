import styled from 'styled-components';
import { PageContainer } from '../Reusable-styled-elements/PageContainer';

export const WelcomeContainer = styled(PageContainer)`
  background-color: var(--platform-blue);
  overflow: hidden;
  height: 100%;
  gap: 50px;
  justify-content: space-between;
`;
WelcomeContainer.displayName = 'WelcomeContainer.styled';
