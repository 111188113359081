import React from 'react';
import { AvatarElement } from '@skillset/ui';
import { getBrandColorByString } from '../../utils/general';
import { DetailsColumnContainer, EmailSpan, FullNameSpan, UserCardElement, UserCardWrapper } from './style';

interface IUserCardPreview {
  fullName: string;
  email: string;
}

export const UserCardPreview: React.FC<IUserCardPreview> = ({ fullName, email }) => {
  const firstNameFirstLetter = fullName.substr(0, 1).toUpperCase();

  return (
    <UserCardWrapper>
      <UserCardElement>
        <AvatarElement $beforeContent={firstNameFirstLetter} backgroundColor={getBrandColorByString(fullName)} />
        <DetailsColumnContainer>
          <FullNameSpan>{fullName}</FullNameSpan>
          <EmailSpan>{email}</EmailSpan>
        </DetailsColumnContainer>
      </UserCardElement>
    </UserCardWrapper>
  );
};
