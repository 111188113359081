import noPermissionsLock from '../../assets/lotties/no-permission-lock.json';
import { LazyLottiePlayer } from '../Lottie/LazyLottiePlayer';
import { ErrorType } from '../../utils/types';

import { NoPermissionsElement } from './style';

interface NoPermissionsProps {
  errorType: ErrorType;
}

export const NoPermissions: React.FC<NoPermissionsProps> = ({ errorType }) => {
  const renderTextForNoInvitation = (
    <>
      <h1>Permission Required</h1>
      <p>Contact your administrator to request permission.</p>
    </>
  );
  const renderTextForNoPermission = (
    <>
      <h1>Permission Required</h1>
      <p>Contact your administrator to request permission.</p>
    </>
  );
  return (
    <NoPermissionsElement>
      <div className="no-permission-text">
        <LazyLottiePlayer className="lock-animation" animationData={noPermissionsLock} aria-hidden="true" />
        {errorType === 'noInvitationLink' ? renderTextForNoInvitation : renderTextForNoPermission}
      </div>
    </NoPermissionsElement>
  );
};
