import { useState, useEffect } from 'react';

interface UseWindowDimensionsProps {
  requestedWidth: number;
}
function getWindowDimensions(requestedWidth: number, hasWindow: boolean) {
  const width = hasWindow ? window.innerWidth : null;
  const height = hasWindow ? window.innerHeight : null;
  const isAtRequestedWidth = !!(width ? width <= requestedWidth : null);

  return { isAtRequestedWidth, width, height };
}

export const useWindowDimensions = ({ requestedWidth }: UseWindowDimensionsProps) => {
  const hasWindow = typeof window !== 'undefined';

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(requestedWidth, hasWindow));

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions(requestedWidth, hasWindow));
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
