import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';

export const AnimatedStarIcon = styled(animated.i).attrs(({ className }) => ({
  className: 'fa-regular fa-star ' + className ?? '',
}))<{ $isMobile?: boolean }>`
  color: ${({ $isMobile }) => ($isMobile ? 'var(--almost-black)' : 'var(--gray)')};
  font-size: 17px;
  :hover {
    @media screen and not (max-width: ${SMALL_DEVICES}) {
      font-weight: 900;
    }
  }
  &.starred {
    color: var(--yellow);
    font-weight: 900;
  }
`;
AnimatedStarIcon.displayName = 'AnimatedStarIcon.styled';
