import React from 'react';
import { CustomerElement, RoleColumnWrapper } from './style';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { HighlightedSearchTerm } from '@skillset/ui';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { EmployersAdministrationSlice } from '../../../store/types/EmployerAdministrationInterface';

interface CustomerColumnProps {
  customer: string;
}

export const CustomerColumn: React.FC<CustomerColumnProps> = ({ customer }) => {
  const { searchTerm } = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );
  const { refCallback } = useOverflowDetection<HTMLSpanElement>('horizontal');

  return (
    <RoleColumnWrapper>
      <CustomerElement ref={refCallback}>
        <HighlightedSearchTerm text={customer} searchTerm={searchTerm.trim()} />
      </CustomerElement>
    </RoleColumnWrapper>
  );
};
