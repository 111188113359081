import styled from 'styled-components';

export const ResettedButton = styled.button`
  color: inherit;
  background-color: unset;
  border: none;
  padding: 0;
  cursor: pointer;
`;
ResettedButton.displayName = 'ResettedButton.styled';
