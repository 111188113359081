import styled, { css } from 'styled-components';
import { IOSScrollbar } from '../../../../utils/IOSScrollbar';
import { OneOfLayoutTypes } from '../../style';
interface CustomizedCheckboxContainerProps {
  layoutType?: OneOfLayoutTypes;
}

export const CustomizedCheckboxContainer = styled.div<CustomizedCheckboxContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${({ layoutType }) => {
    if (layoutType === 'allFilters') {
      return css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
      `;
    }
  }}
`;
CustomizedCheckboxContainer.displayName = 'CustomizedCheckboxContainer.styled';

export const CustomizedCheckboxWithLinkContainer = styled.div<CustomizedCheckboxContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 320px;
  align-items: flex-start;
  border-radius: 5px;
  background: #fff;
  padding: 5px;
`;
CustomizedCheckboxWithLinkContainer.displayName = 'CustomizedCheckboxWithLinkContainer.styled';

export const CheckboxItem = styled.div`
  display: flex;
  padding: 5px 5px 5px 1px;
  gap: 15px;
  label {
    cursor: pointer;
    font-family: var(--primary-font);
    color: var(--almost-black);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  input {
    &[type='checkbox'] {
      cursor: pointer;
      margin: 2px 1px 4px -1px;
      accent-color: var(--almost-black);
      height: 18px;
      width: 18px;
    }
    &:not(:checked) {
      border-radius: 1px;
      appearance: none;
      height: 14px;
      width: 14px;
      margin: 4px 3px 4px 1px;
      box-shadow: 0 0 0 2px var(--almost-black);
      &:hover {
        box-shadow: 0 0 0 2px var(--almost-black);
      }
    }
  }
`;
CheckboxItem.displayName = 'CheckboxItem.styled';

export const OptionsContainerWithScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  max-height: 190px;
  width: 100%;
  padding: 2px;
  ${IOSScrollbar}
`;
OptionsContainerWithScroll.displayName = 'OptionsContainerWithScroll.styled';

export const SearchInput = styled.input`
  font-family: var(--primary-font);
  display: flex;
  height: 45px;
  padding: 11px 11.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  border: 1px solid var(--almost-black);
  border-radius: 5px;
  color: var(--almost-black);
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 71, 0.06), 0px 8px 8px 0px rgba(50, 50, 71, 0.08);
  &::placeholder {
    color: var(--almost-black);
  }
`;
SearchInput.displayName = 'SearchInput.styled';
