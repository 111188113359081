import React from 'react';

import { MenuIconElement, MenuItemButton, MenuItemElement, TextElement } from './style';
export interface IMenuWithIconsOption {
  title: string;
  iconClassName: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}
export const MenuItem: React.FC<IMenuWithIconsOption> = ({ title, iconClassName, onClick }) => {
  return (
    <MenuItemElement onClick={onClick}>
      <MenuItemButton>
        <MenuIconElement className={iconClassName} />
        <TextElement>{title}</TextElement>
      </MenuItemButton>
    </MenuItemElement>
  );
};
