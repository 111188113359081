import styled from 'styled-components';

import { EXTRA_SMALL_DEVICES, SMALL_DEVICES } from '@skillset/ui';
interface FormGroupTextProps {
  required?: boolean;
}

export interface InputGroupContainerProps {
  hasError: boolean;
  touched: boolean;
}

export const InputGroupContainer = styled.div<InputGroupContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 95px;

  // Input type number hiding arrows.
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 0.5px;
    height: 91px;
  }

  & > label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 14px;
    color: var(--standard-grey);
  }

  & input,
  select,
  textarea {
    padding: 10px 15px;
    border: 2px solid var(--gray);
    background-color: var(--white);
    border-color: ${({ hasError }) => (hasError ? `red` : 'var(--gray)')};
    border-radius: 8px;
    font-family: var(--gilroy-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--almost-black);
    outline: none;
    width: 100%;
    height: 44px;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 14px;
      line-height: 16px;
    }

    &:hover {
      box-shadow: 0px 4px 7px -2px rgba(26, 26, 26, 0.12);
    }

    &::placeholder {
      color: var(--gray);
    }
    &:focus {
      border: 2px solid var(--almost-black);
    }
    &:disabled {
      cursor: auto;
      color: var(--grey);
      &:hover {
        box-shadow: none;
      }
    }
  }

  select {
    appearance: none;
    background-image: url('https://shared-assets.tatio.io/images/select-arrow-down.png');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
  }
  textarea {
    height: auto;
    resize: none;
    padding: 16px 15px;
    @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
      height: 200px;
    }
  }
`;
InputGroupContainer.displayName = 'InputGroupContainer.styled';

export const FormInputWrapper = styled.div`
  position: relative;

  & input,
  select {
    padding-right: 50px;
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--dark-gray);

    &:hover {
      color: var(--almost-black);
    }
  }
`;

FormInputWrapper.displayName = 'FormInputWrapper.styled';

export const FormGroupText = styled.span<FormGroupTextProps>`
  &::after {
    position: ${({ required }) => (required ? 'unset' : 'absolute')};
    content: ${({ required }) => required && "'*'"};
    color: ${({ required }) => (required ? 'var(--orange)' : 'var(--almost-black)')};
    font-family: var(--primary-font);
    font-size: 14px;
    right: ${({ required }) => (required ? 'unset' : '0px')};
    margin-left: 6px;
  }
`;
FormGroupText.displayName = 'FormGroupText.styled';

export const InputErrorMessage = styled.span`
  &::before {
    content: '\\00d7';
    font-size: 16px;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-family: var(--gilroy-font);
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: var(--error-red);

  @media screen and (max-width: ${SMALL_DEVICES}) {
    line-height: 14px;
  }
`;
InputErrorMessage.displayName = 'InputErrorMessage.styled';

export const InputSuccessMessage = styled(InputErrorMessage)`
  &::before {
    content: '\\2714';
  }

  color: var(--tatio-green);
`;
InputSuccessMessage.displayName = 'InputSuccessMessage.styled';

export const PasswordContainer = styled(InputGroupContainer)`
  position: relative;
`;
PasswordContainer.displayName = 'PasswordContainer.styled';
