import {
  FocusEvent,
  MouseEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { useCookies } from 'react-cookie';
import { CSSProperties } from 'styled-components';
import { selectPlatformUser } from '@skillset/onelogin';
import { TransparentBackDropLayer, PrimaryButton } from '@skillset/ui';

import { XIconButton } from '../../reusable-elements/XIconButton';
import { useTypedSelector } from '../../store/store';
import { NotesList } from './NotesList/NotesList';
import {
  NotesContainer,
  NotesHeader,
  NotesForm,
  TitleElement,
  WriteNoteTextArea,
  FormControls,
  FormAndListContainer,
} from './style';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { detectAppleMobileDevice } from '../../utils';
import { useGetNoteActions } from './utils';
import { INITIAL_NOTE } from './constants';
import { INote, INoteToCreateOrEdit, NotesIdentifier } from './types';
import { zIndices } from '../../assets/zIndices';

const formButtonsStyle: CSSProperties = {
  padding: '7.5px 12px',
  fontSize: '14px',
};
const cancelButtonProps = { color: 'black', backgroundColor: 'transparent' };
const controlButtonPrefix = 'notes_form_controls';
const NOTE_DRAFT_COOKIE_NAME = 'noteDraft';
const COOKIE_EXPIRATION_DATE = new Date(Date.now() + 1000 * 10 * 60);

interface INotesProps<TNoteIdentifier extends NotesIdentifier> {
  title?: string;
  identifier: TNoteIdentifier;
  notes: INote[];
  toggleDisplay: () => void;
  compareIdentifiers: (draftNoteIdentifier: TNoteIdentifier, noteIdentifier: TNoteIdentifier) => boolean;
}

interface ICookieNote extends INoteToCreateOrEdit {
  identifier: NotesIdentifier;
}

export const Notes = <T extends NotesIdentifier>({
  title,
  identifier,
  notes,
  toggleDisplay,
  compareIdentifiers,
}: PropsWithChildren<INotesProps<T>>): ReactElement | null => {
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([NOTE_DRAFT_COOKIE_NAME]);
  const noteDraft = cookies.noteDraft as ICookieNote;
  const { createNote, updateNote } = useGetNoteActions(identifier);

  const [noteToCreateOrEdit, setNoteToCreateOrEdit] = useState(
    noteDraft && compareIdentifiers(cookies.noteDraft.identifier, identifier) ? noteDraft : INITIAL_NOTE,
  );
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const platformUser = useTypedSelector(selectPlatformUser);
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  if (!platformUser) return null;
  const resetNoteToCreateOrEdit = () => setNoteToCreateOrEdit(INITIAL_NOTE);
  const toggleTextAreaFocusState = () => setIsTextAreaFocused((prevState) => !prevState);
  const setNoteToEdit = (id: number | null, note: string) => {
    setNoteToCreateOrEdit({ id, note });
    textAreaRef.current?.focus();
  };
  const handleClose = () => {
    if (noteToCreateOrEdit.note.length > 0) {
      setCookie(
        NOTE_DRAFT_COOKIE_NAME,
        { ...noteToCreateOrEdit, identifier },
        {
          expires: COOKIE_EXPIRATION_DATE,
        },
      );
    }
    toggleDisplay();
  };

  const handleBlurOrMouseOut = (ev: FocusEvent<HTMLTextAreaElement> | MouseEvent<HTMLTextAreaElement>) => {
    // In case the user be clicking the submit button, the blur will cause a state change > then rerender > and avoid submit to be clicked
    // Next lines are for handling that case
    if (ev.relatedTarget) {
      if ('click' in ev.relatedTarget) {
        const potentialOneOfControlsButton = ev.relatedTarget as HTMLButtonElement;
        const { id } = potentialOneOfControlsButton;
        if (id === `${controlButtonPrefix}_submit_btn` || id === `${controlButtonPrefix}_cancel_btn`) {
          potentialOneOfControlsButton?.click();
        }
      }
    }
    textAreaRef.current?.blur();
    setIsTextAreaFocused(false);
  };
  const submitHandler: FormEventHandler<HTMLFormElement> | undefined = (ev) => {
    const { id, note } = noteToCreateOrEdit;
    ev.preventDefault();
    if (!id) {
      createNote(note);
    } else {
      updateNote(note, id);
    }
    resetNoteToCreateOrEdit();
    removeCookie(NOTE_DRAFT_COOKIE_NAME);
    textAreaRef.current?.blur();
    setIsTextAreaFocused(false);
  };

  const handleCancelAction = () => {
    resetNoteToCreateOrEdit();
    textAreaRef.current?.blur();
    setIsTextAreaFocused(false);
  };

  useEffect(() => {
    if (isTextAreaFocused && textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [isTextAreaFocused]);

  PrimaryButton.defaultProps = { style: formButtonsStyle };

  return (
    <>
      <NotesContainer isMobile={isMobile}>
        {!isMobile && (
          <NotesHeader>
            <TitleElement>{title}</TitleElement>
            <XIconButton onClick={handleClose} />
          </NotesHeader>
        )}
        <FormAndListContainer>
          <NotesForm onSubmit={submitHandler}>
            <WriteNoteTextArea
              ref={textAreaRef}
              onFocus={toggleTextAreaFocusState}
              {...(detectAppleMobileDevice() ? { onMouseOut: handleBlurOrMouseOut } : { onBlur: handleBlurOrMouseOut })}
              value={noteToCreateOrEdit.note}
              onChange={({ target }) =>
                setNoteToCreateOrEdit((prevState) => {
                  return { ...prevState, note: target.value };
                })
              }
            />
            <FormControls isTextAreaFocused={isTextAreaFocused}>
              <PrimaryButton
                id={`${controlButtonPrefix}_cancel_btn`}
                type="button"
                text="Cancel"
                {...cancelButtonProps}
                onClickHandler={() => handleCancelAction()}
              />
              <PrimaryButton
                id={`${controlButtonPrefix}_submit_btn`}
                text="Submit"
                type="submit"
                disabled={noteToCreateOrEdit.note.length === 0}
              />
            </FormControls>
          </NotesForm>
          <NotesList
            noteToCreateOrEdit={noteToCreateOrEdit}
            notes={notes}
            identifier={identifier}
            setNoteToEdit={setNoteToEdit}
            isTextAreaFocused={isTextAreaFocused}
          />
        </FormAndListContainer>
      </NotesContainer>
      {!isMobile && <TransparentBackDropLayer zIndex={zIndices.backdrop} onClick={handleClose} />}
    </>
  );
};
