import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { MenuItemElement, MenuListElement, UserMenuWrapper } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IAuthSlice } from '../../store/slices/authSlice';
import { UserCardPreview } from '../UserCardPreview/UserCardPreview';
import { TransparentBackDropLayer } from '@skillset/ui';
import { zIndices } from '../../assets/zIndices';

export interface IUserMenuOptions {
  icon: IconProp;
  label: string;
  onClick: () => void;
}
interface IUserMenu {
  menuOptions: IUserMenuOptions[];
  closeMenu: () => void;
}

export const UserMenu: React.FC<IUserMenu> = ({ menuOptions, closeMenu }) => {
  const authSlice = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const { platformUser } = authSlice;
  if (!platformUser) return null;
  const { personal } = platformUser;

  const fullName = `${personal.firstName} ${personal.lastName}`;
  const renderMenuOptions = menuOptions.map(({ icon, label, onClick }) => {
    return (
      <MenuItemElement key={label} onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
        <span>{label}</span>
      </MenuItemElement>
    );
  });

  return (
    <>
      <UserMenuWrapper>
        <UserCardPreview fullName={fullName} email={personal.email} />
        <MenuListElement>{renderMenuOptions}</MenuListElement>
      </UserMenuWrapper>
      <TransparentBackDropLayer onClick={closeMenu} zIndex={zIndices.backdrop} />
    </>
  );
};
