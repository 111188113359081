import { useNavigate } from 'react-router-dom';

import { useInput } from '../../hooks/useInput';

import { RECOVER_ROUTES } from '../../constants/routes';
import { isNotEmpty, valueIsMatching } from '../../utils/validations';

import { EMAIL_REGEX } from '../../constants/regularExpressions';
import { FormInputGroup } from '../FormInputGroup/FormInputGroup';

import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { useAppDispatch } from '../../store/store';
import { requestForgotPassword } from '../../store/actions/auth.action';
import { Language } from '../../utils/languages/general';
import { getForgotPasswordTexts } from '../../services/languages.service';

export const ForgotPasswordForm: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const {
    value: email,
    hasError,
    valueIsValid: emailIsValid,
    ...restInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value) && valueIsMatching(value, EMAIL_REGEX),
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailIsValid) {
      restInputProps.onBlur();
      return;
    }
    dispatch(requestForgotPassword({ email, language: registrationLanguage }));
    navigate(RECOVER_ROUTES.RESEND_PASSWORD, { state: email });
  };
  const texts = getForgotPasswordTexts(registrationLanguage);
  return (
    <form onSubmit={submitHandler}>
      <FormInputGroup
        label={texts.email}
        required
        errorMessage={hasError ? texts.pleaseFill : ''}
        isValid={emailIsValid}
        placeholder="mail@domain.com"
        value={email}
        {...restInputProps}
      />
      <ButtonOrangeFilled type="submit">{texts.resetPassword}</ButtonOrangeFilled>
    </form>
  );
};
