import styled from 'styled-components';

/**
 * without this, the SearchableOverflowingText > TableTextElement span is display inline, which does not have width, hence no overflow can be detected
 */
export const FlexProvider = styled.span`
  display: flex;
  justify-content: center;
`;
FlexProvider.displayName = 'FlexProvider.styled';
export const SearchableItemsList = styled.ul`
  margin: 0;
  list-style: none;
  align-items: left;
  justify-content: left;
  width: 100%;
  display: flex;
  gap: 10px;
`;
SearchableItemsList.displayName = 'SearchableItemsList.styled';

export const SingleSearchableItem = styled.li`
  display: inline-block;
  padding: 4px 4px;
  border: none;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--almost-black);
  background-color: var(--border-light-grey);
  height: 26px;
  border-radius: 12px;
  &:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  align-content: center;
`;
SingleSearchableItem.displayName = 'SingleSearchableItem.styled';

export const TooltipContent = styled.div`
  background-color: var(--white);
  color: black;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  pointer-events: auto; // Ensure tooltip content is interactive
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: start;
`;
TooltipContent.displayName = 'TooltipContent.styled';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & th,
  & td {
    padding: 5px;
    text-align: left;
    white-space: nowrap;
  }
`;
Table.displayName = 'Table.styled';

export const TableHeader = styled.th`
  background-color: var(--grey95);
  width: 100%;
`;
TableHeader.displayName = 'TableHeader.styled';

export const ToolTipItemsList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0; /* Remove default padding */
  width: 100%;
  display: flex;
  flex-direction: column; /* Ensure that each item is on its own row */
`;
ToolTipItemsList.displayName = 'ToolTipItemsList.styled';

export const ToolTipItem = styled.li`
  display: block; /* Ensures the item is block-level and takes up only the needed width */
  padding: 4px 8px; /* Adjust padding for better spacing */
  border: none;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--almost-black);
  background-color: var(--border-light-grey);
  border-radius: 12px;
  margin-bottom: 10px; /* Adds 10px space between items */
  align-self: flex-start; /* Aligns each item to the start of the flex container */

  &:last-child {
    margin-bottom: 0; /* Removes margin from the last item */
  }
`;
ToolTipItem.displayName = 'ToolTipItem.styled';
