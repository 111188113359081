import {
  ADMIN_ROUTE,
  ROUTE_LOGIN,
  adminstartionPageCategories,
  atsIntegratedNavigationCategories,
  customersNavigationCategories,
  candidatesNavigationCategories,
} from '../../constants/routes';
import { LogoContainer } from '../LogoContainer/LogoContainer';
import { SideMenuList } from './SideMenuList/SideMenuList';
import { SideMenuContainer } from './style';

interface SideMenuProps {
  isAdministration: boolean;
  userBelongsToBusiness: boolean;
  hasAtsIntegration: boolean;
  zIndexBackdropLayer: number;
  zIndexMobileRouteListWrapper: number;
  zIndexTooltip: number;
  noCustomersBussiness?: boolean;
}
export function getViewerPageCategories(hasAtsIntegration: boolean, noCustomersBussiness: boolean) {
  if (hasAtsIntegration) {
    return atsIntegratedNavigationCategories;
  } else if (noCustomersBussiness) {
    return candidatesNavigationCategories;
  } else {
    return customersNavigationCategories;
  }
}

export const SideMenu: React.FC<SideMenuProps> = ({
  isAdministration,
  userBelongsToBusiness,
  hasAtsIntegration,
  zIndexBackdropLayer,
  zIndexMobileRouteListWrapper,
  zIndexTooltip,
  noCustomersBussiness,
}) => {
  const imageSize = {
    width: '40px',
    height: '40px',
  };
  const viewerPageCategories = getViewerPageCategories(hasAtsIntegration, noCustomersBussiness ?? true);
  return (
    <SideMenuContainer>
      <LogoContainer
        theme="shortLogo"
        imgSize={imageSize}
        route={isAdministration ? ADMIN_ROUTE : ROUTE_LOGIN}
        text={isAdministration ? 'Admin' : ''}
      />
      <SideMenuList
        zIndexBackdropLayer={zIndexBackdropLayer}
        zIndexMobileRouteListWrapper={zIndexMobileRouteListWrapper}
        zIndexTooltip={zIndexTooltip}
        categories={isAdministration ? adminstartionPageCategories : viewerPageCategories}
        shouldDisplayMarketingContent={!userBelongsToBusiness}
        isSmallerThan1919={true}
      />
    </SideMenuContainer>
  );
};
