import { Language } from './general';

export interface Texts {
  forgotPassword: string;
  noWorries: string;
  enterYourEmailAddress: string;
  email: string;
  resetPassword: string;
  pleaseFill: string;
}

export const forgotPasswordTexts: Record<Language, Texts> = {
  en: {
    forgotPassword: 'Forgot your password?',
    noWorries: 'No worries.',
    enterYourEmailAddress: 'Enter your email address and we`ll send you a link to reset your password.',
    email: 'Email',
    resetPassword: 'ResetPassword',
    pleaseFill: 'Please fill out this field',
  },
  es: {
    forgotPassword: '¿Olvidaste tu contraseña',
    noWorries: 'No te preocupes',
    enterYourEmailAddress:
      'Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
    email: ' Correo electrónico',
    resetPassword: 'Restablecer contraseña',
    pleaseFill: 'Completa este campo',
  },
  he: {
    forgotPassword: 'שכחת את הססמא?',
    noWorries: 'אין בעיה',
    enterYourEmailAddress: 'הזן את כתובת הדוא"ל שלך ונשלח לך קישור לאיפוס הסיסמה.',
    email: 'דוא"ל',
    resetPassword: 'איפוס ססמא',
    pleaseFill: 'מלא שדה זה',
  },
  pt: {
    forgotPassword: 'Esqueceu sua senha?',
    noWorries: 'Sem problemas',
    enterYourEmailAddress: 'Insira seu endereço de e-mail e enviaremos um link para redefinir sua senha.',
    email: 'E-mail',
    resetPassword: 'Redefinir senha',
    pleaseFill: 'Preencha este campo',
  },
};
