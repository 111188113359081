import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXTRA_EXTRA_LARGE_DEVICES, EXTRA_LARGE_DEVICES } from '../../../constants/breakpoints';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { Link } from 'react-router-dom';
import { ValidCategoryRoute } from '../../../constants/routes';

export const IconElement = styled(FontAwesomeIcon)`
  font-size: 22px;
`;

IconElement.displayName = 'IconElement.styled';

interface BaseCategoryAsRouteProps {
  $categoryUsedAsRouteHref?: ValidCategoryRoute;
  $isActive?: boolean;
}

export interface ICategoryItemElementProps extends BaseCategoryAsRouteProps {
  $isSmallerThan1919: boolean;
  $shouldMarginBottom?: boolean;
}

export const DisplayArrowIndicator = styled(FontAwesomeIcon).attrs({
  icon: faChevronDown,
})<IDisplayArrowIndicator>`
  margin-left: auto;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotateZ(180deg)' : 'unset')};
`;
DisplayArrowIndicator.displayName = 'DisplayArrowIndicator.styled';

const checkForUsageAsLinkElement = ($categoryUsedAsRouteHref: ValidCategoryRoute | undefined) => {
  if ($categoryUsedAsRouteHref)
    return {
      as: Link,
      to: $categoryUsedAsRouteHref,
    };
};
export const CategoryItemElement = styled.button.attrs<ICategoryItemElementProps>(({ $categoryUsedAsRouteHref }) => {
  return checkForUsageAsLinkElement($categoryUsedAsRouteHref);
})<ICategoryItemElementProps>`
  display: flex;
  background: none;
  align-items: center;
  justify-content: ${({ $categoryUsedAsRouteHref }) => ($categoryUsedAsRouteHref ? 'flex-start' : 'center')};
  text-decoration: none;
  border: none;
  color: white;
  padding: ${({ $isSmallerThan1919 }) => (!$isSmallerThan1919 ? '9px 15px' : 'unset')};
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: ${({ $shouldMarginBottom }) => ($shouldMarginBottom ? '10px' : 'unset')};
  ${DisplayArrowIndicator} {
    transition: all 0.4s ease;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    padding: ${({ $isSmallerThan1919 }) => (!$isSmallerThan1919 ? '9px 5px' : 'unset')};
    font-size: 15px;
  }
  width: ${({ $isSmallerThan1919 }) => (!$isSmallerThan1919 ? '100%' : '24px')};
  height: ${({ $isSmallerThan1919 }) => (!$isSmallerThan1919 ? 'unset' : '24px')};
  cursor: pointer;
  a {
    text-decoration: none;
    color: unset;
  }
  ${IconElement} {
    margin-right: ${({ $isSmallerThan1919 }) => (!$isSmallerThan1919 ? '10px' : '0')};
  }
  &:hover {
    span {
      opacity: 50%;
    }
  }
  ${({ $isActive }) => {
    if ($isActive)
      return css`
        background-color: var(--purple-grey);
      `;
  }}
`;
CategoryItemElement.displayName = 'IconElement.styled';

interface IDisplayArrowIndicator {
  $isExpanded: boolean;
}

export const MobileSelectCategoryUnderline = styled.i.attrs({ className: 'fa-solid fa-horizontal-rule' })`
  font-size: 18.5px;
  color: var(--light-blue);

  &:hover {
    opacity: 50%;
    cursor: pointer;
  }
`;
MobileSelectCategoryUnderline.displayName = 'MobileSelectCategoryUnderline.styled';

interface IMobileCategoryColumnWrapperProps extends BaseCategoryAsRouteProps {
  $isSelected: boolean;
  $isExpanded: boolean;
}

export const MobileCategoryColumnWrapper = styled.div.attrs<IMobileCategoryColumnWrapperProps>(
  ({ $categoryUsedAsRouteHref }) => {
    return checkForUsageAsLinkElement($categoryUsedAsRouteHref);
  },
)<IMobileCategoryColumnWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ $isExpanded }) => ($isExpanded ? '7px' : 'unset')};
  text-decoration: none;
  ${Tooltip} {
    display: none;
  }

  ${({ $isExpanded }) => {
    if (!$isExpanded) {
      return css`
        margin-bottom: 35px;
      `;
    }
  }}
  &:hover {
    ${IconElement} {
      opacity: ${({ $isSelected }) => ($isSelected ? 'unset' : '50%')};
    }
    ${MobileSelectCategoryUnderline} {
      opacity: 50%;
    }
    ${Tooltip} {
      display: flex;
    }
  }
`;
MobileCategoryColumnWrapper.displayName = 'MobileCategoryColumnWrapper.styled';

export const ComingSoonLabel = styled.span`
  background-color: var(--yellow);
  font-weight: 600;
  font-size: 13px;
  color: var(--dark-blue);
  padding: 6px;
  border-radius: 6px;
  margin-left: auto;
  width: max-content;
`;
ComingSoonLabel.displayName = 'ComingSoonLabel.styled';

export const RoutesListElement = styled.ul<{ $isExpanded: boolean }>`
  list-style-type: none;
  transform-origin: top;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'scale(1, 1)' : 'scale(1, 0.1)')};
  opacity: ${({ $isExpanded }) => ($isExpanded ? '1' : '0')};
  height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '0')};
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  li:last-child {
    margin-bottom: 20px;
  }
`;
RoutesListElement.displayName = 'RoutesListElement.styled';

export const MobileRouteListWrapper = styled.div<{
  $isExpanded: boolean;
  zIndex: number;
}>`
  display: flex;
  position: relative;
  margin: 0 auto;
  z-index: ${({ zIndex }) => zIndex};
  left: 0px;
  background-color: var(--mobile-dark-blue);
  border-radius: 12px;
  width: 223px;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  margin-bottom: ${({ $isExpanded }) => ($isExpanded ? '20px' : '0')};
  padding-bottom: 10px;
  padding-left: 18px;
  transform-origin: top;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'scale(1, 1)' : 'scale(1, 0.1)')};
  opacity: ${({ $isExpanded }) => ($isExpanded ? '1' : '0')};
  height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '0')};
  visibility: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${IconElement} {
    transition: margin 0.4s ease;
  }
`;
MobileRouteListWrapper.displayName = 'MobileRouteListWrapper.styled';

export const TreeBranch = styled.img.attrs({ src: 'https://shared-assets.tatio.io/images/TreeBranch.svg' })`
  margin-left: -13.65px;
  rotate: 1deg;
  margin-right: 12px;
  margin-top: -5px;
`;
TreeBranch.displayName = 'TreeBranch.styled';

export const MobileRouteListElement = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  cursor: pointer;
`;
MobileRouteListElement.displayName = 'MobileRouteListElement.styled';

export const TreeTrunk = styled.img.attrs({ src: 'https://shared-assets.tatio.io/images/TreeTrunk.svg' })`
  height: 90%;
`;

interface IRouteItemElementProps {
  active: boolean;
  $isSmallerThan1919: boolean;
  isFirst: boolean;
}

export const RouteItemElement = styled.li<IRouteItemElementProps>`
  display: flex;
  align-items: center;
  padding: 9px 0px 9px 46px;
  color: ${({ active }) => (active ? 'var(--light-blue)' : 'white')};
  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    padding: 9px 0px 9px 38px;
    padding: 0px;
  }

  a {
    font-weight: 500;
    font-size: 15px;

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      font-size: 14px;
    }

    text-decoration: none;
    color: unset;
  }
  &:hover {
    opacity: 50%;
  }
  margin-top: ${({ isFirst, $isSmallerThan1919: isMobile }) => (isMobile && isFirst ? '18px' : '0px')};
`;
RouteItemElement.displayName = 'RouteItemElement.styled';
