import React from 'react';
import { FilterSectionContainer, FiltersSectionRow } from '../../../components/FilterSection/style';
import { CustomizedFilterField } from '@skillset/ui';
import { rolesOptions, statusesOptions } from '../constants';
import { zIndices } from '../../../assets/zIndices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { CustomerAdmin } from '../../../store/types/EmployerAdministrationInterface';

interface AdministrationFilterSectionProps {
  updateCheckboxField: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked: (category: string, value: string) => boolean;
}

export const AdministrationFilterSection: React.FC<AdministrationFilterSectionProps> = ({
  updateCheckboxField,
  isCheckboxOptionChecked,
}) => {
  const customers = useSelector((state: RootState) => state.employersAdministrationSliceReducer.customers);
  const businessCustomer = useSelector(
    (state: RootState) => state.employersAdministrationSliceReducer.businessCustomer,
  );
  const customerNames = customers.map((customer: CustomerAdmin) => ({
    name: customer.customerName,
    value: customer.customerName,
    category: 'customers',
  }));

  if (businessCustomer && businessCustomer.customerName) {
    customerNames.unshift({
      name: businessCustomer.customerName,
      value: businessCustomer.customerName,
      category: 'customers',
    });
  }

  return (
    <FilterSectionContainer>
      <FiltersSectionRow>
        <span className="title">Filter by: </span>
        <CustomizedFilterField
          name="All users"
          type="customizedCheckbox"
          options={rolesOptions}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          zIndexMobilePopup={zIndices.mobilePopup}
        />
        <CustomizedFilterField
          name="Any status"
          type="customizedCheckbox"
          options={statusesOptions}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          zIndexMobilePopup={zIndices.mobilePopup}
        />
        <CustomizedFilterField
          name="Any customer"
          type="customizedCheckbox"
          options={customerNames}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          zIndexMobilePopup={zIndices.mobilePopup}
        />
      </FiltersSectionRow>
    </FilterSectionContainer>
  );
};
