import { Language } from './general';

export interface Texts {
  createNewPasswordTitle: string;
  passwordInstructions: string;
  password: string;
  minCharacters: string;
  confirmPassword: string;
  retypePassword: string;
  resetPassword: string;
  fillOurField: string;
  passwordNotMatch: string;
  success: string;
  passwordResetMessage: string;
  loginToYourAccount: string;
}

export const resetPasswordTexts: Record<Language, Texts> = {
  en: {
    createNewPasswordTitle: 'Create new password',
    passwordInstructions: 'Your new password must be different to previously used passwords.',
    password: 'Password',
    minCharacters: 'Min 8 Characters',
    confirmPassword: 'Confirm Password',
    retypePassword: 'Retype Password',
    resetPassword: 'Reset Password',
    fillOurField: 'Please fill out this field',
    passwordNotMatch: 'Password does not match',
    success: 'Success!',
    passwordResetMessage: 'Your password has been successfully reset. Click below to login to your account.',
    loginToYourAccount: 'Login to your account',
  },
  es: {
    createNewPasswordTitle: 'Crear nueva contraseña',
    passwordInstructions: 'Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.',
    password: 'Contraseña',
    minCharacters: 'Mínimo 8 caracteres',
    confirmPassword: 'Confirmar contraseña',
    retypePassword: 'Vuelve a escribir la contraseña',
    resetPassword: 'Restablecer contraseña',
    fillOurField: 'Por favor, complete este campo',
    passwordNotMatch: 'La contraseña no coincide',
    success: '¡Éxito!',
    passwordResetMessage:
      'Tu contraseña ha sido restablecida con éxito. Haz clic abajo para iniciar sesión en tu cuenta.',
    loginToYourAccount: 'Inicia sesión en tu cuenta',
  },
  he: {
    createNewPasswordTitle: 'צור סיסמה חדשה',
    passwordInstructions: 'הסיסמה החדשה שלך חייבת להיות שונה מסיסמאות שהשתמשת בהן בעבר.',
    password: 'ססמא',
    minCharacters: 'מינימום 8 תווים',
    confirmPassword: 'אשר ססמא',
    retypePassword: 'הקלד מחדש את הסיסמה',
    resetPassword: 'אפס סיסמה',
    fillOurField: 'אנא מלא שדה זה',
    passwordNotMatch: 'הסיסמה אינה תואמת',
    success: '!הצלחה',
    passwordResetMessage: 'הסיסמה שלך אופסה בהצלחה. לחץ למטה כדי להיכנס לחשבונך.',
    loginToYourAccount: 'היכנס לחשבונך',
  },
  pt: {
    createNewPasswordTitle: 'Criar nova senha',
    passwordInstructions: 'Sua nova senha deve ser diferente das senhas usadas anteriormente.',
    password: 'Senha',
    minCharacters: 'Mínimo de 8 caracteres',
    confirmPassword: 'Confirmar senha',
    retypePassword: 'Digite a senha novamente',
    resetPassword: 'Redefinir senha',
    fillOurField: 'Por favor, preencha este campo',
    passwordNotMatch: 'A senha não corresponde',
    success: 'Sucesso!',
    passwordResetMessage: 'Sua senha foi redefinida com sucesso. Clique abaixo para entrar na sua conta.',
    loginToYourAccount: 'Faça login na sua conta',
  },
};
