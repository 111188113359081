import styled, { CSSProperties } from 'styled-components';
import { Tooltip } from '../../../reusable-elements/ToolTip';

interface ITextIconButtonProps {
  sortable: boolean;
}

export const TextIconButton = styled.button<ITextIconButtonProps>`
  background-color: unset;
  border: none;
  font-family: var(--primary-font);
  overflow: hidden;
  color: var(--almost-black);
  font-weight: 600;
  font-size: 15px;
  &:hover {
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
  }
`;
TextIconButton.displayName = 'TextIconButton.styled';

interface HeaderColumnElementProps {
  supportOverflowingText: boolean;
  align?: CSSProperties['justifyContent'];
  padding?: CSSProperties['padding'];
  minWidth?: CSSProperties['minWidth'];
}

export const HeaderColumnElement = styled.span<HeaderColumnElementProps>`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: ${({ align }) => (align ? align : 'center')};
  overflow: hidden;
  min-width: 46px;
  padding: ${({ padding, supportOverflowingText }) => (padding || supportOverflowingText ? '0 11.5px' : '14px')};
  ${TextIconButton} {
    white-space: ${({ supportOverflowingText }) => (supportOverflowingText ? 'nowrap' : 'unset')};
    text-overflow: ${({ supportOverflowingText }) => (supportOverflowingText ? 'ellipsis' : 'unset')};
    ${({ align }) => align === 'left' && 'padding-inline-start: 14px;'}
  }
  ${Tooltip} {
    display: none;
  }
  &:hover {
    ${Tooltip} {
      display: flex;
      bottom: -16px;
    }
  }
`;
HeaderColumnElement.displayName = 'HeaderColumnElement.styled';

export const RowItemColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
RowItemColumn.displayName = 'RowItemColumn.styled';
