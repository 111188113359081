import React from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { LazyLottiePlayer } from '@skillset/ui';
import { NotePreview } from '../NotePreview/NotePreview';
import { NotesListElement } from './style';
import { INote, INoteToCreateOrEdit, NotesIdentifier } from '../types';
import sittingMan from '../../../assets/lotties/sitting-man.json';

interface INotesList {
  notes: INote[];
  noteToCreateOrEdit: INoteToCreateOrEdit;
  identifier: NotesIdentifier;
  setNoteToEdit: (id: number | null, note: string) => void;
  isTextAreaFocused: boolean;
}
export const NotesList: React.FC<INotesList> = ({
  notes,
  identifier,
  setNoteToEdit,
  isTextAreaFocused,
  noteToCreateOrEdit,
}) => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const notesToRender = notes.map((note) => {
    return (
      <NotePreview
        {...note}
        noteToCreateOrEdit={noteToCreateOrEdit}
        identifier={identifier}
        key={note.id}
        setNoteToEdit={setNoteToEdit}
      />
    );
  });

  return (
    <NotesListElement isTextAreaFocused={isTextAreaFocused} isMobile={isMobile} noDataMode={notes.length === 0}>
      {!isMobile || notes.length > 0 ? notesToRender : <LazyLottiePlayer animationData={sittingMan} />}
    </NotesListElement>
  );
};
