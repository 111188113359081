import React, { useEffect, useState } from 'react';
import { ApplyToBusinessJobs, JobApplicationLink } from '@skillset/ui';
import {
  FacebookPinkGradient,
  LinkedinRedGradient,
  TwitterOrangeGradient,
  YoutubeTurquoiseGradient,
} from '../../assets/svgs';
import { useNavigate, useParams } from 'react-router-dom';
import { SimulationService } from '../../services/simulationService';
import { useFetchSocialLinksVisibility } from './useFetchSocialLinksVisibility';

const WELL_DONE = 'Well Done!';
const APPLY_TO = 'Apply now to';
const SIMULATION_COMPLETED = 'Your simulation was completed successfully and received in our system';

const RenderApplyToBusinessJobs = ({}) => {
  const { uniqueLink } = useParams();
  const navigate = useNavigate();

  const { socialLinksVisible } = useFetchSocialLinksVisibility(uniqueLink);
  const [simulationCompletedTexts, setSimulationCompletedTexts] = useState<{
    wellDone: string;
    applyTo: string;
    simulationRecieved: string;
  }>({ wellDone: WELL_DONE, applyTo: APPLY_TO, simulationRecieved: SIMULATION_COMPLETED });

  const [jobApplicationLinks, setJobApplicationLinks] = useState<JobApplicationLink[]>([]);

  useEffect(() => {
    // Retrieve data from local storage when the component mounts
    const storedJobApplicationLinks = localStorage.getItem(`${uniqueLink}_jobApplicationLinks`);

    const fetchSimulationCompeletedTexts = async () => {
      const texts = await SimulationService.getSimulationCompletedTexts(uniqueLink || '');
      setSimulationCompletedTexts(texts);
    };
    fetchSimulationCompeletedTexts();

    // Parse the retrieved data if it exists
    if (storedJobApplicationLinks) {
      setJobApplicationLinks(JSON.parse(storedJobApplicationLinks));

      // Remove the data from localStorage
      localStorage.removeItem(`${uniqueLink}_simulationName`);
      localStorage.removeItem(`${uniqueLink}_jobApplicationLinks`);
    } else {
      // No data found, navigate to /administration/settings
      navigate('/administration/settings');
    }
  }, [uniqueLink]);

  return (
    <ApplyToBusinessJobs
      applyTo={simulationCompletedTexts.applyTo}
      simulationCompletedDefault={SIMULATION_COMPLETED}
      simulationReceived={simulationCompletedTexts.simulationRecieved}
      wellDone={simulationCompletedTexts?.wellDone ? simulationCompletedTexts.wellDone : WELL_DONE}
      jobApplicationLinks={jobApplicationLinks}
      FacebookIcon={FacebookPinkGradient}
      LinkedinIcon={LinkedinRedGradient}
      YoutubeIcon={YoutubeTurquoiseGradient}
      TwitterIcon={TwitterOrangeGradient}
      socialLinksVisible={socialLinksVisible}
      sessionId=""
      dir="ltr"
    />
  );
};

export default RenderApplyToBusinessJobs;
