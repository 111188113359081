import styled from 'styled-components';

interface CircularProgressBarProps {
  size?: string;
  fontSize?: string;
  fontWeight?: string;
}

export const CircularScoring = styled.span<CircularProgressBarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
  width: fit-content;
  color: black;
  position: absolute;
  border-radius: 50%;
  height: ${({ size }) => (size ? size : '50px')};
  width: ${({ size }) => (size ? size : '50px')};
  top: 0;
`;
CircularScoring.displayName = 'CircularScoring.styled';

export const CircularProgressBarWrapper = styled.div<CircularProgressBarProps>`
  height: ${({ size }) => (size ? size : '50px')};
  width: ${({ size }) => (size ? size : '50px')};
  justify-self: center;
  position: relative;
  div:first-of-type {
    justify-content: unset !important;
    align-items: unset !important;
  }
`;
CircularProgressBarWrapper.displayName = 'CircularProgressBarWrapper.styled';

export const NestedProgressBar = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
`;
NestedProgressBar.displayName = 'NestedProgressBar.styled';
