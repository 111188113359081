export const brandColors = [
  'var(--brand-purple)',
  'var(--brand-light-purple)',
  'var(--brand-pink)',
  'var(--brand-orange)',
  'var(--brand-yellow)',
];
export const ROUTE_LOGIN = '/login';
export const SMALL_DEVICES = '767px';
export const SALES_CALENDLY_LINK = 'https://calendly.com/barry-ae1-tatio/30min';
