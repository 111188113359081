import styled from 'styled-components';

export const CloseIcon = styled.button.attrs({ className: 'fa-regular fa-xmark' })`
  align-self: flex-end;
  color: var(--almost-black);
  font-weight: 400;
  font-size: 22px;
  border: none;
  background: none;
  padding-right: 32px;
  padding-top: 32px;
`;

CloseIcon.displayName = 'CloseIcon.styled';

export const NoDataPopupElement = styled.section<{ zIndex: number }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;

  .icon {
    width: 24px;
    height: 24px;
    font-weight: 400;
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--almost-black);
    margin-left: 29px;
    margin-top: -18px;
  }
  .title {
    width: 352px;
    height: 28px;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--almost-black);
    margin-left: 29px;
    margin-top: 11px;
  }
  .subtitle {
    width: 352px;
    height: 40px;

    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--almost-black);
    margin-left: 29px;
    margin-top: 6px;
  }
  .closeButton {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 24px;
    padding-top: 15px;
  }

  position: fixed;
  top: 50%;
  right: 50%;
  width: 400px;
  height: 230px;
  transform: translate(50%, -50%);
  z-index: ${({ zIndex }) => zIndex};

  background: white;
  /* Shadow / xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
`;
NoDataPopupElement.displayName = 'NoDataPopupElement.styled';
