import styled from 'styled-components';

export const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 30px;
  align-items: center;
  justify-content: center;
`;
PageContainer.displayName = 'PageContainer.styled';

export const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 106px;
  height: 106px;
`;
LottieContainer.displayName = 'LottieContainer.styled';

export const Title = styled.h1`
  font-family: var(--primary-font);
  font-size: 26px;
  font-weight: 700;
  color: var(--almost-black);
  text-align: center;
`;
Title.displayName = 'Title.styled';

export const SubTitle = styled.span`
  margin-top: 5px;
  color: var(--standard-grey);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

SubTitle.displayName = 'SubTitle.styled';
