import React from 'react';
import { OptionalBusinessUser } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { StatusColumnWrapper } from './style';
import { HighlightedSearchTerm } from '@skillset/ui';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { EmployersAdministrationSlice } from '../../../store/types/EmployerAdministrationInterface';
import { TableTextElement } from '@skillset/ui';
import { formatDate } from '../../../utils';

interface StatusColumnProps {
  status: OptionalBusinessUser['status'];
  expirationDate: ReturnType<Date['toJSON']> | null;
}

export const StatusColumn: React.FC<StatusColumnProps> = ({ status, expirationDate }) => {
  const { searchTerm } = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('horizontal');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const statusToRender =
    expirationDate && status === 'Invitation was expired'
      ? `${status} on ${formatDate(new Date(expirationDate))}`
      : status;
  return (
    <StatusColumnWrapper>
      <TableTextElement ref={refCallback}>
        <HighlightedSearchTerm text={statusToRender} searchTerm={searchTerm.trim()} />
      </TableTextElement>
      {isOverflowing && !isMobile && (
        <Tooltip color="var(--almost-black)" direction="down" fontWeight="600" textColor="white" top="-5px">
          {statusToRender}
        </Tooltip>
      )}
    </StatusColumnWrapper>
  );
};
