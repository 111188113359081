import { Action, AnyAction, CombinedState, Reducer } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { IAuthSlice } from './slices/authSlice';

export type RootState = ReturnType<
  Reducer<
    CombinedState<{
      authSliceReducer: IAuthSlice;
    }>,
    AnyAction
  >
>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
