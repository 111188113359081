export interface BusinessUser {
  fullName: string;
  email: string;
  role: string;
  status: 'User is active';
  lastVisit: ReturnType<Date['toJSON']>;
  expirationDate: null;
  customerName: string;
}

export interface SuspendedUser {
  fullName: string;
  email: string;
  role: string;
  status: 'User is suspended';
  lastVisit: ReturnType<Date['toJSON']>;
  expirationDate: null;
  customerName: string;
}
export interface InvitedBusinessUser {
  fullName: null;
  email: string;
  role: string;
  status: 'Invitation was sent' | 'Invitation was expired';
  lastVisit: null;
  expirationDate: ReturnType<Date['toJSON']>;
  customerUUID: string;
  customerName?: string;
}

export type OptionalBusinessUser = BusinessUser | InvitedBusinessUser | SuspendedUser;

export type BusinessUserRole = 'viewer' | 'admin' | 'superAdmin';

export interface AdministrationFilterBy {
  roles: string[];
  statuses: string[];
  customers: string[];
}

export const INITIAL_ADMIN_FILTERBY: AdministrationFilterBy = {
  roles: [],
  statuses: [],
  customers: [],
};
