import React from 'react';
import { TransparentBackDropLayer } from '../BackDropLayer/BackDropLayer';
import { INavigationMenuOption } from './constants';
import { NavigationItem } from './NavigationItem/NavigationItem';
import { NaivigationMenuWrapper, NavigationList } from './style';

export interface INavigationMenuProps {
  menuOptions: INavigationMenuOption[];
  zIndexMenu: number;
  zIndexBackdropLayer: number;
  closeMenu: () => void;
}

export const NavigationMenu: React.FC<INavigationMenuProps> = ({
  menuOptions,
  zIndexMenu,
  zIndexBackdropLayer,
  closeMenu,
}) => {
  const renderMenuItems = menuOptions.map(({ title, route, iconAssetName }) => {
    return (
      <NavigationItem key={route} title={title} route={route} iconAssetName={iconAssetName} closeMenu={closeMenu} />
    );
  });
  return (
    <>
      <NaivigationMenuWrapper zIndex={zIndexMenu}>
        <NavigationList>{renderMenuItems}</NavigationList>
      </NaivigationMenuWrapper>
      <TransparentBackDropLayer zIndex={zIndexBackdropLayer} onClick={closeMenu} />
    </>
  );
};
