import { Divider, MobileSideMenuListElement, SideMenuListElement } from './style';
import { NavigationCategoryItem } from '../../../constants/routes';
import { SideMenuCategory } from '../SideMenuCategory/SideMenuCategory';
interface ISideMenuListProps {
  categories: NavigationCategoryItem[];
  shouldDisplayMarketingContent: boolean;
  isSmallerThan1919: boolean;
  zIndexTooltip: number;
  zIndexBackdropLayer: number;
  zIndexMobileRouteListWrapper: number;
}

export const SideMenuList: React.FC<ISideMenuListProps> = ({
  categories,
  isSmallerThan1919,
  zIndexTooltip,
  zIndexBackdropLayer,
  zIndexMobileRouteListWrapper,
}) => {
  const categoriesToRender = categories.map((category, index) => {
    return (
      <SideMenuCategory
        zIndexTooltip={zIndexTooltip}
        zIndexBackdropLayer={zIndexBackdropLayer}
        zIndexMobileRouteListWrapper={zIndexMobileRouteListWrapper}
        category={category}
        $isSmallerThan1919={isSmallerThan1919}
        index={index}
        key={index}
      />
    );
  });
  return isSmallerThan1919 ? (
    <>
      <MobileSideMenuListElement>{categoriesToRender}</MobileSideMenuListElement>
    </>
  ) : (
    <SideMenuListElement>
      {categoriesToRender}
      <Divider />
    </SideMenuListElement>
  );
};
