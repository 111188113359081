import React from 'react';
import { WithSwipedXComponentProps } from '../../../hooks/useAnimatedSwipeX';
import { ArrowRightIcon, ClosePopupButton, FullScreenVerticalContainer, MobileFullScreenPopupElement } from './style';

interface IMobileFullscreenPopup extends WithSwipedXComponentProps {
  title: string;
  scrollable: boolean;
  zIndexPopup: number;
}

export const MobileFullscreenPopup: React.FC<IMobileFullscreenPopup> = ({
  title,
  children,
  scrollable,
  display,
  x,
  zIndexPopup,
  bind,
  close,
}) => {
  return (
    <MobileFullScreenPopupElement zIndex={zIndexPopup} style={{ display, x }} {...bind()}>
      <span className="header">
        <span>{title}</span>
        <ClosePopupButton onClick={() => close()}>
          <ArrowRightIcon />
        </ClosePopupButton>
      </span>
      <FullScreenVerticalContainer scrollable={scrollable}>{children}</FullScreenVerticalContainer>
    </MobileFullScreenPopupElement>
  );
};
