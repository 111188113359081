import React from 'react';
import { BlobElement, QuoteElement } from './style';

const quotes = [
  [
    '"TaTiO has created a new way that allows companies to screen people IN instead of OUT. By bringing much-needed efficiency to recruiting processes, they change the reality in the market."',
    'Forbes',
  ],
  [
    '"TaTiO has a new paradigm that looks at the hiring process differently, in a way that both sides benefit from."',
    'HR Brew',
  ],
  [
    '"We should see more solutions like this, that meet the Future Of Work and are about the way people are engaged in work."',
    'HR Brew',
  ],
  [
    '"While every other solution relies on resumes, TaTiO brings in something different. Applicants are more engaged from the start and are qualified based on their skills."',
    'Tech Crunch',
  ],
  [
    '"TaTiO offers an engaging experience that replaces the old process and helps uncover whoever is qualified for the job."',
    'Chad and Cheese',
  ],
];

export const QuotesBlob: React.FC = () => {
  const randomQuoteIndex = Math.floor(Math.random() * quotes.length);
  const quote = quotes[randomQuoteIndex][0];
  const by = quotes[randomQuoteIndex][1];
  return (
    <BlobElement>
      <QuoteElement>
        <section className="quote">{quote}</section>
        <section className="by">{by}</section>
      </QuoteElement>
    </BlobElement>
  );
};
