import styled, { CSSProperties } from 'styled-components';

interface IAvatarElementProps {
  $beforeContent: string;
  backgroundColor?: CSSProperties['backgroundColor'];
}
export const AvatarElement = styled.div<IAvatarElementProps>`
  ::before {
    content: '${({ $beforeContent }) => $beforeContent}';
  }
  position: relative;
  font-family: Gilroy;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'var(--yellow)'};
  min-height: 35px;
  height: 35px;
  min-width: 35px;
  width: 35px;
  cursor: pointer;

  &.sim-candidate-name {
    background-color: var(--avatar-dark-blue);
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    width: 40px;
  }
`;
AvatarElement.displayName = 'AvatarElement.styled';
