import { Navigate } from 'react-router-dom';

import { useTypedSelector } from '../../store/store';
import { OptionalBusinessPage } from '../../store/types/EmployerBusinessSliceInterface';
import { selectMainBusinessPage } from '../../store/selectors';

interface ProtectedRouteByPlanProps {
  forbiddenMainBusinessPage: OptionalBusinessPage;
  element: JSX.Element;
}
export const ProtectedRouteByPlan = ({ forbiddenMainBusinessPage, element }: ProtectedRouteByPlanProps) => {
  const mainBusinessPage = useTypedSelector(selectMainBusinessPage);

  if (mainBusinessPage === forbiddenMainBusinessPage) return <Navigate replace to={mainBusinessPage} />;
  return element;
};
