export const ADMINISTRATION_ROW_GRID_TEMPLATE_COLUMNS = '240px 1fr 1fr 1fr  128px 64px';
export const ADMINISTRATION_MOBILE_GRID_TEMPLATE_COLUMNS = '1fr 1fr auto';

export interface AdministrationSortBy {
  keyName: string;
  orderBy: 'ASC' | 'DESC';
}

export const INITIAL_SORTBY_STATE: AdministrationSortBy = {
  keyName: 'email',
  orderBy: 'DESC',
};

export const rolesOptions = [
  {
    name: 'Viewer',
    value: 'viewer',
    category: 'roles',
  },
  {
    name: 'Admin',
    value: 'admin',
    category: 'roles',
  },
  {
    name: 'Super Admin',
    value: 'superAdmin',
    category: 'roles',
  },
];

export const statusesOptions = [
  {
    name: 'Invitation was sent',
    value: 'Invitation was sent',
    category: 'statuses',
  },
  {
    name: 'Invitation was expired',
    value: 'Invitation was expired',
    category: 'statuses',
  },
  {
    name: 'User is active',
    value: 'User is active',
    category: 'statuses',
  },
  {
    name: 'User is suspended',
    value: 'User is suspended',
    category: 'statuses',
  },
];
