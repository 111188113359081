import React from 'react';
import { useSelector } from 'react-redux';
import { selectPlatformUser } from '@skillset/onelogin';
import { HighlightedSearchTerm, AvatarElement, TableTextElement } from '@skillset/ui';

import { ColumnContainer, EmailElement, UserColumnContainer } from './style';
import { getBrandColorByOrder, getFirstNameFirstLetter } from '../../../utils';
import { RootState, useTypedSelector } from '../../../store/store';
import { EmployersAdministrationSlice } from '../../../store/types/EmployerAdministrationInterface';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Tooltip } from '../../../reusable-elements/ToolTip';

interface IUserColumn {
  fullName: string | null;
  email: string;
  colorIndex: number;
}

export const UserColumn: React.FC<IUserColumn> = ({ fullName, email, colorIndex }) => {
  const platformUser = useTypedSelector(selectPlatformUser);
  const { searchTerm } = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('horizontal');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const additionaLabel = platformUser?.email === email ? ' (you)' : '';
  const activeOrSuspendedUserColumn = (
    <>
      <TableTextElement ref={refCallback}>
        <HighlightedSearchTerm searchTerm={searchTerm} text={`${fullName} ${additionaLabel}`} />
      </TableTextElement>
      <EmailElement>
        <HighlightedSearchTerm searchTerm={searchTerm} text={email} />
      </EmailElement>
    </>
  );

  const invitedUserColumn = (
    <TableTextElement>
      <HighlightedSearchTerm searchTerm={searchTerm} text={email} />
    </TableTextElement>
  );
  return (
    <UserColumnContainer>
      <AvatarElement
        $beforeContent={fullName ? getFirstNameFirstLetter(fullName) : email[0].toUpperCase()}
        backgroundColor={getBrandColorByOrder(colorIndex)}
      ></AvatarElement>
      <ColumnContainer shouldNotBeCentered={!!fullName}>
        {fullName ? activeOrSuspendedUserColumn : invitedUserColumn}
        {isOverflowing && !isMobile && (
          <Tooltip color="var(--almost-black)" direction="down" fontWeight="600" textColor="white" top="-5px">
            {email}
          </Tooltip>
        )}
      </ColumnContainer>
    </UserColumnContainer>
  );
};
