import { API } from '@skillset/onelogin';
import { SuccessResponse } from '../utils/types';
import { ISortBy } from '../hooks/useSortBy';
import { compareSortValues } from '../utils';
import { Customer } from '../store/types/EmployerCustomersInterface';

const getAllCustomers = () => {
  return API.get('api', '/employers/customers', {}) as Promise<SuccessResponse<Customer[]>>;
};
const getCustomerInfo = (customerUUID: string) => {
  const queryStringParameters = { customerUUID } as const;
  return API.get('api', '/employers/customers', { queryStringParameters }) as Promise<SuccessResponse<Customer>>;
};

export const sortCustomers = (customers: Customer[], sortBy: ISortBy, businessName: string): Customer[] => {
  const { keyName, orderBy } = sortBy;

  // Filter the customers into main and others
  const mainCustomer = customers.filter((customer) => customer.customerName === businessName);
  const customerForSort = customers.filter((customer) => customer.customerName !== businessName);

  // Sort the other customers based on the sortBy criteria
  switch (keyName) {
    case 'customerName':
    case 'location':
    case 'agreementDate':
    case 'interactions':
      customerForSort.sort((a, b) => {
        return compareSortValues(a[keyName], b[keyName], orderBy);
      });
      break;
    default:
      break;
  }

  // Return the main customer followed by the sorted customers
  return [...mainCustomer, ...customerForSort];
};

export const CustomerService = {
  getAllCustomers,
  getCustomerInfo,
  sortCustomers,
};
