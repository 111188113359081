import { forwardRef } from 'react';
import { useMergeRefs } from '@floating-ui/react';

import { useMenuItem } from './useMenuItem';
import { MenuItemElement } from './style';

interface MenuItemProps {
  label: string;
  disabled?: boolean;
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ label, disabled, ...props }, forwardedRef) => {
    const { item, menu, tree } = useMenuItem({ label, disabled });

    const isActive = item.index === menu.activeIndex;

    return (
      <MenuItemElement
        {...props}
        ref={useMergeRefs([item.ref, forwardedRef])}
        type="button"
        role="menuitem"
        className="MenuItem"
        tabIndex={isActive ? 0 : -1}
        disabled={disabled}
        {...menu.getItemProps({
          onClick(event: React.MouseEvent<HTMLButtonElement>) {
            props.onClick?.(event);
            tree?.events.emit('click');
          },
          onFocus(event: React.FocusEvent<HTMLButtonElement>) {
            props.onFocus?.(event);
            menu.setHasFocusInside(true);
          },
        })}
      >
        {props.children ?? label}
      </MenuItemElement>
    );
  },
);
