import styled from 'styled-components';
import { SpinnerElement, ResettedButton } from '@skillset/ui';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { AnimatedStarIcon } from '@skillset/ui';
import { OverflowingTextElement } from '@skillset/ui';

export const SmallCssSpinner = styled.span`
  height: 24px;
  width: 24px;
  border: 3px solid var(--avatar-dark-blue);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
SmallCssSpinner.displayName = 'SmallCssSpinner.styled';

export const TextColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: start;
  padding-inline-start: 14px;
  padding-inline-end: 16px;
  position: relative;
  ${Tooltip} {
    display: none;
  }
  &:hover ${OverflowingTextElement} ~ ${Tooltip} {
    display: flex;
  }
`;
TextColumnWrapper.displayName = 'TextColumnWrapper.styled';

// fa-regular fa-message
export const NotesButtonIcon = styled(ResettedButton)`
  color: var(--almost-black);
  font-size: 24px;
  ${Tooltip} {
    display: none;
  }
  &:hover ${OverflowingTextElement} ~ ${Tooltip} {
    display: flex;
  }
`;

NotesButtonIcon.displayName = 'NotesButtonIcon.styled';

interface IResumeLinkWrapperProps {
  disabled: boolean;
}
export const ResumeLinkWrapper = styled.div<IResumeLinkWrapperProps>`
  ${Tooltip} {
    display: none;
  }

  &:hover {
    .icon {
      ${({ disabled }) => disabled && 'var(--grey)'};
    }
    .${SpinnerElement} {
      border: 3px solid var(--almost-black);
      border-bottom-color: transparent;
    }
    ${Tooltip} {
      display: flex;
    }
  }
  .icon {
    color: ${({ disabled }) => (disabled ? 'var(--grey)' : 'var(--almost-black)')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    font-size: 24px;
  }
`;

ResumeLinkWrapper.displayName = 'ResumeLinkWrapper.styled';

export const DownloadResume = styled(ResettedButton)`
  font-family: var(--primary-font);
  font-style: normal;
  font-size: 24px;
  color: var(--almost-black);
`;
DownloadResume.displayName = 'DownloadResume.styled';

export const DownloadIcon = styled.i.attrs(({ className }) => ({
  className: 'fa-regular fa-download ' + className ?? '',
}))`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 17px;
  color: var(--almost-black);
`;
DownloadIcon.displayName = 'DownloadIcon.styled';

export const NotesButtonIconWrapper = styled.div`
  position: relative;
  ${Tooltip} {
    display: none;
  }
  &:hover {
    ${Tooltip} {
      display: flex;
    }
  }
`;
NotesButtonIconWrapper.displayName = 'NotesButtonIconWrapper.styled';

export const CounterOverIconElement = styled.span`
  position: absolute;
  bottom: -2px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 11px;
  font-family: var(--primary-font);
  font-weight: 500;
  pointer-events: none;
`;
CounterOverIconElement.displayName = 'CounterOverIconElement.styled';
export const StaticStarIcon = styled(AnimatedStarIcon).attrs({ as: 'i' })`
  margin-inline-start: auto;
  padding-inline-start: 7px;
  font-size: 14px;
`;
StaticStarIcon.displayName = 'StaticStarIcon.styled';
