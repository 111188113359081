import styled from 'styled-components';
interface IBackDropLayerProps {
  zIndex: number;
  background?: string;
  blur?: boolean;
}
export const BackDropLayer = styled.div<IBackDropLayerProps>`
  position: fixed;
  display: flex;
  inset: 0;
  background: ${(props) => props?.background || 'rgba(0, 0, 0, 0.37)'};
  z-index: ${({ zIndex }) => zIndex};
  ${({ blur }) => blur && 'backdrop-filter: blur(8px)'}
`;
BackDropLayer.displayName = 'BackDropLayer.styled';

export const TransparentBackDropLayer = styled(BackDropLayer).attrs({ background: 'transparent' })``;
TransparentBackDropLayer.displayName = 'TransparentBackDropLayer.styled';
