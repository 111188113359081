import { Language } from './general';

export interface Texts {
  checkYourEmailTitle: string;
  weveSent: string;
  resendPassword: string;
}

export const resendPasswordTexts: Record<Language, Texts> = {
  en: {
    checkYourEmailTitle: 'Check your email',
    weveSent: 'We`ve sent a password reset link to:',
    resendPassword: 'ResendPassword',
  },
  es: {
    checkYourEmailTitle: 'Revisa tu correo electrónico',
    weveSent: 'Hemos enviado un enlace para restablecer la contraseña a:',
    resendPassword: 'Reenviar contraseña',
  },
  he: {
    checkYourEmailTitle: 'בדוק את הדוא"ל שלך',
    weveSent: 'שלחנו קישור לאיפוס סיסמה לכתובת:',
    resendPassword: 'שלח מחדש את הסיסמה',
  },
  pt: {
    checkYourEmailTitle: 'Verifique seu e-mail',
    weveSent: 'Enviamos um link para redefinir a senha para',
    resendPassword: 'Reenviar senha',
  },
};
