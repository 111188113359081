import React from 'react';
import { InputsRowContainer } from './styles';
import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { IAuthSlice } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { saveUserData } from '../../store/actions/auth.action';
import { prepareUserToSave, useCreateInputGroup, useCreatePhoneInputGroup } from './utils';
import { isNotEmpty } from '../../utils/validations';

interface BusinessQuestionareFormProps {
  currentBusinessName: string | null;
  platformName: string;
}
export const BusinessQuestionareForm: React.FC<BusinessQuestionareFormProps> = ({
  currentBusinessName,
  platformName,
}) => {
  const {
    value: firstName,
    formInputGroup: firstNameInputElement,
    valueIsValid: firstNameIsValid,
    onBlur: onFirstNameBlur,
  } = useCreateInputGroup('First Name', true, isNotEmpty);

  const {
    value: lastName,
    formInputGroup: lastNameInputElement,
    valueIsValid: lastNameIsValid,
    onBlur: onLastNameBlur,
  } = useCreateInputGroup('Last Name', true, isNotEmpty);

  const {
    value: phoneNumber,
    formInputGroup: phoneNumberInputElement,
    valueIsValid: phoneNumberIsValid,
    onBlur: onPhoneNumberBlur,
  } = useCreatePhoneInputGroup('Phone', true, undefined, undefined, '(555) 555-1234');

  const {
    value: businessName,
    formInputGroup: businessNameInputElement,
    valueIsValid: businessNameIsValid,
    onBlur: onBusinessNameBlur,
  } = useCreateInputGroup('Company Name', true, isNotEmpty, 'en', currentBusinessName);

  const {
    value: jobTitle,
    formInputGroup: jobTitleInputElement,
    valueIsValid: jobTitleIsValid,
    onBlur: onJobTitleBlur,
  } = useCreateInputGroup('Your Role', true, isNotEmpty);

  const formIsValid =
    firstNameIsValid &&
    lastNameIsValid &&
    (businessNameIsValid || currentBusinessName) &&
    jobTitleIsValid &&
    phoneNumberIsValid;

  const { cognitoUser } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!cognitoUser) return;
    if (!formIsValid) {
      onFirstNameBlur();
      onLastNameBlur();
      onPhoneNumberBlur();
      onBusinessNameBlur();
      onJobTitleBlur();
      return;
    }
    const personalInfo = {
      firstName,
      lastName,
      phoneNumber,
      jobTitle,
      dateOfBirth: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      nextJobWill: '',
    };
    const userToSave = await prepareUserToSave(cognitoUser, platformName, personalInfo, businessName);
    const results = await dispatch(saveUserData({ userToSave, platformName, uniqueLink: undefined }));
    if (results.meta.requestStatus === 'fulfilled') navigate('/');
  };

  return (
    <form onSubmit={submitHandler}>
      <InputsRowContainer>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {firstNameInputElement}
          {lastNameInputElement}
        </div>
        {phoneNumberInputElement}
        {businessNameInputElement}
        {jobTitleInputElement}
        <ButtonOrangeFilled type="submit">Continue</ButtonOrangeFilled>
      </InputsRowContainer>
    </form>
  );
};
