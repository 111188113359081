import { useDrag } from '@use-gesture/react';
import { useSpring, config, Interpolation, SpringValue } from '@react-spring/web';

export const useAnimatedSwipeX = (desiredWidth: number) => {
  const [{ x }, api] = useSpring(() => ({ x: desiredWidth }));

  const open = ({ canceled }: { canceled: boolean }) => {
    // Cancel represents if the user has passed the threshold, and the following code changes the spring config to create a nice wobbly animation effect.
    api.start({ x: 0, immediate: false, config: canceled ? config.wobbly : config.stiff });
  };
  const close = (velocity = 0) => {
    api.start({ x: desiredWidth, immediate: false, config: { ...config.stiff, velocity } });
  };
  const bind = useDrag(
    ({ last, velocity: [velocityX], direction: [directionX], movement: [movementX], cancel, canceled, event }) => {
      event.stopPropagation();
      // if the user drags up passed a threshold, then we cancel the drag so that the sheet resets to its open position
      if (movementX < -70) cancel();
      // When the user releases the sheet, we check whether it passed the threshold for it to close, or if we reset it to its open position
      if (last) {
        if (movementX > desiredWidth * 0.5 || (velocityX > 0.5 && directionX > 0)) {
          close(velocityX);
        } else {
          open({ canceled });
        }
      }
      // When the user keeps dragging, we just move the sheet according to the cursor position
      else api.start({ x: movementX, immediate: true });
    },
    { from: () => [0, x.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true },
  );

  const display = x.to((py) => (py < desiredWidth ? 'flex' : 'none'));
  const backDropStyle = {
    display,
    transform: x.to([0, desiredWidth], ['translateX(-8%) scale(1.16)', 'translateX(0px) scale(1.05)']),
    opacity: x.to([0, desiredWidth], [0.4, 1], 'clamp'),
  };
  return { bind, display, x, open, close, backDropStyle };
};
export interface WithUseAnimatedSwipeXProps {
  display: Interpolation<number, 'flex' | 'none'>;
  x: SpringValue<number>;
  backDropStyle: {
    display: Interpolation<number, 'flex' | 'none'>;
    transform: Interpolation<number, 'translateX(-8%) scale(1.16)' | 'translateX(0px) scale(1.05)'>;
  };
  open: ({ canceled }: { canceled: boolean }) => void;
  close: (velocity?: number) => void;
  bind: (...args: unknown[]) => import('@use-gesture/react/dist/declarations/src/types').ReactDOMAttributes;
}

export type WithSwipedXComponentProps = Omit<WithUseAnimatedSwipeXProps, 'open'>;
