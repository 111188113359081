import React, { useState } from 'react';

export interface UseInputProps {
  validate: (value: string) => boolean;
  defaultValue: string;
}

export const useInput = ({ validate, defaultValue }: UseInputProps) => {
  const [value, setValue] = useState<string>(defaultValue);
  const [touched, setTouched] = useState<boolean>(false);

  const valueIsValid = validate(value);
  const hasError = !valueIsValid && touched;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
    setValue(e.target.value);
  const handleBlur = () => setTouched(true);

  return { value, onChange: handleChange, onBlur: handleBlur, hasError, valueIsValid, touched };
};
