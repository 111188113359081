import styled from 'styled-components';
import { ResettedButton, AvatarElement } from '@skillset/ui';

export const NotePreviewContainer = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--light-grey);
`;

NotePreviewContainer.displayName = 'NotePreviewContainer.styled';

export const NameTitleElement = styled.span`
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 14px;
  color: var(--almost-black);
  margin-right: auto;
`;

NameTitleElement.displayName = 'NameTitleElement.styled';

export const DateTextElement = styled.span`
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 12px;
  color: var(--almost-black);
  margin-right: 7px;
`;

DateTextElement.displayName = 'DateTextElement.styled';

export const ClockIconElement = styled.i.attrs({ className: 'fa-regular fa-clock' })`
  font-weight: 400;
  font-size: 12px;
  margin-right: 13.5px;
`;

ClockIconElement.displayName = 'ClockIconElement.styled';

export const OptionsIconButton = styled(ResettedButton).attrs({ className: 'fa-solid fa-ellipsis-vertical' })`
  font-weight: 400;
  font-size: 12px;
  margin-right: 13.5px;
  width: 10px;
  color: var(--almost-black);
`;

OptionsIconButton.displayName = 'OptionsIconButton.styled';

export const DetailsAndControlsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7.5px;
  ${AvatarElement} {
    margin-right: 10px;
  }
`;
DetailsAndControlsContainer.displayName = 'DetailsAndControlsContainer.styled';

export const NoteContentContainer = styled.div`
  padding: 7.5px 11.5px;
`;

NoteContentContainer.displayName = 'NoteContentContainer.styled';

interface INoteContentElementProps {
  isOverflowedTextHidden: boolean;
}
export const NoteContentElement = styled.span<INoteContentElementProps>`
  display: block;
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 400;
  color: var(--almost-black);
  line-height: 25px;
  max-height: ${({ isOverflowedTextHidden }) => (isOverflowedTextHidden ? '75px' : 'unset')};
  overflow-y: ${({ isOverflowedTextHidden }) => (isOverflowedTextHidden ? 'hidden' : 'unset')};
  overflow-wrap: break-word;
`;

NoteContentElement.displayName = 'NoteContentElement.styled';

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
`;
RelativeContainer.displayName = 'RelativeContainer.styled';
