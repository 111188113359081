import React from 'react';

import { NaturalSortingIcon, SortedDownIcon, SortedUpIcon } from './style';

interface ICellSortingIndicator {
  orderBy: 'ASC' | 'DESC' | null;
  onClick?: () => void;
}

export const CellSortingIndicator: React.FC<ICellSortingIndicator> = ({ orderBy, onClick }) => {
  const wrapAccordingToFunctionality:
    | { as: 'button'; style: { backgroundColor: string; border: string } }
    | Record<string, never> = onClick ? { as: 'button', style: { backgroundColor: 'unset', border: 'none' } } : {};
  switch (orderBy) {
    case 'ASC':
      return <SortedUpIcon onClick={onClick} {...wrapAccordingToFunctionality} />;
    case 'DESC':
      return <SortedDownIcon onClick={onClick} {...wrapAccordingToFunctionality} />;
    default:
      return <NaturalSortingIcon onClick={onClick} {...wrapAccordingToFunctionality} />;
  }
};
