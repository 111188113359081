import { getTermsOfUseTexts } from '../../services/languages.service';
import { Language } from '../../utils/languages/general';
import { TermsOfUseContainer, TextElement, PolicyWrapper, BoldHeader } from './style';

export const TermsOfUse: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const texts = getTermsOfUseTexts(registrationLanguage);
  return (
    <TermsOfUseContainer>
      <h1 className="header">{texts.title}</h1>
      <div>
        <br />
        <PolicyWrapper>
          <section>
            <TextElement>{texts.section1}</TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section2Title}</BoldHeader>
            <TextElement>{texts.section2}</TextElement>
          </section>
          <br />
          <section>
            <TextElement>{texts.section3}</TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section4Title}</BoldHeader>
            <TextElement>{texts.section4}</TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section5Title}</BoldHeader>
            <TextElement>
              {texts.section5ListTitle}
              <ul style={{ paddingLeft: '17px' }}>
                <li>{texts.section5Li1}</li>
                <li>{texts.section5Li2}</li>
                <li>{texts.section5Li3}</li>
                <li>{texts.section5Li4}</li>
                <li>{texts.section5Li5}</li>
              </ul>
            </TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section6Title}</BoldHeader>
            <TextElement>{texts.section6}</TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section7Title}</BoldHeader>
            <TextElement>
              {texts.section7ListTitle}
              <ul style={{ paddingLeft: '17px' }}>
                <li>{texts.section7Li1}</li>
                <li>{texts.section7Li2}</li>
                <li>
                  <a href={texts.section7Li3ref}>{texts.section7Li3}</a>{' '}
                </li>
                <li>
                  <a href={texts.section7Li4ref}>{texts.section7Li4}</a>
                </li>
              </ul>
              {texts.section7ListFooter}
            </TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section8Title}</BoldHeader>
            <TextElement>{texts.section8}</TextElement>
          </section>
          <br />
          <section>
            <BoldHeader>{texts.section9Title}</BoldHeader>
            <TextElement>{texts.section9}</TextElement>
          </section>
        </PolicyWrapper>
      </div>
    </TermsOfUseContainer>
  );
};
