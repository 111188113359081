import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerService } from '../../services/customersService';
import { ICustomerUniqueLinksNames } from '../types/EmployerCustomersInterface';

export const getAllCustomers = createAsyncThunk('employerCustomers/getAllCustomers', async (_, thunkAPI) => {
  try {
    const { payload } = await CustomerService.getAllCustomers();
    return payload.map((item) => {
      return { ...item };
    });
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data.name);
  }
});

export const getCustomerInfo = createAsyncThunk(
  'employerCustomers/getCustomerInfo',
  async (params: { customerUUID: string }, thunkAPI) => {
    const { customerUUID } = params;
    try {
      const { payload } = await CustomerService.getCustomerInfo(customerUUID);

      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const setCurrentCustomer = createAsyncThunk(
  'employerCustomers/setCurrentCustomer',
  async (
    params: {
      customerUUID: string;
      customerName: string;
      location: string;
      agreementDate: string;
      interactions: number;
      positions: ICustomerUniqueLinksNames[];
      totalInteractions: number;
    },
    thunkAPI,
  ) => {
    try {
      const { customerUUID, customerName, location, agreementDate, interactions, positions, totalInteractions } =
        params;
      return { customerUUID, customerName, location, agreementDate, interactions, positions, totalInteractions };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
