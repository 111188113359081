import React, { useEffect, useRef, useState } from 'react';
import { CleanInput, Container, INITIAL_INPUT_WIDTH, MeasuringInputTextElement } from './style';
import { EmailAddressItem } from './EmailAddressItem';

interface EmailAddressesInputProps {
  value: string;
  emailsList: string[];
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onRemoveEmailAddress: (emailAddress: string) => void;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
}

export const EmailAddressesInput: React.FC<EmailAddressesInputProps> = ({
  value,
  emailsList,
  onChange,
  onInputKeyDown,
  onRemoveEmailAddress,
  handleBlur,
}) => {
  const [inputWidth, setInputWidth] = useState(INITIAL_INPUT_WIDTH);
  const inputContentMeasureRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const focusEmailInput = () => inputRef.current?.focus();

  const renderEmailsList = emailsList.map((eAddress) => (
    <EmailAddressItem email={eAddress} onRemoveClick={() => onRemoveEmailAddress(eAddress)} key={eAddress} />
  ));

  useEffect(() => {
    focusEmailInput();
    if (inputContentMeasureRef.current) {
      if (inputContentMeasureRef.current.offsetWidth > INITIAL_INPUT_WIDTH) {
        setInputWidth(inputContentMeasureRef.current.offsetWidth);
      }
    }
  }, [value]);

  return (
    <Container onClick={focusEmailInput}>
      {renderEmailsList}
      <MeasuringInputTextElement ref={inputContentMeasureRef}>{value}</MeasuringInputTextElement>
      <CleanInput
        ref={inputRef}
        placeholder="Email, comma separated"
        value={value}
        onChange={onChange}
        onKeyDown={onInputKeyDown}
        style={{ width: inputWidth + 'px' }}
        onBlur={handleBlur}
      />
    </Container>
  );
};
