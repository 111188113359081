import styled, { css } from 'styled-components';
import { IOSScrollbar } from '../../../utils/IOSScrollbar';

interface INotesListElement {
  isTextAreaFocused: boolean;
  isMobile: boolean;
  noDataMode: boolean;
}
export const NotesListElement = styled.div<INotesListElement>`
  display: flex;
  flex-direction: column;
  margin-top: 7.5px;
  max-height: ${({ isTextAreaFocused, isMobile }) => (isMobile ? 'unset' : isTextAreaFocused ? '203px' : '285px')};
  overflow-y: auto;
  ${({ isMobile }) => {
    if (!isMobile) {
      return IOSScrollbar;
    }
  }};

  ${({ noDataMode }) => {
    if (noDataMode) {
      return css`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: visible;
      `;
    }
  }}
`;

NotesListElement.displayName = 'NotesListElement.styled';
