import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RECOVER_ROUTES, ROUTE_LOGIN } from '../../constants/routes';

import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { PageContainer } from '../../components/Reusable-styled-elements/PageContainer';
import { ResendPasswordFormContainer } from './style';

import { useAppDispatch } from '../../store/store';
import { requestForgotPassword } from '../../store/actions/auth.action';
import { Language } from '../../utils/languages/general';
import { getResendPasswordTexts } from '../../services/languages.service';

interface UseLocationProps {
  state?: string;
}

export const ResendPassword: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: email } = location as UseLocationProps;

  const backHandler = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });

  useEffect(() => {
    if (!email) {
      navigate(RECOVER_ROUTES.FORGOT_PASSWORD);
    }
  }, []);
  if (!email) return null;
  const texts = getResendPasswordTexts(registrationLanguage);
  return (
    <PageContainer>
      <LogoContainerWrapper clickHandler={backHandler} />

      <ResendPasswordFormContainer>
        <ImageContainer src="https://shared-assets.tatio.io/images/confirm-email.gif" />
        <Heading>{texts.checkYourEmailTitle}</Heading>
        <Subheader>{texts.weveSent}</Subheader>
        <Subheader>{email}</Subheader>

        <ButtonOrangeFilled
          onClick={() => {
            dispatch(requestForgotPassword({ email, language: registrationLanguage }));
          }}
        >
          {texts.resendPassword}
        </ButtonOrangeFilled>
      </ResendPasswordFormContainer>
    </PageContainer>
  );
};
