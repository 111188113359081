import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OptionalBusinessUser } from '../../pages/Administration/types';
import { AdministrationSortBy } from '../../pages/Administration/constants';
import { AdministrationService } from '../../services/administrationService';
import {
  checkAPIKeyGenerated,
  getBusinessUsers,
  getSimTypesPrimaryLinks,
  getSimUniqueAndApplicationLinks,
  getUserIntegration,
  getCustomers,
  setCurrentCustomer,
} from '../actions/employerAdministration.action';
import {
  EmployersAdministrationSlice,
  SimUniqueAndApplicationLink,
  SimTypePrimaryLink,
  CustomerAdmin,
} from '../types/EmployerAdministrationInterface';

const initialState: EmployersAdministrationSlice = {
  users: [],
  searchTerm: '',
  integrationIdentifier: null,
  apiKeyWasGenerated: false,
  loading: false,
  simTypesPrimaryLinks: [],
  simUniqueAndApplicationLinks: [],
  customers: [],
  currenCustomer: { customerName: '', customerUUID: '' },
  businessCustomer: { customerName: '', customerUUID: '' },
  loadingCurrentCustomer: false,
  currentSimTypesPrimaryLinks: [],
};

const employersAdministrationSlice = createSlice({
  name: 'employerAdministration',
  initialState,
  reducers: {
    applySortBy(state, { payload: { sortBy } }: PayloadAction<{ sortBy: AdministrationSortBy }>) {
      state.users = AdministrationService.sortUsers(state.users, sortBy);
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    updateUsersList(state, { payload }: PayloadAction<{ users: OptionalBusinessUser[] }>) {
      state.users = payload.users;
    },
    setIntegration(state, { payload }: PayloadAction<string | null>) {
      state.integrationIdentifier = payload;
    },
    apiKeyGeneratedSuccess(state) {
      state.apiKeyWasGenerated = true;
    },
    removePrimaryLinkJobApp(state, { payload }: PayloadAction<{ uniqueLink: string; appLink: string }>) {
      const { uniqueLink, appLink } = payload;
      const uniqueLinkIdx = state.simTypesPrimaryLinks.findIndex((link) => link.primaryLink === uniqueLink);
      const updatedLinks: SimTypePrimaryLink[] = JSON.parse(JSON.stringify(state.simTypesPrimaryLinks));
      updatedLinks[uniqueLinkIdx].applicationLinks = updatedLinks[uniqueLinkIdx].applicationLinks.filter(
        (currAppLink) => currAppLink.applicationLink !== appLink,
      );
      state.simTypesPrimaryLinks = updatedLinks;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessUsers.fulfilled, (state, { payload }: PayloadAction<OptionalBusinessUser[]>) => {
        state.users = payload;
      })
      .addCase(getUserIntegration.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.integrationIdentifier = payload;
        state.loading = false;
      })
      .addCase(getSimTypesPrimaryLinks.fulfilled, (state, { payload }: PayloadAction<SimTypePrimaryLink[]>) => {
        state.simTypesPrimaryLinks = payload;
        state.loading = false;
      })
      .addCase(getSimTypesPrimaryLinks.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getSimTypesPrimaryLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomers.fulfilled, (state, { payload }: PayloadAction<CustomerAdmin[]>) => {
        const [firstCustomer, ...restCustomers] = payload;
        state.customers = restCustomers;
        state.businessCustomer = firstCustomer;
        state.loading = false;
      })
      .addCase(getCustomers.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCurrentCustomer.pending, (state) => {
        state.loadingCurrentCustomer = true;
      })
      .addCase(setCurrentCustomer.rejected, (state) => {
        state.loadingCurrentCustomer = false;
      })
      .addCase(setCurrentCustomer.fulfilled, (state, { payload }) => {
        state.loadingCurrentCustomer = false;
        state.currenCustomer = payload;
        state.currentSimTypesPrimaryLinks = state.simTypesPrimaryLinks?.filter(
          (link) => link.customerUUID === payload.customerUUID,
        );
      })
      .addCase(getUserIntegration.rejected, (state) => {
        state.loading = false;
        return state;
      })
      .addCase(getSimUniqueAndApplicationLinks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSimUniqueAndApplicationLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getSimUniqueAndApplicationLinks.fulfilled,
        (state, { payload }: PayloadAction<SimUniqueAndApplicationLink[]>) => {
          state.loading = false;
          state.simUniqueAndApplicationLinks = payload;
        },
      )
      .addCase(getUserIntegration.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAPIKeyGenerated.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.apiKeyWasGenerated = payload;
        state.loading = false;
      })
      .addCase(checkAPIKeyGenerated.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkAPIKeyGenerated.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { apiKeyGeneratedSuccess, applySortBy, setSearchTerm, setIntegration, removePrimaryLinkJobApp } =
  employersAdministrationSlice.actions;

export const employersAdministrationSliceReducer = employersAdministrationSlice.reducer;
