import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  PageHeaderControlsContainer,
  PageWithTableContainer,
} from '../../reusable-elements/Page/PageWithTableContainer';
import { Table, TableSearch, PageHeaderAndTitles } from '@skillset/ui';
import { INITIAL_SORTBY_STATE, JOBS_ROW_GRID_TEMPLATE_COLUMNS } from './constants';
import { getJobsColumns } from './columns/columns';
import { TableAndFiltersWrapper } from '../../reusable-elements/TableAndFiltersWrapper';
import { INITIAL_JOBS_FILTERBY, JobsFilterBy } from './types';
import { useSortBy } from '../../hooks/useSortBy';
import { JobsFilterSection } from './FilterSection/JobsFilterSection';
import { RootState, useAppDispatch } from '../../store/store';
import { getJobs, getJobsSampleData } from '../../store/actions/employerBusinessSlice.action';
import { useSelector } from 'react-redux';
import { EmployerBusinessSlice } from '../../store/types/EmployerBusinessSliceInterface';
import { filterJobs } from './FilterSection/utils';
import { applySortBy } from '../../store/reducers/employerBusinessSlice';
import { JobsTableNoData } from './JobsTableNoData/JobsTableNoData';
import { zIndices } from '../../assets/zIndices';

export const Jobs = () => {
  const [sortBy, handleSortClick, getSortByOrder] = useSortBy(INITIAL_SORTBY_STATE);
  const businessSlice = useSelector<RootState, EmployerBusinessSlice>((state) => state.employersBusinessSliceReducer);
  const { jobs, loading } = businessSlice;
  const [filterBy, setFilterBy] = useState<JobsFilterBy>(INITIAL_JOBS_FILTERBY);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobsColumns] = getJobsColumns(searchTerm);
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (!jobs.length) dispatch(getJobs());
  }, []);

  useEffect(() => {
    dispatch(applySortBy({ sortBy }));
  }, [sortBy]);

  const updateCheckboxField = (category: string, value: string, checked: boolean) => {
    const castedCategory = category as keyof typeof filterBy;
    setFilterBy((prevFilterBy) => {
      return {
        ...prevFilterBy,
        [castedCategory]: checked
          ? [...prevFilterBy[castedCategory], value]
          : prevFilterBy[castedCategory].filter((option) => option !== value),
      };
    });
  };

  const isCheckboxOptionChecked = (category: string, value: string) => {
    return filterBy[category as keyof typeof filterBy].includes(value);
  };

  const jobFilterOptions = useMemo(() => {
    return jobs.map((job) => {
      const jobName = job.name;
      return { name: jobName, value: jobName, category: 'jobsNames' };
    });
  }, [jobs]);

  const loadSampleData = () => dispatch(getJobsSampleData());

  const filteredJobs = filterJobs(jobs, searchTerm, filterBy);
  const areThereFilteredJobs = filteredJobs.length > 0;
  const shouldRenderFreeTrialScreen = !loading && jobs.length === 0;

  return (
    <PageWithTableContainer>
      <PageHeaderAndTitles title="Jobs" subTitle="All Jobs">
        <PageHeaderControlsContainer>
          <TableSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </PageHeaderControlsContainer>
      </PageHeaderAndTitles>
      <TableAndFiltersWrapper>
        <JobsFilterSection
          options={jobFilterOptions}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
        />
        <Table
          zIndexTooltip={zIndices.sideMenuTooltip}
          rowGridTemplateColumns={JOBS_ROW_GRID_TEMPLATE_COLUMNS}
          mobileRowGridTemplateColumns={JOBS_ROW_GRID_TEMPLATE_COLUMNS}
          columns={jobsColumns}
          renderDataCondition={areThereFilteredJobs}
          renderRowProps={() => {
            return {
              style: { height: '59px' },
            };
          }}
          data={filteredJobs.map((col, i) => {
            return {
              ...col,
              componentKey: i,
            };
          })}
          components={{
            NoData: () => (
              <JobsTableNoData
                shouldRenderFreeTrialScreen={shouldRenderFreeTrialScreen}
                loadSampleData={loadSampleData}
                loading={loading}
              />
            ),
          }}
          handleSortClick={handleSortClick}
          getSortByOrder={getSortByOrder}
          rowsBorderLess={true}
        />
      </TableAndFiltersWrapper>
    </PageWithTableContainer>
  );
};
