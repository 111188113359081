import styled from 'styled-components';

export const QuoteElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  gap: 10px;
  background-color: var(--blue);
  font-family: var(--primary-font);
  text-align: left;
  color: white;

  .quote {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
  }
  .by {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
  }
`;

QuoteElement.displayName = 'QuoteElement.styled';

export const BlobElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
`;
BlobElement.displayName = 'BlobElement.styled';
