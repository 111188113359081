import { useEffect, useReducer } from 'react';
import { BusinessService } from '../../services/employerBusinessService';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

type ActionType =
  | { type: 'FETCH_INIT' }
  | { type: 'FETCH_SUCCESS'; payload: boolean }
  | { type: 'FETCH_FAILURE'; payload: Error };

interface StateType {
  socialLinksVisible: boolean;
  isLoading: boolean;
  error: Error | null;
}

// Define the reducer function with proper typing
const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, error: null };
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false, socialLinksVisible: action.payload, error: null };
    case 'FETCH_FAILURE':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Unhandled action type');
  }
};
// Initial state for the reducer
const initialState = {
  socialLinksVisible: false,
  isLoading: false,
  error: null,
};

export const useFetchSocialLinksVisibility = (uniqueLink?: string) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!uniqueLink) return;

    const fetchSocialLinks = async () => {
      dispatch({ type: FETCH_INIT });
      try {
        const response = await BusinessService.getSocialLinksVisibility(uniqueLink);
        dispatch({ type: FETCH_SUCCESS, payload: response.success ? response.payload : false });
      } catch (err) {
        dispatch({ type: FETCH_FAILURE, payload: err as Error });
      }
    };

    fetchSocialLinks();
  }, [uniqueLink]);

  return state;
};
