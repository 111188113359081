import { ReactElement, PropsWithChildren } from 'react';

import { TableRowItemProps } from '../types';

import { RelativeContainer, TableRowItemElement } from './style';

export const TableRowItem = ({
  children,
  rowGridTemplateColumns,
  mobileRowGridTemplateColumns,
  $borderLess,
  rowProps,
  $index,
}: PropsWithChildren<TableRowItemProps>): ReactElement | null => {
  return (
    <RelativeContainer data-test-id="candidates">
      <TableRowItemElement
        $rowGridTemplateColumns={rowGridTemplateColumns}
        $mobileRowGridTemplateColumns={mobileRowGridTemplateColumns}
        $borderLess={$borderLess}
        $index={$index}
        {...(rowProps ?? {})}
      >
        {children}
      </TableRowItemElement>
    </RelativeContainer>
  );
};
