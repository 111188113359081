import { ReactElement } from 'react';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { CellSortingIndicator } from '../../CellSortingIndicator/CellSortingIndicator';
import { CenteredColumnProps } from '../TableRowItem/style';
import { HeaderColumnElement, TextIconButton } from './style';
import { CSSProperties } from 'styled-components';

export interface Column<RowData> {
  title: string;
  alignment?: CSSProperties['justifyContent'];
  padding?: CSSProperties['padding'];
  keyName: keyof RowData extends string ? keyof RowData : never;
  render?: (rowData: RowData, index: number) => ReactElement | null;
  tableCellProps?: CenteredColumnProps;
  hideSortingFeature?: boolean;
  supportOverflowingText?: boolean;
  mobileMainLayout: boolean;
  getCSVEntries?: (rowData: RowData) => [string, string | number][];
}

interface IHeaderColumnProps {
  title: string;
  alignment?: CSSProperties['justifyContent'];
  padding?: CSSProperties['padding'];
  zIndexTooltip: number;
  hideSortingFeature?: boolean;
  supportOverflowingText?: boolean;
  handleSorting: () => void;
  getSortByOrder: () => 'ASC' | 'DESC' | null;
}
export const HeaderColumn: React.FC<IHeaderColumnProps> = ({
  handleSorting,
  getSortByOrder,
  title,
  alignment,
  padding,
  zIndexTooltip,
  hideSortingFeature = false,
  supportOverflowingText = false,
}) => {
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLButtonElement>('horizontal');
  return (
    <HeaderColumnElement align={alignment} padding={padding} supportOverflowingText={supportOverflowingText}>
      <TextIconButton
        sortable={!hideSortingFeature}
        ref={refCallback}
        onClick={!hideSortingFeature ? handleSorting : undefined}
      >
        {title}
      </TextIconButton>
      {!hideSortingFeature && <CellSortingIndicator orderBy={getSortByOrder()} onClick={handleSorting} />}
      {supportOverflowingText && isOverflowing && (
        <Tooltip color="var(--purple-grey)" direction="up" fontWeight="600" textColor="white" zIndex={zIndexTooltip}>
          {title}
        </Tooltip>
      )}
    </HeaderColumnElement>
  );
};
