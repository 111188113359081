import { useState } from 'react';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { SearchableOverflowingText } from '../SearchableOverflowingText/SearchableOverflowingText';

import { FlexProvider, SingleSearchableItem } from './style';

interface MultipleSearchableOverflowingTextItemProps {
  setLabelRef: (instance: HTMLLIElement | null) => void;
  zIndexTooltip: number;
  label: string;
  searchTerm: string;
  isViewed: boolean | undefined;
  portalize: boolean | undefined;
  showTooltip: boolean;
  renderTooltipContent: JSX.Element;
}
export const MultipleSearchableOverflowingTextItem: React.FC<MultipleSearchableOverflowingTextItemProps> = ({
  zIndexTooltip,
  label,
  searchTerm,
  isViewed,
  portalize,
  showTooltip,
  renderTooltipContent,
  setLabelRef,
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <SingleSearchableItem
      ref={setLabelRef}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <FlexProvider>
        <SearchableOverflowingText
          zIndexTooltip={zIndexTooltip}
          text={label}
          searchTerm={searchTerm}
          highlightBackground="var(--white)"
          isViewed={isViewed}
          portalize={portalize}
        />
        {isHovered && showTooltip && (
          <Tooltip
            color="var(--white)"
            fontWeight="500"
            textColor="white"
            direction="up"
            top="20px"
            zIndex={zIndexTooltip}
          >
            {renderTooltipContent}
          </Tooltip>
        )}
      </FlexProvider>
    </SingleSearchableItem>
  );
};
