import styled from 'styled-components';
import { ResettedButton } from '../../reusable-elements/ResettedButton';

export const ButtonContainer = styled(ResettedButton)`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-white);
`;
ButtonContainer.displayName = 'ButtonContainer.styled';

export const LinkIcon = styled.i.attrs({ className: 'fa-regular fa-link' })`
  font-size: 17px;
  color: var(--almost-black);
`;
LinkIcon.displayName = 'LinkIcon.styled';

export const QRCodeIcon = styled.i.attrs({ className: 'fa-regular fa-qrcode' })`
  font-size: 17px;
  color: var(--almost-black);
`;
QRCodeIcon.displayName = 'QRCodeIcon.styled';
