import { Column } from '@skillset/ui';
import { Job } from '../../../store/types/EmployerBusinessSliceInterface';
import { CandidatesCountColumn } from './CandidatesCountColumn';
import { IDColumn } from './IDColumn';
import { JobNotesColumn } from './JobNotesColumn';
import { NameColumn } from './NameColumn';
import { ShareColumn } from './ShareColumn';
import { ResettedLink } from './style';

export const getJobsColumns = (searchTerm: string) => {
  const jobsColumns: Column<Job>[] = [
    {
      title: 'Job Name',
      keyName: 'name',
      render: ({ name, id }) => (
        <ResettedLink to={`/candidates/job/${id}`} id={`job-row-${id}`}>
          <NameColumn name={name} searchTerm={searchTerm} />
        </ResettedLink>
      ),
      mobileMainLayout: true,
      alignment: 'flex-start',
      padding: '14px 0 0 11.5px',
    },
    {
      title: 'ID',
      keyName: 'id',
      render: ({ id }) => (
        <ResettedLink to={`/candidates/job/${id}`}>
          <IDColumn id={id} searchTerm={searchTerm} />
        </ResettedLink>
      ),
      mobileMainLayout: true,
    },
    {
      title: '# of Candidates',
      keyName: 'candidatesCount',
      render: ({ candidatesCount, id }) => (
        <ResettedLink to={`/candidates/job/${id}`}>
          <CandidatesCountColumn candidatesCount={candidatesCount} searchTerm={searchTerm} />
        </ResettedLink>
      ),
      mobileMainLayout: true,
    },
    {
      title: '',
      keyName: 'uniqueLink',
      render: ({ uniqueLink, simName }) => <ShareColumn simLink={uniqueLink} simName={simName} />,
      mobileMainLayout: true,
      hideSortingFeature: true,
    },
    {
      title: '',
      keyName: 'notes',
      render: ({ name, id, notes }) => <JobNotesColumn jobId={id} jobName={name} notes={notes} />,
      mobileMainLayout: true,
      hideSortingFeature: true,
    },
  ];
  return [jobsColumns];
};
