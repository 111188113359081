import { INote } from '../../components/Notes/types';
import { ISimulationType } from './EmployersSimulationSliceInterface';

export const MainBusinessPages = {
  jobs: '/jobs',
  simulations: '/candidates/simulation',
  customers: '/customers',
} as const;

export type OptionalBusinessPage = (typeof MainBusinessPages)[keyof typeof MainBusinessPages];

export interface Job {
  id: string;
  name: string;
  candidatesCount: number;
  uniqueLink: string;
  simName: string;
  notes: INote[];
}

export interface EmployerBusinessSlice {
  jobs: Job[];
  simulationTypes: ISimulationType[];
  loading: boolean;
  loadSimulationTypes: boolean;
  mainBusinessPage: OptionalBusinessPage | null;
  hasMatchedJobs: boolean;
  hasAllowedSimulations: boolean;
  showResume: boolean;
  businessName: string;
}
