import { useDrag } from '@use-gesture/react';
import { useSpring, config, Interpolation, SpringValue } from '@react-spring/web';
import { useEffect } from 'react';

export const useAnimatedSwipeY = (desiredHeight: number) => {
  const [{ y }, api] = useSpring(() => ({ y: desiredHeight }));

  useEffect(() => {
    // Syncing Y value to be as desiredHeight incase it changes.
    if (y.get() !== 0) {
      y.set(desiredHeight);
    }
  }, [desiredHeight]);

  const open = ({ canceled }: { canceled: boolean }) => {
    // Cancel represents if the user has passed the threshold, and the following code changes the spring config to create a nice wobbly animation effect.
    api.start({ y: 0, immediate: false, config: canceled ? config.wobbly : config.stiff });
  };
  const close = (velocity = 0) => {
    api.start({ y: desiredHeight, immediate: false, config: { ...config.stiff, velocity } });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, velocityY],
      direction: [, directionY],
      movement: [, movementY],
      cancel,
      canceled,
      event,
    }) => {
      event.stopPropagation();
      // if the user drags up passed a threshold, then we cancel the drag so that the sheet resets to its open position
      if (movementY < -70) cancel();
      // When the user releases the sheet, we check whether it passed the threshold for it to close, or if we reset it to its open position
      if (last) {
        if (movementY > desiredHeight * 0.5 || (velocityY > 0.5 && directionY > 0)) {
          close(velocityY);
        } else {
          open({ canceled });
        }
      }
      // When the user keeps dragging, we just move the sheet according to the cursor position
      else api.start({ y: movementY, immediate: true });
    },
    { from: () => [0, y.get()], filterTaps: false, bounds: { top: 0 }, rubberband: true },
  );
  const display = y.to((py) => (py < desiredHeight ? 'flex' : 'none'));
  const backDropStyle = {
    display: display,
    transform: y.to([0, desiredHeight], ['translateY(-8%) scale(1.16)', 'translateY(0px) scale(1.05)']),
    opacity: y.to([0, desiredHeight], [0.4, 1], 'clamp'),
  };
  return { bind, display, y, open, close, backDropStyle };
};

export interface WithUseAnimatedSwipeYProps {
  display: Interpolation<number, 'flex' | 'none'>;
  y: SpringValue<number>;
  backDropStyle: {
    display: Interpolation<number, 'flex' | 'none'>;
    transform: Interpolation<number, 'translateY(-8%) scale(1.16)' | 'translateY(0px) scale(1.05)'>;
  };
  open: ({ canceled }: { canceled: boolean }) => void;
  close: (velocity?: number) => void;
  bind: (...args: unknown[]) => import('@use-gesture/react/dist/declarations/src/types').ReactDOMAttributes;
}

export type WithSwipedYComponentProps = Omit<WithUseAnimatedSwipeYProps, 'open'>;
