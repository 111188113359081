import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ROUTE_LOGIN } from '../../constants/routes';
import { confirmSignUp } from '../../store/actions/auth.action';
import { useAppDispatch } from '../../store/store';
import { LoadingScreen } from '@skillset/ui';
import { Language } from '../../utils/languages/general';
import { getRegistrationTexts } from '../../services/languages.service';

export const ConfirmEmail: React.FC<{ platformName: string; registrationLanguage?: Language }> = ({
  platformName,
  registrationLanguage,
}) => {
  const { username, code } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const texts = getRegistrationTexts(registrationLanguage);
  const navigateToLogin = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
  useEffect(() => {
    if (username && code) {
      dispatch(confirmSignUp({ username, code, platformName }));
    }
    navigateToLogin();
  }, []);

  return <LoadingScreen blockBodyOverflow={false} text={texts.confirmEmailLoaderMessage} />;
};
