import styled from 'styled-components';

export const NoPermissionsElement = styled.main`
  font-family: var(--primary-font);
  font-style: normal;
  height: calc(100% - 55px);
  display: grid;
  place-items: center;
  .lock-animation {
    margin: 0 auto;
    height: 104px;
    width: 104px;
  }
  .no-permission-text {
    text-align: center;
  }
  h1 {
    color: var(--almost-black);
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    margin: 0 0 5px;
  }
  p {
    color: var(--standard-grey);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
`;
NoPermissionsElement.displayName = 'NoPermissionsElement.styled';
