import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EmployerCandidatesSlice } from '../types/EmployerCandidatesInterface';
import {
  createCandidatePerJobNote,
  createCandidatePerSimulationTypeNote,
  getJobCandidates,
  getSimulationCandidates,
  removeFavoriteCandidate,
  setCandidateFields,
  setCandidateNotes,
} from '../actions/employerCandidates.action';
import { ISortBy } from '../../hooks/useSortBy';
import { CandidateService } from '../../services/candidatesService';

const initialState: EmployerCandidatesSlice = {
  candidates: [],
  source: null,
  loading: false,
};

const employerCandidatesSlice = createSlice({
  name: 'employerCandidates',
  initialState: initialState,
  reducers: {
    applySortBy(state, { payload: { sortBy } }: PayloadAction<{ sortBy: ISortBy }>) {
      state.candidates = CandidateService.sortCandidates(state.candidates, sortBy);
    },
    resetCandidates(state) {
      state.candidates = initialState.candidates;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobCandidates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobCandidates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getJobCandidates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.candidates = payload;
      })
      .addCase(getSimulationCandidates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimulationCandidates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSimulationCandidates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.candidates = payload;
      })
      .addCase(setCandidateFields, (state, { payload }) => {
        const candidateToUpdateIdx = state.candidates.findIndex(
          (candidate) => candidate.uuid === payload.candidateUUID,
        );
        if (candidateToUpdateIdx !== -1) {
          const { /* startAnimation, */ ...rest } = payload.fields;
          Object.assign(state.candidates[candidateToUpdateIdx], rest);
        }
        // if (favoriteToUpdateIdx !== -1) Object.assign(state.favorites[favoriteToUpdateIdx], payload.fields);
      })
      .addCase(removeFavoriteCandidate, (state, { payload }) => {
        const { candidateUUID, isFavorite } = payload;
        if (isFavorite) return;
        state.candidates = state.candidates.filter((candidate) => candidate.uuid !== candidateUUID);
      })
      .addCase(setCandidateNotes, (state, { payload }) => {
        const { candidateUUID, notes } = payload;
        const candidateIndex = state.candidates.findIndex((candidate) => candidate.uuid === candidateUUID);
        if (candidateIndex !== -1) state.candidates[candidateIndex].notes = notes;
      })
      .addMatcher(
        (action) =>
          [createCandidatePerSimulationTypeNote.fulfilled.match, createCandidatePerJobNote.fulfilled.match].some(
            (matcher) => matcher(action),
          ),
        (
          state,
          {
            payload,
          }: ReturnType<
            (typeof createCandidatePerSimulationTypeNote)['fulfilled'] | (typeof createCandidatePerJobNote)['fulfilled']
          >,
        ) => {
          const { createdNoteId, candidateUUID, tempId } = payload;
          const candidateIndex = state.candidates.findIndex((candidate) => candidate.uuid === candidateUUID);
          const candidateNoteToUpdate = state.candidates[candidateIndex].notes.find((note) => note.id === tempId);
          if (candidateNoteToUpdate) {
            candidateNoteToUpdate.id = createdNoteId;
            candidateNoteToUpdate.isPrivileged = true;
          }
        },
      );
  },
});

//Actions are also reducers functions
export const { applySortBy, resetCandidates } = employerCandidatesSlice.actions;

export const employerCandidatesSliceReducer = employerCandidatesSlice.reducer;
