import styled from 'styled-components';

export const NaturalSortingIcon = styled.i.attrs({ className: 'fa-duotone fa-sort' })`
  color: var(--almost-black);
  margin-inline-start: 8px;
  --fa-primary-opacity: 0.5;
  --fa-secondary-opacity: 0.5;
  cursor: pointer;
`;
NaturalSortingIcon.displayName = 'NaturalSortingIcon.styled';

export const SortedUpIcon = styled(NaturalSortingIcon)`
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 0.5;
`;
SortedUpIcon.displayName = 'SortedUpIcon.styled';

export const SortedDownIcon = styled(NaturalSortingIcon)`
  --fa-primary-opacity: 0.5;
  --fa-secondary-opacity: 1;
`;
SortedDownIcon.displayName = 'SortedDownIcon.styled';
