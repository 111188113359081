import styled, { css } from 'styled-components';
import { Tooltip } from '../../../reusable-elements/ToolTip';
import { OverflowingTextElement, ResettedButton } from '@skillset/ui';

export const UserColumnContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px 14px;
`;
UserColumnContainer.displayName = 'UserColumnContainer.styled';

interface IColumnContainer {
  shouldNotBeCentered: boolean;
}

export const ColumnContainer = styled.div<IColumnContainer>`
  display: flex;
  flex-direction: column;
  ${({ shouldNotBeCentered }) => {
    if (!shouldNotBeCentered) {
      return css`
        align-self: center;
      `;
    }
  }}
`;
ColumnContainer.displayName = 'ColumnContainer.styled';

export const EmailElement = styled.span`
  color: var(--grey);
  font-weight: 400;
  font-size: 13px;
`;
EmailElement.displayName = 'EmailElement.styled';

export const RoleElement = styled(OverflowingTextElement)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: var(--grey);
  color: white;
  font-weight: 500;
  font-size: 14px;
`;
RoleElement.displayName = 'RoleElement.styled';

export const CustomerElement = styled(OverflowingTextElement)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
`;
CustomerElement.displayName = 'CustomerElement.styled';

export const SupportedOverflowedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${Tooltip} {
    display: none;
  }
  &:hover ${OverflowingTextElement} ~ ${Tooltip} {
    display: flex;
  }
`;
SupportedOverflowedWrapper.displayName = 'SupportedOverflowedWrapper.styled';
export const RoleColumnWrapper = styled(SupportedOverflowedWrapper)``;
RoleColumnWrapper.displayName = 'RoleColumnWrapper.styled';

export const StatusColumnWrapper = styled(SupportedOverflowedWrapper)``;
StatusColumnWrapper.displayName = 'StatusColumnWrapper.styled';

export const OptionsMenuButton = styled(ResettedButton).attrs({ className: 'fa-solid fa-ellipsis-vertical' })`
  font-size: 14px;
  padding: 5px;
`;
OptionsMenuButton.displayName = 'OptionsMenuButton.styled';

export const FloatingMenuWrapper = styled.div`
  position: fixed;
`;
FloatingMenuWrapper.displayName = 'FloatingMenuWrapper.styled';

export const DateAndTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
DateAndTimeContainer.displayName = 'DateAndTimeContainer.styled';
