import { RootState } from './store';

import { isUserBelongsToBusiness } from '../utils';

export const userBelongsToBusinessSelector = ({ authSliceReducer: { platformUser } }: RootState) =>
  isUserBelongsToBusiness(platformUser);

export const selectSearchTerm = (state: RootState) => state.employersSimulationSliceReducer.search;

export * from './selectors/businesses';
export * from './selectors/customers';
