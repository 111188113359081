export const SALES_CALENDLY_LINK = 'https://calendly.com/barry-ae1-tatio/30min';

export const brandColors = [
  'var(--brand-purple)',
  'var(--brand-light-purple)',
  'var(--brand-pink)',
  'var(--brand-orange)',
  'var(--brand-yellow)',
];

export const BLUE_HEADER_HEIGHT = '51px';
