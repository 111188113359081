import React from 'react';

import { OptionNameElement, RadioOptionContainer, SelectedElement, UnselectedElement } from './style';
import { RadioOption } from '../../types';

interface RadioOptionProps {
  radioOption: RadioOption;
  isSelected: boolean;
  setRadioOption: (option: RadioOption) => void;
  close: () => void;
}
export const RadioOptionComponent: React.FC<RadioOptionProps> = ({
  radioOption,
  isSelected,
  setRadioOption,
  close,
}) => {
  const handleSelect = () => {
    setRadioOption(radioOption);
    close();
  };

  return (
    <RadioOptionContainer onClick={handleSelect}>
      {isSelected ? <SelectedElement /> : <UnselectedElement />}
      <OptionNameElement>{radioOption.name}</OptionNameElement>
    </RadioOptionContainer>
  );
};
