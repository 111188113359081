import { ROUTE_LOGIN } from '../../constants/routes';
import { LogoContainer, SMALL_DEVICES } from '@skillset/ui';

interface LogoContainerWrapperProps {
  clickHandler?: () => void;
  style?: React.CSSProperties;
  className?: string;
}
export const LogoContainerWrapper: React.FC<LogoContainerWrapperProps> = ({ clickHandler, style, className }) => {
  const imgSize = { width: '133px', height: '35px' };
  const breakPointImgSize = { width: '113.44px', height: '30px', breakpoint: SMALL_DEVICES };
  const route = ROUTE_LOGIN;
  return (
    <LogoContainer
      imgSize={imgSize}
      breakPointImgSize={breakPointImgSize}
      clickHandler={clickHandler}
      style={style}
      theme="whiteLongLogo"
      route={route}
      className={className}
    ></LogoContainer>
  );
};
