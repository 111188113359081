import styled from 'styled-components';
import { CategoryItemElement } from '../SideMenuCategory/style';

export const SideMenuListElement = styled.ul`
  list-style-type: none;
  margin-top: 60px;
`;

SideMenuListElement.displayName = 'SideMenuListElement.styled';

export const LowerControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

LowerControlsWrapper.displayName = 'LowerControlsWrapper.styled';

export const MobileSideMenuListElement = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  height: 100%;
  margin-top: 66px;
  ${LowerControlsWrapper} {
    align-items: center;
  }
`;
MobileSideMenuListElement.displayName = 'MobileSideMenuListElement.styled';

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #edeff1;
  width: 100%;
  margin: 20px 0px;
`;
Divider.displayName = 'Divider.styled';

export const SupportButtonElement = styled(CategoryItemElement)`
  width: ${({ $isSmallerThan1919: isMobile }) => (!isMobile ? 'unset' : '24px')};
  height: ${({ $isSmallerThan1919: isMobile }) => (!isMobile ? 'unset' : '24px')};
`;
SupportButtonElement.displayName = 'SupportButtonElement.styled';
