import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SMALL_DEVICES, CheckboxContainer } from '@skillset/ui';

export const RememberMeText = styled.p`
  font-size: 16px;
  color: var(--dark-gray);
  font-family: var(--primary-font);
  font-weight: 400;
  line-height: 26px;
  cursor: pointer;
`;
RememberMeText.displayName = 'RememberMeText.styled';

export const ForgotPasswordLink = styled(Link)`
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--almost-black);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

ForgotPasswordLink.displayName = 'ForgotPasswordLink.styled';

export const RememberMeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--primary-font);
  text-align: start;
  color: var(--dark-grey);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  & label {
    margin: 0;
  }

  & ${ForgotPasswordLink}, ${RememberMeText} {
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 14px;
    }
  }

  ${CheckboxContainer} {
    gap: 10px;
  }
`;
RememberMeContainer.displayName = 'RememberMeContainer.styled';
