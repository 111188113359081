import styled, { css } from 'styled-components';

import { SMALL_DEVICES } from '@skillset/ui';

export const Subheader = styled.span<{ alignSelf?: string }>`
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: var(--very-dark-gray);
  ${({ alignSelf }) => {
    if (alignSelf) {
      return css`
        align-self: ${alignSelf};
        text-align: ${alignSelf};
      `;
    } else {
      return css`
        align-self: center;
        text-align: center;
      `;
    }
  }};
  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 16px;
    line-height: 18px;
  }
`;
Subheader.displayName = 'Subheader.styled';
