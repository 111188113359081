import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';

import { FormInputGroupProps } from './FormInputGroup';

import { InputErrorMessage, PasswordContainer, FormGroupText, FormInputWrapper, InputSuccessMessage } from './style';

export const PasswordInput: React.FC<FormInputGroupProps> = ({
  label,
  errorMessage,
  isValid,
  children,
  required,
  touched,
  displayInputStatus = true,
  showSuccessIndications = false,
  value,
  toggleInputHandler,
  ...restInputAttributes
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const showError = errorMessage && 0 < errorMessage.length && <InputErrorMessage>{errorMessage}</InputErrorMessage>;
  const showSuccess = showSuccessIndications && <InputSuccessMessage>Success</InputSuccessMessage>;
  const inputStatus = !isValid ? showError : showSuccess;
  return (
    <PasswordContainer hasError={(errorMessage && 0 < errorMessage?.length) || false} touched={touched}>
      <label>
        <FormGroupText required={required}>{label}</FormGroupText>
        <FormInputWrapper>
          <input
            required={required}
            value={value}
            {...restInputAttributes}
            onBlur={() => toggleInputHandler?.(false)}
            onFocus={() => toggleInputHandler?.(true)}
            type={isHidden ? 'password' : 'text'}
          />
          <FontAwesomeIcon
            icon={isHidden ? faEyeSlash : faEye}
            onClick={() => setIsHidden((prevState) => !prevState)}
          />
          {displayInputStatus && inputStatus}
        </FormInputWrapper>
        {children}
      </label>
    </PasswordContainer>
  );
};
