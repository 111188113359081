import styled from 'styled-components';
import { ButtonContainer } from '../../components/RegisterForm/style';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { SMALL_DEVICES } from '@skillset/ui';
import { RegisterFormContainer } from '../Register/style';

export const LoginFormContainer = styled(RegisterFormContainer)`
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    justify-content: flex-start;
    max-width: 330px;
    width: 100%;
  }

  & form {
    margin-top: 35px;
    width: 400px;
    @media screen and (max-width: ${SMALL_DEVICES}) {
      width: 100%;
      margin: 0 auto;
    }

    ${ButtonContainer} {
      margin-top: 16px;
    }
  }

  ${ImageContainer} {
    display: none;
  }
`;
LoginFormContainer.displayName = 'LoginFormContainer.styled';
