const Z_INDEX_DISTANCE = 100;
const zIndexLayer = [
  'minimal',
  'bookDemoPopup',
  'backdrop',
  'sideMenuTooltip',
  'sideMenuMobileRouteList',
  'statusSelector',
  'allFilters',
  'filterField',
  'positionDropSelector',
  'header',
  'userMenu',
  'mobilePopup',
  'loadingBackDrop',
  'noDataBackdrop',
  'noDataPopUp',
] as const;
type ZIndexLayer = (typeof zIndexLayer)[number];

export const zIndices = zIndexLayer.reduce<Record<ZIndexLayer, number>>((agg, name, index) => {
  agg[name] = (index + 1) * Z_INDEX_DISTANCE;
  return agg;
}, {} as Record<ZIndexLayer, number>);
