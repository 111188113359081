import { getCognitoUtmData } from '../../utils/general';
import { CognitoUserInfo, GoogleCognitoUserInfo, IPlatformUser, IPlatformUserPersonal } from '../../utils/types';
import { useInput } from '../../hooks/useInput';
import { FormInputGroup } from '../FormInputGroup/FormInputGroup';
import { usePhoneInput } from '../../hooks/usePhoneInput';
import { Language } from '../../utils/languages/general';
import { getQuestionnaireTexts } from '../../services/languages.service';

function isValidEmail(email: string) {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function getEmailOrUsernameFromCognito(cognitoUser: CognitoUserInfo | GoogleCognitoUserInfo) {
  let email = cognitoUser.attributes?.email;
  if (!isValidEmail(email)) {
    if ('email' in cognitoUser && cognitoUser.email && cognitoUser.email.length > 0) {
      email = cognitoUser.email;
    } else if ('username' in cognitoUser) {
      email = cognitoUser.username;
    }
  }

  return email;
}

export const prepareUserToSave = async (
  cognitoUser: CognitoUserInfo | GoogleCognitoUserInfo,
  platformName: string,
  userPersonalData: Omit<IPlatformUserPersonal, 'email'>,
  businessName?: string,
) => {
  const desiredUrl = localStorage.getItem('desiredUrl');

  const sourceUrl = desiredUrl?.substring(1, desiredUrl.indexOf('?') > 0 ? desiredUrl.indexOf('?') : desiredUrl.length);

  const { utmMedium, utmCampaign, utmSource } = getCognitoUtmData(cognitoUser);
  const email = getEmailOrUsernameFromCognito(cognitoUser);

  const userToSave: Omit<IPlatformUser, 'businessId'> = {
    aff: '',
    subAff: '',
    sAff: '',
    userUUID: cognitoUser?.id,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_source: utmSource,
    lang: 'en',
    email: email,
    source: sourceUrl || '',
    platformName,
    businessName,
    personal: {
      email,
      ...userPersonalData,
    },
  };
  return userToSave;
};

export const useCreateInputGroup = (
  label: string,
  required: boolean,
  validation: (value: string) => boolean,
  registrationLanguage?: Language,
  givenValue?: string | null,
  type?: string,
  placeholder?: string,
) => {
  const basicInitUseInput = {
    defaultValue: '',
    validate: validation,
  };
  const { value, hasError, valueIsValid, onBlur, ...restOfInputProps } = useInput(basicInitUseInput);
  const texts = getQuestionnaireTexts(registrationLanguage);
  const formInputGroup = (
    <FormInputGroup
      label={label}
      required={required}
      errorMessage={hasError ? texts.fillFieldError : ''}
      isValid={valueIsValid}
      value={givenValue ?? value}
      onBlur={onBlur}
      disabled={givenValue ? true : false}
      type={type}
      placeholder={placeholder}
      {...restOfInputProps}
    ></FormInputGroup>
  );

  return { value, formInputGroup, valueIsValid, onBlur };
};

export const useCreatePhoneInputGroup = (
  label: string,
  required: boolean,
  givenValue?: string | null,
  type?: string,
  placeholder?: string,
  registrationLanguage?: Language,
) => {
  const { value, hasError, valueIsValid, onBlur, onChange, inputRef, ...restOfInputProps } = usePhoneInput();
  const texts = getQuestionnaireTexts(registrationLanguage);
  const formInputGroup = (
    <FormInputGroup
      label={label}
      required={required}
      errorMessage={hasError ? texts.fillValidPhone : ''}
      isValid={valueIsValid}
      value={givenValue ?? value}
      onBlur={onBlur}
      disabled={givenValue ? true : false}
      type={type}
      placeholder={placeholder}
      {...restOfInputProps}
      inputMode="tel"
      phoneRef={inputRef}
      onChange={onChange}
    ></FormInputGroup>
  );

  return { value, formInputGroup, valueIsValid, onBlur };
};
