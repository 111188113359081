import { FloatingList } from '@floating-ui/react';

import { MenuContext } from './MenuContext';

export const MenuListProvider = ({
  children,
  elementsRef,
  labelsRef,
  value,
}: React.ComponentProps<typeof FloatingList> & React.ComponentProps<typeof MenuContext.Provider>) => (
  <MenuContext.Provider value={value}>
    <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
      {children}
    </FloatingList>
  </MenuContext.Provider>
);
