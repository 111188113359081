import { createJobNote, deleteJobNote, updateJobNote } from '../../store/actions/employerBusinessSlice.action';
import {
  createBusinessSimulationNote,
  deleteBusinessSimulationNote,
  updateBusinessSimulationNote,
} from '../../store/actions/employersSimulation.action';
import {
  createCandidatePerJobNote,
  createCandidatePerSimulationTypeNote,
  deleteCandidatePerJobNote,
  deleteCandidatePerSimulationTypeNote,
  updateCandidatePerJobNote,
  updateCandidatePerSimulationTypeNote,
} from '../../store/actions/employerCandidates.action';
import { useAppDispatch } from '../../store/store';
import { SampleNoteId } from '../../services/candidatesService';
import { NotesIdentifier } from './types';

export const useGetNoteActions = (noteIdentifier: NotesIdentifier) => {
  const dispatch = useAppDispatch();
  if (typeof noteIdentifier === 'number') {
    return {
      createNote: (note: string) => dispatch(createBusinessSimulationNote({ simulationId: noteIdentifier, note })),
      updateNote: (note: string, noteId: number) =>
        dispatch(updateBusinessSimulationNote({ note, noteId, simulationId: noteIdentifier })),
      deleteNote: (noteId: number) => dispatch(deleteBusinessSimulationNote({ noteId, simulationId: noteIdentifier })),
    };
  } else
    switch (noteIdentifier.name) {
      case 'NotePerJob': {
        const { jobId } = noteIdentifier;
        return {
          createNote: (note: string) => dispatch(createJobNote({ jobId, note })),
          updateNote: (note: string, noteId: number) => dispatch(updateJobNote({ note, noteId, jobId })),
          deleteNote: (noteId: number) => dispatch(deleteJobNote({ noteId, jobId })),
        };
      }
      case 'NotePerJobPerCandidate': {
        const { jobId, candidateUUID } = noteIdentifier;
        return {
          createNote: (note: string) => dispatch(createCandidatePerJobNote({ jobId, note, candidateUUID })),
          updateNote: (note: string, noteId: number) =>
            dispatch(updateCandidatePerJobNote({ note, noteId, candidateUUID })),
          deleteNote: (noteId: number | SampleNoteId) => dispatch(deleteCandidatePerJobNote({ noteId, candidateUUID })),
        };
      }
      case 'NotePerSimulationTypePerCandidate': {
        const { candidateUUID, simulationTypeId } = noteIdentifier;
        return {
          createNote: (note: string) =>
            dispatch(createCandidatePerSimulationTypeNote({ simulationTypeId, note, candidateUUID })),
          updateNote: (note: string, noteId: number) =>
            dispatch(updateCandidatePerSimulationTypeNote({ note, noteId, candidateUUID })),
          deleteNote: (noteId: number) => dispatch(deleteCandidatePerSimulationTypeNote({ noteId, candidateUUID })),
        };
      }

      default:
        // needs to send create, update and delete according to noteIdentifier when relevant actions (for jobs and candidates) are created
        return {
          createNote: (note: string) => console.log(note),
          updateNote: (note: string, noteId: number) => console.log(note, noteId),
          deleteNote: (noteId: number) => console.log(noteId),
        };
    }

  {
  }
};
