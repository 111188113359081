import styled from 'styled-components';
import { zIndices } from '../../assets/zIndices';
import { SMALL_DEVICES } from '../../utils/constants';

export const Icon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
`;
Icon.displayName = 'Icon.styled';

interface ILogoutButton {
  active: boolean;
}

export const LogoutButton = styled.button<ILogoutButton>`
  cursor: pointer;
  color: ${({ active }) => (active ? 'var(--blue)' : 'var(--dark-grey)')};
  font-size: 14px;
  font-weight: 400;
  font-family: var(--primary-font);
  border: none;
  background: transparent;
  text-align: center;
  user-select: none;
  text-decoration: none;
  position: relative;
  :not(:focus-visible) {
    outline: none;
  }

  &:hover {
    color: var(--blue);
  }
`;
LogoutButton.displayName = 'LogoutButton.styled';

export const UserMenuWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 39px;
  width: 200px;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
  z-index: ${zIndices.userMenu};

  &::after {
    content: ' ';
    position: absolute;
    top: -5px;
    left: 93%;
    transform: rotate(90deg);
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    right: 27px;
  }
`;
UserMenuWrapper.displayName = 'UserMenuWrapper.styled';

export const MenuListElement = styled.div`
  list-style: none;
  font-family: var(--primary-font);
  margin: 0;
  cursor: pointer;
  background-color: white;
  padding-bottom: 12.5px;
`;
MenuListElement.displayName = 'MenuListElement.styled';

export const MenuItemElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: var(--almost-black);
  font-size: 12.5px;
  font-weight: 500;
  line-height: 15px;
  cursor: pointer;
  height: 25px;

  &:hover {
    background-color: var(--light-grey);
  }

  & svg {
    //FontAwesome icons specificity is higher, an important is required.
    margin-left: 18.5px !important;
  }

  [dir='rtl'] & {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-right: 50px !important;
    & svg {
      // margin-left: 15px !important;
      margin-right: 10px !important;
    }
  }
`;

MenuItemElement.displayName = 'MenuItemElement.styled';
