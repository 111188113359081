import { getSideMenuTexts } from '../../services/languages.service';
import { Language } from '../../utils/languages/general';
import { CandidateMenuItems, CurrentItemIcon, MenuItemWrapper, SecondItemIcon, ThirdItemIcon } from './style';

const MenuItem: React.FC<{ description: string; icon: JSX.Element }> = ({ description, icon }) => {
  return (
    <MenuItemWrapper>
      {icon}
      <section className="description" style={{ marginLeft: '8px' }}>
        {description}
      </section>
    </MenuItemWrapper>
  );
};

interface ICandidateSideMenu {
  registrationLanguage?: Language;
}

export const CandidateSideMenu: React.FC<ICandidateSideMenu> = ({ registrationLanguage }) => {
  const texts = getSideMenuTexts(registrationLanguage);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: 'auto' }}>
      <MenuItemWrapper>
        <section className="title">{texts.theEntireFlow}</section>
      </MenuItemWrapper>
      <CandidateMenuItems>
        <MenuItem icon={<CurrentItemIcon />} description={texts.createAccount}></MenuItem>
        <MenuItem icon={<SecondItemIcon />} description={texts.tryOutTheJob}></MenuItem>
        <MenuItem icon={<ThirdItemIcon />} description={texts.discoverYourNextJob}></MenuItem>
      </CandidateMenuItems>
    </div>
  );
};
