import styled from 'styled-components';
export const PositionSelectorContainer = styled.div<{ zIndex: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  top: 35px;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 5px;
  padding: 20px 25px;
  gap: 10px;
`;
PositionSelectorContainer.displayName = 'PositionSelectorContainer.styled';

export const TitleElement = styled.span`
  font-weight: 700;
  font-size: 14px;
`;
TitleElement.displayName = 'TitleElement.styled';

export const RadioOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: baseline;
`;
RadioOptionsList.displayName = 'RadioOptionsList.styled';

interface IPositionPreviewElement {
  isSelected: boolean;
}
export const PositionPreviewElement = styled.button<IPositionPreviewElement>`
  border: none;
  background: none;
`;
PositionPreviewElement.displayName = 'PositionPreviewElement.styled';

export const RadioOptionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
  cursor: pointer;
`;
RadioOptionContainer.displayName = 'RadioOptionContainer.styled';

export const UnselectedElement = styled.div`
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 2px solid var(--almost-black);
  border-radius: 16px;
`;
UnselectedElement.displayName = 'UnselectedElement.styled';

export const OptionNameElement = styled.span`
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 14px;
`;
OptionNameElement.displayName = 'OptionNameElement.styled';

export const SelectedElement = styled.div`
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 8px solid var(--almost-black);
  border-radius: 16px;
`;
SelectedElement.displayName = 'SelectedElement.styled';
