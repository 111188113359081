import React from 'react';
import { NavLink } from 'react-router-dom';
import { INavigationMenuOption } from '../constants';
import { AssetContainer, NavigationItemElement, NavigationTitle } from './style';

interface INavigationItemProps extends INavigationMenuOption {
  closeMenu: () => void;
}

export const NavigationItem: React.FC<INavigationItemProps> = ({ title, route, iconAssetName, closeMenu }) => {
  return (
    <NavigationItemElement onClick={closeMenu}>
      <NavLink to={route} className={({ isActive }) => (isActive ? 'active' : '')}>
        <AssetContainer assetName={iconAssetName} />
        <NavigationTitle>{title}</NavigationTitle>
      </NavLink>
    </NavigationItemElement>
  );
};
