import { useNavigate } from 'react-router-dom';

import { ROUTE_LOGIN } from '../../constants/routes';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';

import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { PageContainer } from '../../components/Reusable-styled-elements/PageContainer';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { ForgotPasswordFormContainer } from './style';
import { Language } from '../../utils/languages/general';
import { getForgotPasswordTexts } from '../../services/languages.service';

export const ForgotPassword: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const navigate = useNavigate();
  const backHandler = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
  const texts = getForgotPasswordTexts(registrationLanguage);
  return (
    <PageContainer>
      <LogoContainerWrapper clickHandler={backHandler} />
      <ForgotPasswordFormContainer>
        <ImageContainer src="https://shared-assets.tatio.io/images/forgot-password.gif" />
        <Heading style={{ width: 'max-content' }}>{texts.forgotPassword}</Heading>
        <Heading>{texts.noWorries}</Heading>
        <Subheader>{texts.enterYourEmailAddress}</Subheader>
        <ForgotPasswordForm registrationLanguage={registrationLanguage} />
      </ForgotPasswordFormContainer>
    </PageContainer>
  );
};
