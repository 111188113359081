import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Welcome } from '../Welcome/Welcome';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { ROUTE_LOGIN, SIGN_UP_ROUTES } from '../../constants/routes';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { PageContainer, PageWrapper } from '../../components/Reusable-styled-elements/PageContainer';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { LoginFormContainer } from './style';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { textTemplates } from '../../utils/types';
import { AdditionalInfo } from '../../components/Reusable-styled-elements/AdditionalInfo';
import { resetError } from '../../store/slices/authSlice';
import { useAppDispatch } from '../../store/store';
import { Language } from '../../utils/languages/general';
import { getLoginTexts } from '../../services/languages.service';

interface ILoginProps {
  title: string;
  subTitle?: string;
  platformName: string;
  alignHeader?: string;
  isBusinessUser?: boolean;
  desiredUrl?: string;
  registrationLanguage?: Language;
}

export const Login: React.FC<ILoginProps> = ({
  title,
  platformName,
  alignHeader,
  isBusinessUser,
  desiredUrl,
  registrationLanguage,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isMobile } = useWindowDimensions({ widthConsideredAsMobile: 1024 });

  const navigateToLogin = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });

  const navigateToRegister = () => {
    dispatch(resetError());
    navigate(SIGN_UP_ROUTES.REGISTER);
  };
  const texts = getLoginTexts(registrationLanguage);
  return (
    <PageWrapper textTemplate={textTemplates.LOGIN}>
      {!isMobile && (
        <Welcome
          textTemplate={textTemplates.LOGIN}
          isBusinessUser={isBusinessUser}
          registrationLanguage={registrationLanguage}
        />
      )}
      <PageContainer>
        {isMobile && <LogoContainerWrapper clickHandler={navigateToLogin} style={{ alignSelf: 'start' }} />}
        <LoginFormContainer>
          <Heading alignSelf={alignHeader}>{title}</Heading>
          <Subheader alignSelf={alignHeader}>
            <AdditionalInfo size={'18px'}>
              {texts.haveAcountQuestion}
              <button onClick={navigateToRegister} type="button">
                {texts.createNewAccount}
              </button>
            </AdditionalInfo>
          </Subheader>
          <LoginForm platformName={platformName} desiredUrl={desiredUrl} registrationLanguage={registrationLanguage} />
        </LoginFormContainer>
      </PageContainer>
    </PageWrapper>
  );
};
