import styled from 'styled-components';
import { EXTRA_LARGE_DEVICES } from '@skillset/ui';

export const ImageContainer = styled.img`
  max-width: 106px;
  max-height: 106px;
  object-fit: cover;
  margin: 0 auto;

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    max-width: 100px;
    max-height: 100px;
  }
`;
ImageContainer.displayName = 'ImageContainer.styled';
