import styled from 'styled-components';

import {
  EXTRA_EXTRA_LARGE_DEVICES,
  EXTRA_LARGE_DEVICES,
  LARGE_DEVICES,
  MEDIUM_DEVICES,
  SMALL_DEVICES,
} from '@skillset/ui';

import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { ButtonContainer } from '../../components/RegisterForm/style';

export const RegisterFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  height: 100%;
  align-items: center;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    text-align: center;
    height: unset;
    justify-content: flex-start;
  }

  & form {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;

    @media screen and (max-width: ${SMALL_DEVICES}) {
      margin-top: 22px;
    }
    input {
      font-size: 16px;

      @media screen and (max-width: ${SMALL_DEVICES}) {
        font-size: 16px;
      }
    }

    ${ButtonContainer} {
      button {
        @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
          font-size: 18.5px;
        }

        @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
          font-size: 17px;
        }

        @media screen and (max-width: ${LARGE_DEVICES}) {
          font-size: 18.5px;
        }

        @media screen and (max-width: ${MEDIUM_DEVICES}) {
          font-size: 17px;
        }
      }
    }
  }
`;
RegisterFormContainer.displayName = 'RegisterFormContainer.styled';

export const RegisterSubheading = styled(Subheader)`
  & button {
    font: inherit;
    font-weight: 500;
    color: inherit;
    border: none;
    background-color: transparent;
    text-decoration: underline;
  }
`;
RegisterSubheading.displayName = 'RegisterSubheading.styled';

export const SparklesIcon = styled.i.attrs({ className: 'fa-sharp fa-regular fa-sparkles' })`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: var(--orange);
  background: rgba(255, 88, 17, 0.1);
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  margin-bottom: 20px;
`;
SparklesIcon.displayName = 'SparklesIcon.styled';
