import styled from 'styled-components';

import { SMALL_DEVICES } from '@skillset/ui';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  text-align: center;
  padding: 18px 0px;
  background-color: var(--white);
  border-radius: 8px;
  border: 2px solid var(--gray);
  color: var(--almost-black);
  font-weight: 600;
  font-family: var(--gilroy-font);
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  height: 44px;

  img {
    height: 18.5px;
    width: 18.5px;
  }
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(204, 204, 204, 0.25);
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    line-height: 20px;
  }
`;
Button.displayName = 'Button.styled';

export const ButtonOrangeFilled = styled(Button)`
  background: var(--orange);
  border: none;
  color: var(--white);
  gap: 8px;
  line-height: 24px;
  outline: none;
  font-weight: 600;
  font-size: 17px;
  & svg {
    height: 24px;
    width: 24px;
    font-weight: 900;

    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 16px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 12px var(--platform-pink-hover);
  }

  &:focus {
    box-shadow: 0px 4px 12px var(--platform-pink-hover);
  }

  &:active {
    box-shadow: none;
  }
`;
ButtonOrangeFilled.displayName = 'ButtonPinkFilled.styled';

export const ButtonPinkOutlined = styled(Button)`
  border: 2px solid var(--platform-pink);
  color: var(--platform-pink);
  outline: none;

  &:hover {
    box-shadow: 0px 4px 12px var(--platform-pink-hover);
  }

  &:focus {
    background: linear-gradient(0deg, var(--platform-pink-focus), var(--platform-pink-focus)), var(--white);
  }
`;
ButtonPinkOutlined.displayName = 'ButtonPinkOutlined.styled';
