import styled, { CSSProperties, css } from 'styled-components';
import { EXTRA_EXTRA_LARGE_DEVICES, SMALL_DEVICES } from '../../../constants/breakpoints';
interface WithBackgroundColor {
  backgroundColor: CSSProperties['backgroundColor'];
}
interface WithBackground {
  background: CSSProperties['background'];
}

export type EitherBackground = WithBackgroundColor | WithBackground;

type FilledBarProps = {
  percentage: `${number}%`;
} & EitherBackground;

interface HorizontalProgressBarElementProps {
  height?: CSSProperties['height'];
  borderRadius?: CSSProperties['borderRadius'];
}
export const HorizontalProgressBarElement = styled.div<HorizontalProgressBarElementProps>`
  display: flex;
  background-color: var(--grey-with-opacity);
  height: ${({ height }) => height ?? '4px'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '2px'};
  @media screen and (max-width: ${SMALL_DEVICES}) {
    width: 143.5px;
  }

  /* @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    width: 120px;
  }
  @media screen and (max-width: 1330px) {
    width: 110px;
  } */
`;
HorizontalProgressBarElement.displayName = 'HorizontalProgressBarElement.styled';

export const FilledBar = styled(HorizontalProgressBarElement)<FilledBarProps>`
  border-radius: inherit;

  ${(props) => {
    if ('background' in props) {
      return css`
         {
          background: ${props.background};
        }
      `;
    } else if ('backgroundColor' in props) {
      return css`
         {
          background-color: ${props.backgroundColor};
        }
      `;
    }
  }}
  width: ${(props) => props.percentage};
  height: 100%;
`;
FilledBar.displayName = 'FilledBar.styled';

interface ProgressBarColumnContainerProps {
  width?: CSSProperties['width'];
}
export const ProgressBarColumnContainer = styled.div<ProgressBarColumnContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
        ${FilledBar} {
          background-size: ${width};
        }
      `;
    } else {
      return css`
        width: 100 %;
      `;
    }
  }}
`;
ProgressBarColumnContainer.displayName = 'ProgressBarColumnContainer.styled';

interface GradeElementProps {
  fontSize?: string;
  fontWeight?: string;
}
export const GradeElement = styled.span<GradeElementProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  align-self: flex-end;
  @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
    font-size: 13px;
  }
`;
GradeElement.displayName = 'GradeElement.styled';
