import { ColumnContainer, NoDataImage, NoDataViewContainer, NoResultsText } from './style';

export const NoDataView = () => {
  return (
    <NoDataViewContainer>
      <ColumnContainer>
        <NoDataImage />
        <NoResultsText>No results to show</NoResultsText>
      </ColumnContainer>
    </NoDataViewContainer>
  );
};
