import styled from 'styled-components';

export const FreeTrialScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 15px;
`;

export const TextElement = styled.span`
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 500;
  color: var(--almost-black);
`;
TextElement.displayName = 'TextElement.styled';
FreeTrialScreenContainer.displayName = 'FreeTrialScreenContainer.styled';
