import { Language } from './general';

export interface Texts {
  verifyYourEmail: string;
  sentVerificationEmail: string;
  expireSoon: string;
  resendEmail: string;
  needToChangeEmailQuestion: string;
  needTo: string;
  createAccount: string;
}

export const confirmEmailTexts: Record<Language, Texts> = {
  en: {
    verifyYourEmail: 'Verify your Email',
    sentVerificationEmail: 'We`ve sent a verification email to:',
    expireSoon: 'It will expire shortly, so verify soon.',
    resendEmail: 'Resend email',
    needToChangeEmailQuestion: 'Need to change your email address?',
    needTo: 'You`ll need to',
    createAccount: 'create a new account',
  },
  es: {
    verifyYourEmail: 'Verifica tu correo electrónico',
    sentVerificationEmail: 'Hemos enviado un correo de verificación a:',
    expireSoon: 'xpirará pronto, así que verifica pronto',
    resendEmail: 'Reenviar correo electrónico',
    needToChangeEmailQuestion: '¿Necesitas cambiar tu dirección de correo electrónico?',
    needTo: 'Necesitarás',
    createAccount: 'crear una nueva cuenta',
  },
  he: {
    verifyYourEmail: 'אשר את הדוא"ל שלך',
    sentVerificationEmail: 'שלחנו אימייל אימות ל:',
    expireSoon: 'זה יפוג בקרוב, אנא אמת בהקדם.',
    resendEmail: 'שלח אימייל שוב',
    needToChangeEmailQuestion: 'צריך לשנות את כתובת הדוא"ל שלך?',
    needTo: 'תצטרך',
    createAccount: 'ליצור חשבון חדש',
  },
  pt: {
    verifyYourEmail: 'Verifique seu e-mail',
    sentVerificationEmail: 'Enviamos um e-mail de verificação para:',
    expireSoon: 'Irá expirar em breve, então verifique logo.',
    resendEmail: 'Reenviar e-mail',
    needToChangeEmailQuestion: 'Precisa mudar seu endereço de e-mail?',
    needTo: 'Você precisará',
    createAccount: 'criar uma nova conta',
  },
};
