import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';
import { Slider } from './Selectors/DualInputRange/style';

const layoutTypes = {
  regular: 'regular',
  allFilters: 'allFilters',
  topBar: 'topBar',
} as const;

export type OneOfLayoutTypes = (typeof layoutTypes)[keyof typeof layoutTypes];

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
`;
RelativeContainer.displayName = 'RelativeContainer.styled';

interface IFieldButtonProps {
  isTopBarFilter: boolean;
  hasDarkBackgrond?: boolean;
}

export const FieldButton = styled.button<IFieldButtonProps>`
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  color: var(--almost-black);
  font-size: 14px;
  border: none;
  border-radius: 6px;
  ${({ isTopBarFilter }) => {
    if (isTopBarFilter) {
      return css`
        background-color: var(--white);
        height: 40px;
        font-weight: 400;
        border-radius: 8px;
        border: 1px solid var(--almost-black);
        background: #fff;
      `;
    } else {
      return css`
        background-color: var(--light-grey);
        font-weight: 600;
        &:hover {
          box-shadow: 0px 0px 0px 1px #cccccc inset;
        }
      `;
    }
  }}

  ${({ hasDarkBackgrond }) => {
    if (hasDarkBackgrond) {
      return css`
        background-color: var(--almost-black);
        color: var(--white);
      `;
    }
  }}

  gap: 8px;
  padding: 5px 10px;
  white-space: nowrap;
`;
FieldButton.displayName = 'FieldButton.styled';

export const ExpansionArrowIconWithDarkBackground = styled(FontAwesomeIcon).attrs({
  color: 'var(--dark-grey)',
})`
  width: 9px;
  height: 10px;
  font-weight: 900;
  color: var(--white);
`;
ExpansionArrowIconWithDarkBackground.displayName = 'ExpansionArrowIcon.styled';

export const ExpansionArrowIcon = styled(FontAwesomeIcon).attrs({
  color: 'var(--dark-grey)',
})`
  width: 9px;
  height: 10px;
  font-weight: 900;
  color: var(--almost-black);
`;
ExpansionArrowIcon.displayName = 'ExpansionArrowIcon.styled';

export const ExpansionTopBarIcon = styled(FontAwesomeIcon).attrs({
  color: 'var(--dark-grey)',
})`
  width: 17px;
  height: 17px;
  font-weight: 900;
  color: var(--almost-black);
`;
ExpansionTopBarIcon.displayName = 'ExpansionTopBarIcon.styled';

interface ISelectorsContainerProps {
  layoutType: OneOfLayoutTypes;
  zIndex: number;
}

export const SelectorsContainer = styled.div<ISelectorsContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 12.5px;
  ${({ layoutType }) => {
    const regularLayout = css`
      position: fixed;
      width: 320px;
      height: 160px;
      box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
      padding: 20px 25px;
      ${Slider} {
        width: 266px;
      }
      .thumb {
        width: 266px;
      }
    `;
    if (layoutType === 'regular') {
      return css`
        ${regularLayout}
        top: 228px;
      `;
    } else if (layoutType == 'topBar') {
      return css`
        ${regularLayout}
        top: 130px;
        right: 130px;
      `;
    } else {
      return css`
        position: relative;
        height: 118px;
        ${Slider} {
          width: 230px;
        }
        .thumb {
          width: 230px;
        }
      `;
    }
  }}
  z-index: ${({ zIndex }) => zIndex};
  background: #ffffff;
  border-radius: 5px;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    position: unset;
    width: 100%;
    height: 112px;
    padding: 10px 30px 20px 30px;
    align-items: center;
    justify-content: center;
    ${Slider} {
      width: calc(100vw - 60px);
    }
    .thumb {
      width: calc(100vw - 60px);
    }
  }
`;
SelectorsContainer.displayName = 'SelectorsContainer.styled';

export const TitleElement = styled.span`
  font-weight: 700;
  font-size: 14px;
`;
TitleElement.displayName = 'TitleElement.styled';

export const StateSectionWrapper = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  .state-filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .all-states {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    height: 120px;
    gap: 17px;
  }
`;
StateSectionWrapper.displayName = 'StateSectionWrapper.styled';

export const StateLabel = styled.span`
  font-family: var(--primary-font);
  color: var(--almost-black);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;
StateLabel.displayName = 'StateLabel.styled';

export const CustomCheckbox = styled.div`
  width: 25px;
  height: 25px;
  border: 2px solid var(--almost-black);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border: 2px solid var(--tatio-pink);
  }
  &.selected {
    background: var(--tatio-pink);
    color: var(--white);
    border: none;
  }
`;
CustomCheckbox.displayName = 'CustomCheckbox.styled';
