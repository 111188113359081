import styled from 'styled-components';

/* The switch - the box around the slider */

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 23px;
  height: 13px;
  right: 2px;
`;
SwitchLabel.displayName = 'SwitchLabel.styled';

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: 0.4s;
  border-radius: 70px;
  outline: 2px solid var(--grey);
  &:before {
    top: 2.5px;
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 3px;
    background-color: var(--grey);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
Slider.displayName = 'Slider.styled';

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
    ~ ${Slider} {
      outline: 2px solid var(--almost-black);
      &:before {
        background-color: var(--almost-black);
        -webkit-transform: translateX(9px);
        -ms-transform: translateX(9px);
        transform: translateX(9px);
      }
    }
  }
  &:focus {
    ~ ${Slider} {
      box-shadow: 0 0 1px #2196f3;
    }
  }
`;
CheckboxInput.displayName = 'CheckboxInput.styled';

export const TitleAndCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
`;
TitleAndCheckboxWrapper.displayName = 'TitleAndCheckboxWrapper.styled';

export const TitleElement = styled.span`
  font-weight: 700;
  font-size: 14px;
`;
TitleElement.displayName = 'TitleElement.styled';
