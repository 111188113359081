import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import { signInWithGoogle } from '../../store/actions/auth.action';
import { LoadingScreen } from '@skillset/ui';

export const LoginWithGoogle: React.FC<{ path: string; platformName: string; desiredUrl?: string }> = ({
  path,
  platformName,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(signInWithGoogle({ navigate, errorRoute: '/login', successRoute: path, platformName }));
  }, []);

  return <LoadingScreen blockBodyOverflow={false} text="authenticating with google" />;
};
