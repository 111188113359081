import React from 'react';
import { ProgressBar, ProgressBarSection } from './style';

interface IPasswordProgressBarProps {
  conditionsLength: number;
}

interface ISmartProgressBarProps {
  propcolor: string;
  howMuchWithColor: number;
}
const SmartProgressBar: React.FC<ISmartProgressBarProps> = ({ propcolor, howMuchWithColor }) => {
  const barsColor = Array(howMuchWithColor)
    .fill(propcolor)
    .concat(Array(4 - howMuchWithColor).fill('white'));
  return (
    <>
      {barsColor.map((color, index) => (
        <ProgressBar key={index} background={color} />
      ))}
    </>
  );
};

export const PasswordProgressBar: React.FC<IPasswordProgressBarProps> = ({ conditionsLength }) => {
  const arr = [1, 2, 3, 4];

  const progressBarToRender = arr.map((ele) => {
    return <ProgressBar key={ele} />;
  });
  if (conditionsLength === 1) {
    return (
      <ProgressBarSection>
        <SmartProgressBar howMuchWithColor={1} propcolor="#FF6600" />
      </ProgressBarSection>
    );
  }
  if (conditionsLength === 2) {
    return (
      <ProgressBarSection>
        <SmartProgressBar howMuchWithColor={2} propcolor="var(--light-blue)" />
      </ProgressBarSection>
    );
  }
  if (conditionsLength > 2) {
    return (
      <ProgressBarSection>
        <SmartProgressBar howMuchWithColor={4} propcolor="var(--tatio-green)" />
      </ProgressBarSection>
    );
  }
  return <ProgressBarSection>{progressBarToRender}</ProgressBarSection>;
};
