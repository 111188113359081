import styled from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';
import { TableAndFiltersWrapper } from '../../reusable-elements/TableAndFiltersWrapper';

export const PageHeaderElement = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 20px 41px 15px 30px;
  max-height: 116px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding: 16px 0px 10px 14px;
    color: white;
  }
`;
PageHeaderElement.displayName = 'PageHeaderElement.styled';

export const PageMainTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  color: var(--almost-black);
  line-height: 150%;

  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 22px;
  }
`;
PageMainTitle.displayName = 'PageMainTitle.styled';

export const PageSubtitle = styled.h2`
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    font-size: 14px;
  }
`;

PageSubtitle.displayName = 'PageSubtitle.styled';

export const TitlesColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
TitlesColumnContainer.displayName = 'TitlesColumnContainer.styled';

export const OuterRelativeWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: var(--light-white);
  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding-right: 16px;
  }
  padding-bottom: 3px;
  ${TableAndFiltersWrapper} {
    width: 100%;
  }
`;
OuterRelativeWrapper.displayName = 'OuterRelativeWrapper.styled';
