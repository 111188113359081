import styled from 'styled-components';

export const MenuWithIconsWrapper = styled.menu`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 5px;
  padding: 15px;
`;
MenuWithIconsWrapper.displayName = 'MenuWithIconsWrapper.styled';

export const MenuListElement = styled.ul`
  list-style: none;
  font-family: var(--primary-font);
  margin: 0;
  cursor: pointer;
  background-color: white;
  width: 100%;
`;
MenuListElement.displayName = 'MenuListElement.styled';
