import { Language } from './general';

export interface Texts {
  weakPassword: string;
  averagePassword: string;
  strongPassword: string;
  mustBe8Chars: string;
  upperAndLowerCase: string;
  oneOrMoreNumeric: string;
  symbol: string;
  atLeat8Chars: string;
}

export const passwordHintTexts: Record<Language, Texts> = {
  en: {
    weakPassword: 'Weak Password',
    averagePassword: 'Average Password',
    strongPassword: 'Strong Password',
    mustBe8Chars: 'Must be at least 8 characters',
    upperAndLowerCase: 'Use both upper & lower case letters',
    oneOrMoreNumeric: 'One or more numerical digits',
    symbol: 'A symbol',
    atLeat8Chars: 'At least 8 characters',
  },
  es: {
    weakPassword: 'Contraseña debil',
    averagePassword: 'Contraseña promedio',
    strongPassword: 'Contraseña segura',
    mustBe8Chars: 'Debe tener al menos 8 caracteres',
    upperAndLowerCase: 'Utilizar letras mayúsculas y minúsculas',
    oneOrMoreNumeric: 'Uno o más dígitos numéricos',
    symbol: 'Un símbolo',
    atLeat8Chars: 'Al menos 8 carácteres',
  },
  he: {
    weakPassword: 'ססמה חלשה',
    averagePassword: 'סיסמה ממוצעת',
    strongPassword: 'סיסמא חזקה',
    mustBe8Chars: 'חייב להיות לפחות 8 תווים',
    upperAndLowerCase: 'השתמש גם באותיות גדולות וגם באותיות קטנות',
    oneOrMoreNumeric: 'ספרה מספרית אחת או יותר',
    symbol: 'תווים מיוחדים',
    atLeat8Chars: 'לפחות 8 תווים',
  },
  pt: {
    weakPassword: 'Senha fraca',
    averagePassword: 'Senha média',
    strongPassword: 'Senha forte',
    mustBe8Chars: 'Deve ter pelo menos 8 caracteres',
    upperAndLowerCase: 'Use letras maiúsculas e minúsculas',
    oneOrMoreNumeric: 'Um ou mais dígitos numéricos',
    symbol: 'Um símbolo',
    atLeat8Chars: 'Pelo menos 8 caracteres',
  },
};
