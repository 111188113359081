export const ROUTE_LOGIN = '/login';
export const ADMIN_ROUTE = '/administration';

import { faBriefcase, faGear, faUser, faUserVneck, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
export type ValidCategoryRoute = `/${string}` | '#';
export const atsIntegratedNavigationCategories: NavigationCategoryItem[] = [
  {
    isExpanded: true,
    mobileIsExpanded: false,
    iconClass: faBriefcase,
    iconColor: 'var(--yellow)',
    text: 'Jobs',
    items: [
      {
        text: 'Jobs',
        route: '/jobs',
      },
    ],
  },
];

export const noAtsNavigationCategories: NavigationCategoryItem[] = [
  {
    isExpanded: true,
    mobileIsExpanded: false,
    iconClass: faUser,
    iconColor: 'var(--tatio-pink)',
    text: 'Candidates',
    items: [
      {
        text: 'Qualified Candidates',
        route: '/candidates/simulation',
      },
    ],
  },
];

export const customersNavigationCategories: NavigationCategoryItem[] = [
  {
    isExpanded: false,
    mobileIsExpanded: false,
    iconClass: faBriefcase,
    iconColor: 'var(--yellow)',
    text: 'Customers',
    items: [
      {
        text: 'Customers',
        route: '/customers',
      },
    ],
  },
  {
    isExpanded: false,
    mobileIsExpanded: false,
    iconClass: faUser,
    iconColor: 'var(--orange)',
    text: 'Candidates',
    items: [
      {
        text: 'Qualified Candidates',
        route: '/candidates/simulation',
      },
    ],
  },
];

export const candidatesNavigationCategories: NavigationCategoryItem[] = [
  {
    isExpanded: false,
    mobileIsExpanded: false,
    iconClass: faUser,
    iconColor: 'var(--orange)',
    text: 'Candidates',
    items: [
      {
        text: 'Qualified Candidates',
        route: '/candidates/simulation',
      },
    ],
  },
];

export const adminstartionPageCategories: NavigationCategoryItem[] = [
  {
    isExpanded: false,
    mobileIsExpanded: false,
    iconClass: faUserVneck,
    iconColor: 'var(--orange)',
    text: 'Users',
    items: [
      {
        text: 'Users',
        route: '/administration',
      },
    ],
  },
  {
    isExpanded: false,
    mobileIsExpanded: false,
    iconClass: faGear,
    iconColor: 'white',
    text: 'Settings',
    items: [
      {
        text: 'Settings',
        route: '/administration/settings',
      },
    ],
  },
];

interface NavigationRouteItem {
  text: string;
  route: ValidCategoryRoute;
  notClickable?: boolean;
}

export interface NavigationCategoryItem {
  isExpanded: boolean;
  mobileIsExpanded: boolean;
  iconClass: IconDefinition;
  iconColor: string;
  text: string;
  items: NavigationRouteItem[];
  isComingSoon?: boolean;
}

export const authRoutes = ['/login', '/accounts/signup', '/accounts/signup/', '/accounts/recover', '/terms-of-use'];

export const sideMenuStartsWithRoutes = ['/candidates', '/administration', '/jobs', '/customers'];
