import styled from 'styled-components';
import { LogoContainerElement } from '../LogoContainer/style';

export const SideMenuContainer = styled.nav`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: var(--menu-dark-blue);
  height: 100%;
  padding: 40px 30px;
  color: white;
  width: 323px;
  z-index: 999999;
  @media screen {
    width: 243px;
    padding: 35px 20px;
    ${LogoContainerElement} {
      align-self: center;
      img {
        height: 40px;
        width: 40px;
      }
    }
    max-width: 70px;
    padding: 35px 15px 27px 15px;
  }
`;

SideMenuContainer.displayName = 'SideMenuContainer.styled';

export const NavigationList = styled.ul`
  list-style-type: none;
`;

NavigationList.displayName = 'NavigationList.styled';
