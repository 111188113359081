import React, { useState, CSSProperties } from 'react';
import { getBrandColorByString } from '@skillset/onelogin';
import { MenuWithIcons, PrimaryButton, MobilePopup, AvatarElement } from '@skillset/ui';
import { zIndices } from '../../../assets/zIndices';
import { useAnimatedSwipeY } from '../../../hooks/useAnimatedSwipeY';
import { useOverflowDetection } from '../../../hooks/useOverflowDetection';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { formatDate, getFirstNameFirstLetter } from '../../../utils';

import {
  ClockIconElement,
  DateTextElement,
  DetailsAndControlsContainer,
  NameTitleElement,
  NoteContentContainer,
  NoteContentElement,
  NotePreviewContainer,
  OptionsIconButton,
  RelativeContainer,
} from './style';

import { useGetNoteActions } from '../utils';
import { INote, INoteToCreateOrEdit, NotesIdentifier } from '../types';
import { INITIAL_NOTE } from '../constants';
import { CandidateService } from '../../../services/candidatesService';

const desktopMenuOptionsStyle: CSSProperties = {
  position: 'absolute',
  right: '0px',
  top: '25px',
  zIndex: zIndices.mobilePopup,
  width: '220px',
};
const mobileMenuOptionsStyle: CSSProperties = {
  position: 'relative',
  zIndex: zIndices.mobilePopup,
  width: '100%',
};

const showMoreLessStyling: CSSProperties = {
  background: 'white',
  color: 'var(--brand-pink)',
  fontSize: '10px',
  fontWeight: '500',
};
interface INotePreview extends INote {
  identifier: NotesIdentifier;
  noteToCreateOrEdit: INoteToCreateOrEdit;
  setNoteToEdit: (id: number | null, note: string) => void;
}
export const NotePreview: React.FC<INotePreview> = ({
  id,
  authorFirstName,
  authorLastName,
  createdAt,
  note,
  isPrivileged,
  identifier,
  noteToCreateOrEdit,
  setNoteToEdit,
}) => {
  const [optionsMenuDisplay, setOptionsMenuDisplay] = useState(false);
  const { deleteNote } = useGetNoteActions(identifier);

  const fullName = `${authorFirstName} ${authorLastName}`;
  const dateToRender = formatDate(new Date(createdAt));
  const firstNameFirstLetter = getFirstNameFirstLetter(authorFirstName);
  const { refCallback, isOverflowing } = useOverflowDetection<HTMLSpanElement>('vertical');
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const [isOverflowedTextHidden, setIsOverflowedTextHidden] = useState(true);
  const toggleOverflowedTextDisplay = () => setIsOverflowedTextHidden((prevState) => !prevState);
  const { open, ...restOfAnimatedProps } = useAnimatedSwipeY(260);

  const closeOptionsMenuDisplay = () => {
    if (!isMobile) {
      setOptionsMenuDisplay(false);
    } else {
      restOfAnimatedProps.close();
    }
  };

  const handleOpenOptionsMenu = () => {
    if (!isPrivileged) return;
    if (!isMobile) {
      setOptionsMenuDisplay((prevState) => !prevState);
    } else {
      open({ canceled: false });
    }
  };
  const dispatchDeleteSimNote = () => {
    if (!isPrivileged) return;
    if (typeof id !== 'number' && !CandidateService.isSampleNoteId(id)) return;
    if (id === noteToCreateOrEdit.id) {
      setNoteToEdit(INITIAL_NOTE.id, INITIAL_NOTE.note);
    }
    deleteNote(id as number /*patch to allow `sample-${number}` kind of ids*/);
  };

  const menuOptionsConfig = [
    {
      title: 'Edit Note',
      iconClassName: 'fa-regular fa-pencil',
      onClick: () => isPrivileged && setNoteToEdit(id as number, note),
    },
    {
      title: 'Delete Note',
      iconClassName: 'fa-regular fa-trash-can',
      onClick: () => dispatchDeleteSimNote(),
    },
  ];

  const renderMenu = (
    <MenuWithIcons
      zIndexBackdrop={zIndices.backdrop}
      closeMenu={closeOptionsMenuDisplay}
      menuOptions={menuOptionsConfig}
      style={isMobile ? mobileMenuOptionsStyle : desktopMenuOptionsStyle}
    />
  );

  const renderMenuAccordingToScreens = () => {
    if (!isMobile) {
      if (optionsMenuDisplay) {
        return renderMenu;
      } else {
        return null;
      }
    } else {
      return (
        <MobilePopup
          zIndexBackdropLayer={zIndices.backdrop}
          zIndexPopup={zIndices.mobilePopup}
          {...restOfAnimatedProps}
        >
          {renderMenu}
        </MobilePopup>
      );
    }
  };

  return (
    <RelativeContainer>
      <NotePreviewContainer>
        <DetailsAndControlsContainer>
          <AvatarElement $beforeContent={firstNameFirstLetter} backgroundColor={getBrandColorByString(fullName)} />
          <NameTitleElement>{fullName}</NameTitleElement>
          <DateTextElement>{dateToRender}</DateTextElement>
          <ClockIconElement />
          <OptionsIconButton onClick={handleOpenOptionsMenu} />
        </DetailsAndControlsContainer>
        <NoteContentContainer>
          <NoteContentElement ref={refCallback} isOverflowedTextHidden={isMobile ? false : isOverflowedTextHidden}>
            {note}
          </NoteContentElement>
        </NoteContentContainer>
        {!isMobile && (isOverflowing || !isOverflowedTextHidden) && (
          <PrimaryButton
            style={showMoreLessStyling}
            className="no-shadow"
            text={isOverflowedTextHidden ? 'Show more' : 'Show less'}
            onClickHandler={toggleOverflowedTextDisplay}
          />
        )}
      </NotePreviewContainer>
      {renderMenuAccordingToScreens()}
    </RelativeContainer>
  );
};
