import React from 'react';
import { EmailAddressContainer, EmailAddressTextElement, IconContainer, RemoveIconButton } from './style';

interface EmailAddressItemProps {
  email: string;
  onRemoveClick?: () => void;
}

export const EmailAddressItem: React.FC<EmailAddressItemProps> = ({ email, onRemoveClick }) => {
  return (
    <EmailAddressContainer onClick={(ev) => ev.stopPropagation()}>
      <EmailAddressTextElement>{email}</EmailAddressTextElement>
      <IconContainer>
        <RemoveIconButton onClick={onRemoveClick} />
      </IconContainer>
    </EmailAddressContainer>
  );
};
