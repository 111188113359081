import React from 'react';
import { FreeTrialScreenContainer, TextElement } from './style';
import { PrimaryButton, NoDataView } from '@skillset/ui';
import { faStars } from '@fortawesome/pro-regular-svg-icons';
import { SpinnerElement } from '@skillset/ui';
interface JobsTableNoDataProps {
  shouldRenderFreeTrialScreen: boolean;
  loadSampleData: () => void;
  loading: boolean;
}

export const JobsTableNoData: React.FC<JobsTableNoDataProps> = ({
  shouldRenderFreeTrialScreen,
  loadSampleData,
  loading,
}) => {
  const buttonStyle = { display: 'flex', gap: '8px', fontSize: '14px' };

  if (shouldRenderFreeTrialScreen) {
    return (
      <FreeTrialScreenContainer>
        <PrimaryButton
          iconFirst={true}
          icon={faStars}
          text="Load sample data"
          onClickHandler={loadSampleData}
          style={buttonStyle}
          backgroundColor="var(--almost-black)"
        />
        <TextElement>
          Currently, there is no data available for display. Feel free to explore the product using our sample data
        </TextElement>
      </FreeTrialScreenContainer>
    );
  } else if (!loading) {
    return <NoDataView />;
  } else {
    return (
      <FreeTrialScreenContainer>
        <SpinnerElement />
      </FreeTrialScreenContainer>
    );
  }
};
