import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { BLUE_HEADER_HEIGHT } from '../../../constants';
import { ResettedButton } from '../../../reusable-elements/ResettedButton';
import { VerticallyScrollableContainer } from '../MobilePopup/style';

export const MobileFullScreenPopupElement = styled(animated.div)<{ zIndex: number }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex};
  height: calc(100% - ${BLUE_HEADER_HEIGHT});
  inset: ${BLUE_HEADER_HEIGHT} 0 0;
  border-radius: 8px 8px 0px 0px;
  touch-action: pan-y;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    width: 100%;
    border-bottom: 1px solid var(--border-light-grey);
    font-weight: 700;
    padding: 12.5px 21px 12.5px 30px;
    justify-content: space-between;
  }
`;
MobileFullScreenPopupElement.displayName = 'MobileFullScreenPopupElement.styled';

export const ClosePopupButton = styled(ResettedButton)`
  font-family: var(--primary-font);
  font-style: normal;
  font-size: 16px;
  color: var(--almost-black);
`;
ClosePopupButton.displayName = 'ClosePopupButton.styled';

interface IFullScreenVerticalContainer {
  scrollable: boolean;
}

export const FullScreenVerticalContainer = styled(VerticallyScrollableContainer)<IFullScreenVerticalContainer>`
  border-top: none;
  height: 100%;
  overflow-y: ${({ scrollable }) => (scrollable ? 'auto' : 'visible')};
`;
VerticallyScrollableContainer.displayName = 'VerticallyScrollableContainer.styled';

export const ArrowRightIcon = styled.i.attrs({ className: 'fa-regular fa-arrow-right' })``;
ArrowRightIcon.displayName = 'ArrowRightIcon.styled';
