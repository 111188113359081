import styled from 'styled-components';

export const HeaderAndPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 100%;
`;
HeaderAndPageWrapper.displayName = 'HeaderAndPageWrapper.styled';
