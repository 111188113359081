import { useNavigate } from 'react-router-dom';

import { ROUTE_LOGIN } from '../../constants/routes';

import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { ImageContainer } from '../../components/Reusable-styled-elements/ImageContainer';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { ResetSuccessContainer, ResetSuccessInnerContainer } from './style';
import { Language } from '../../utils/languages/general';
import { getResetPasswordTexts } from '../../services/languages.service';

export const ResetSuccess: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  const navigate = useNavigate();
  const navigateToLogin = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });

  const texts = getResetPasswordTexts(registrationLanguage);
  return (
    <ResetSuccessContainer>
      <LogoContainerWrapper className="logoContainerOverride" />

      <ResetSuccessInnerContainer>
        <ImageContainer src="https://shared-assets.tatio.io/images/success-check.gif" />
        <Heading>{texts.success}</Heading>
        <Subheader>{texts.passwordResetMessage}</Subheader>

        <ButtonOrangeFilled onClick={navigateToLogin}>{texts.loginToYourAccount}</ButtonOrangeFilled>
      </ResetSuccessInnerContainer>
    </ResetSuccessContainer>
  );
};
