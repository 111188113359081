import { combineReducers } from '@reduxjs/toolkit';
import { authSliceReducer } from '@skillset/onelogin';
import { employersSimulationSliceReducer } from './employersSimulationSlice';
import { employersAdministrationSliceReducer } from './employersAdministrationSlice';
import { employersBusinessSliceReducer } from './employerBusinessSlice';
import { employerCandidatesSliceReducer } from './employerCandidatesSlice';
import { employerCustomersSliceReducer } from './employerCustomersSlice';
export const rootReducer = combineReducers({
  authSliceReducer,
  employersBusinessSliceReducer,
  employersSimulationSliceReducer,
  employersAdministrationSliceReducer,
  employerCandidatesSliceReducer,
  employerCustomersSliceReducer,
});
