import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { ROUTE_LOGIN } from '../../constants/routes';

import { OnBoardingWelcomeContainer } from './style';
import { QuotesBlob } from '../../components/QuotesBlob/QuotesBlob';
import { BusinessSideMenu } from '../../components/RegistrationSideMenu/BusinessSideMenu';
import { TextTemplate, textTemplates } from '../../utils/types';
import { CandidateWelcome } from './CandidateWelcome';
import { CandidateSideMenu } from '../../components/RegistrationSideMenu/CandidateSideMenu';
import { Language } from '../../utils/languages/general';

interface IWelcomeProps {
  textTemplate: TextTemplate;
  isBusinessUser?: boolean;
  style?: CSSProperties;
  registrationLanguage?: Language;
}

export const Welcome: React.FC<IWelcomeProps> = ({ textTemplate, isBusinessUser, style, registrationLanguage }) => {
  const navigate = useNavigate();
  const navigateToLogin = () => navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
  const sideMenu = isBusinessUser ? (
    <BusinessSideMenu textTemplate={textTemplate} />
  ) : (
    <CandidateSideMenu registrationLanguage={registrationLanguage} />
  );
  const topElement =
    (textTemplate === textTemplates.LOGIN || textTemplate === textTemplates.SIGNUP) && !isBusinessUser ? (
      <CandidateWelcome registrationLanguage={registrationLanguage}></CandidateWelcome>
    ) : null;

  const bottomElement = textTemplate === textTemplates.LOGIN && isBusinessUser ? <QuotesBlob /> : null;
  const shouldRenderSideMenu = textTemplate !== textTemplates.LOGIN;

  return (
    <OnBoardingWelcomeContainer style={style} textTemplate={textTemplate} isBusinessUser={isBusinessUser}>
      <LogoContainerWrapper clickHandler={navigateToLogin} />
      {topElement}
      {shouldRenderSideMenu && sideMenu}
      {bottomElement}
    </OnBoardingWelcomeContainer>
  );
};
