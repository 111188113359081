import styled from 'styled-components';

export const PasswordHintWrapper = styled.div`
  width: 274px;
  height: 115px;
  border-radius: 4px;
  background: #00004f;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 95%;
  z-index: 10;
  justify-content: space-between;

  [dir='rtl'] & {
    text-align: right;
    ul {
      align-items: flex-start;
    }
    li {
      justify-content: flex-start;
    }
    .tip {
      left: auto;
      right: 50%;
      transform: translate(50%, 20%) rotate(-135deg);
    }
  }

  span {
    font-family: var(--gilroy-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--white);
  }

  li {
    font-family: var(--gilroy-font);
    color: var(--white);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10.5px;

    svg {
      font-size: 10px;
    }
  }

  ul {
    padding-inline-start: 4px;
    margin-block-start: unset;
    margin-block-end: unset;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
  }

  .tip {
    z-index: -1;
    width: 20px;
    height: 20px;
    background: #00004f;
    position: absolute;
    border-radius: 5px;
    bottom: 90%;
    left: 50%;
    transform: translate(-50%, 20%) rotate(-135deg);
  }
`;

PasswordHintWrapper.displayName = 'PasswordHintWrapper.styled';

export const ProgressBarSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
ProgressBarSection.displayName = 'ProgressBarSection.styled';

export const ProgressBar = styled.div<{ background?: string }>`
  width: 60.5px;
  height: 1.75px;
  background: ${(props) => (props.background ? props.background : 'var(--white)')};
  border-radius: 2px;
`;
ProgressBar.displayName = 'ProgressBar.styled';

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background: var(--white);
  border-radius: 50%;
  margin-left: 5px;
`;
Dot.displayName = 'Dot.styled';
