import { MenuItemWrapper, StandardSectionWrapper } from '../../components/RegistrationSideMenu/style';
import { getLoginTexts } from '../../services/languages.service';
import { Language } from '../../utils/languages/general';

export const CandidateWelcome: React.FC<{
  registrationLanguage?: Language;
  alignCenter?: boolean;
  titleSize?: string;
}> = ({ alignCenter, titleSize, registrationLanguage }) => {
  const alignSelf = alignCenter ? 'center' : 'self-start';
  const textAlign = alignCenter ? 'center' : 'start';
  const texts = getLoginTexts(registrationLanguage);
  return (
    <MenuItemWrapper titleSize={titleSize}>
      <StandardSectionWrapper>
        <section className="title" style={{ alignSelf }}>
          {texts.welcome}
        </section>
        <section className="description" style={{ alignSelf, textAlign }}>
          {texts.welcomeMessage}
        </section>
      </StandardSectionWrapper>
    </MenuItemWrapper>
  );
};
