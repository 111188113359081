import { CSSProperties } from 'styled-components';
import { OneOfLayoutTypes } from '../../../style';
import { CheckboxItem, CustomizedCheckboxContainer } from '../style';

export interface CustomizedCheckboxOption {
  name: string;
  value: string;
  category: string;
}

export interface CustomizedCheckBoxProps {
  options: CustomizedCheckboxOption[];
  updateCheckboxField: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked: (category: string, value: string) => boolean;
  layoutType?: OneOfLayoutTypes;
  style?: CSSProperties;
}

export const CustomizedCheckbox: React.FC<CustomizedCheckBoxProps> = ({
  options,
  layoutType,
  style,
  updateCheckboxField,
  isCheckboxOptionChecked,
}) => {
  const renderCheckboxOptions = options.map(({ name, value, category }) => {
    return (
      <CheckboxItem key={name}>
        <input
          type="checkbox"
          name={name}
          value={value}
          id={name}
          onChange={({ target }) => updateCheckboxField(category, value, target.checked)}
          checked={isCheckboxOptionChecked(category, value)}
        />
        <label htmlFor={name}>{name}</label>
      </CheckboxItem>
    );
  });
  return (
    <CustomizedCheckboxContainer layoutType={layoutType} style={style}>
      {renderCheckboxOptions}
    </CustomizedCheckboxContainer>
  );
};
