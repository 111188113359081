import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import { PrimaryButtonElement } from './styles';

interface IPrimaryButtonBaseProps {
  text?: string;
  style?: CSSProperties;
  icon?: IconDefinition;
  iconFirst?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
  color?: CSSProperties['color'];
  className?: string;
}
type ButtonPropsByType =
  | { as: 'a'; target: '_blank'; href: string }
  | {
      id: string | undefined;
      onClick: () => void;
      disabled: boolean;
      type: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    }
  | { id: string | undefined; type: 'submit'; disabled: boolean };

interface IPrimaryButtonAsHref extends IPrimaryButtonBaseProps {
  href: string;
}

interface IPrimaryButtonAsButton extends IPrimaryButtonBaseProps {
  onClickHandler: () => void;
  id?: string;
  disabled?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

interface IPrimaryButtonAsSubmit extends IPrimaryButtonBaseProps {
  type: 'submit';
  id?: string;
  disabled?: boolean;
}

type IPrimaryButtonProps = IPrimaryButtonAsButton | IPrimaryButtonAsHref | IPrimaryButtonAsSubmit;

/**
 * Default Color: Platform-Pink
 *```tsx
 *      <PrimaryButton
 *      onClickHandler={()=>{}}
 *      iconFirst
 *      text="Meet the team"
 *      icon={faCalendar}
 *      style={{ display: 'flex', gap: '9px' }}
 *    />
 * ```
 *```tsx
 *      <PrimaryButton
 *      href={SALES_CALENDLY_LINK}
 *      iconFirst
 *      text="Meet the team"
 *      icon={faCalendar}
 *      style={{ display: 'flex', gap: '9px' }}
 *    />
 * ```
 */
export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({
  text,
  style,
  backgroundColor,
  color,
  icon,
  iconFirst = false,
  className,
  ...restOfProps
}) => {
  let buttonPropsByType: ButtonPropsByType;

  if ('href' in restOfProps) {
    const { href } = restOfProps;
    buttonPropsByType = { as: 'a', href, target: '_blank' };
  } else if ('onClickHandler' in restOfProps) {
    const { id, onClickHandler, disabled, type } = restOfProps;
    buttonPropsByType = { onClick: onClickHandler, disabled: !!disabled, id, type };
  } else {
    const { id, type, disabled } = restOfProps;
    buttonPropsByType = { type: type, disabled: !!disabled, id };
  }

  return (
    <PrimaryButtonElement
      {...buttonPropsByType}
      style={style}
      backgroundColor={backgroundColor}
      className={className}
      color={color}
    >
      {icon && iconFirst && <FontAwesomeIcon icon={icon} />}
      {text}
      {!iconFirst && icon && <FontAwesomeIcon icon={icon} />}
    </PrimaryButtonElement>
  );
};
