import styled from 'styled-components';
import { ResettedButton } from '../../reusable-elements/ResettedButton';

interface IStyledUserFile {
  disabled: boolean;
}

export const StyledUserFileButton = styled(ResettedButton)<IStyledUserFile>`
  :active {
    .title {
      color: var(--tatio-pink);
    }
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  .title {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${({ disabled }) => (disabled ? 'var(--grey)' : 'var(--almost-black)')};
    left: 17px;
    position: relative;
  }

  .icon {
    font-size: 14px;
    color: ${({ disabled }) => (disabled ? 'var(--grey)' : 'var(--almost-black)')};
  }
`;

StyledUserFileButton.displayName = 'StyledUserFileButton.styled';

export const StyledUserFilesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px 20px 30px;
  gap: 21px;
  a {
    text-decoration: none;
    color: inherit;
    cursor: default;
  }
`;
StyledUserFilesList.displayName = 'StyledUserFilesList.styled';

export const PerformancePDFIcon = styled.i.attrs({ className: 'fa-regular  fa-chart-bar' })`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--almost-black);
`;
PerformancePDFIcon.displayName = 'PerformancePDFIcon.styled';

export const ResumeFileIcon = styled.i.attrs({ className: 'fa-regular  fa-page' })`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--almost-black);
`;
ResumeFileIcon.displayName = 'ResumeFileIcon.styled';
