import styled from 'styled-components';
import { SMALL_DEVICES } from '../../constants/breakpoints';

export const PageWithTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--light-white);
  padding: 0px 0px 20px 20px;
  @media screen and (max-width: ${SMALL_DEVICES}) {
    padding: 0px 0px 16px 16px;
  }
`;
PageWithTableContainer.displayName = 'PageWithTableContainer.styled';

export const PageHeaderControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
PageHeaderControlsContainer.displayName = 'PageHeaderControlsContainer.styled';
