import React from 'react';
import { RadioOptionComponent } from './RadioOptionComponent';
import { RadioOptionsList } from './style';
import { RadioOption } from '../../types';

export interface RadioButtonOptionsAndSetter {
  setRadioOption: (option: RadioOption) => void;
  radioOptions: RadioOption[];
  selectedOption: string | boolean | number;
}
interface CustomizedRadioButtonProps extends RadioButtonOptionsAndSetter {
  close: () => void;
}

export const CustomizedRadioButton: React.FC<CustomizedRadioButtonProps> = ({
  radioOptions,
  selectedOption,
  setRadioOption,
  close,
}) => {
  const renderRadioOptions = radioOptions.map((radioOption) => (
    <RadioOptionComponent
      key={radioOption.name + radioOption.value}
      radioOption={radioOption}
      isSelected={radioOption.value === selectedOption}
      setRadioOption={setRadioOption}
      close={close}
    />
  ));

  return <RadioOptionsList>{renderRadioOptions}</RadioOptionsList>;
};
