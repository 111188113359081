import { Language } from './general';

export interface Texts {
  setupYourAccount: string;
  provideEmail: string;
  email: string;
  fillValidEmailError: string;
  password: string;
  fillValidPasswordError: string;
  confirmPassword: string;
  success: string;
  termsOfUsePart1: string;
  termsOfUsePart2: string;
  termsOfUsePart3: string;
  termsOfUsePart4: string;
  alreadyHaveAccount: string;
  confirmEmailLoaderMessage: string;
  createYourAccount: string;
  login: string;
  passwordMatch: string;
}

export const registrationTexts: Record<Language, Texts> = {
  en: {
    setupYourAccount: 'Let’s set up your account',
    provideEmail: 'Provide your email and create a password',
    email: 'Email',
    fillValidEmailError: 'Please fill a valid email',
    password: 'Password',
    fillValidPasswordError: 'Please fill a valid password',
    confirmPassword: 'Confirm Password',
    success: 'Success',
    termsOfUsePart1: 'By proceeding, you agree to our',
    termsOfUsePart2: 'Terms and conditions',
    termsOfUsePart3: '. and to our',
    termsOfUsePart4: 'Privacy Policy',
    alreadyHaveAccount: 'Already have an account?',
    confirmEmailLoaderMessage: 'Confirming your account...',
    createYourAccount: 'Create your account',
    login: 'Login',
    passwordMatch: 'Please make sure your passwords match!',
  },
  es: {
    setupYourAccount: 'Vamos a configurar tu cuenta',
    provideEmail: 'Proporciona tu correo electrónico y crea una contraseña',
    email: 'Correo electrónico',
    fillValidEmailError: 'Por favor, ingresa un correo electrónico válido',
    password: 'Contraseña',
    fillValidPasswordError: 'Por favor, ingresa una contraseña válida',
    confirmPassword: 'Confirmar contraseña',
    success: 'Éxito',
    termsOfUsePart1: 'Al continuar, aceptas nuestros',
    termsOfUsePart2: 'Términos y condiciones',
    termsOfUsePart3: ' y nuestra',
    termsOfUsePart4: 'Política de Privacidad',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    confirmEmailLoaderMessage: 'Confirmando tu cuenta...',
    createYourAccount: 'Crea tu cuenta',
    login: 'Iniciar sesión',
    passwordMatch: '¡Por favor, asegúrese de que sus contraseñas coinciden!',
  },
  he: {
    setupYourAccount: 'בוא נקים את החשבון שלך',
    provideEmail: 'ספק את כתובת הדוא"ל שלך וצור סיסמה',
    email: 'דוא"ל',
    fillValidEmailError: 'אנא מלא כתובת דוא"ל חוקית',
    password: 'סיסמה',
    fillValidPasswordError: 'אנא מלא סיסמה חוקית',
    confirmPassword: 'אשר סיסמה',
    success: 'הצלחה',
    termsOfUsePart1: 'בהמשך הפעולה, אתה מסכים ל-',
    termsOfUsePart2: 'תנאים והגבלות ',
    termsOfUsePart3: 'שלנו, ול ',
    termsOfUsePart4: 'מדיניות הפרטיות',
    alreadyHaveAccount: 'כבר יש לך חשבון?',
    confirmEmailLoaderMessage: 'מאשרים את החשבון...',
    createYourAccount: 'צור חשבון',
    login: 'כניסה',
    passwordMatch: '!אנא ודא שהסיסמאות שלך תואמות',
  },
  pt: {
    setupYourAccount: 'Vamos configurar sua conta',
    provideEmail: 'Forneça seu e-mail e crie uma senha',
    email: 'E-mail',
    fillValidEmailError: 'Por favor, insira um e-mail válido',
    password: 'Senha',
    fillValidPasswordError: 'Por favor, insira uma senha válida',
    confirmPassword: 'Confirmar senha',
    success: 'Sucesso',
    termsOfUsePart1: 'Ao continuar, você concorda com os nossos',
    termsOfUsePart2: 'Termos e condições',
    termsOfUsePart3: 'e com a ',
    termsOfUsePart4: 'nossa Política de Privacidade.',
    alreadyHaveAccount: 'Já tem uma conta?',
    confirmEmailLoaderMessage: 'Confirmando sua conta...',
    createYourAccount: 'Crie sua conta',
    login: 'Entrar',
    passwordMatch: 'Por favor, certifique-se de que suas senhas correspondam!',
  },
};
