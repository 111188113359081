export type LogoThemeType = 'whiteLongLogo' | 'blueLongLogo' | 'shortLogo';

export interface ILogoTheme {
  alignSelf: string;
  img: string;
}

export const LogoThemeDetails: Record<LogoThemeType, ILogoTheme> = {
  whiteLongLogo: {
    alignSelf: 'self-start',
    img: 'https://shared-assets.tatio.io/images/tatio-logo-white.svg',
  },
  blueLongLogo: {
    alignSelf: 'unset',
    img: 'https://shared-assets.tatio.io/images/tatio-logo-blue.svg',
  },
  shortLogo: {
    alignSelf: 'center',
    img: 'https://shared-assets.tatio.io/images/mobile-tatio-logo.svg',
  },
} as const;
