import styled from 'styled-components';
export const DualInputRangeContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: 30px;
  font-family: var(--primary-font);
  .slider__track,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track {
    border-radius: 20px;
    height: 6px;
  }

  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .slider__left-value,
  .slider__right-value {
    font-family: var(--primary-font);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    width: 45px;
    height: 35px;
    color: var(--almost-black);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
  }

  .slider__left-value {
    left: 0px;
  }

  .slider__right-value {
    right: 0px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-image: url('https://shared-assets.tatio.io/images/twoBgLines.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.3em 0.5em;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 266px;
    outline: none;
  }

  .thumb--zindex-3 {
    z-index: 3;
  }

  .thumb--zindex-4 {
    z-index: 4;
  }

  .thumb--zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: var(--almost-black);
    border: none;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: var(--almost-black);
    border: none;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;
DualInputRangeContainer.displayName = 'Slider.styled';

export const Slider = styled.div`
  position: relative;
  width: 266px;
  .slider__left-value,
  .slider__right-value {
    align-self: flex-end;
    &:hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 78, 0.2);
    }
    &:focus-visible {
      outline: 1px solid var(--almost-black);
    }
  }
`;
Slider.displayName = 'Slider.styled';

export interface ISliderRangeProps {
  minVal: number;
  maxVal: number;
}

export const SliderRange = styled.div<ISliderRangeProps>`
  position: absolute;
  border-radius: 20px;
  height: 6px;
  background-color: var(--almost-black);
  z-index: 2;
  :before {
    position: absolute;
    top: -30px;
    background-color: var(--almost-black);
    font-size: 12px;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 2px;
    color: white;
    content: '${(props) => props.minVal}';
  }
  :after {
    position: absolute;
    top: -30px;
    right: 0px;
    background-color: var(--almost-black);
    font-size: 12px;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 2px;
    color: white;
    content: '${(props) => props.maxVal}';
  }
`;
SliderRange.displayName = 'SliderRange.styled';
