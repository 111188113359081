import React from 'react';
import { areEqual } from 'react-window';
import styled, { CSSProperties, css } from 'styled-components';

import { SMALL_DEVICES } from '../../../constants/breakpoints';

interface CenteredColumnUIProps {
  padding?: CSSProperties['padding'];
}
const CenteredColumn = styled.div<CenteredColumnUIProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 46px;
  ${({ padding }) => padding && `padding: ${padding}`};
`;
CenteredColumn.displayName = 'CenteredColumn.styled';
/**
 * @link https://github.com/bvaughn/react-window/blob/master/src/areEqual.js
 */
const MemoizedCenteredColumn = React.memo(CenteredColumn, areEqual);
export { MemoizedCenteredColumn as CenteredColumn };
export type CenteredColumnProps = typeof CenteredColumn.defaultProps;

export interface ITableRowItemElement {
  $rowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  $mobileRowGridTemplateColumns: CSSProperties['gridTemplateColumns'];
  $borderLess: boolean;
  $index: number; // New prop for index
}

export const tableRowItemHeight = 68;
export const TableRowItemElement = styled.div<ITableRowItemElement>`
  display: grid;
  grid-template-columns: ${({ $rowGridTemplateColumns }) => $rowGridTemplateColumns};
  align-items: center;
  width: 100%;
  background-color: ${({ $index }) =>
    $index === 0 ? 'var(--gray-light)' : 'var(--white)'}; // Conditional background color
  height: ${tableRowItemHeight}px;
  ${({ $borderLess }) => {
    if (!$borderLess) {
      return css`
        border-bottom: 1px solid var(--border-light-grey);
        ${CenteredColumn}:not(:last-child) {
          border-right: 1.2px solid #efefef;
        }
      `;
    }
  }}

  &:hover {
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    z-index: 1; // without z index the shadow is not visible
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    grid-template-columns: ${({ $mobileRowGridTemplateColumns }) => $mobileRowGridTemplateColumns};
    ${CenteredColumn}:not(:last-child) {
      border-right: unset;
    }
  }
`;

TableRowItemElement.displayName = 'TableRowItemElement.styled';

export type TableRowElementProps = typeof TableRowItemElement.defaultProps & Parameters<typeof TableRowItemElement>[0];

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
`;
RelativeContainer.displayName = 'RelativeContainer.styled';
