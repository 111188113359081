import { brandColors } from './constants';
import { CognitoUserInfo, GoogleCognitoUserInfo } from './types';

export const getBrandColorByString = (str: string) => {
  const strIdx = Math.floor(str.charCodeAt(str.length - 1) % (brandColors.length - 1));
  return brandColors[strIdx];
};

export const getCognitoUtmData = (cognitoUser: CognitoUserInfo | GoogleCognitoUserInfo) => {
  let utmMedium = cognitoUser.attributes?.['custom:utm_medium'];
  if ('utmMedium' in cognitoUser) {
    utmMedium = cognitoUser.utmMedium;
  }
  let utmCampaign = cognitoUser.attributes?.['custom:utm_campaign'];
  if ('utmCampaign' in cognitoUser) {
    utmCampaign = cognitoUser.utmCampaign;
  }
  let utmSource = cognitoUser.attributes?.['custom:utm_source'];
  if ('utmSource' in cognitoUser) {
    utmSource = cognitoUser.utmSource;
  }
  return { utmMedium, utmCampaign, utmSource };
};
