export const IOSScrollbar = `
      ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
      }
    
      ::-webkit-scrollbar-thumb {
        min-height: 25px;
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-border-radius: 7px;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
    
      ::-webkit-scrollbar-button {
        display: none;
        width: 0;
        height: 0;
      }
    
      ::-webkit-scrollbar-corner {
        background-color: transparent;
      }`;
