import styled from 'styled-components';
import { zIndices } from '../assets/zIndices';

export const toolTipDirections = {
  up: 'up',
  down: 'down',
  right: 'right',
  left: 'left',
  none: 'none',
} as const;

export type DirectionOptionsOptions = (typeof toolTipDirections)[keyof typeof toolTipDirections];

interface ITooltipProps {
  color?: string;
  fontWeight?: string;
  textColor?: string;
  direction: DirectionOptionsOptions;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

export const Tooltip = styled.div<ITooltipProps>`
  display: flex;
  position: absolute;
  padding: 6px 8px;
  background-color: ${({ color }) => (color ? color : 'var(--almost-black)')};
  border-radius: 4px;
  align-items: center;
  z-index: ${zIndices.sideMenuTooltip};
  font-size: 12px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  white-space: nowrap;
  color: ${({ textColor }) => (textColor ? textColor : 'white')};
  ${({ left }) => (left ? `left: ${left}` : '')};
  ${({ right }) => (right ? `right: ${right}` : '')};
  ${({ top }) => (top ? `top: ${top}` : '')};
  ${({ bottom }) => (bottom ? `bottom: ${bottom}` : '')};
  &::after {
    content: ' ';
    position: absolute;
    left: ${({ direction }) => {
      switch (direction) {
        case 'left': {
          return 'calc(0% - 9px)';
        }
        case 'right': {
          return '100%';
        }
        default: {
          return 'calc(50% - 5px)';
        }
      }
    }};
    top: ${({ direction }) => {
      switch (direction) {
        case 'up': {
          return '-9px';
        }
        case 'down': {
          return '26px';
        }
        default: {
          return 'calc(50% - 5px)';
        }
      }
    }};
    transform: ${({ direction }) => {
      switch (direction) {
        case 'up': {
          return 'rotate(90deg)';
        }
        case 'down': {
          return 'rotate(-90deg)';
        }
        case 'right': {
          return 'rotate(180deg)';
        }
        default: {
          return 'unset';
        }
      }
    }};
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${(props) => (props.direction !== 'none' ? props.color : 'transparent')} transparent
      transparent;
  }
  :hover {
    cursor: default;
  }
  pointer-events: none;
`;
Tooltip.displayName = 'Tooltip.styled';
