import styled from 'styled-components';
import { SHARED_ASSETS_URL } from '../../../utils/constants';

export const NavigationItemElement = styled.li`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  &:hover {
    background-color: var(--light-grey);
  }
  a {
    text-decoration: none;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    &.active {
      background-color: var(--light-grey);
    }
  }
  &::after {
    content: ' ';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: rotate(90deg);
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
`;
NavigationItemElement.displayName = 'NavigationItemElement.styled';

interface IAssetContainer {
  assetName: string;
}
export const AssetContainer = styled.div<IAssetContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-image: ${({ assetName }) => `url(${SHARED_ASSETS_URL}${assetName})`}, var(--blue-gradient);
  background-position: center center;
  background-repeat: no-repeat;
`;
AssetContainer.displayName = 'AssetContainer.styled';

export const NavigationTitle = styled.span`
  font-family: var(--primary-font);
  font-size: 12.5px;
  font-weight: 500;
  line-height: 14.7px;
  margin-left: 10px;
  color: var(--almost-black);
`;
NavigationTitle.displayName = 'NavigationTitle.styled';
