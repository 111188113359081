import styled from 'styled-components';

export const SocialsList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
SocialsList.displayName = 'SocialsList.styled';

export const SocialLink = styled.a`
  display: inline-block;
  height: 20px;
  width: 20px;
  path {
    fill: var(--black);
  }
`;
SocialLink.displayName = 'SocialLink.styled';
