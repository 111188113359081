import { useState } from 'react';
import { faChevronDown, faChevronUp, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { DualInputRange } from './Selectors/DualInputRange/DualInputRange';
import { CustomizedRadioButton, RadioButtonOptionsAndSetter } from './Selectors/PositionSelector/CustomizedRadioButton';
import { SelectorsContainerComponent } from './Selectors/SelectorsContainerComponent/SelectorsContainerComponent';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useAnimatedSwipeY } from '../../hooks/useAnimatedSwipeY';

import {
  ExpansionArrowIcon,
  ExpansionArrowIconWithDarkBackground,
  ExpansionTopBarIcon,
  FieldButton,
  OneOfLayoutTypes,
  RelativeContainer,
} from './style';
import { FilterSelectorsNames, IFilterRange, NumericRange } from './types';
import {
  CustomizedCheckbox,
  CustomizedCheckboxOption,
} from './Selectors/CustomizedCheckboxes/CustomizedCheckbox/CustomizedCheckbox';
import { CustomizedCheckboxWithSearch } from './Selectors/CustomizedCheckboxes/CustomizedCheckboxWithSearch/CustomizedCheckboxWithSearch';
import { DateRangePicker } from './Selectors/DateRangePicker/DateRangePicker';
import { DateRange } from 'react-day-picker';
import { formatDate } from '../../utils';
import { CSSProperties } from 'styled-components';
import ToggleButton from './Selectors/ToggleButton/ToggleButton';
import { TransparentBackDropLayer } from '../BackDropLayer/BackDropLayer';
import {
  CustomizedCheckboxWithLinkAndSearch,
  CustomizedWithLinkAndSearchOption,
} from './Selectors/CustomizedCheckboxes/CustomizedCheckboxWithLinkAndSearch/CustomizedCheckboxWithLinkAndSearch';

export interface ICustomizedFilterFieldProps extends Partial<RadioButtonOptionsAndSetter> {
  name: string;
  type: keyof typeof FilterSelectorsNames;
  zIndexMobilePopup: number;
  zIndexBackdrop: number;
  zIndexFilterField: number;
  selectedSimulationsTypeId?: number;
  initialInputRangeValues?: IFilterRange;
  toggleValue?: boolean;
  initalDateRangeValues?: DateRange | undefined;
  minMax?: NumericRange;
  shouldSyncInputs?: boolean;
  options?: CustomizedCheckboxOption[];
  topBarOptions?: CustomizedWithLinkAndSearchOption[];
  shouldLabelIncludeRange?: boolean;
  layoutType?: OneOfLayoutTypes;
  selectorWrapperStyle?: CSSProperties;
  setNumberRangeFilter?: (filterRange: IFilterRange) => void;
  setDateRangeFilter?: (range: DateRange | undefined) => void;
  updateCheckboxField?: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked?: (category: string, value: string) => boolean;
  setToggleButton?: (val: boolean) => void;
  step?: number;
  isSingleCustomer?: boolean;
}
export const CustomizedFilterField: React.FC<ICustomizedFilterFieldProps> = ({
  name,
  type,
  zIndexMobilePopup,
  zIndexBackdrop,
  zIndexFilterField,
  selectedOption,
  initialInputRangeValues,
  initalDateRangeValues,
  minMax,
  options,
  topBarOptions,
  radioOptions,
  shouldLabelIncludeRange,
  layoutType,
  shouldSyncInputs,
  toggleValue,
  selectorWrapperStyle,
  step,
  setToggleButton,
  setRadioOption,
  setNumberRangeFilter,
  setDateRangeFilter,
  updateCheckboxField,
  isCheckboxOptionChecked,
  isSingleCustomer = true,
}) => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });
  const { display, y, backDropStyle, open, close, bind } = useAnimatedSwipeY(184.5);
  const isAllFiltersType = layoutType === 'allFilters';
  const [desktopFilterDisplay, setDesktopFilterDisplay] = useState(isAllFiltersType);
  const closeFilter = () => setDesktopFilterDisplay(false);
  const componentZIndices = {
    zIndexBackdrop,
    zIndexFilterField,
    zIndexMobilePopup,
  };
  const renderFilterSelector = () => {
    switch (type) {
      case 'dualInputRange':
        if (minMax && setNumberRangeFilter) {
          const { min, max } = minMax;
          return (
            <DualInputRange
              initialValues={initialInputRangeValues}
              min={min}
              max={max}
              onChange={(minMaxObj: IFilterRange) => setNumberRangeFilter(minMaxObj)}
              shouldSyncInputs={shouldSyncInputs}
              step={step}
            />
          );
        } else {
          return null;
        }
      case 'customizedRadioButton':
        if (setRadioOption && radioOptions && typeof selectedOption !== 'undefined') {
          return (
            <CustomizedRadioButton
              setRadioOption={setRadioOption}
              radioOptions={radioOptions}
              selectedOption={selectedOption}
              close={closeFilter}
            />
          );
        } else {
          return null;
        }
      case 'customizedRadioButtonWithSelectionDisplay':
        if (setRadioOption && radioOptions && typeof selectedOption !== 'undefined') {
          return (
            <CustomizedRadioButton
              setRadioOption={setRadioOption}
              radioOptions={radioOptions}
              selectedOption={selectedOption}
              close={closeFilter}
            />
          );
        } else {
          return null;
        }
      case 'customizedCheckbox':
        if (options && updateCheckboxField && isCheckboxOptionChecked) {
          return (
            <CustomizedCheckbox
              options={options}
              updateCheckboxField={updateCheckboxField}
              isCheckboxOptionChecked={isCheckboxOptionChecked}
              layoutType={layoutType}
            />
          );
        } else {
          return null;
        }
      case 'toggleButton':
        if (setToggleButton && typeof toggleValue !== 'undefined') {
          return <ToggleButton value={toggleValue} setValue={setToggleButton} title={name} />;
        } else {
          return null;
        }
      case 'customizedCheckboxWithSearch':
        if (options && updateCheckboxField && isCheckboxOptionChecked) {
          return (
            <CustomizedCheckboxWithSearch
              options={options}
              updateCheckboxField={updateCheckboxField}
              isCheckboxOptionChecked={isCheckboxOptionChecked}
            />
          );
        } else {
          return null;
        }
      case 'customizedCheckboxWithLinkAndSearch':
        if (topBarOptions && updateCheckboxField && isCheckboxOptionChecked) {
          return (
            <CustomizedCheckboxWithLinkAndSearch
              options={topBarOptions}
              updateCheckboxField={updateCheckboxField}
              isCheckboxOptionChecked={isCheckboxOptionChecked}
              zIndex={componentZIndices.zIndexMobilePopup}
            />
          );
        } else {
          return null;
        }
      case 'dateRangePicker':
        if (setDateRangeFilter) {
          return (
            <DateRangePicker
              setDateRangeFilter={setDateRangeFilter}
              range={initalDateRangeValues}
              layoutType={layoutType}
            />
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const renderCountOrFilterRange = () => {
    switch (type) {
      case FilterSelectorsNames.dualInputRange:
        if ((minMax || initialInputRangeValues) && shouldLabelIncludeRange) {
          return (
            <>{`${name} (${initialInputRangeValues?.min || minMax?.min} - ${
              initialInputRangeValues?.max || minMax?.max
            })`}</>
          );
        } else {
          return name;
        }
      case FilterSelectorsNames.dateRangePicker:
        if (initalDateRangeValues?.from && initalDateRangeValues?.to) {
          return <>{`${formatDate(initalDateRangeValues.from)} - ${formatDate(new Date(initalDateRangeValues.to))}`}</>;
        } else {
          return name;
        }
      case FilterSelectorsNames.customizedRadioButtonWithSelectionDisplay:
        if (selectedOption) {
          const radioOptionName = radioOptions?.find((a) => a.value === selectedOption)?.name;
          return <>{radioOptionName}</>;
        } else {
          return name;
        }

      default:
        return name;
    }
  };
  let iconToDisplay = desktopFilterDisplay ? faChevronUp : faChevronDown;
  if (type === 'customizedCheckboxWithLinkAndSearch') {
    iconToDisplay = faFilter;
  }
  return (
    <RelativeContainer>
      {!isAllFiltersType && (
        <FieldButton
          isTopBarFilter={type === 'customizedCheckboxWithLinkAndSearch' ? true : false}
          onClick={() => (!isMobile ? setDesktopFilterDisplay((prevState) => !prevState) : open({ canceled: false }))}
          data-test-id="positionButton"
          hasDarkBackgrond={type === 'customizedRadioButtonWithSelectionDisplay' && !isSingleCustomer}
        >
          {renderCountOrFilterRange()}
          {type === 'customizedCheckboxWithLinkAndSearch' ? (
            <ExpansionTopBarIcon icon={iconToDisplay} />
          ) : type !== 'customizedRadioButtonWithSelectionDisplay' ? (
            <ExpansionArrowIcon icon={iconToDisplay} />
          ) : (
            <ExpansionArrowIconWithDarkBackground icon={iconToDisplay} />
          )}
        </FieldButton>
      )}
      <>
        <SelectorsContainerComponent
          name={name}
          type={type}
          {...componentZIndices}
          desktopFilterDisplay={desktopFilterDisplay}
          display={display}
          y={y}
          style={selectorWrapperStyle}
          backDropStyle={backDropStyle}
          close={close}
          bind={bind}
          data-test-id="core-task-container-title"
          layoutType={layoutType}
        >
          {renderFilterSelector()}
        </SelectorsContainerComponent>
        {!isAllFiltersType && desktopFilterDisplay && (
          <TransparentBackDropLayer
            zIndex={zIndexBackdrop}
            data-test-id="TransparentBackDropLayer"
            onClick={closeFilter}
          />
        )}
      </>
    </RelativeContainer>
  );
};
