import styled from 'styled-components';

import { ButtonOrangeFilled } from '../../components/Reusable-styled-elements/Button';
import { Heading } from '../../components/Reusable-styled-elements/Heading';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import {
  EXTRA_EXTRA_LARGE_DEVICES,
  EXTRA_EXTRA_SMALL_DEVICES,
  EXTRA_LARGE_DEVICES,
  EXTRA_SMALL_DEVICES,
  LARGE_DEVICES,
  MEDIUM_DEVICES,
  SMALL_DEVICES,
} from '@skillset/ui';

export const ResetPasswordFormContainer = styled.div`
  max-width: 458px;
  margin: auto 0;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
    max-width: 410px;
  }

  ${Heading} {
    margin-bottom: 10px;

    @media screen and (max-width: ${MEDIUM_DEVICES}) {
      margin-top: 5px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${SMALL_DEVICES}) {
    margin-top: 55px;
    max-width: 308px;
  }

  @media screen and (max-width: ${EXTRA_SMALL_DEVICES}) {
    max-width: unset;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media screen and (max-width: ${EXTRA_EXTRA_SMALL_DEVICES}) {
    margin-top: 35px;
  }

  ${ButtonOrangeFilled} {
    margin-top: 20px;
  }

  & form {
    width: 100%;
    text-align: left;
    margin-top: 50px;
  }
  ${Subheader} {
    margin-top: 15px;
    color: var(--dark-grey);
    @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
      font-size: 18.5px;
    }
    @media screen and (max-width: ${SMALL_DEVICES}) {
      font-size: 16px;
    }
  }
  ${ButtonOrangeFilled} {
    @media screen and (max-width: ${EXTRA_EXTRA_LARGE_DEVICES}) {
      font-size: 18.5px;
    }

    @media screen and (max-width: ${EXTRA_LARGE_DEVICES}) {
      font-size: 17px;
    }

    @media screen and (max-width: ${LARGE_DEVICES}) {
      font-size: 18.5px;
    }

    @media screen and (max-width: ${MEDIUM_DEVICES}) {
      font-size: 17px;
    }
  }
`;
ResetPasswordFormContainer.displayName = 'ResetPasswordFormContainer.styled';
