export default {
  stage: 'staging',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'skillset-storage-platform-staging-s3bucket-1smfg1fmmc8t2',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://55athaikr7.execute-api.us-east-1.amazonaws.com/platform-staging',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_VR98ic5Pw',
    APP_CLIENT_ID: '530uscg1hrktf925flfdqs0hlb',
    IDENTITY_POOL_ID: 'us-east-1:939810ee-8304-45c3-ad1c-c3b2c0fd21b4',
    GOOGLE_REDIRECT_SIGN_IN: location.origin + '/social-login',
    GOOGLE_REDIRECT_SIGN_OUT: location.origin + '/login',
    GOOGLE_COGNITO_DOMAIN: 'tstaging.auth.us-east-1.amazoncognito.com',
  },
  social: {
    GOOGLE_ANALYTICS: 'UA-182396580-1',
    TAG_MANAGER_ARGS: 'GTM-TP2N8K2',
  },
  kinesisFirehose: {
    REGION: 'us-east-1',
    STREAM_NAME: 'FirehoseDeliveryStream-platform-staging-skillset-analytics',
  },
  webCameraUse: {
    disable: false,
  },
  urls: {
    simulations_url: 'https://simulations-staging.tatio.io/',
    api_documentation: 'developer-staging.tatio.io',
  },
};
