import { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';
import { DualInputRangeContainer, Slider, SliderRange } from './style';
import { IFilterRange } from '../../types';

interface IDualInputRangeProps {
  min: number;
  max: number;
  initialValues?: IFilterRange | null;
  onChange: (minMaxObj: IFilterRange) => void;
  shouldSyncInputs?: boolean;
  step?: number;
}

export const DualInputRange: React.FC<IDualInputRangeProps> = ({
  min,
  max,
  onChange,
  initialValues,
  shouldSyncInputs,
  step,
}) => {
  const [minVal, setMinVal] = useState(initialValues?.hasOwnProperty('min') ? +initialValues.min : min);
  const [maxVal, setMaxVal] = useState(initialValues?.hasOwnProperty('max') ? +initialValues.max : max);

  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);
  // Convert to percentage
  const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current) {
      onChange({ min: minVal, max: maxVal });
    }
  }, [minVal, maxVal]);

  useEffect(() => {
    if (!initialValues || !shouldSyncInputs) return;
    setMinVal(+initialValues?.min);
    setMaxVal(+initialValues?.max);
  }, [initialValues]);

  const handleMinValChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (+target.value === minVal || (+target.value < min && min === minVal)) return;
    const valueToSet = +target.value < min ? min : +target.value;
    const value = Math.min(valueToSet, maxVal - 1);
    setMinVal(value);
    target.value = value.toString();
  };

  const handleMaxValChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (+target.value === maxVal || (+target.value > max && max === maxVal)) return;
    const valueToSet = +target.value > max ? max : +target.value;
    const value = Math.max(valueToSet, minVal + 1);
    setMaxVal(value);
    target.value = value.toString();
  };

  return (
    <DualInputRangeContainer>
      <input
        type="range"
        step={step || max / 100}
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={handleMinValChange}
        className={'thumb thumb--zindex-3 ' + (minVal > max - 100 ? 'thumb--zindex-5' : '')}
      />
      <input
        type="range"
        step={step || max / 100}
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={handleMaxValChange}
        className="thumb thumb--zindex-4"
      />
      <Slider>
        <div className="slider__track"></div>
        <SliderRange ref={range} minVal={minVal} maxVal={maxVal}></SliderRange>
        <input
          className="slider__left-value"
          value={minVal}
          type="number"
          min={min}
          max={max}
          onChange={handleMinValChange}
        ></input>
        <input
          className="slider__right-value"
          value={maxVal}
          type="number"
          min={min}
          max={max}
          onChange={handleMaxValChange}
        ></input>
      </Slider>
    </DualInputRangeContainer>
  );
};
