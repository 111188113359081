import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@aws-amplify/api-rest/node_modules/axios/index';

import { CandidateService, SampleNoteId } from '../../services/candidatesService';
import { WithTypesType } from '../types/WithTypesType';
import { INote } from '../../components/Notes/types';
import { Candidate, WithJobIdOrSimType } from '../types/EmployerCandidatesInterface';
import { startRowRemoveAnimation } from '@skillset/ui';

export const getJobCandidates = createAsyncThunk(
  'employerCandidates/getJobCandidates',
  async (params: { jobId: string; favoritesOnly?: boolean }, thunkAPI) => {
    const { favoritesOnly, jobId } = params;
    try {
      const { payload } = await CandidateService.getAllByJobId(jobId);
      if (favoritesOnly)
        return payload
          .filter((candidate) => candidate.isFavorite)
          .map((item) => {
            return { ...item, showReport: true };
          });
      return payload.map((item) => {
        return { ...item, showReport: true };
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getSimulationCandidates = createAsyncThunk(
  'employerCandidates/getSimulationCandidates',
  async (params: { simulationTypeId: number; favoritesOnly?: boolean }, thunkAPI) => {
    const { simulationTypeId, favoritesOnly } = params;
    try {
      const { payload } = await CandidateService.getAllBySimulationTypeId(simulationTypeId);
      if (favoritesOnly)
        return payload
          .filter((candidate) => candidate.isFavorite)
          .map((item) => {
            return { ...item, showReport: true };
          });
      return payload.map((item) => {
        return { ...item, showReport: true };
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

/* 
The following variables are made for optimistic rendered notes temporarily IDs.
*/
const TEMP_NOTE_ID_PREFIX = 'tempJobNoteId';
let jobNotesTempIdCounter = 0;

export const setCandidateNotes = createAction(
  'employerCandidates/setCandidateNotes',
  (payload: { candidateUUID: string; notes: INote[] }) => {
    return { payload };
  },
);

export const setCandidateFields = createAction(
  'employerCandidates/setCandidateFields',
  (payload: { candidateUUID: string; fields: Partial<Candidate> }) => {
    return { payload };
  },
);
interface CreateCandidatePerJobNote {
  jobId: string;
  note: string;
  candidateUUID: string;
}

export const createCandidatePerJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/createCandidatePerJobNote',
  async (params: CreateCandidatePerJobNote, thunkAPI) => {
    const tempId = `${TEMP_NOTE_ID_PREFIX}${jobNotesTempIdCounter++}`;
    const { jobId, note, candidateUUID } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;
    const { platformUser } = thunkAPI.getState().authSliceReducer;
    const currentNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!platformUser?.personal || !Array.isArray(currentNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes or user personal details');
    }
    const noteToCreate: INote = {
      id: tempId,
      createdAt: new Date(Date.now()).toJSON(),
      authorFirstName: platformUser.personal.firstName,
      authorLastName: platformUser.personal.lastName,
      note,
      isPrivileged: false,
    };
    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: [noteToCreate, ...currentNotes] }));
    try {
      const { payload } = await CandidateService.createCandidatePerJobNote(candidateUUID, jobId, note);
      return { tempId, candidateUUID, createdNoteId: payload.createdNoteId };
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

interface UpdateCandidatePerJobNote extends Omit<CreateCandidatePerJobNote, 'jobId'> {
  noteId: number | SampleNoteId;
}
export const updateCandidatePerJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/updateCandidatePerJobNote',
  async (params: UpdateCandidatePerJobNote, thunkAPI) => {
    const { candidateUUID, noteId, note } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;

    const jobNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!Array.isArray(jobNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    const notesToUpdate = jobNotes.map((currNote) => {
      return currNote.id === noteId ? { ...currNote, note } : currNote;
    });

    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: notesToUpdate }));
    try {
      const { payload } = await CandidateService.updateCandidatePerJobNote(noteId, note);
      return { payload };
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: jobNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

type DeleteCandidatePerJobNote = Omit<UpdateCandidatePerJobNote, 'note'>;

export const deleteCandidatePerJobNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/deleteCandidatePerJobNote',
  async (params: DeleteCandidatePerJobNote, thunkAPI) => {
    const { candidateUUID, noteId } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;
    const currentNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!currentNotes) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes.filter((note) => note.id !== noteId) }));
    try {
      const { payload } = await CandidateService.deleteCandidatePerJobNote(noteId);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

/**
 * optimistically updates isFavorite, reverts on error
 */
export const updateFavoriteCandidateStatus = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/updateFavoriteCandidateStatus',
  async (
    params: {
      candidateUUID: string;
      statusToUpdate: boolean;
      // origin: StarredFrom;
    } & WithJobIdOrSimType,
    thunkAPI,
  ) => {
    const { candidateUUID, statusToUpdate, ...queryParams } = params;

    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;
    const currentStatus = !!candidates.find(({ uuid }) => uuid === candidateUUID)?.isFavorite;
    try {
      thunkAPI.dispatch(setCandidateFields({ candidateUUID, fields: { isFavorite: statusToUpdate } }));

      const { payload } = await CandidateService.updateCandidateFavorite(candidateUUID, statusToUpdate, queryParams);

      return { candidateUUID, updatedStatus: payload.isFavorite };
    } catch (err) {
      thunkAPI.dispatch(setCandidateFields({ candidateUUID, fields: { isFavorite: currentStatus } }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const updateViewedCandidateStatus = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/updateViewedCandidateStatus',
  async (
    params: {
      candidateUUID: string;
    } & WithJobIdOrSimType,
    thunkAPI,
  ) => {
    const { candidateUUID, ...queryParams } = params;
    try {
      await CandidateService.updateCandidateViewed(candidateUUID, queryParams);
      thunkAPI.dispatch(setCandidateFields({ candidateUUID, fields: { isViewed: true } }));

      return { candidateUUID, updatedStatus: true };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

interface CreateCandidatePerSimulationTypeNote {
  simulationTypeId: number;
  note: string;
  candidateUUID: string;
}

export const createCandidatePerSimulationTypeNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/createCandidatePerSimulationTypeNote',
  async (params: CreateCandidatePerSimulationTypeNote, thunkAPI) => {
    const tempId = `${TEMP_NOTE_ID_PREFIX}${jobNotesTempIdCounter++}`;
    const { simulationTypeId, note, candidateUUID } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;
    const { platformUser } = thunkAPI.getState().authSliceReducer;
    const currentNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!platformUser?.personal || !Array.isArray(currentNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes or user personal details');
    }
    const noteToCreate: INote = {
      id: tempId,
      createdAt: new Date(Date.now()).toJSON(),
      authorFirstName: platformUser.personal.firstName,
      authorLastName: platformUser.personal.lastName,
      note,
      isPrivileged: false,
    };
    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: [noteToCreate, ...currentNotes] }));
    try {
      const { payload } = await CandidateService.createCandidatePerSimulationTypeNote(
        candidateUUID,
        simulationTypeId,
        note,
      );
      return { tempId, candidateUUID, createdNoteId: payload.createdNoteId };
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

interface UpdateCandidatePerSimulationTypeNote extends Omit<CreateCandidatePerSimulationTypeNote, 'simulationTypeId'> {
  noteId: number;
}
export const updateCandidatePerSimulationTypeNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/updateCandidatePerSimulationTypeNote',
  async (params: UpdateCandidatePerSimulationTypeNote, thunkAPI) => {
    const { candidateUUID, noteId, note } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;

    const jobNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!Array.isArray(jobNotes)) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    const notesToUpdate = jobNotes.map((currNote) => {
      return currNote.id === noteId ? { ...currNote, note } : currNote;
    });

    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: notesToUpdate }));
    try {
      const { payload } = await CandidateService.updateCandidatePerSimulationTypeNote(noteId, note);
      return { payload };
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: jobNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

type DeleteCandidatePerSimulationTypeNote = Omit<UpdateCandidatePerSimulationTypeNote, 'note'>;

export const deleteCandidatePerSimulationTypeNote = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/deleteCandidatePerSimulationTypeNote',
  async (params: DeleteCandidatePerSimulationTypeNote, thunkAPI) => {
    const { candidateUUID, noteId } = params;
    const { candidates } = thunkAPI.getState().employerCandidatesSliceReducer;
    const currentNotes = candidates.find((c) => c.uuid === candidateUUID)?.notes;
    if (!currentNotes) {
      return thunkAPI.rejectWithValue('Actions has failed finding simulation notes');
    }
    thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes.filter((note) => note.id !== noteId) }));
    try {
      const { payload } = await CandidateService.deleteCandidatePerSimulationTypeNote(noteId);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(setCandidateNotes({ candidateUUID, notes: currentNotes }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const removeFavoriteCandidate = createAction(
  'employerCandidates/removeFavoriteCandidate',
  (payload: { candidateUUID: string; isFavorite: boolean }) => {
    return { payload };
  },
);

/**
 * optimistically updates isFavorite, reverts on error
 */
export const removeFromFavorites = createAsyncThunk.withTypes<WithTypesType>()(
  'employerCandidates/removeFromFavorites',
  async (
    params: {
      candidateUUID: string;
    } & WithJobIdOrSimType,
    thunkAPI,
  ) => {
    const { candidateUUID, ...queryParams } = params;

    try {
      thunkAPI.dispatch(setCandidateFields({ candidateUUID, fields: { isFavorite: false } }));

      startRowRemoveAnimation.set(candidateUUID, true);

      const { payload } = await CandidateService.updateCandidateFavorite(candidateUUID, false, queryParams);
      return thunkAPI.dispatch(removeFavoriteCandidate({ candidateUUID, isFavorite: !!payload.isFavorite }));
    } catch (err) {
      console.error(err);
      startRowRemoveAnimation.set(candidateUUID, false);
      thunkAPI.dispatch(setCandidateFields({ candidateUUID, fields: { isFavorite: true } }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
