import React from 'react';
import { PageHeaderElement, PageMainTitle, PageSubtitle, TitlesColumnContainer } from './style';
import { Link } from 'react-router-dom';
import { ChevronBack } from '../../reusable-elements/ChevronBack';

interface IPageHeaderAndTitlesProps {
  title: string;
  subTitle: string;
}

export const PageHeaderAndTitles: React.FC<IPageHeaderAndTitlesProps> = ({ title, subTitle, children }) => {
  return (
    <PageHeaderElement>
      <TitlesColumnContainer>
        <PageMainTitle>
          {title === 'Jobs' || title === 'Settings' || title === 'Users' ? null : (
            <ChevronBack as={Link} to={`/jobs`} />
          )}{' '}
          {title}
        </PageMainTitle>
        <PageSubtitle>{subTitle}</PageSubtitle>
      </TitlesColumnContainer>
      {children}
    </PageHeaderElement>
  );
};
