import styled from 'styled-components';

import { DayPicker } from 'react-day-picker';

export const StyledDayPicker = styled(DayPicker)`
  font-family: var(--primary-font);
  margin: 0;
  --rdp-caption-font-size: 14px;
  --rdp-cell-size: 32px;
  --rdp-accent-color: var(--dark-grey);
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */

  svg {
    color: var(--grey);
  }

  .rdp-nav_button_previous,
  .rdp-nav_button_next {
    svg {
      width: 14px;
    }
    color: var(--grey);
    padding: 0;
    height: 24px;
    width: 24px;
  }

  .rdp-caption_label {
    font-weight: 600;
    font-size: 14px;
  }

  .rdp-head_cell {
    height: 24px;
  }
  .rdp-day {
    color: var(--almost-black);
    font-size: 14px;
    font-weight: 600;
  }
  .rdp-day_today {
    border-radius: 50px;
    border: 1px solid var(--gray, #ccc);
  }
  .rdp-day_range_start,
  .rdp-day_range_middle,
  .rdp-day_range_end {
    color: white;
  }

  .rdp-day_range_start,
  .rdp-day_range_end {
    position: relative;
    border-radius: 50px;
    background-color: var(--almost-black);
  }

  .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
  }
  .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }
  .start-cell {
    background-color: var(--dark-grey);
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }
  .end-cell {
    background-color: var(--dark-grey);
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }
  .today-cell,
  .middle-cell {
    background-color: var(--dark-grey);
  }
`;
StyledDayPicker.displayName = 'StyledDayPicker.styled';
