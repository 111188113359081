import { API } from '@skillset/onelogin';

import { ISimulationType } from '../store/types/EmployersSimulationSliceInterface';
import { SuccessResponse } from '../utils/types';

const getAll = (customerUUID: string) => {
  return API.get('api', `/employers/simulationType/${customerUUID}`, {}) as Promise<SuccessResponse<ISimulationType[]>>;
};
export const SimulationTypeService = {
  getAll,
};
